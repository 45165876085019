<template>
	<tr
		v-if="columns && data"
		class="table-totals"
		:class="{
			'grand-total': label === t('reportsPage.grand'),
			'shop-totals': label === t('shop'),
			'customer-totals': label === t('reportsPage.customer'),
			'contractor-totals': label === t('reportsPage.contractor')
		}"
	>
		<td v-for="n in fake" :key="n" class="fake-cell" />

		<td
			class="totals-cell-text cell"
			:colspan="
				columns.length - reportsGetters.getDollarColumns(columns).length
			"
		>
			({{ data.length }}) {{ label }} {{ t('reportsPage.totals') }}:
		</td>
		<td
			v-for="col in reportsGetters.getDollarColumns(columns)"
			:key="col.prop"
			class="cell"
		>
			{{ reportsGetters.getColumnsSum(col, data) }}
		</td>
		<td v-if="data[0]['forty_percent']" class="cell">
			{{ reportsGetters.getFortyPercentSum(data) }}
		</td>
		<td
			v-if="data[0]['margin_percentage']"
			:style="{
				color: data['margin_percentage_color'],
				fontWeight: 'bold',
				fontSize: '12px',
				padding: '5px',
				textAlign: 'left'
			}"
			v-html="reportsGetters.getTotalMarginPercentageWithColor(data)"
		></td>
	</tr>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

const { t } = useI18n();

defineProps<{
	label?: string;
	fake?: number;
	columns: any;
	data: any;
}>();
</script>

<style scoped>
.table-totals {
	display: table-row;
}

.table-totals > * {
	text-align: right;
	font-weight: 600;
}

.table-totals .cell {
	border-top: 2px solid #3e94ff;
}

.table-totals .fake-cell:nth-child(2) {
	border-left: 2px solid #00a736;
}

.table-totals.shop-totals .cell {
	border-top: 2px solid #00a736;
}

.table-totals.shop-totals .fake-cell:nth-child(2) {
	border-top: 2px solid #00a736;
	border-left: none !important;
}

.table-totals.customer-totals .cell {
	border-top: 2px solid #928403;
}

.table-totals.customer-totals .fake-cell:nth-child(2) {
	border-top: 2px solid #928403;
	border-left: none !important;
}

.table-totals.contractor-totals .cell {
	border-top: 2px solid #928403;
}

.table-totals.contractor-totals .fake-cell:nth-child(2) {
	border-top: 2px solid #928403;
	border-left: none !important;
}

.table-totals.grand-total .cell {
	border-top: none;
	font-size: var(--font-lg);
}

.table-totals.grand-total .fake-cell:nth-child(2) {
	border-top: none;
	border-left: none !important;
}

.table-totals .cell:last-child {
	border-left: 1px solid #e5e7eb;
}
</style>
