<template>
	<tr class="table-row">
		<td v-for="n in fake" :key="n" class="fake-cell" />
		<td
			v-for="col in columns"
			:key="col.prop"
			class="cell table-cell"
			:class="{
				dollar: col.dollar,
				strong: col.strong,
				wrapped: col.prop === 'customer_name' || col.wrap,
				xAction: col.prop === 'x_action_number',
				memo: col.prop === 'memo'
			}"
		>
			<a
				v-if="col.link"
				:href="
					data[col.prop].status === 'draft'
						? `${baseUrl}/new/${data.id}`
						: `${baseUrl}/${data.id}`
				"
				target="_blank"
				>{{ data[col.prop] }}</a
			>
			<div v-else-if="col.dollar">
				${{ reportsGetters.formatNumber(data[col.prop]) }}
			</div>
			<div v-else-if="col.isArray">
				<span v-for="(item, index) in data[col.prop]" :key="item.name">
					{{ item.name }}{{ index + 1 === data[col.prop].length ? null : ',' }}
				</span>
			</div>
			<div v-else>{{ data[col.prop] }}</div>
		</td>

		<td v-if="data['forty_percent']" style="padding: 5px; font-size: 12px">
			${{ data['forty_percent'] }}
		</td>
		<td
			v-if="data['margin_percentage']"
			:style="{
				color: data['margin_percentage_color'],
				fontWeight: 'bold',
				fontSize: '12px',
				padding: '5px'
			}"
		>
			{{ data['margin_percentage'] }}
		</td>
	</tr>
</template>

<script setup lang="ts">
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

import { TableColumn } from '../../api/types';

defineProps<{
	data: Record<string, any>;
	columns: TableColumn[];
	fake?: number;
}>();

const baseUrl = 'https://pro.glassbiller.com/jobs';
</script>

<style scoped>
.table-row {
	display: table-row;
}
</style>
