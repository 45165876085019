import { useAsyncState } from '@vueuse/core';
import dayjs from 'dayjs';
import { defineStore, storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { reactive, ref, watchEffect } from 'vue';

import { getCsrs, getMany, update, voidMany } from '@/entities/quote/lib/api';
import { Quote, QuoteRequestParams } from '@/entities/quote/lib/types';
import { useUserStore } from '@/entities/user/store';
import { filterEmptyFields } from '@/shared/helpers';

import { filtersInit } from './constants';
import { FiltersWithMetadata, SortBy } from './types';

export const useQuotesStore = defineStore('quotes', () => {
	const toast = useToast();
	const { user } = storeToRefs(useUserStore());

	const selectedQuotes: Quote[] = reactive([]);

	const shops = reactive(user.value.shops || []);
	const selectedShop = ref(
		shops.length
			? shops.find((v: any) => v.id === user.value.user.shop_id)
			: null
	);
	const sort = reactive({ desc: true, field: 'id' });
	const filters = ref<FiltersWithMetadata<Quote>>({
		...filtersInit
	});
	const pagination = reactive({
		page: 1,
		pageSize: 10
	});

	const quotes = useAsyncState(
		(args: QuoteRequestParams) => {
			const sort = args.sortBy as keyof typeof SortBy;
			const searchFilters = filterEmptyFields(
				filters?.value as Record<string, any>,
				'value'
			);

			const applyDate = (fl: any, key: string) => {
				Object.assign(fl, {
					[key]: dayjs(fl.quoteDate as string).format('M/D/YYYY')
				});
			};

			if (searchFilters.quoteDate) {
				applyDate(searchFilters, 'quoteDate');
			}
			if (searchFilters.followupDate) {
				applyDate(searchFilters, 'followupDate');
			}

			return getMany({
				...args,
				filters: searchFilters,
				sortBy: SortBy[sort]
			}).catch(error => {
				toast.add({
					detail: error,
					life: 3000,
					severity: 'error',
					summary: 'Error Message'
				});
				return { numberOfRowsFound: 0, rows: [] };
			});
		},
		{ numberOfRowsFound: 0, rows: [] },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const csrs = useAsyncState(
		(args: number) => {
			return getCsrs(args).catch(error => {
				toast.add({
					detail: error,
					life: 3000,
					severity: 'error',
					summary: 'Error Message'
				});
				return [];
			});
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') {
			return;
		}

		sort.field = sortData.field;
		sort.desc = sortData.desc;
	};

	const composeGetQuotesArgs = async () => {
		const params: any = {
			filters: filters.value,
			limit: pagination.pageSize,
			page: pagination.page,
			sortBy: sort.field,
			sortDesc: sort.desc
		};

		if (selectedShop.value) {
			params.shop = selectedShop.value;
		}

		return params;
	};

	const updateQuoteAndRefetchQuotes = async (
		id: string,
		follow_up_date: Date | null
	) => {
		const formattedDate = follow_up_date
			? dayjs(follow_up_date).format('YYYY-MM-DD')
			: null;
		try {
			await update(id, formattedDate);
			await quotes.execute(0, await composeGetQuotesArgs());
		} catch (error) {
			toast.add({
				detail: error,
				life: 3000,
				severity: 'error',
				summary: 'Error Message'
			});
		}
	};

	const voidQuotesAndRefetchQuotes = async (ids: Array<number>) => {
		try {
			const update = {
				status: 'void',
				void_date: dayjs(new Date()).format('YYYY-MM-DD')
			};
			await voidMany(ids, update);
			await quotes.execute(0, await composeGetQuotesArgs());
		} catch (error) {
			toast.add({
				detail: error,
				life: 3000,
				severity: 'error',
				summary: 'Error Message'
			});
		}
	};

	watchEffect(async () => {
		await Promise.all([
			quotes.execute(0, await composeGetQuotesArgs()),
			csrs.execute(0, selectedShop.value!.organization_id)
		]);
	});

	return {
		composeGetQuotesArgs,
		csrs,
		filters,
		handleSort,
		pagination,
		quotes,
		selectedQuotes,
		selectedShop,
		shops,
		sort,
		updateQuoteAndRefetchQuotes,
		voidQuotesAndRefetchQuotes
	};
});
