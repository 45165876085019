<script setup lang="ts">
import Button from 'primevue/button';
import ColorPicker from 'primevue/colorpicker';
import Menu from 'primevue/menu';
import { computed, ref } from 'vue';

import { blackOrWhiteFromRGBA } from '@/shared/helpers';

const ID_PREFIX = 'overlay_menu_color_picker_';
const WHITE_RGB = 'rgb(255,255,255)';

const props = defineProps<{
	id: string;
	modelValue?: string;
	loading: boolean;
}>();
const emit = defineEmits<{ (e: 'update:modelValue', v: string): void }>();

const localColor = ref();
const currentSelected = computed(() =>
	props.modelValue && props.modelValue !== '' ? props.modelValue : WHITE_RGB
);

const menu = ref<any>();

const openMenu = (e: MouseEvent) => {
	localColor.value = formatRgb(currentSelected.value);
	menu.value?.toggle(e);
};

const save = () => {
	menu.value?.toggle();
	emit(
		'update:modelValue',
		(formatRgb(localColor.value) as string) ?? WHITE_RGB
	);
};

const formatRgb = (v?: { r: string; g: string; b: string } | string) => {
	if (!v) return;

	if (typeof v === 'string') {
		const backgroundColorParts = v
			.replace('rgb(', '')
			.replace('rgba(', '')
			.replace(')', '')
			.split(',');

		return {
			r: backgroundColorParts[0],
			g: backgroundColorParts[1],
			b: backgroundColorParts[2]
		};
	} else {
		return `rgb(${v.r},${v.g},${v.b})`;
	}
};
</script>

<template>
	<div class="tw3-m-auto">
		<Button
			:aria-controls="ID_PREFIX + id"
			aria-haspopup="true"
			:disabled="loading"
			icon="pi pi-palette"
			:loading="loading"
			severity="secondary"
			:style="{
				color: blackOrWhiteFromRGBA(currentSelected),
				backgroundColor: currentSelected,
				borderColor: blackOrWhiteFromRGBA(currentSelected)
			}"
			text
			type="button"
			@click="openMenu"
		/>
		<Menu :id="ID_PREFIX + id" ref="menu" popup>
			<template #start>
				<div
					class="tw3-flex tw3-flex-col tw3-gap-[0.5rem] tw3-min-w-[11rem] tw3-mb-[0.25rem] tw3-mx-[0.75rem] tw3-mt-[0.75rem]"
				>
					<ColorPicker
						v-model="localColor"
						:baseZIndex="51"
						format="rgb"
						inline
						:pt:panel:style="{
							background: 'var(--p-menu-background)',
							borderColor: 'var(--p-menu-border-color)'
						}"
					/>
					<div class="tw3-flex tw3-items-center tw3-justify-between">
						<Button
							label="Close"
							outlined
							size="small"
							type="button"
							@click="() => menu?.toggle()"
						/>
						<Button label="Apply" size="small" type="button" @click="save" />
					</div>
				</div>
			</template>
		</Menu>
	</div>
</template>
