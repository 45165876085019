<template>
	<div style="padding-left: 1rem; padding-right: 1rem">
		<table style="width: 100%">
			<thead>
				<tr
					style="
						border: 0;
						border-bottom: 1px solid;
						height: 2rem;
						font-size: 0.75rem;
						color: #6b7280;
					"
				>
					<td>Invoice date</td>
					<td>Invoice #</td>
					<td>Referral #</td>
					<td>Customer</td>
					<td>Third party</td>
					<td>Days</td>
					<td :class="{ 'pdf-no-display': !anyPaymentsOpen }">Payments</td>
					<td>Total invoice</td>
					<td>Balance due</td>
				</tr>
			</thead>
			<tbody>
				<template v-for="(i, index) in invoices" :key="index + 'row'">
					<tr
						style="height: 2rem; font-size: 0.875rem; color: #4b5563"
						:style="{
							borderBottom: !paymentDetailsOpen[index] ? '1px solid' : 'none',
							border: '0'
						}"
					>
						<td>{{ formatDate(i.invoice_date) }}</td>
						<td>
							<a :href="`/jobs/${i.job_id}`" target="_blank">
								{{ shopId }}-{{ i.job_id }}
							</a>
						</td>
						<td>{{ i.referral_number }}</td>
						<td>{{ i.consumer_company_name || getConsumerName(i) }}</td>
						<td>{{ i.trading_partner }}</td>
						<td>{{ i.days_old }}</td>
						<td :class="{ 'pdf-no-display': !paymentDetailsOpen[index] }">
							<div
								v-if="i.payments.length"
								style="display: flex; align-items: center; cursor: pointer"
								@click="openPayments(index)"
							>
								<i
									:class="
										paymentDetailsOpen[index]
											? 'el-icon-arrow-down'
											: 'el-icon-arrow-right'
									"
									style="font-weight: bold"
								/>
								<div
									style="
										display: flex;
										align-items: center;
										border-radius: 9999px;
										background-color: #f9fafb;
										border: 1px solid #d1d5db;
										box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
										padding-left: 0.5rem;
										padding-right: 0.5rem;
										padding-top: 2px;
										padding-bottom: 2px;
										margin-left: 0.5rem;
									"
								>
									<i class="pi pi-copy" />
									<span style="padding-left: 0.5rem; color: #4b5563">
										{{ i.payments.length }}
									</span>
								</div>
							</div>
						</td>
						<td>${{ i.total_balance }}</td>
						<td>${{ i.remaining_balance }}</td>
					</tr>
					<tr
						v-show="paymentDetailsOpen[index] || expandPayments"
						style="border: 0; border-bottom: 1px solid"
					>
						<td colspan="9">
							<div
								style="
									padding-top: 0.25rem;
									padding-left: 3rem;
									background-color: #f9fafb;
								"
							>
								<table style="width: 500px">
									<thead>
										<tr
											style="height: 2rem; font-size: 0.75rem; color: #6b7280"
										>
											<td>Date</td>
											<td>Type</td>
											<td>X-ActionType</td>
											<td>Amount</td>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="p in i.payments"
											:key="p.id"
											style="
												height: 2rem;
												font-size: 0.875rem;
												color: #4b5563;
												border: 0;
												border-top: 1px solid;
											"
										>
											<td>{{ formatDate(p.date) }}</td>
											<td>{{ p.type }}</td>
											<td>{{ p.x_action_type }}</td>
											<td>${{ p.amount }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, getCurrentInstance, ref, watch } from 'vue';

const props = defineProps<{
	invoices: any[];
	shopId: number;
	expandPayments: boolean;
}>();

const paymentDetailsOpen = ref({});
const anyPaymentsOpen = computed(() => {
	const keys = Object.keys(paymentDetailsOpen.value);
	for (let i = 0; i < keys.length; i++) {
		if (paymentDetailsOpen.value[keys[i]]) {
			return true;
		}
	}
	return false;
});

watch(
	() => props.expandPayments,
	() => {
		if (!props.expandPayments) {
			paymentDetailsOpen.value = {};
		}
	}
);

const formatDate = (date: string) => {
	const dt = new Date(date);
	const dtDateOnly = new Date(
		dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
	);
	return dayjs(dtDateOnly).format('MMM D, YYYY');
};

const getConsumerName = (c: {
	consumer_company_name?: string;
	consumer_first_name: string;
	consumer_last_name?: string;
}) => {
	if (c.consumer_company_name) {
		return c.consumer_company_name;
	}
	let name = c.consumer_first_name;
	if (c.consumer_last_name) {
		name += ' ' + c.consumer_last_name;
	}
	return name;
};

const openPayments = (index: number) => {
	paymentDetailsOpen.value[index] = !paymentDetailsOpen.value[index];
	forceReRender();
};

const instance = getCurrentInstance();

const forceReRender = () => {
	if (instance) {
		instance.proxy?.$forceUpdate(); // Force re-render
	}
};
</script>
