import { VendorComparison } from '../model/enums';
import { VendorsFiltersWithMetadata, VendorTable } from '../model/types';

export const vendorsFilters: VendorsFiltersWithMetadata<VendorTable> = {
	id: { val: null, type: VendorComparison.like },
	name: { val: null, type: VendorComparison.like },
	vendor_type: { val: null, type: VendorComparison.exact },
	invitation_status: { val: null, type: VendorComparison.exact },
	full_address: { val: null, type: VendorComparison.like },
	email: { val: null, type: VendorComparison.like },
	phone: { val: null, type: VendorComparison.like },
	fax: { val: null, type: VendorComparison.like },
	status: { val: null, type: VendorComparison.exact },
	notes: { val: null, type: VendorComparison.like },
	commission: { val: null, type: VendorComparison.like },
	chip_commission: { val: null, type: VendorComparison.like }
};
