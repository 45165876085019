<template>
	<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<rect
			fill="none"
			height="16.5"
			rx="1"
			ry="1"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
			width="10.5"
			x=".75"
			y=".75"
		/>
		<path
			d="M8.25 23.25a1.5 1.5 0 0 1-1.5-1.5M21.75 23.25a1.5 1.5 0 0 0 1.5-1.5M6.75 17.25v1.5M23.25 17.25v1.5M11.25 23.25h2.25M16.5 23.25h2.25M21.75 12.75a1.5 1.5 0 0 1 1.5 1.5M11.25 12.75h2.25M16.5 12.75h2.25M15.439 1.5 13.72 3.22a.749.749 0 0 0 0 1.06L15.439 6M18 8.561l1.72 1.719a.749.749 0 0 0 1.06 0l1.72-1.719"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
		<path
			d="M13.5 3.75h3.75a3 3 0 0 1 3 3v3.75"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		/>
	</svg>
</template>
