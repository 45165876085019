<template>
	<div
		class="tw3-flex tw3-h-full tw3-flex-col tw3-mx-auto tw3-gap-6 tw3-max-w-[1440px] tw3-w-full"
	>
		<template v-if="currentReportType.id === 'aging'">
			<ReportsHeader :hasPermission="userStore.checkUserCan('reportaging')" />
			<ReportsBody v-if="userStore.checkUserCan('reportaging')" />
		</template>
		<template v-else>
			<ReportsHeader :hasPermission="isReportAllowed" />
			<ReportsBody v-if="isReportAllowed" />
		</template>
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

import { useUserStore } from '@/entities/user/store';
import { useReportsStore } from '@/widgets/reportsElements';
import { ReportsBody } from '@/widgets/reportsElements';
import { ReportsHeader } from '@/widgets/reportsElements';

const userStore = useUserStore();

const reportsStore = useReportsStore();
const { currentReportType } = storeToRefs(reportsStore);

const isReportAllowed = computed(() => {
	if (currentReportType.value.permissionId) {
		return userStore.checkUserCan(currentReportType.value.permissionId);
	} else {
		return true;
	}
});

onBeforeRouteLeave(() => {
	reportsStore.resetStoreData();
});
</script>
