import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TableHeader } from '../types';

export const useStorageStore = defineStore(
	'featuresColumnSettings',
	() => {
		const customerColumns = ref<TableHeader[]>([]);
		const vendorColumns = ref<TableHeader[]>([]);

		const updateCustomerColumns = (columns: TableHeader[]) => {
			customerColumns.value = columns;
		};

		const updateVendorColumns = (columns: TableHeader[]) => {
			vendorColumns.value = columns;
		};

		return {
			customerColumns,
			vendorColumns,
			updateCustomerColumns,
			updateVendorColumns
		};
	},
	{ persist: true }
);

export default useStorageStore;
