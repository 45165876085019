import { FilterMatchMode } from '@primevue/core/api';

import { i18n } from '@/shared/i18n';
import { TableFiltersWithMetadata } from '@/shared/types/tables';

import type { RejectedInvoicesFilters } from './types';
import type { RejectedInvoicesTableHeader } from './types';

export const tableFilter: TableFiltersWithMetadata<RejectedInvoicesFilters> = {
	amount: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	customer_name: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	id: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	referral: { matchMode: FilterMatchMode.CONTAINS, value: '' },
	trading_partner: { matchMode: FilterMatchMode.CONTAINS, value: '' }
};

const { t } = i18n.global;

export const rejectedInvoicesHeader: RejectedInvoicesTableHeader[] = [
	{ key: 'id', title: t('rejectedInvoices.columnNames.invoice') },
	{
		key: 'customer_name',
		title: t('rejectedInvoices.columnNames.customer')
	},
	{
		key: 'referral',
		title: t('rejectedInvoices.columnNames.referral')
	},
	{
		key: 'amount',
		title: t('rejectedInvoices.columnNames.amount')
	},
	{
		key: 'trading_partner',
		title: t('rejectedInvoices.columnNames.tradingPartner')
	}
];
