import { formatAsPhoneNumber } from '@/shared/helpers';

import type {
	Burst,
	BurstAPI,
	FaxInbox,
	FaxInboxAPI,
	FaxOutbox,
	FaxOutboxAPI
} from './types';

export const normalizeInbox = (data: FaxInboxAPI[]): FaxInbox[] => {
	return data.map((item: FaxInboxAPI) => {
		return {
			dateTime: item.dateTime,
			faxId: item.faxId,
			fileName: item.filename,
			pages: item.pages,
			referral: item.referralNotes,
			sentFrom: formatAsPhoneNumber(item.sentFrom),
			sentTo: formatAsPhoneNumber(item.sentTo),
			status: item.status,
			url: item.url
		};
	});
};

export const normalizeOutbox = (data: FaxOutboxAPI[]): FaxOutbox[] => {
	return data.map((item: FaxOutboxAPI) => {
		const receiverAsString = item.receiver.toString();
		const senderAsString = item.sender.toString();
		return {
			dateTime: item.date,
			faxId: item.faxsentId,
			fileName: item.file,
			pages: item.pages,
			sentFrom: formatAsPhoneNumber(senderAsString),
			sentTo: formatAsPhoneNumber(receiverAsString),
			status: item.status,
			url: item.fileurl,
			jobid: item.jobid
		};
	});
};

export const normalizeBurst = (data: BurstAPI[]): Burst[] => {
	return data.map((item: BurstAPI) => {
		return {
			...item,
			faxId: item.burstId
		};
	});
};
