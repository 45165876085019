import { i18n } from '@/shared/i18n';

export * from './jobs-table-config';
export * from './jobs-filters-config';
export * from './job-types';
export * from './install-context';

export const ITEMS_PER_REQUEST = 50;
export const TABLE_ID = 'job-table';

export const ediStatuses = [
	{ name: 'Unknown', id: 'unknown' },
	{ name: 'Accepted', id: 'accepted' },
	{ name: 'Paid', id: 'paid' },
	{ name: 'Sent', id: 'sent' },
	{ name: 'Queued', id: 'queued' },
	{ name: 'Rejected', id: 'rejected' },
	{ name: 'Pending', id: 'pending' },
	{ name: 'Unlocked', id: 'unlocked' },
	{ name: 'Unsubmitted', id: 'Unsubmitted' }
];

export const customerTypes = [
	{ name: i18n.global.t('commercial'), id: 1 },
	{ name: i18n.global.t('individual'), id: 0 }
];
