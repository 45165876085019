import { FilterMatchMode } from '@primevue/core';

export const JOBS_FILTERS = {
	'shop.name': { val: null, type: FilterMatchMode.EQUALS },
	job_id: { val: null, type: FilterMatchMode.CONTAINS },
	status: { val: null, type: FilterMatchMode.EQUALS },
	referral_number: { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.name.last': { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.name.first': { val: null, type: FilterMatchMode.CONTAINS },
	parts: { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.email.email': { val: null, type: FilterMatchMode.CONTAINS },
	'ediqueue.status': { val: null, type: FilterMatchMode.EQUALS },
	'consumer.is_commercial': { val: null, type: FilterMatchMode.EQUALS },
	shop_id: { val: null, type: FilterMatchMode.EQUALS },
	quote_date: { val: null, type: FilterMatchMode.EQUALS },
	work_order_date: { val: null, type: FilterMatchMode.EQUALS },
	dispatch_date: { val: null, type: FilterMatchMode.EQUALS },
	invoice_date: { val: null, type: FilterMatchMode.EQUALS },
	follow_up_date: { val: null, type: FilterMatchMode.EQUALS },
	tags: { val: null, type: FilterMatchMode.EQUALS },
	taxes: { val: null, type: FilterMatchMode.EQUALS },
	documents: { val: null, type: FilterMatchMode.CONTAINS },
	notes: { val: null, type: FilterMatchMode.CONTAINS },
	total_materials: { val: null, type: FilterMatchMode.EQUALS },
	total_labor: { val: null, type: FilterMatchMode.EQUALS },
	total_subtotal: { val: null, type: FilterMatchMode.EQUALS },
	total_taxes: { val: null, type: FilterMatchMode.EQUALS },
	total_after_taxes: { val: null, type: FilterMatchMode.EQUALS },
	deductible: { val: null, type: FilterMatchMode.EQUALS },
	total_balance_after_payments: { val: null, type: FilterMatchMode.EQUALS },
	job_csr: { val: null, type: FilterMatchMode.CONTAINS },
	job_salesource: { val: null, type: FilterMatchMode.CONTAINS },
	total_sale_source_commission: { val: null, type: FilterMatchMode.EQUALS },
	job_salesrep: { val: null, type: FilterMatchMode.CONTAINS },
	total_sales_rep_commission: { val: null, type: FilterMatchMode.EQUALS },
	job_tech: { val: null, type: FilterMatchMode.CONTAINS },
	total_tech_commission: { val: null, type: FilterMatchMode.EQUALS },
	job_location: { val: null, type: FilterMatchMode.CONTAINS },
	total_location_commission: { val: null, type: FilterMatchMode.EQUALS },
	commercialaccount_name: { val: null, type: FilterMatchMode.CONTAINS },
	internal_number: { val: null, type: FilterMatchMode.CONTAINS },
	total_cost: { val: null, type: FilterMatchMode.EQUALS },
	customer_rebate: { val: null, type: FilterMatchMode.EQUALS },
	other_costs: { val: null, type: FilterMatchMode.EQUALS },
	total_margin: { val: null, type: FilterMatchMode.EQUALS },
	'consumer.phone.number': { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.address.address': { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.address.zip': { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.address.city': { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.address.state': { val: null, type: FilterMatchMode.CONTAINS },
	'consumer.address.unit': { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.year': { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.make': { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.model': { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.sub_model': { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.style': { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.vin': { val: null, type: FilterMatchMode.CONTAINS },
	insurance_fleet_name: { val: null, type: FilterMatchMode.CONTAINS },
	'bill_to.consumer_edi.trading_partner': {
		val: null,
		type: FilterMatchMode.EQUALS
	},
	loss_date: { val: null, type: FilterMatchMode.EQUALS },
	scheduled_date: { val: null, type: FilterMatchMode.EQUALS },
	install_date: { val: null, type: FilterMatchMode.EQUALS },
	scheduled_time_start: { val: null, type: FilterMatchMode.CONTAINS },
	scheduled_time_end: { val: null, type: FilterMatchMode.CONTAINS },
	'install_g_address.address': { val: null, type: FilterMatchMode.CONTAINS },
	'install_g_address.city': { val: null, type: FilterMatchMode.CONTAINS },
	'install_g_address.state': { val: null, type: FilterMatchMode.CONTAINS },
	'install_g_address.zip': { val: null, type: FilterMatchMode.CONTAINS },
	'install_g_address.unit': { val: null, type: FilterMatchMode.CONTAINS },
	contact_name: { val: null, type: FilterMatchMode.CONTAINS },
	contact_phone1: { val: null, type: FilterMatchMode.CONTAINS },
	contact_phone2: { val: null, type: FilterMatchMode.CONTAINS },
	purchase_order_number: { val: null, type: FilterMatchMode.CONTAINS },
	requisition_order_number: { val: null, type: FilterMatchMode.CONTAINS },
	dot_number: { val: null, type: FilterMatchMode.CONTAINS },
	lot_number: { val: null, type: FilterMatchMode.CONTAINS },
	install_context: { val: null, type: FilterMatchMode.EQUALS },
	policy_number: { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.unit_number': { val: null, type: FilterMatchMode.CONTAINS },
	install_notes: { val: null, type: FilterMatchMode.CONTAINS },
	consumer_id: { val: null, type: FilterMatchMode.CONTAINS },
	pending_schedule: { val: null, type: FilterMatchMode.CONTAINS },
	pending_inspection: { val: null, type: FilterMatchMode.CONTAINS },
	full_job_number: { val: null, type: FilterMatchMode.CONTAINS },
	technician_notes: { val: null, type: FilterMatchMode.CONTAINS },
	'vehicle.plate_number': { val: null, type: FilterMatchMode.CONTAINS },
	vehicle_mileage: { val: null, type: FilterMatchMode.CONTAINS },
	last_updated: { val: null, type: FilterMatchMode.EQUALS },
	salesidejob_id: { val: null, type: FilterMatchMode.CONTAINS },
	warranty_date: { val: null, type: FilterMatchMode.EQUALS },
	warranty_complete_date: { val: null, type: FilterMatchMode.EQUALS },
	recal_date: { val: null, type: FilterMatchMode.EQUALS },
	recal_complete_date: { val: null, type: FilterMatchMode.EQUALS },
	call_back_date: { val: null, type: FilterMatchMode.EQUALS },
	call_back_complete_date: { val: null, type: FilterMatchMode.EQUALS },
	r_and_i_date: { val: null, type: FilterMatchMode.EQUALS },
	r_and_i_complete_date: { val: null, type: FilterMatchMode.EQUALS },
	tint_date: { val: null, type: FilterMatchMode.EQUALS },
	tint_complete_date: { val: null, type: FilterMatchMode.EQUALS },
	pick_up_date: { val: null, type: FilterMatchMode.EQUALS },
	pick_up_complete_date: { val: null, type: FilterMatchMode.EQUALS },
	drop_off_date: { val: null, type: FilterMatchMode.EQUALS },
	drop_off_complete_date: { val: null, type: FilterMatchMode.EQUALS }
};
