import { defineStore, storeToRefs } from 'pinia';
import { reactive, watchEffect } from 'vue';

import { useUserStore } from '@/entities/user/store';

// Organizations of current user
const useOrganizationsStore = defineStore('entitiesOrganizations', () => {
	const { user } = storeToRefs(useUserStore());

	const organizations = reactive(user.value.organizations || []);

	watchEffect(() => {
		organizations.splice(0, organizations.length, ...user.value.organizations);
	});

	return {
		organizations
	};
});

export default useOrganizationsStore;
