<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Chart from 'primevue/chart';
import ProgressSpinner from 'primevue/progressspinner';
import Select from 'primevue/select';
import Timeline from 'primevue/timeline';
import TreeSelect from 'primevue/treeselect';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/entities/user/lib/store';
import DashboardLifetime from '@/widgets/dashboard/DashboardLifetime.vue';
import { formatNumber, formatPrice } from '@/widgets/dashboard/lib/utils';

import { TimeRange } from './lib/enums';
import { useDashboardStore } from './lib/store';
import { DashboardLifetimeData, DashboardMainGraph } from './lib/types';
import {
	calculatePercentageDifference,
	formatDate,
	prettifyPercentageDifference
} from './lib/utils';

const router = useRouter();
const store = useDashboardStore();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const shopOptions = computed(() => [
	{
		key: 'allShops',
		label: 'All shops'
	},
	...user.value.organizations.map(v => ({
		key: 'organizations_' + v.id,
		label: v.name,
		children: user.value.shops
			.filter(s => s.organization_id === v.id)
			.map(s => ({ key: 'shops_' + s.id, label: s.name }))
	}))
]);

const selectedShop = ref({ allShops: true });
const apiSelectedShop = computed(() => {
	let shopIds: number[] = [];
	Object.keys(selectedShop.value).forEach(v => {
		if (v === 'allShops' || v === 'organizations' || v === 'shops') return;
		else {
			const data = v.split('_');
			if (data[0] === 'shops') shopIds.push(Number(data[1]));
			else {
				shopIds = user.value.shops
					.filter(v => v.organization_id === Number(data[1]))
					.map(v => v.id);
			}
		}
	});
	return shopIds.length ? shopIds : undefined;
});

const headTitle = ref('Compare revenue trends over time');
const chartTitle = ref('Revenue and Number of Invoices');
const chartHeaderTooltips = ref([
	{
		data: '$0.00',
		title: 'TTM Revenue',
		desc: 'total amount of invoices'
	}
]);

const selectedMainChart = ref(1);

const mainChart = ref();
const chartOptions = ref();
const numberOfInvoicesChart = ref();
const averageOfInvoicesChart = ref();
const revenueOfInvoicesChart = ref();
const totalCustomerCountChart = ref();

const selectedMode = ref({ name: 'Month', code: TimeRange.MONTH });
const modes = ref([
	{ name: 'Day', code: TimeRange.DAY },
	{ name: 'Week', code: TimeRange.WEEK },
	{ name: 'Month', code: TimeRange.MONTH },
	{ name: 'Year', code: TimeRange.YEAR }
]);

const mainChartLabels = ref<string[]>([]);

const mainChartDesc = computed(() => {
	if (selectedMode.value.code === TimeRange.MONTH)
		return ' for trailing twelve months (ttm)';
	else if (selectedMode.value.code === TimeRange.WEEK)
		return ' for trailing twenty four weeks (ttw)';
	else if (selectedMode.value.code === TimeRange.YEAR)
		return ' for trailing seven years (tsy)';
	else return ' for trailing thirty days (ttd)';
});

const updateChartMode = async (v: number) => {
	selectedMainChart.value = v;
	selectedShop.value = { allShops: true };
	selectedMode.value = { name: 'Month', code: TimeRange.MONTH };
	await nextTick();
	await getAndSetMainChartData();
};

const updateMode = async () => {
	await nextTick();
	await getAndSetMainChartData();
};

const updateShops = async () => {
	await nextTick();
	await getAndSetMainChartData();
};

const getShopName = (id: number) => {
	const index = user.value.shops.findIndex(v => v.id === id);
	return user.value.shops[index]?.name ?? 'Not found';
};

const getAndSetMainChartData = async () => {
	const params = {
		time_range: selectedMode.value.code,
		shop_ids: apiSelectedShop.value
	};
	let headPrefix = '';
	if (selectedMode.value.code === TimeRange.DAY) {
		headPrefix = 'TTD';
	} else if (selectedMode.value.code === TimeRange.MONTH) {
		headPrefix = 'TTM';
	} else if (selectedMode.value.code === TimeRange.WEEK) {
		headPrefix = 'TTW';
	} else if (selectedMode.value.code === TimeRange.YEAR) {
		headPrefix = 'TSY';
	}
	if (selectedMainChart.value === 1) {
		await store.revenueAndInvoicesAmount.execute(0, params);
		const totalRevenue =
			store.revenueAndInvoicesAmount.state.revenue.reduce(
				(acc, v) => acc + v.amount,
				0
			) - store.revenueAndInvoicesAmount.state.revenue[0].amount;
		const totalInvoices =
			store.revenueAndInvoicesAmount.state.invoices.reduce(
				(acc, v) => acc + v.amount,
				0
			) - store.revenueAndInvoicesAmount.state.invoices[0].amount;
		headTitle.value = 'Compare revenue trends over time';
		chartTitle.value = 'Revenue and Number of Invoices';
		chartHeaderTooltips.value = [
			{
				data: formatPrice(formatNumber(totalRevenue)),
				title: headPrefix + ' Revenue',
				desc: 'Total amount of invoices'
			},
			{
				data: formatNumber(totalInvoices),
				title: headPrefix + ' Invoice Count',
				desc: 'Total number of invoices'
			}
		];
		setMainChartTwoArrays(
			store.revenueAndInvoicesAmount.state,
			selectedMode.value.code
		);
	} else if (selectedMainChart.value === 4) {
		await store.filteredNumberOfCustomers.execute(0, params);
		const totalCustomers =
			store.filteredNumberOfCustomers.state.reduce(
				(acc, v) => acc + v.amount,
				0
			) - store.filteredNumberOfCustomers.state[0].amount;
		headTitle.value = 'See you customer count grow over time';
		chartTitle.value = 'Number of customers';
		chartHeaderTooltips.value = [
			{
				data: formatNumber(totalCustomers),
				title: headPrefix + ' Customer Count',
				desc: 'Total number of customers'
			}
		];
		setMainChartArray(
			store.filteredNumberOfCustomers.state,
			selectedMode.value.code,
			'#3B82F6',
			'Customers'
		);
	} else {
		if (selectedMainChart.value === 3) {
			await store.filteredNumberOfInvoices.execute(0, params);
			const totalInvoices =
				store.filteredNumberOfInvoices.state.reduce(
					(acc, v) => acc + v.amount,
					0
				) - store.filteredNumberOfInvoices.state[0].amount;
			headTitle.value = 'See you invoice count grow over time';
			chartTitle.value = 'Number of invoices';
			chartHeaderTooltips.value = [
				{
					data: formatNumber(totalInvoices),
					title: headPrefix + ' Invoice Count',
					desc: 'Total number of invoices'
				}
			];
			setMainChartArray(
				store.filteredNumberOfInvoices.state,
				selectedMode.value.code,
				'#fc6161',
				'Invoices'
			);
		} else {
			await store.filteredAverageOfInvoices.execute(0, params);
			const totalAverageOfInvoices =
				store.filteredAverageOfInvoices.state.reduce(
					(acc, v) => acc + v.amount,
					0
				) - store.filteredAverageOfInvoices.state[0].amount;
			headTitle.value = 'Monitor average invoice size over time';
			chartTitle.value = 'Single invoice amount';
			chartHeaderTooltips.value = [
				{
					data: formatPrice(
						formatNumber(
							totalAverageOfInvoices /
								(store.filteredAverageOfInvoices.state.length - 1),
							true
						)
					),
					title: 'Average Invoice',
					desc: 'Average Invoice'
				}
			];
			setMainChartArray(
				store.filteredAverageOfInvoices.state,
				selectedMode.value.code,
				'#0bd18a',
				'Invoices'
			);
		}
	}
	setChartOptions();
};

const setMainChartArray = (
	data: DashboardLifetimeData[],
	code: TimeRange,
	backgroundColor: string,
	label: string
) => {
	mainChartLabels.value = data.map((_, index) =>
		formatDate(data[data.length - 1 - index].date, code)
	);
	mainChart.value = {
		labels: mainChartLabels.value,
		datasets: [
			{
				label,
				data: data.map((_, index) => data[data.length - 1 - index].amount),
				backgroundColor,
				fill: true,
				barPercentage: 0.5,
				yAxisID: 'y'
			}
		]
	};
};

const setMainChartTwoArrays = (data: DashboardMainGraph, code: TimeRange) => {
	const documentStyle = getComputedStyle(document.documentElement);

	mainChartLabels.value = data.invoices.map((_, index) =>
		formatDate(data.invoices[data.invoices.length - 1 - index].date, code)
	);
	mainChart.value = {
		labels: mainChartLabels.value,
		datasets: [
			{
				label: 'Invoices',
				data: data.invoices.map(
					(_, index) => data.invoices[data.invoices.length - 1 - index].amount
				),
				borderColor: documentStyle.getPropertyValue('--p-gray-300'),
				pointBorderColor: documentStyle.getPropertyValue('--p-gray-300'),
				pointBackgroundColor: documentStyle.getPropertyValue('--p-gray-300'),
				type: 'line',
				fill: false,
				barPercentage: 0.5,
				tension: 0.7,
				yAxisID: 'yRight'
			},
			{
				label: 'Revenue',
				data: data.revenue.map(
					(_, index) => data.revenue[data.revenue.length - 1 - index].amount
				),
				backgroundColor: '#00d0de',
				fill: true,
				barPercentage: 0.5,
				yAxisID: 'y'
			}
		]
	};
};

const setChartOptions = () => {
	const documentStyle = getComputedStyle(document.documentElement);
	const textColorSecondary = documentStyle.getPropertyValue(
		'--p-text-muted-color'
	);

	chartOptions.value = {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						let label = context.dataset.label || '';

						if (label) {
							label += ': ';
						}
						if (context.parsed.y !== null) {
							label +=
								context.dataset.yAxisID === 'yRight' ||
								selectedMainChart.value !== 1
									? formatNumber(context.parsed.y)
									: formatPrice(formatNumber(context.parsed.y));
							if (context.parsed.x > 0) {
								label +=
									' (' +
									prettifyPercentageDifference(
										calculatePercentageDifference(
											context.parsed.y,
											context.dataset.data[context.parsed.x - 1]
										)
									) +
									')';
							}
						}
						return label;
					}
				}
			}
		},
		responsive: true,
		maintainAspectRatio: false,
		hover: {
			mode: 'index'
		},
		scales: {
			y: {
				ticks: {
					color: textColorSecondary,
					callback: function (value: number) {
						return selectedMainChart.value === 1
							? formatPrice(formatNumber(value))
							: formatNumber(value);
					}
				},
				grid: {
					display: false
				}
			},
			...(selectedMainChart.value === 1
				? {
						yRight: {
							position: 'right',
							ticks: {
								color: textColorSecondary,
								callback: function (value: number) {
									return formatNumber(value);
								}
							},
							grid: {
								display: false
							}
						}
					}
				: {}),
			x: {
				ticks: {
					color: textColorSecondary,
					callback: (value: string, index: number) => {
						if (
							selectedMode.value.code !== TimeRange.DAY &&
							selectedMode.value.code !== TimeRange.WEEK
						) {
							return mainChartLabels.value[index];
						}
						const totalTicks = mainChartLabels.value.length;
						if (
							index === 0 ||
							index === Math.floor(totalTicks / 2) ||
							index === totalTicks - 1
						) {
							return mainChartLabels.value[index];
						}
					}
				},
				barPercentage: 0.5,
				grid: {
					display: false
				}
			}
		}
	};
};

const lifetimeData = (
	canvasId: string,
	data: DashboardLifetimeData[],
	color: string
) => {
	const documentStyle = getComputedStyle(document.documentElement);

	let borderColor: CanvasGradient | string =
		documentStyle.getPropertyValue('--p-blue-500');

	const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
	if (canvas) {
		const ctx = canvas.getContext('2d');

		if (ctx) {
			const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
			gradient.addColorStop(0, `rgba(${color}, 0)`);
			gradient.addColorStop(1, `rgba(${color}, 1)`);
			borderColor = gradient;
		}
	}

	return {
		labels: data.map((_, index) => data[data.length - 1 - index].date),
		datasets: [
			{
				label: 'Invoices',
				data: data.map((_, index) => data[data.length - 1 - index].amount),
				borderColor,
				pointBorderColor: 'transparent',
				pointBackgroundColor: 'transparent',
				fill: false,
				barPercentage: 0.5
			}
		]
	};
};

onMounted(async () => {
	const promises: Promise<any>[] = [getAndSetMainChartData()];

	if (store.amountAndRevenueOfInvoices.state.data.length === 0) {
		promises.push(store.amountAndRevenueOfInvoices.execute(0));
	}

	if (store.averageAndNumberOfInvoices.state.data.length === 0) {
		promises.push(store.averageAndNumberOfInvoices.execute(0));
	}

	if (store.totalAndNumberOfCustomers.state.data.length === 0) {
		promises.push(store.totalAndNumberOfCustomers.execute(0));
	}

	if (store.totalAndNumberOfInvoices.state.data.length === 0) {
		promises.push(store.totalAndNumberOfInvoices.execute(0));
	}

	if (store.newestInvoices.state.length === 0) {
		promises.push(store.newestInvoices.execute(0));
	}

	await Promise.all(promises);

	averageOfInvoicesChart.value = lifetimeData(
		'averageOfInvoices',
		store.averageAndNumberOfInvoices.state.data,
		'11, 209, 138'
	);
	numberOfInvoicesChart.value = lifetimeData(
		'numberOfInvoices',
		store.totalAndNumberOfInvoices.state.data,
		'252, 97, 97'
	);
	revenueOfInvoicesChart.value = lifetimeData(
		'revenueOfInvoices',
		store.amountAndRevenueOfInvoices.state.data,
		'0, 208, 222'
	);
	totalCustomerCountChart.value = lifetimeData(
		'customerCount',
		store.totalAndNumberOfCustomers.state.data,
		'59, 130, 246'
	);
});
</script>

<template>
	<div>
		<div
			class="tw3-max-w-[1440px] tw3-m-auto tw3-grid tw3-grid-cols-12 tw3-gap-4 md:tw3-gap-8"
		>
			<DashboardLifetime
				:cardStyle="
					selectedMainChart === 1
						? {
								boxShadow: '0px 6px 20px rgba(0, 208, 222, 0.5) !important'
							}
						: undefined
				"
				:chart="revenueOfInvoicesChart"
				chartId="revenueOfInvoices"
				:differenceNumber="store.amountAndRevenueOfInvoices.state.difference"
				isRevenue
				showTooltip
				title="LIFETIME REVENUE"
				:value="store.amountAndRevenueOfInvoices.state.count"
				:valueStyle="{
					backgroundColor: '#00d0de',
					boxShadow: '0px 6px 20px rgba(0, 208, 222, 0.3)'
				}"
				@click="updateChartMode(1)"
			/>

			<DashboardLifetime
				:cardStyle="
					selectedMainChart === 2
						? {
								boxShadow: '0px 6px 20px rgba(11, 209, 138, 0.5) !important'
							}
						: undefined
				"
				:chart="averageOfInvoicesChart"
				chartId="averageOfInvoices"
				:differenceNumber="store.averageAndNumberOfInvoices.state.difference"
				isRevenue
				title="AVERAGE INVOICE AMOUNT"
				:value="store.averageAndNumberOfInvoices.state.count"
				:valueStyle="{
					backgroundColor: '#0bd18a',
					boxShadow: '0px 6px 20px rgba(11, 209, 138, 0.3)'
				}"
				@click="updateChartMode(2)"
			/>

			<DashboardLifetime
				:cardStyle="
					selectedMainChart === 3
						? {
								boxShadow: '0px 6px 20px rgba(252, 97, 97, 0.5) !important'
							}
						: undefined
				"
				:chart="numberOfInvoicesChart"
				chartId="numberOfInvoices"
				:differenceNumber="store.totalAndNumberOfInvoices.state.difference"
				title="LIFETIME INVOICES"
				:value="store.totalAndNumberOfInvoices.state.count"
				:valueStyle="{
					backgroundColor: '#fc6161',
					boxShadow: '0px 6px 20px rgba(252, 97, 97, 0.3)'
				}"
				@click="updateChartMode(3)"
			/>

			<DashboardLifetime
				:cardStyle="
					selectedMainChart === 4
						? {
								boxShadow: '0px 6px 20px rgba(59, 130, 246, 0.5) !important'
							}
						: undefined
				"
				:chart="totalCustomerCountChart"
				chartId="customerCount"
				:differenceNumber="store.totalAndNumberOfCustomers.state.difference"
				title="LIFETIME CUSTOMERS"
				:value="store.totalAndNumberOfCustomers.state.count"
				:valueStyle="{
					backgroundColor: '#3B82F6',
					boxShadow: '0px 6px 20px rgba(59, 130, 246, 0.3)'
				}"
				@click="updateChartMode(4)"
			/>

			<div class="tw3-col-span-12 lg:tw3-col-span-8">
				<div
					class="card widget-visitor-graph !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-4"
				>
					<div
						class="tw3-font-medium tw3-flex tw3-flex-col sm:tw3-flex-row sm:tw3-justify-between sm:tw3-items-center tw3-leading-loose"
					>
						<span>{{ headTitle }}</span>
						<div
							class="tw3-flex tw3-flex-col sm:tw3-flex-row tw3-items-center tw3-gap-2"
						>
							<TreeSelect
								v-model="selectedShop"
								class="tw3-w-full sm:tw3-w-auto"
								:loading="
									store.revenueAndInvoicesAmount.isLoading ||
									store.filteredNumberOfCustomers.isLoading ||
									store.filteredNumberOfInvoices.isLoading
								"
								:options="shopOptions"
								placeholder="Select an Item"
								@update:model-value="updateShops"
							/>
							<Select
								v-model="selectedMode"
								class="tw3-w-full sm:tw3-w-auto"
								:loading="
									store.revenueAndInvoicesAmount.isLoading ||
									store.filteredNumberOfCustomers.isLoading ||
									store.filteredNumberOfInvoices.isLoading
								"
								optionLabel="name"
								:options="modes"
								@update:model-value="updateMode"
							/>
						</div>
					</div>
					<div
						class="graph-content tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-mt-6"
					>
						<div
							v-for="(item, index) in chartHeaderTooltips"
							:key="index"
							class="tw3-col-span-12 md:tw3-col-span-6"
						>
							<div class="tw3-text-3xl tw3-font-semibold">
								{{ item.data }}
							</div>
							<div class="tw3-font-semibold tw3-my-4">{{ item.title }}</div>
							<p class="tw3-text-surface-500">
								{{ item.desc + mainChartDesc }}
							</p>
						</div>
					</div>

					<div class="graph">
						<div class="tw3-text-xl tw3-font-semibold tw3-my-6">
							{{ chartTitle }}
						</div>

						<Chart
							:data="mainChart"
							:height="590"
							:options="chartOptions"
							type="bar"
						/>
					</div>
				</div>
			</div>

			<div class="tw3-col-span-12 lg:tw3-col-span-4 tw3-mb-[1.25rem]">
				<div
					class="card !tw3-border-0 !tw3-shadow-transparent tw3-rounded-xl tw3-p-0"
				>
					<div
						class="timeline-header tw3-p-4 tw3-flex tw3-justify-between tw3-items-center"
					>
						<p class="tw3-m-0">Newest Invoices</p>
						<div class="header-icons"></div>
					</div>
					<div class="timeline-content tw3-pb-4">
						<div
							v-if="store.newestInvoices.isLoading"
							class="tw3-h-96 tw3-flex tw3-items-center tw3-justify-center"
						>
							<ProgressSpinner />
						</div>
						<Timeline
							v-else
							class="customized-timeline tw3-py-0 tw3-px-4"
							:value="store.newestInvoices.state"
						>
							<template #marker>
								<span
									class="tw3-rounded-full tw3-p-1 tw3-w-8 tw3-h-8 tw3-flex tw3-justify-center tw3-items-center tw3-bg-[#0F8BFD]"
								>
									<i class="pi pi-check"></i>
								</span>
							</template>
							<template #content="slotProps">
								<div class="tw3-flex tw3-items-center tw3-justify-between">
									<a
										class="tw3-m-0"
										href="#"
										@click="router.push(`/jobs/${slotProps.item.jobId}`)"
									>
										{{
											getShopName(slotProps.item.shopId) +
											' #' +
											slotProps.item.jobId
										}}
									</a>
									<h6 class="tw3-m-0 tw3-text-black">
										{{ formatPrice(formatNumber(slotProps.item.total)) }}
									</h6>
								</div>
								<span
									class="tw3-text-sm tw3-text-surface-500 dark:tw3-text-surface-400"
								>
									{{ formatDate(slotProps.item.modified, TimeRange.DAY) }}
								</span>
							</template>
						</Timeline>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
:deep(.customized-timeline) {
	.p-timeline-event:nth-child(even) {
		flex-direction: row !important;

		.p-timeline-event-content {
			text-align: left !important;
		}
	}

	.p-timeline-event-opposite {
		flex: 0;
		padding: 0;
	}

	.p-card {
		margin-top: 1rem;
	}
}
</style>
