export const getDocumentNameByType = (document_type: string) => {
	if (document_type === 'swo') {
		return 'SWO';
	} else if (document_type === 'dis') {
		return 'DIS';
	} else if (document_type === 'pop') {
		return 'PoP';
	} else if (document_type === 'fiv') {
		return 'FIV';
	} else if (document_type === 'quo') {
		return 'QUO';
	} else if (document_type === 'dl') {
		return 'DL';
	} else if (document_type === 'ssmpic') {
		return 'SSMPic';
	} else if (document_type === 'tsmpic') {
		return 'TSMPic';
	} else if (document_type === 'ssign') {
		return 'SSIGN';
	} else if (document_type === 'tsign') {
		return 'TSIGN';
	} else if (document_type === 'oth') {
		return 'OTH';
	} else if (document_type === 'pic') {
		return 'PIC';
	} else if (document_type === 'app') {
		return 'APP';
	} else if (document_type === 'auth') {
		return 'AUTH';
	} else if (document_type === 'recal') {
		return 'RECAL';
	} else if (document_type === 'bill') {
		return 'BILL';
	} else if (document_type === 'bill_payment') {
		return 'BILLPAYMENT';
	} else if (document_type === 'expense') {
		return 'EXPENSE';
	} else if (document_type === 'journal_entry') {
		return 'JOURNALENTRY';
	} else if (document_type === 'esign') {
		return 'ESIGN';
	} else if (document_type === 'smssign') {
		return 'SMSSIGN';
	} else if (document_type === 'payment') {
		return 'PAYMENT';
	} else if (document_type === 'presign') {
		return 'INSPECTION';
	} else if (document_type === 'tsmcnp') {
		return 'TSMCNP';
	}
	return '';
};
