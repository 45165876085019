import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
	getEmailHistory,
	getEmailTemplates
} from '../../reportsElements/api/api';
const useReportsEmailStore = defineStore('reportsEmail', () => {
	const emailTemplatesIsLoading = ref();
	async function getEmailTemplatesHandler(type?: number) {
		emailTemplatesIsLoading.value = true;
		const response = await getEmailTemplates(type);
		emailTemplatesIsLoading.value = false;
		return response;
	}

	const emailHistory = useAsyncState(
		async (docType: string) => {
			return getEmailHistory(docType);
		},
		[],
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);

	return { getEmailTemplatesHandler, emailTemplatesIsLoading, emailHistory };
});

export default useReportsEmailStore;
