<template>
	<div id="accounting-reports">
		<span class="section-header"> {{ t('reportsPage.table.income') }} </span>
		<table class="co-accounts-table">
			<tbody>
				<tr
					v-for="accountDetail in data.incomeAccountDetails"
					:key="accountDetail.id"
				>
					<td class="column-one">{{ accountDetail.name }}</td>
					<td class="column-two"></td>
					<td align="right" class="column-three">
						{{ accountDetail.displayValue }}
					</td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td class="column-two">
						{{ t('reportsPage.total') }} {{ t('reportsPage.table.income') }}
					</td>
					<td align="right" class="column-three">
						<div class="border-top-div black-text">{{ data.totalIncome }}</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span class="section-header"
			>{{ t('reportsPage.table.costOfGoodsSold') }}
		</span>
		<table class="co-accounts-table">
			<tbody>
				<tr
					v-for="accountDetail in data.cogsAccountDetails"
					:key="accountDetail.id"
				>
					<td class="column-one">{{ accountDetail.name }}</td>
					<td class="column-two"></td>
					<td align="right" class="column-three">
						{{ accountDetail.displayValue }}
					</td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td class="column-two">{{ t('reportsPage.total') }} COGS</td>
					<td align="right" class="column-three">
						<div class="border-top-div black-text">{{ data.totalCogs }}</div>
					</td>
				</tr>
				<tr>
					<td class="column-one"><br /></td>
					<td class="column-two"></td>
					<td align="right" class="column-three"></td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td
						:class="{
							'column-two': true,
							'green-text': data.profitMarginPositive,
							'red-text': !data.profitMarginPositive
						}"
					>
						{{ t('reportsPage.table.profitMargin') }} ({{
							data.profitMarginPercent
						}})
					</td>
					<td
						align="right"
						:class="{
							'column-three': true,
							'green-text': data.profitMarginPositive,
							'red-text': !data.profitMarginPositive
						}"
					>
						{{ data.profitMarginValue }}
					</td>
				</tr>
			</tbody>
		</table>
		<span class="section-header">{{ t('reportsPage.table.expenses') }}</span>
		<table class="co-accounts-table">
			<tbody>
				<tr
					v-for="accountDetail in data.expenseAccountDetails"
					:key="accountDetail.id"
				>
					<td class="column-one">{{ accountDetail.name }}</td>
					<td class="column-two"></td>
					<td align="right" class="column-three">
						{{ accountDetail.displayValue }}
					</td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td class="column-two">
						{{ t('reportsPage.total') }} {{ t('reportsPage.table.expenses') }}
					</td>
					<td align="right" class="column-three">
						<div class="border-top-div black-text">
							{{ data.totalExpenses }}
						</div>
					</td>
				</tr>
				<tr>
					<td class="column-one"><br /></td>
					<td class="column-two"></td>
					<td class="column-three"></td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td
						:class="{
							'column-two': true,
							'green-text': data.netProfitPositive,
							'red-text': !data.netProfitPositive
						}"
					>
						{{ t('reportsPage.table.netProfit') }}
					</td>
					<td
						align="right"
						:class="{
							'column-three': true,
							'green-text': data.netProfitPositive,
							'red-text': !data.netProfitPositive
						}"
					>
						{{ data.netProfit }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps<{
	data: any;
}>();

const { t } = useI18n();
</script>

<style scoped>
#accounting-reports .section-header {
	font-weight: bold;
	text-decoration: underline;
	font-size: 16px;
}

#accounting-reports .co-accounts-table {
	width: 93%;
	margin-left: 40px;
	margin-right: auto;
	color: #6b6b6b;
}

#accounting-reports .column-one {
	width: 68%;
}

#accounting-reports .column-two {
	width: 20%;
}

#accounting-reports .column-three {
	width: 12%;
}

#accounting-reports .red-text {
	color: red;
}

#accounting-reports .green-text {
	color: #32c600;
}

#accounting-reports .black-text {
	color: black;
}

#accounting-reports .border-top-div {
	border-top: 1px solid black;
}
</style>
