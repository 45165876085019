<template>
	<tr class="subhead" :class="subheadClass">
		<td v-for="item in fake" :key="item" class="fake-cell"></td>
		<td class="cell" :colspan="columns.length - fake">
			{{ label }}
		</td>
		<td class="cell" colspan="2">
			({{ data.length }}) {{ totalLabel }}: ${{ total }}
		</td>
	</tr>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { ReportType } from '../../api/types';

const { t } = useI18n();

// Props definition
const props = withDefaults(
	defineProps<{
		label: string;
		data: any;
		columns: any;
		type: string;
		extraInfo?: string;
		reportType: ReportType;
		fake?: number;
	}>(),
	{
		extraInfo: '',
		type: 'none',
		fake: 0
	}
);

// Computed properties
const total = computed(() =>
	getTotal(props.reportType?.total_key || '')(props.data)
);

const toDouble = (number: number) => (+number).toFixed(2);

const getTotal = (key: string) => (data: any[]) => {
	const sum = data.reduce((acc, item) => acc + (item[key] || 0), 0);
	return toDouble(sum);
};

const totalLabel = computed(() => {
	switch (props.type) {
		case 'customer_name':
			return `${t('reportsPage.customer')} ${t('reportsPage.total')}`;
		case 'customerName':
			return `${t('reportsPage.customer')} ${t('reportsPage.total')}`;
		case 'name':
			return `${t('reportsPage.contractor')} ${t('reportsPage.total')}`;
		case 'shop_name':
			return `${t('shop')} ${t('reportsPage.total')}`;
		case 'x_action_type':
			return `${t('reportsPage.xType')} ${t('reportsPage.total')}`;
		case 'date':
			return `${t('reportsPage.daily')} ${t('reportsPage.total')}`;
		default:
			return t('reportsPage.total');
	}
});

const subheadClass = computed(() => `table-${props.type}-subhead`);
</script>

<style lang="css" scoped>
.table-customer_name-subhead,
.table-customerName-subhead,
.table-name-subhead,
.table-shop_name-subhead,
.table-x_action_type-subhead,
.table-date-subhead {
	display: table-row;
}

.table-customer_name-subhead > *,
.table-customerName-subhead > *,
.table-name-subhead > *,
.table-shop_name-subhead > *,
.table-x_action_type-subhead > *,
.table-date-subhead > .cell {
	background: #fff;
	font-weight: 600;
}

.table-customer_name-subhead > *,
.table-customerName-subhead > * {
	background: #fdfae0;
	color: #928403;
}

.table-name-subhead > * {
	background: #fdfae0;
	color: #928403;
}

.table-shop_name-subhead > * {
	background: #d1ffd0;
	color: #00a736;
}

.table-x_action_type-subhead > * {
	background: #fdfae0;
	color: #928403;
}

.table-date-subhead .cell {
	background: #fff;
	background: #d0e5ff;
	color: #3e94ff;
	font-weight: 600;
}

.table-customer_name-subhead:last-child,
.table-customerName-subhead:last-child,
.table-name-subhead:last-child,
.table-shop_name-subhead:last-child,
.table-x_action_type-subhead:last-child,
.table-date-subhead:last-child {
	text-align: right;
}

.cell {
	padding: 10px;
	border: 1px solid #ddd;
}

.border-left-customer_name > .fake-cell:first-child,
.border-left-customerName > .fake-cell:first-child,
.border-left-name > .fake-cell:first-child,
.border-left-shop_name > .fake-cell:first-child,
.border-left-x_action_type > .fake-cell:first-child,
.border-left-date > .fake-cell:first-child {
	border-right: 2px solid #00a736;
}
</style>
