<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable, { DataTableRowReorderEvent } from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import ToggleButton from 'primevue/togglebutton';
import { useConfirm } from 'primevue/useconfirm';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useMessages } from '@/shared/composables';
import { isEmptyValue } from '@/shared/helpers';

import {
	allColumnsTableConf,
	defaultTableConf,
	JOB_TABLE_CONFIG,
	JOBS_FILTERS
} from '../../config';
import { useJobsStore } from '../../model/store';
import JobColumnBgPopup from '../popups/JobColumnBgPopup.vue';
import JobColumnFilterPopup from '../popups/JobColumnFilterPopup.vue';

type FilterKey = keyof typeof JOBS_FILTERS;

const { t } = useI18n();

const sortOptions = [
	{ name: t('ascending'), id: false },
	{ name: t('descending'), id: true }
];

const emit = defineEmits<{ (e: 'on-close'): void }>();

const store = useJobsStore();
const {
	viewChanges,
	view,
	updateViewLoading,
	updateViewApiLoading,
	loading,
	jobFiltersData
} = storeToRefs(store);

const message = useMessages();
const confirm = useConfirm();

const createDisabled = computed(() => {
	if (!view.value.selectedViewName) {
		return true;
	}
	const index = jobFiltersData.value.views.findIndex(
		v => v.name === view.value.selectedViewName
	);
	if (
		index !== -1 ||
		view.value.selectedViewName === defaultTableConf.name ||
		view.value.selectedViewName === allColumnsTableConf.name
	) {
		return true;
	}
	return false;
});

const onRowReorder = (event: DataTableRowReorderEvent) => {
	updateViewLoading.value = true;

	setTimeout(() => {
		view.value.selectedColumns = event.value;
		updateViewLoading.value = false;
	}, 100);
};

const getFilterIconClass = (field: string) => {
	const filter = view.value.filters[field as FilterKey];

	return filter && isEmptyValue(filter.val)
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const checkNameNotEmpty = () => {
	if (!view.value.selectedViewName) {
		message.showError(t('viewNotEmptyMsg'));
		return;
	}
};

const createButtonPressed = () => {
	checkNameNotEmpty();
	const index = jobFiltersData.value.views.findIndex(
		v => v.name === view.value.selectedViewName
	);
	if (
		index !== -1 ||
		view.value.selectedViewName === defaultTableConf.name ||
		view.value.selectedViewName === allColumnsTableConf.name
	) {
		message.showError(t('viewUniqueMsg'));
		return;
	}
	store.createOrUpdateView(-1);
};

const removeButtonPressed = async (v: number) => {
	confirm.require({
		accept: async () => {
			await store.deleteView(v);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('removeView'),
		message: t('removeViewConfirm'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};
</script>

<template>
	<Card
		pt:body:class="tw3-overflow-y-auto tw3-h-full"
		pt:content:class=" tw3-overflow-y-auto tw3-h-full"
		pt:root:class="tw3-z-50 tw3-w-full xl:tw3-w-auto tw3-h-full tw3-absolute tw3-right-0 tw3-overflow-y-auto"
	>
		<template #title>
			<div
				class="tw3-flex tw3-items-center tw3-justify-between tw3-gap-3 tw3-overflow-hidden tw3-max-w-full xl:tw3-max-w-[464px]"
			>
				<div
					class="tw3-flex tw3-items-center tw3-gap-3 tw3-overflow-hidden tw3-w-full"
				>
					<span>{{ $t('edit') }}</span>
					<InputText
						v-model="view.selectedViewName"
						class="p-column-filter tw3-w-full !tw3-text-gray-700 !tw3-text-2xl !tw3-font-semibold !tw3-underline"
						:disabled="loading || updateViewLoading || updateViewApiLoading"
						placeholder=""
						style="padding: 0; border-width: 0; box-shadow: none"
						type="text"
					/>
					<span
						v-if="viewChanges"
						class="tw3-text-sm tw3-pt-[3px] tw3-whitespace-nowrap"
						style="color: var(--p-button-text-warn-color)"
					>
						{{ $t('unsavedChanges') }}
					</span>
				</div>
				<Button
					icon="pi pi-times"
					rounded
					severity="secondary"
					style="flex-shrink: 0"
					text
					type="button"
					@click="emit('on-close')"
				/>
			</div>

			<div
				class="tw3-flex tw3-items-center tw3-flex-col sm:tw3-flex-row tw3-gap-2 tw3-mt-5 tw3-max-w-full xl:tw3-max-w-[464px]"
			>
				<div class="tw3-relative tw3-w-full tw3-pt-2 tw3-overflow-x-auto">
					<Select
						class="p-column-filter tw3-w-full"
						:disabled="loading || updateViewLoading || updateViewApiLoading"
						:loading="loading || updateViewLoading || updateViewApiLoading"
						:modelValue="view.sort.field"
						optionLabel="label"
						:options="JOB_TABLE_CONFIG"
						optionValue="key"
						@update:model-value="
							v => store.handleSort({ field: v, desc: view.sort.desc })
						"
					/>
					<span
						class="tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-0 tw3-px-[0.25rem] tw3-rounded"
					>
						{{ $t('sortColumn') }}
					</span>
				</div>

				<div class="tw3-relative tw3-w-full tw3-pt-2 tw3-overflow-x-auto">
					<Select
						class="p-column-filter tw3-w-full"
						:disabled="loading || updateViewLoading || updateViewApiLoading"
						:loading="loading || updateViewLoading || updateViewApiLoading"
						:modelValue="view.sort.desc"
						optionLabel="name"
						:options="sortOptions"
						optionValue="id"
						@update:model-value="
							v => store.handleSort({ field: view.sort.field, desc: v })
						"
					/>
					<span
						class="tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-0 tw3-px-[0.25rem] tw3-rounded"
					>
						{{ $t('sortDirection') }}
					</span>
				</div>

				<div class="tw3-pt-2 tw3-min-w-full sm:tw3-min-w-[7.75rem]">
					<ToggleButton
						class="tw3-w-full"
						:disabled="
							loading ||
							updateViewLoading ||
							updateViewApiLoading ||
							view.selectedView === defaultTableConf.id ||
							view.selectedView === allColumnsTableConf.id
						"
						:modelValue="view.defaultView === view.selectedView"
						offLabel="Set as Default"
						onLabel="Default"
						@update:model-value="() => (view.defaultView = view.selectedView)"
					/>
				</div>
			</div>
		</template>

		<template #content>
			<DataTable
				class="p-datatable"
				pt:tablecontainer:style="scrollbar-width: none"
				scrollable
				scrollHeight="flex"
				:value="view.selectedColumns"
				@row-reorder="onRowReorder"
			>
				<Column headerStyle="width: 3rem" rowReorder />
				<Column field="label" header="Column">
					<template #body="{ data }">
						<p class="md:tw3-whitespace-nowrap">{{ data.label }}</p>
					</template>
				</Column>
				<Column field="hidden" header="Visible">
					<template #body="{ data, index }">
						<Checkbox
							binary
							:disabled="
								data.key === 'job_id' ||
								updateViewLoading ||
								updateViewApiLoading
							"
							:modelValue="!data.hidden"
							@update:model-value="
								v => (view.selectedColumns[index].hidden = !v)
							"
						/>
					</template>
				</Column>
				<Column field="backgroundColor" header="Color">
					<template #body="{ index }">
						<JobColumnBgPopup
							:id="view.selectedColumns[index].key"
							v-model="view.selectedColumns[index].backgroundColor"
							:loading="updateViewLoading || updateViewApiLoading"
						/>
					</template>
				</Column>
				<Column field="filter" header="Filter">
					<template #body="{ data }">
						<JobColumnFilterPopup
							:id="data.key"
							:icon="getFilterIconClass(data.key)"
							:loading="loading || updateViewLoading || updateViewApiLoading"
							:modelValue="view.filters[data.key as FilterKey]"
							:name="data.label"
							@clear="
								() =>
									store.updateFilters({
										...view.filters,
										[data.key as FilterKey]: JOBS_FILTERS[data.key as FilterKey]
									})
							"
							@update:model-value="
								v =>
									store.updateFilters({
										...view.filters,
										[data.key as FilterKey]: v
									})
							"
						/>
					</template>
				</Column>
			</DataTable>
		</template>

		<template #footer>
			<div class="tw3-flex tw3-items-center tw3-gap-2">
				<Button
					v-if="
						view.selectedView !== defaultTableConf.id &&
						view.selectedView !== allColumnsTableConf.id
					"
					:disabled="loading || updateViewLoading || updateViewApiLoading"
					:label="$t('delete')"
					:loading="loading || updateViewLoading || updateViewApiLoading"
					:outlined="loading || updateViewLoading || updateViewApiLoading"
					severity="danger"
					type="button"
					@click="removeButtonPressed(view.selectedView)"
				/>
				<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-ml-auto">
					<Button
						v-if="
							view.selectedView !== defaultTableConf.id &&
							view.selectedView !== allColumnsTableConf.id
						"
						:disabled="
							loading ||
							updateViewLoading ||
							updateViewApiLoading ||
							!viewChanges
						"
						:label="$t('update')"
						:loading="loading || updateViewLoading || updateViewApiLoading"
						:outlined="
							loading ||
							updateViewLoading ||
							updateViewApiLoading ||
							!viewChanges
						"
						severity="info"
						type="button"
						@click="
							() => {
								checkNameNotEmpty();
								store.createOrUpdateView(view.selectedView);
							}
						"
					/>
					<Button
						v-tooltip="createDisabled ? $t('uniqueViewName') : undefined"
						:disabled="
							loading ||
							updateViewLoading ||
							updateViewApiLoading ||
							createDisabled
						"
						:label="$t('create')"
						:loading="loading || updateViewLoading || updateViewApiLoading"
						:outlined="loading || updateViewLoading || updateViewApiLoading"
						type="button"
						@click="createButtonPressed"
					/>
				</div>
			</div>
		</template>
	</Card>
</template>
