<template>
	<ActionPopover buttonContainerClass="tw3-h-full">
		<template #button>
			<Button
				v-tooltip.top="{
					value: 'Export',
					pt: { text: 'tw3-text-center' }
				}"
				class="tw3-min-w-[40px] !tw3-w-full !tw3-text-sm !tw3-h-full"
				:disabled="disabled"
				icon="material-icons md-ios_share"
				:pt:icon="'!tw3-text-xl !tw3-h-auto'"
				severity="secondary"
			/>
		</template>
		<template #content>
			<div class="tw3-flex tw3-gap-2 tw3-flex-col tw3-max-w-[200px]">
				<div class="tw3-flex tw3-flex-col tw3-gap-2">
					<Button
						class="tw3-min-w-[40px] tw3-items-start !tw3-justify-start"
						:disabled="disabled"
						:label="
							orientation === 'portrait'
								? t('reportsPage.filters.portrait')
								: t('reportsPage.filters.landscape')
						"
						severity="secondary"
						text
						@click="
							orientation =
								orientation === 'portrait' ? 'landscape' : 'portrait'
						"
					>
						<template #icon>
							<RotateLandscape
								v-if="orientation === 'portrait'"
								class="tw3-w-[18px]"
							/>
							<RotatePortrait v-else class="tw3-w-[18px]" />
						</template>
					</Button>

					<Divider class="tw3-m-0 !tw3-my-1" />

					<Button
						v-if="currentReportType.id !== 'aging'"
						class="tw3-min-w-[40px] tw3-items-start !tw3-justify-start"
						:disabled="disabled"
						icon="pi pi-print"
						label="Print"
						:loading="isLoading"
						severity="secondary"
						text
						@click="printHandler(true)"
					/>
					<Button
						class="tw3-min-w-[40px] tw3-items-start !tw3-justify-start"
						:disabled="disabled"
						icon="pi pi-file-pdf"
						label="Download Pdf"
						severity="secondary"
						text
						@click.once="printHandler(false)"
					/>
					<Button
						v-if="canCsv"
						class="tw3-min-w-[40px] tw3-items-start !tw3-justify-start"
						:disabled="disabled"
						icon="pi pi-file-excel"
						label="Download Csv/Excel"
						severity="secondary"
						text
						@click="exportCSV"
					/>

					<Button
						v-if="currentReportType.id !== 'aging'"
						class="tw3-min-w-[40px] tw3-items-start !tw3-justify-start"
						:disabled="disabled"
						icon="pi pi-envelope"
						label="Share Via Email"
						:loading="isEmailLoading"
						severity="secondary"
						text
						@click="shareReport"
					/>
				</div>
			</div>
		</template>
	</ActionPopover>

	<ReportShareDialog
		v-if="emailModalData && showEmailDialog"
		v-model:visible="showEmailDialog"
		:data="emailModalData"
	/>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import * as filestack from 'filestack-js';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useAppStore from '@/entities/app/lib/store';
import { CORE_API } from '@/shared/api';
import { useMessages } from '@/shared/composables';
import {
	downloadFile,
	getFileUrlFromArrayBuffer,
	printFile
} from '@/shared/helpers';
import { ActionPopover } from '@/shared/ui';
import { ReportShareDialog } from '@/widgets/reportShareDialog';

import { csvColumns } from '../model/constants';
import useReportsStore from '../model/store';
import { prepForExcelExport } from '../model/utils/excelExport';

import RotateLandscape from './icons/RotateLandscape.vue';
import RotatePortrait from './icons/RotatePortrait.vue';

const messages = useMessages();

defineProps<{
	disabled: any;
}>();

const { t } = useI18n();

const appStore = useAppStore();
const reportsStore = useReportsStore();

const { currentReportType, selectedShop, reportData, isConsumer, tableMode } =
	storeToRefs(reportsStore);

const isLoading = ref(false);
const printHandler = async (forPrint: boolean = false) => {
	let html = document.querySelector('.table-container')?.outerHTML || '';
	html = await replaceImagesWithBase64(html);

	if (!html) {
		messages.showWarning(t('reportsPage.filters.generateAReport'));
		return;
	}
	isLoading.value = true;

	const file = await reportsStore.generatePdf({
		html: html,
		orientation: orientation.value
	});
	if (!file) {
		messages.showError('Error while generating pdf, try again later.');
		isLoading.value = false;
		return;
	}

	const url = getFileUrlFromArrayBuffer(file);

	if (forPrint) {
		printFile(url);
	} else {
		downloadFile(
			url,
			`${dayjs().format('YYYY-MM-DD')} - ${currentReportType.value.label} (${
				selectedShop.value
			}).pdf`
		);
	}
	isLoading.value = false;
};

const canCsv = computed(() => {
	const groups = ['sales', 'payment', 'install-list'];
	const ids = ['sales', 'margin', 'receipt', 'install-list'];

	const AllowForINstallAndAging =
		currentReportType.value.group === 'install' ||
		currentReportType.value.id === 'aging';
	return (
		(groups.includes(currentReportType.value.group as string) &&
			ids.includes(currentReportType.value.id)) ||
		AllowForINstallAndAging
	);
});

const exportCSV = () => {
	if (currentReportType.value.id === 'aging') {
		const name = 'aging';
		const exportType = 'csv';
		const data = [];
		let t = null;
		if (reportsStore.tableMode === 'age') {
			t = reportsStore.reportData.categories;
		}
		if (reportsStore.tableMode === 'consumer') {
			t = reportsStore.reportData.consumers;
		}
		if (t) {
			for (const key in t) {
				for (const i of t[key].invoices) {
					data.push({
						'Invoice Date': i.invoice_date,
						'Invoice #': i.job_id,
						'Referral #': i.referral_number,
						Customer: i.consumer_company_name || getConsumerName(i),
						'Third Party': i.trading_partner,
						Days: i.days_old,
						'Total Invoice': i.total_balance,
						'Balance Due': i.remaining_balance
					});
				}
			}
			if (data.length) {
				exportFromJSON({ data, fileName: name, exportType });
			}
		}
	} else if (currentReportType.value.group === 'install') {
		if (!tableMode.value) return;
		const excelData = prepForExcelExport({
			type: currentReportType.value.id,
			data: reportData.value,
			mode: tableMode.value
		});
		const fileName = currentReportType.value.id;
		const exportType = 'csv';
		exportFromJSON({ data: excelData, fileName, exportType });
	} else {
		const dateTime = dayjs().format('MM/DD/YYYY hh:mm A');
		const id = currentReportType.value.id;
		const group = currentReportType.value?.group || '';
		const columns = csvColumns[group][id];
		const keys = Object.keys(columns);

		if (reportData.value) {
			const exportData = [];

			reportData.value.forEach(row => {
				const exportRow = {};

				keys.forEach(key => {
					if (row[key]) {
						exportRow[columns[key].label] = row[key];
					} else if (!key.includes('date')) {
						exportRow[columns[key].label] = columns[key].dollar ? 0 : '';
					}
				});

				exportData.push(exportRow);
			});

			exportFromJSON({
				data: exportData,
				fileName: `${currentReportType.value.label} - ${dateTime}`,
				exportType: 'csv'
			});
		}
	}
};

const getConsumerName = c => {
	if (c.consumer_company_name) {
		return c.consumer_company_name;
	}
	let name = c.consumer_first_name;
	if (c.consumer_last_name) {
		name += ' ' + c.consumer_last_name;
	}
	return name;
};

const isEmailLoading = ref(false);
const shareReport = async () => {
	isEmailLoading.value = true;

	await appStore.appConfig.execute(0);
	const client = filestack.init(appStore.appConfig.state.filePickerKey);
	let html = document.querySelector('.table-container')?.outerHTML || '';
	html = await replaceImagesWithBase64(html);

	let consumerCommRequest;
	if (isConsumer.value && currentReportType.value.group === 'statements') {
		try {
			consumerCommRequest = CORE_API.get(`pieces/${isConsumer.value}/emails`);
		} catch (error) {
			messages.showError(t('reportsPage.filters.errorFetchingConsumer'));
		}
	}

	const response = await reportsStore.generatePdf({
		html: html,
		orientation: orientation.value
	});
	if (!response) {
		messages.showError('Error while generating pdf, try again later.');
		isEmailLoading.value = false;
		return;
	}

	const dataBlob = new Blob([response], { type: 'application/pdf' });

	const dateTime = dayjs().format('MM/DD/YYYY hh:mm A');
	const filename = `${currentReportType.value.label} - ${dateTime}`;
	const fileType = 'report';

	const { name, url } = await client.upload(dataBlob, {}, { filename });

	isEmailLoading.value = false;

	let consumerCommData: Record<string, any> = {};

	if (isConsumer.value && currentReportType.value.group === 'statements') {
		try {
			const { data: retData } = await consumerCommRequest!;
			consumerCommData = { options: retData };
		} catch (error) {
			messages.showError(t('reportsPage.filters.errorFetchingConsumer'));
		}
	}

	openEmail({
		fileObj: { name, url },
		fileType,
		shops: selectedShop.value ? selectedShop.value : [],
		...consumerCommData
	});
};

const showEmailDialog = ref<boolean>(false);
const emailModalData = ref();
const openEmail = (params: {
	fileObj: {
		name: string;
		url: string;
	};
	fileType: string;
	shops: number[];
}) => {
	emailModalData.value = { ...params };
	showEmailDialog.value = true;
};

const orientationOption = [
	{
		value: 'portrait',
		name: t('reportsPage.filters.portrait'),
		icon: 'material-icons md-crop_portrait'
	},
	{
		value: 'landscape',
		name: t('reportsPage.filters.landscape'),
		icon: 'material-icons md-crop_landscape'
	}
];
const orientation = ref<string>(orientationOption[0].value);

async function convertImgToBase64(url: string) {
	const response = await fetch(url);
	const blob = await response.blob();
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
}

async function replaceImagesWithBase64(htmlString: string) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');
	const images = doc.querySelectorAll('img');

	for (const img of images) {
		if (img.src.startsWith('http')) {
			img.src = await convertImgToBase64(img.src);
		}
	}

	return doc.documentElement.outerHTML;
}
</script>
