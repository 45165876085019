// This is the shared store with major accounting entites that all accounting pages may use:
// - Accounting profiles
// - Account types and subtypes
// - Accounts
import { useAsyncState } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { computed, reactive, ref, watch, watchEffect } from 'vue';

import {
	listAccount,
	listAccountSubtype,
	listAccountType
} from '@/entities/accounting/accounts/lib/api';
import {
	Account,
	AccountStatus,
	AccountSubtype,
	AccountType
} from '@/entities/accounting/accounts/lib/types';
import { listProfile } from '@/entities/accounting/profiles/lib/api';
import { Profile } from '@/entities/accounting/profiles/lib/types';
import { Shop } from '@/entities/shop/lib/types';
import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';

const useAccountingStore = defineStore('featuesAccounting', () => {
	const { showError } = useMessages();

	const loadingCounter = ref(0);
	const loading = computed(() => {
		return loadingCounter.value > 0;
	});

	const selectedProfileId = ref<number | undefined>(undefined);
	const profilesLoaded = ref(false);

	const profilesState = useAsyncState(
		() => {
			loadingCounter.value++;
			return listProfile()
				.catch(error => {
					showError(error);
					return [] as Profile[];
				})
				.then(profiles => {
					profilesLoaded.value = true;
					if (profiles.length > 0 && selectedProfileId.value == undefined) {
						selectedProfileId.value = profiles[0].organizationId;
					}
					return profiles;
				})
				.finally(() => {
					loadingCounter.value--;
				});
		},
		[] as Profile[],
		{
			immediate: true,
			resetOnExecute: false
		}
	);

	const loadProfiles = async () => {
		await profilesState.execute(0);
	};

	const statuses = reactive([
		{ name: 'Active', id: AccountStatus.ACCOUNT_STATUS_ACTIVE },
		{ name: 'Inactive', id: AccountStatus.ACCOUNT_STATUS_DISABLED }
	]);

	const statusesById = computed(() => {
		const res: Record<string, string> = {};
		statuses.forEach(status => {
			res[status.id] = status.name;
		});
		return res;
	});

	const typesState = useAsyncState(
		() => {
			loadingCounter.value++;
			return listAccountType()
				.catch(error => {
					showError(error);
					return [];
				})
				.finally(() => {
					loadingCounter.value--;
				});
		},
		[],
		{
			immediate: true,
			resetOnExecute: false
		}
	);

	const typesById = computed(() => {
		const res: Record<string, AccountType> = {};
		typesState.state.value.forEach(type => {
			res[type.id] = type;
		});
		return res;
	});

	const subtypesState = useAsyncState(
		() => {
			loadingCounter.value++;
			return listAccountSubtype()
				.catch(error => {
					showError(error);
					return [];
				})
				.finally(() => {
					loadingCounter.value--;
				});
		},
		[],
		{
			immediate: true,
			resetOnExecute: false
		}
	);

	const subtypesById = computed(() => {
		const res: Record<string, AccountSubtype> = {};
		subtypesState.state.value.forEach(subtype => {
			res[subtype.id] = subtype;
		});
		return res;
	});

	const groupedSubtypes = computed(() => {
		const res: Record<string, any[]> = {};
		subtypesState.state.value.forEach(subtype => {
			if (!res[subtype.accountTypeName]) {
				res[subtype.accountTypeName] = [];
			}
			res[subtype.accountTypeName].push(subtype);
		});
		return Object.keys(res)
			.map(key => {
				return {
					id: res[key][0].accountTypeId,
					name: res[key][0].accountTypeName,
					items: res[key]
				};
			})
			.sort((a, b) => a.id - b.id);
	});

	const accountsState = useAsyncState(
		(organizationId: number) => {
			loadingCounter.value++;
			return listAccount(organizationId)
				.catch(error => {
					showError(error);
					return [];
				})
				.finally(() => {
					loadingCounter.value--;
				});
		},
		[] as Account[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const loadAccounts = async () => {
		if (!selectedProfileId.value) {
			return;
		}
		await accountsState.execute(0, selectedProfileId.value);
	};

	watch(
		selectedProfileId,
		async () => {
			await loadAccounts();
		},
		{ immediate: true }
	);

	const { user } = storeToRefs(useUserStore());

	const shops = ref<Shop[]>(user.value.shops || []);

	watchEffect(() => {
		shops.value = user.value.shops.filter(
			shop => shop.organization_id === selectedProfileId.value
		);
	});

	const shopsById = computed(() => {
		const res: Record<string, Shop> = {};
		shops.value.forEach(shop => {
			res[shop.id] = shop;
		});
		return res;
	});

	return {
		loading,
		statuses,
		statusesById,
		types: typesState.state,
		typesById,
		subtypes: subtypesState.state,
		subtypesById,
		groupedSubtypes,
		accounts: accountsState.state,
		loadAccounts,
		selectedProfileId,
		profiles: profilesState.state,
		profilesLoaded,
		loadProfiles,
		shops,
		shopsById
	};
});

export default useAccountingStore;
