<template>
	<Button
		:class="{
			'tw3-w-full': !isPc,
			'tw3-w-fit !tw3-absolute tw3-m-auto tw3-bottom-[-27px] tw3-left-0 tw3-right-0 !tw3-bg-white !tw3-text-[0.75rem]':
				isPc
		}"
		:icon="additionalFilterIsVisible ? 'pi pi-angle-up' : 'pi pi-angle-down'"
		iconPos="right"
		label="Additional Filters"
		outlined
		severity="secondary"
		size="small"
		@click="additionalFilterIsVisible = !additionalFilterIsVisible"
	/>

	<div class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1">
		<p class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm">
			{{ capitalizeStr(t('reportsPage.filters.filterBy.csrs')) }}
		</p>
		<ReportsSelectFilter
			v-model="selectedCsrs"
			v-tooltip.top="
				!reportsStore.selectedShop || !reportsStore.selectedShop.length
					? 'Shop is required'
					: null
			"
			:disabled="
				!reportsStore.selectedShop || !reportsStore.selectedShop.length
			"
			:dropdownOptions="csrOptions"
			filter
			:loading="contractorFilterOptionsLoading"
			:maxSelectedLabels="1"
			optionGroupChildren="items"
			optionGroupLabel="label"
			optionLabel="name"
			optionValue="id"
			:placeholder="`${t('select')} ${t('reportsPage.filters.csrs')}...`"
			@before-show="refreshOptions"
		/>
	</div>

	<template v-if="additionalFilterIsVisible">
		<span
			v-if="
				currentReportType.id !== 'receipt' &&
				currentReportType.id !== 'margin' &&
				isPc
			"
		></span>

		<div class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1">
			<p class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm">
				{{ capitalizeStr(t('reportsPage.filters.filterBy.salesources')) }}
			</p>
			<ReportsSelectFilter
				v-model="selectedSalesources"
				v-tooltip.top="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
						? 'Shop is required'
						: null
				"
				dataKey="id"
				:disabled="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
				"
				:dropdownOptions="salesourcesOptions"
				filter
				:loading="contractorFilterOptionsLoading"
				:maxSelectedLabels="1"
				optionGroupChildren="items"
				optionGroupLabel="label"
				optionLabel="name"
				optionValue="id"
				:placeholder="`${t('select')} ${t('reportsPage.filters.salesources').toLocaleLowerCase()}...`"
			/>
		</div>
		<span v-if="currentReportType.id == 'receipt' && isPc"></span>

		<div class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1">
			<p class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm">
				{{ capitalizeStr(t('reportsPage.filters.filterBy.salesreps')) }}
			</p>
			<ReportsSelectFilter
				v-model="selectedSalesreps"
				v-tooltip.top="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
						? 'Shop is required'
						: null
				"
				dataKey="id"
				:disabled="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
				"
				:dropdownOptions="salesrepsOptions"
				filter
				:loading="contractorFilterOptionsLoading"
				:maxSelectedLabels="1"
				optionGroupChildren="items"
				optionGroupLabel="label"
				optionLabel="name"
				optionValue="id"
				:placeholder="`${t('select')} ${t('reportsPage.filters.salesreps').toLocaleLowerCase()}...`"
			/>
		</div>

		<div class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1">
			<p class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm">
				{{ capitalizeStr(t('reportsPage.filters.filterBy.techs')) }}
			</p>
			<ReportsSelectFilter
				v-model="selectedTechs"
				v-tooltip.top="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
						? 'Shop is required'
						: null
				"
				dataKey="id"
				:disabled="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
				"
				:dropdownOptions="techsOptions"
				filter
				:loading="contractorFilterOptionsLoading"
				:maxSelectedLabels="1"
				optionGroupChildren="items"
				optionGroupLabel="label"
				optionLabel="name"
				optionValue="id"
				:placeholder="`${t('select')} ${t('reportsPage.filters.techs').toLocaleLowerCase()}...`"
			/>
		</div>

		<div class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1">
			<p class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm">
				{{ capitalizeStr(t('reportsPage.filters.filterBy.locations')) }}
			</p>
			<ReportsSelectFilter
				v-model="selectedLocations"
				v-tooltip.top="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
						? 'Shop is required'
						: null
				"
				dataKey="id"
				:disabled="
					!reportsStore.selectedShop || !reportsStore.selectedShop.length
				"
				:dropdownOptions="locationsOptions"
				filter
				:loading="contractorFilterOptionsLoading"
				:maxSelectedLabels="1"
				optionGroupChildren="items"
				optionGroupLabel="label"
				optionLabel="name"
				optionValue="id"
				:placeholder="`${t('select')} ${t('reportsPage.filters.locations').toLocaleLowerCase()}...`"
			/>
		</div>
	</template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useWindowSizes } from '@/shared/composables';
import { capitalizeStr } from '@/shared/helpers';
import useReportsStore from '@/widgets/reportsElements/model/store';

import { allFiltersTypes } from '../../model/constants';

import ReportsSelectFilter from './ReportsSelectFilter.vue';

const { t } = useI18n();

const reportsStore = useReportsStore();
const {
	contractorFilterTypes,
	contractorFilterOptions,
	contractorFilterValue,
	contractorFilterOptionsLoading,
	additionalFilterIsVisible,
	currentReportType
} = storeToRefs(reportsStore);

const { isPc } = useWindowSizes();

interface Organization {
	id: number;
	name: string;
	salesources?: Option[];
	csrs?: Option[];
	salesreps?: Option[];
	techs?: Option[];
	locations?: Option[];
}

interface Option {
	id: number;
	name: string;
}

const selectedCsrs = ref<number[]>([]);
const selectedSalesources = ref<number[]>([]);
const selectedSalesreps = ref<number[]>([]);
const selectedTechs = ref<number[]>([]);
const selectedLocations = ref<number[]>([]);

const groupOptionsByOrganization = (key: keyof Organization) => {
	const groupedOptions: { label: string; items: Option[] }[] = [];
	contractorFilterOptions.value.forEach(shop => {
		const organization = shop.organization;
		const options = organization ? organization[key] : false;
		if (options && Array.isArray(options)) {
			groupedOptions.push({
				label: organization?.name || '',
				items: options
			});
		}
	});
	return groupedOptions;
};

const csrOptions = computed(() => groupOptionsByOrganization('csrs'));
const salesourcesOptions = computed(() =>
	groupOptionsByOrganization('salesources')
);
const salesrepsOptions = computed(() =>
	groupOptionsByOrganization('salesreps')
);
const techsOptions = computed(() => groupOptionsByOrganization('techs'));
const locationsOptions = computed(() =>
	groupOptionsByOrganization('locations')
);

const someFiltersIsActive = computed(() => {
	return (
		selectedCsrs.value.length !== 0 ||
		selectedSalesources.value.length !== 0 ||
		selectedSalesreps.value.length !== 0 ||
		selectedTechs.value.length !== 0 ||
		selectedLocations.value.length !== 0
	);
});

const activeFiltersList = computed<string[]>(() => {
	const preparedArr = [];
	if (selectedCsrs.value.length !== 0) {
		preparedArr.push('csrs');
	}
	if (selectedSalesources.value.length !== 0) {
		preparedArr.push('salesources');
	}
	if (selectedSalesreps.value.length !== 0) {
		preparedArr.push('salesreps');
	}
	if (selectedTechs.value.length !== 0) {
		preparedArr.push('techs');
	}
	if (selectedLocations.value.length !== 0) {
		preparedArr.push('locations');
	}
	return [];
});

watch(activeFiltersList, () => {
	contractorFilterTypes.value = activeFiltersList.value;
});

const combinedFilter = computed(() => {
	let preparedObj: { [key: string]: (number | string)[] } = {
		csrs: [],
		salesources: [],
		salesreps: [],
		techs: [],
		locations: []
	};
	if (someFiltersIsActive.value) {
		preparedObj = {
			csrs: selectedCsrs.value.length ? selectedCsrs.value : [],
			salesources: selectedSalesources.value.length
				? selectedSalesources.value
				: [],
			salesreps: selectedSalesreps.value.length ? selectedSalesreps.value : [],
			techs: selectedTechs.value.length ? selectedTechs.value : [],
			locations: selectedLocations.value.length ? selectedLocations.value : []
		};
	}
	return preparedObj;
});

const setDefaultFiltersValues = () => {
	selectedSalesources.value = [];
	selectedSalesreps.value = [];
	selectedTechs.value = [];
	selectedLocations.value = [];
	if (!reportsStore.selectedShop || !reportsStore.selectedShop.length) {
		selectedCsrs.value = [];
	}
};

const refreshOptions = () => {
	if (!reportsStore.contractorFilterOptions.length) {
		reportsStore.contractorFilterOptionsHandler.execute(
			0,
			additionalFilterIsVisible.value ? allFiltersTypes : ['csrs']
		);
	} else if (!salesourcesOptions.value.length) {
		reportsStore.contractorFilterOptionsHandler.execute(0, allFiltersTypes);
	}
	if (!additionalFilterIsVisible.value) {
		setDefaultFiltersValues();
	}
};

watch(
	() => reportsStore.selectedShop,
	() => {
		if (!reportsStore.selectedShop || !reportsStore.selectedShop.length) {
			setDefaultFiltersValues();
		}
	}
);

watch(contractorFilterValue, () => {
	if (contractorFilterValue.value.length === 0) {
		setDefaultFiltersValues();
	}
});

watch(combinedFilter, () => {
	contractorFilterValue.value = combinedFilter.value;
});

watch(additionalFilterIsVisible, refreshOptions);
</script>
