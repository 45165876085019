<template>
	<DataTable
		v-model:expandedRows="expandedRows"
		class="tw3-h-full"
		dataKey="sent"
		lazy
		paginator
		:rows="pageSize"
		scrollable
		scrollHeight="flex"
		tableClass="tw3-w-full tw3-h-full tw3-max-h-full"
		:totalRecords="emailStore.emailHistory.state.length"
		:value="emailStore.emailHistory.isLoading ? skeletonArray : preparedHistory"
		@page="handlePage"
	>
		<Column expander style="width: 3rem" />
		<Column
			v-for="column of currentHeaders"
			:key="column.key"
			:field="column.key"
			:header="column.title"
		>
			<template #body="{ data }">
				<div class="cell-container">
					<Skeleton v-if="emailStore.emailHistory.isLoading" />
					<template v-else>
						<div
							v-if="column.key === 'sent_documents'"
							class="tw3-flex tw3-gap-2 tw3-flex-wrap"
						>
							<Button
								v-for="(attachment, index) in data[column.key]"
								:key="index"
								icon="pi pi-file"
								:label="attachment.document.document_type"
								severity="secondary"
								size="small"
								@click="openFilePreview(attachment.url, 'report')"
							/>
						</div>
						<span
							v-else-if="column.key === 'status' && data.email_statuses.length"
						>
							<Button
								class="tw3-uppercase"
								:label="data.email_statuses[0].event"
								severity="secondary"
								size="small"
								@click="displayStatus($event, data)"
							/>
						</span>
						<span v-else>{{ data[column.key] }}</span>
					</template>
				</div>
			</template>
		</Column>
		<template #expansion="slotProps">
			<div v-if="slotProps.data.subject" class="tw3-flex tw3-mb-4 tw3-gap-2">
				<p class="tw3-font-bold">{{ t('subject') }}:</p>
				<p>{{ slotProps.data.subject }}</p>
			</div>
			<div v-if="slotProps.data.body" class="tw3-flex tw3-gap-2">
				<p class="tw3-font-bold">{{ t('body') }}:</p>
				<div v-html="slotProps.data.body"></div>
			</div>
		</template>
	</DataTable>

	<Popover ref="popoverEl">
		<Timeline
			align="left"
			pt:event:class="'!tw3-min-h-4'"
			:value="selectedRow.email_statuses"
		>
			<template #marker="slotProps">
				<div class="p-timeline-event-marker tw3-flex tw3-items-center">
					<div
						:class="[
							'center tw3-rounded tw3-w-[0.375rem] tw3-h-[0.375rem] tw3-absolute',
							getStatusType(slotProps.item.event)
						]"
					></div>
				</div>
			</template>
			<template #content="slotProps">
				<div class="tw3-flex tw3-flex-col tw3-gap-1">
					<p class="tw3-text-md tw3-font-bold">
						{{ getStatusName(slotProps.item.event) }}
					</p>
					<p class="tw3-text-sm">
						{{ formatTimestamp(slotProps.item.timestamp) }}
					</p>
				</div>
			</template>
		</Timeline>
	</Popover>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable, { DataTablePageEvent } from 'primevue/datatable';
import Popover from 'primevue/popover';
import Skeleton from 'primevue/skeleton';
import Timeline from 'primevue/timeline';
import { computed, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { openFilePreview } from '@/shared/helpers/file';
import useReportsEmailStore from '@/widgets/reportShareDialog/model/store';

import { EmailHistoryRow } from '../model/types';

const { t } = useI18n();

const skeletonArray = Array(10).fill({});

const emailStore = useReportsEmailStore();
const currentHeaders = [
	{
		title: t('reportsPage.textMessagesTemplate.sent'),
		key: 'sent'
	},
	{
		title: t('reportsPage.textMessagesTemplate.destination'),
		key: 'destination'
	},
	{
		title: t('reportsPage.textMessagesTemplate.sent_documents'),
		key: 'sent_documents'
	},
	{
		title: t('reportsPage.textMessagesTemplate.status'),
		key: 'status'
	}
];

const formatData = (data: string) => dayjs(data).format('MM/DD/YYYY hh:mmA');
const formatTimestamp = (dt: number) => {
	const date = dayjs.unix(dt).format('MMM D, h:mm A');
	// const time = dayjs.unix(dt).format('h:mm A');
	return date;
};

const selectedRow = ref();
const popoverEl = ref();

const displayStatus = (event: Event, row: EmailHistoryRow) => {
	popoverEl.value.hide();

	if (selectedRow.value?.id === row.id) {
		selectedRow.value = null;
	} else {
		selectedRow.value = row;

		nextTick(() => {
			popoverEl.value.show(event);
		});
	}
};

const getStatusType = (status: string) => {
	if (status === 'delivered') return 'tw3-bg-green-500';
	if (status === 'delivery') return 'tw3-bg-green-500';
	if (status === 'open') return 'tw3-bg-sky-500';
	if (status === 'click') return 'tw3-bg-sky-500';
	if (status === 'processed') return 'tw3-bg-sky-500';
	if (status === 'bounce') return 'tw3-bg-red-500';
	if (status === 'spamreport') return 'tw3-bg-red-500';
	if (status === 'dropped') return 'tw3-bg-red-500';
};
const getStatusName = (status: string) => {
	if (status === 'spamreport') return 'spam report';
	if (status === 'group_unsubscribe') return 'group unsubscribe';
	if (status === 'group_resubscribe') return 'group resubscribe';
	return status;
};

const currentPage = ref<number>(1);
const pageSize = ref(10);
const preparedHistory = computed(() => {
	const prepHistory: EmailHistoryRow[] = emailStore.emailHistory.state;
	prepHistory.map(item => {
		return {
			...item,
			sent: formatData(item.sent)
		};
	});

	const startIndex = (currentPage.value - 1) * pageSize.value;
	const endIndex = startIndex + pageSize.value;
	return prepHistory.slice(startIndex, endIndex);
});

const handlePage = (state: DataTablePageEvent) => {
	currentPage.value = state.page + 1;
};

const expandedRows = ref({});
</script>

<style scoped>
:deep(.p-timeline-event-opposite) {
	display: none !important;
}

:deep(.p-timeline-event) {
	min-height: auto !important;
}

:deep(.p-timeline-event:not(:last-child) > .p-timeline-event-content) {
	padding-bottom: 1rem !important;
}
</style>
