import { capitalize } from 'lodash-es';

const ES = {
	agencyName: 'Nombre de la Agencia',
	agentFirstName: 'Nombre del Agente',
	agentLastName: 'Apellido del Agente',
	all: 'Todo',
	appointmentDate: 'Fecha de la Cita',
	archive: 'Archivar',
	archiveDialog: {
		archiveTitle: '¿Está seguro de que desea desarchivar esta reclamación?',
		unarchiveTitle: '¿Está seguro de que desea archivar esta reclamación?'
	},
	autoAccept: 'Aceptación Automática',
	bodyInjury: 'Lesión Corporal',
	bodyInjuryTooltip: '¿Hubo una lesión relacionada con esta reclamación?',
	//@ts-ignore
	capitalize: function (ctx) {
		//@ts-ignore
		return capitalize(ctx.named('key'));
	},
	causeOfLoss: 'Causa de Pérdida',
	causeOfLossTooltip: 'Determine cómo ocurrió la pérdida',
	city: 'Ciudad',
	claimForJob: 'Reclamación para Trabajo',
	claims: 'Reclamaciones',
	claimsDialog: {
		claimsSended: 'Solicitud enviada correctamente'
	},
	contactPhone: 'Teléfono de Contacto',
	coverage: 'Cobertura',
	customer: 'Cliente',
	customers: 'Clientes',
	damage: 'Daño',
	damageTitles: {
		location: 'Capturar qué vidrio será reparado o reemplazado',
		position: 'Código de Posición Nags',
		qty: 'Capturar las piezas de vidrio que se usarán para reparar el vehículo',
		relLocation: 'Código de Ubicación Relativa Nags',
		repairable:
			'Si la apertura del daño en el vidrio es el parabrisas,\nel minorista debe certificar que el parabrisas\nno era reparable',
		side: 'Código de Lado Nags'
	},
	date: 'Fecha',
	deductable: 'Deducible',
	details: 'Detalles',
	driverName: 'Nombre del Conductor (si está disponible)',
	error: 'Error',
	faxDialog: {
		fax: 'Fax',
		actions: {
			actions: 'Acciones',
			attachFax: {
				attach: 'Adjuntar al trabajo',
				error: 'No se ha podido adjuntar este fax',
				success: 'El documento se ha adjuntado correctamente'
			},
			burst: {
				burst: 'Ráfaga',
				plural: '¿Quieres reventar estos faxes?',
				pluralTooltip: 'Ráfaga de faxes seleccionados',
				single: '¿Quieres reventar este fax?'
			},
			cancel: 'Cancelar',
			changeReferral: {
				error:
					'Se ha producido un error durante la actualización Referencia/Notas',
				placeholder: 'Introducir remisión/Notas',
				success: '¡Refferal/Notes successfullyffully updated!'
			},
			changeStatus: {
				error: 'Se ha producido un error al cambiar el estado',
				success: '¡Estado cambiado con éxito!'
			},
			delete: {
				delete: 'Borrar',
				error: 'Se ha producido un error al eliminar un fax.',
				plural: '¿Desea eliminar estos faxes?',
				pluralTooltip: 'Borrar faxes seleccionados',
				single: '¿Desea eliminar este fax?',
				success: 'Faxes eliminados con éxito'
			},
			download: 'Descargar fax',
			downloadConfirmation: 'Descargar la página de transmisión',
			noConfirmationAvailable: 'No hay página de transmisión disponible',
			confirmationError:
				'Algo ha ido mal al recuperar la copia de su fax anterior. Por favor, inténtelo de nuevo.',
			confirmationSuccess: 'Página de transmisión descargada correctamente',
			pluralActions: 'Acciones en plural:',
			pluralActionsTooltip: 'Acciones con los faxes seleccionados',
			upload: {
				upload: 'Cargar fax'
			}
		},
		inbox: {
			dateTime: 'Fecha/Hora',
			pages: 'Páginas',
			referral: 'Remisión/Notas',
			sentFrom: 'Enviado Desde',
			sentTo: 'Enviado A',
			status: 'Estado',
			title: 'Buzón De Fax'
		},
		outbox: {
			dateTime: 'Fecha/Hora',
			file: 'File Name',
			pages: 'Páginas',
			sentFrom: 'Enviado Desde',
			sentTo: 'Enviado A',
			status: 'Estado',
			title: 'Buzón De Salida De Fax'
		},
		status: {
			burst: 'Ráfaga',
			error: 'Error',
			queued: 'En cola',
			read: 'Leer',
			sending: 'Envío de',
			sent: 'Enviado',
			unread: 'Sin leer'
		},
		statusResponses: {
			error: 'Error al enviar un fax',
			queued: 'El fax está en la cola',
			read: 'Se lee el fax',
			sending: 'El fax está enviando',
			sent: 'Fax enviado',
			unread: 'Fax no leído'
		},
		searchForJob: 'Buscar trabajo...',
		type: 'Tipo',
		attachFaxJob: 'Adjuntar fax al trabajo',
		selectType: 'Seleccione un tipo',
		selectStatus: 'Seleccionar estado',
		attachJob: 'Adjuntar trabajo',
		uploadFax: {
			fileName: 'Nombre del archivo:',
			dateTime: 'Fecha/Hora:',
			enterFaxNumber: 'Introduzca el número de fax',
			yourFile: 'Tu expediente:',
			uploadFaxHeader: 'Enviar/Borrar nuevo fax',
			burstNewFax: {
				burstFax: 'Fax de ráfaga',
				error: 'Este fax no ha podido ser reventado.',
				success: 'El fax se ha enviado correctamente.'
			},
			sendFax: {
				sendFax: 'Enviar fax',
				error: 'Se ha producido un error al enviar un fax',
				success: 'Fax enviado correctamente'
			},
			chooseFile: 'Elegir archivo'
		}
	},
	job: 'Empleo',
	confirmation: 'Confirmación',
	defaultToast: {
		successSummary: 'Mensaje de éxito',
		errorSummary: 'Mensaje de error',
		errorDetails: 'Algo salió mal'
	},
	searchBy: 'Buscar Por ',
	glassOnlyLoss: 'Pérdida Solo de Vidrio',
	glassOnlyLossTooltip: 'Determine si esta es una reclamación solo de vidrio',
	inbox: 'Bandeja de entrada',
	incident: 'Incidente en',
	insurance: 'Seguro',
	jobId: 'ID de Trabajo',
	location: 'Ubicación',
	mobileIndicator: 'Móvil',
	new: 'Nuevo',
	no: 'No',
	noData: 'Datos No Encontrados',
	openClaim: 'Abrir Reclamación',
	pending: 'Pendiente',
	phone: 'Teléfono',
	plate: 'Placa',
	policy: 'Póliza',
	position: 'Posición',
	qty: 'Cantidad',
	refNumber: 'Número de Referencia',
	reference: 'Referencia',
	rejectedInvoices: {
		tabs: {
			rejected: 'Rechazado',
			queued: 'En cola',
			preparing: 'Preparación',
			pending: 'Pendiente',
			failed: 'Fallido',
			unsubmitted: 'No enviado'
		},
		columnNames: {
			amount: 'Importe',
			customer: 'Cliente',
			invoice: 'Factura',
			referral: 'Remisión',
			tradingPartner: 'Socio comercial'
		},
		addTagsTitle: 'Añadir etiquetas',
		title: 'Facturas rechazadas',
		applyTags: 'Aplicar etiquetas',
		tagDeletedSuccess: 'Etiqueta eliminada correctamente',
		tagDeletedFailed: 'No se ha podido eliminar la etiqueta.',
		tagAddedSuccess: 'Nuevas etiquetas añadidas',
		tags: 'Etiquetas',
		showDetails: 'Mostrar detalles',
		voidedJobs: 'Incluir trabajos anulados'
	},
	addNewTag: 'Añadir nueva etiqueta',
	relLocation: 'Ubicación Relativa',
	removeDamageInfoHeader: 'Eliminar Información de Daños',
	removeDamageInfoMsg:
		'¿Está seguro de que desea eliminar esta información de daños?',
	repairable: 'Reparable',
	required: 'Requerido',
	result: 'Resultado',
	selectShop: 'Seleccionar Tienda',
	shopContactPerson: 'Persona de Contacto del Taller',
	shopContactPersonTooltip:
		'Permite a la compañía de seguros o TPA contactar al originador.',
	showActive: 'Mostrar Activos',
	showArchive: 'Mostrar Archivados',
	side: 'Lado',
	statePolicty: 'Política Estatal donde fue Escrita',
	status: 'Estado',
	statusResponses: {
		coverage: 'Respuesta de cobertura recibida',
		error: 'Ocurrió un error',
		new: 'Reclamación creada',
		pending: 'Aviso de pérdida enviado',
		success: 'Acuse de recibo enviado'
	},
	street: 'Calle',
	submitClaim: 'Enviar Reclamación',
	submitClaimMsg: '¿Está seguro de que desea enviar esta reclamación?',
	subrogationContactName: {
		CAUSE_OF_LOSS_CODE_105: 'Nombre del Conductor (si está disponible)',
		CAUSE_OF_LOSS_CODE_111: null,
		CAUSE_OF_LOSS_CODE_199:
			'Nombre de la Persona (si está disponible/aplicable)',
		CAUSE_OF_LOSS_CODE_201: 'Nombre del Conductor (si está disponible)',
		CAUSE_OF_LOSS_CODE_301: 'Nombre de la Persona (si está disponible)',
		CAUSE_OF_LOSS_CODE_341: 'Nombre de la Persona (si está disponible)',
		CAUSE_OF_LOSS_CODE_499: null
	},
	subrogationData: 'Datos de subrogación',
	subrogationDataKeys: {
		CAUSE_OF_LOSS_CODE_105: 'Número de Placa',
		CAUSE_OF_LOSS_CODE_111: 'Tipo de Animal',
		CAUSE_OF_LOSS_CODE_199: 'Descripción del Objeto',
		CAUSE_OF_LOSS_CODE_201:
			'Número de Reclamación (si está disponible) e Información de Contacto',
		CAUSE_OF_LOSS_CODE_301: 'Información de Contacto',
		CAUSE_OF_LOSS_CODE_341:
			'Información de Contacto (por ejemplo, informe de la policía)',
		CAUSE_OF_LOSS_CODE_499:
			'Tipo de Tormenta (por ejemplo, relámpago, viento, arena, etc.)'
	},
	success: 'Éxito',
	type: 'Tipo',
	unarchive: 'Desarchivar',
	vehicle: 'Vehículo',
	vehicleNumber: 'Número de Vehículo',
	vehicleOwnership: 'Propiedad del Vehículo',
	vehicleType: 'Tipo de Vehículo',
	vin: 'VIN',
	vinTooltip:
		'Un titular de póliza a menudo tiene más de un vehículo con la misma marca/modelo/año en una póliza, por lo tanto, si está disponible, el VIN puede ser enviado para filtrar la información de confirmación de cobertura.',
	voidJobs: {
		message: '¿Está seguro de que desea invalidar estos trabajos?',
		title: 'Invalidar Trabajos'
	},
	workLocation: 'Código Postal de Ubicación de Trabajo',
	yes: 'Sí',
	download: 'Descargar',
	shops: 'Tiendas',
	both: 'Ambos',
	labor: 'Trabajo',
	subtotal: 'Subtotal',
	materials: 'Materiales',
	flat: 'Plana',
	aging: 'Envejecimiento',
	charge: 'Cargo',
	credit: 'Crédito',
	due: 'Vencido',
	description: 'Descripción',
	payments: 'Pagos',
	payment: 'Pago',
	forward: 'Adelante',
	balance: 'Saldo',
	period: 'Período',
	summary: 'Resumen',
	invalid: 'Inválido',
	style: 'Estilo',
	model: 'Modelo',
	make: 'Marca',
	select: 'Seleccionar',
	filter: 'Filtrar',
	pickDate: 'Elegir fecha',
	pickDates: 'Intervalo de fechas de selección',
	subject: 'Asunto',
	body: 'Cuerpo',
	noteText: 'Nota',
	update: 'Actualizar',
	create: 'Crear',
	default: 'Predeterminado',
	warning: 'Advertencia',
	email: 'Correo electrónico',
	history: 'Historial',
	to: 'A',
	replyTo: 'Responder A',
	attachments: 'Archivos adjuntos',
	additionalAttachments: 'Archivos adicionales',
	shop: 'Tienda',
	message: 'Mensaje',
	collapse: 'Colapsar',
	expand: 'Expandir',
	user: 'Usuario',
	invoice: 'Factura',
	copyTextToast: {
		succesSummary: '¡Copiado!',
		succesDetails: 'El texto fue copiado al portapapeles',
		errorDetail: 'Error al copiar el texto al portapapeles',
		errorSummary: '¡Error!'
	},
	allReports: 'All Reports',
	reportsPage: {
		repair: 'Repare',
		replace: 'Sustituir',
		colNames: {
			partCost: 'Costo de la pieza',
			total_taxes: 'Impuesto de ventas',
			total_after_taxes: 'Ventas brutas',
			total_commission: 'Comisiones',
			customer_rebate: 'Reembolso',
			other_costs: 'Otros',
			total_margin: 'Margen',
			total_cost: 'Costo de piezas',
			customer_name: 'Nombre del cliente',
			referral_number: 'Número de referencia',
			total_tech_commission: 'Comisión',
			total_sales_rep_commission: 'Comisión',
			total_sale_source_commission: 'Comisión',
			total_location_commission: 'Comisión',
			total_csr_commission: 'Comisión',
			payer: 'Pagador',
			quote_date: 'Fecha de cotización',
			work_order_date: 'Fecha de orden de trabajo',
			invoice_date: 'Fecha de factura',
			install_date: 'Fecha de instalación',
			warranty_date: 'Fecha de garantía',
			warranty_complete_date: 'Fecha de finalización de garantía',
			recal_date: 'Fecha de recalibración',
			recal_complete_date: 'Fecha de finalización de recalibración',
			call_back_date: 'Fecha de devolución de llamada',
			call_back_complete_date: 'Fecha de finalización de devolución de llamada',
			r_and_i_date: 'Fecha de desmontaje e instalación',
			r_and_i_complete_date:
				'Fecha de finalización de desmontaje e instalación',
			tint_date: 'Fecha de polarizado',
			tint_complete_date: 'Fecha de finalización de polarizado',
			pick_up_date: 'Fecha de recogida',
			pick_up_complete_date: 'Fecha de finalización de recogida',
			drop_off_date: 'Fecha de entrega',
			drop_off_complete_date: 'Fecha de finalización de entrega',
			void_date: 'Fecha de anulación',
			payment_list: 'Pagos',
			time: 'Tiempo',
			part_list: 'Piezas',
			install_notes: 'Notas del trabajo',
			vehicle: 'Año / Marca / Modelo',
			total_after_taxes2: 'Total de la factura',
			type: 'Tipo de pago',
			tpa: 'TPA',
			amount: 'Monto del pago',
			x_action_type: 'Tipo de acción X',
			x_action_number: 'Número de acción X',
			memo: 'Nota',
			total: 'Total de impuestos seleccionados',
			total_taxes2: 'Total de impuestos del trabajo',
			total_after_taxes3: 'Costo bruto'
		},

		colNamesSecondary: {
			total_taxes: 'Total Tax',
			total_taxable: 'Net Taxable',
			material_exempt: 'Material Exempt',
			labor_exempt: 'Labor Exempt',
			total_exempt: 'Subtotal Exempt',
			total_subtotal: 'Subtotal',
			amount: 'Total Collected',
			total_materials_taxes: 'Total Materials Taxes',
			total_labor_taxes: 'Total Labor Taxes',
			total_tax_collected: 'Total Tax Collected',
			repairSales: 'Repair Sales Volume Collected',
			royaltyPercentage: 'Repair Percentage',
			repairRoyalty: 'Repair Royalties',
			replaceSales: 'Replacement Sales Volume Collected',
			replaceRoyaltyPercentage: 'Replacement Percentage',
			replaceRoyalty: 'Replacement Royalties',
			additionalSales: 'Additional Products & Services Sales Volume Collected',
			additionalRoyaltyPercentage: 'Additional Products & Services Percentage',
			additionalRoyalty: 'Additional Royalties',
			repairVehicles: 'Total Vehicles for Repair Service',
			replaceVehicles: 'Total Vehicles for Replacement Service',
			additionalInvoices: 'Invoices for Additional Products & Services',
			royaltySubtotal: 'Royalty Subtotal'
		},

		filters: {
			workType: 'Tipo de Trabajo',
			jobTypes: {
				jobType: 'Tipo de Trabajo',
				jobTypes: 'Tipos de Trabajo',
				quote: 'Cotización',
				workOrder: 'Orden de Trabajo',
				invoice: 'Factura',
				warranty: 'Garantía',
				warrantyComplete: 'Garantía Completa',
				recal: 'Recalibración',
				recalComplete: 'Recalibración Completa',
				callBack: 'Llamada de Retorno',
				callBackComplete: 'Llamada de Retorno Completa',
				rAndI: 'R & I',
				rAndIComplete: 'R & I Completo',
				tint: 'Polarizado',
				tintComplete: 'Polarizado Completo',
				pickUp: 'Recogida',
				pickUpComplete: 'Recogida Completa',
				dropOff: 'Entrega',
				dropOffComplete: 'Entrega Completa',
				void: 'Anulado'
			},
			customerTypes: {
				cash: 'Efectivo',
				commercial: 'Comercial',
				insurance: 'Seguro'
			},
			groupBy: {
				age: 'Por edad',
				groupBy: 'Agrupar por',
				none: 'Ninguno',
				date: 'Por fecha',
				shop_name: 'Por tienda',
				customer_name: 'Por cliente',
				x_action_type: 'Por tipo de acción X',
				insurancefleet_name: 'Por seguro',
				vendor: 'Por proveedor'
			},
			customFilterLabels: {
				installer: 'Instalador',
				techs: 'Técnicos',
				salesreps: 'Representantes de ventas',
				salesources: 'Fuentes de venta',
				locations: 'Ubicaciones',
				taxes: 'Impuestos de ventas',
				csrs: 'CSR`S'
			},
			accrualCashOptions: {
				accrual: 'Devengo',
				cashBasis: 'Base de Efectivo'
			},
			errorFetchingConsumer:
				'Error al obtener opciones de comunicación del consumidor:',
			generateAReport: 'Por favor, genere un informe primero.',
			portrait: 'Vertical',
			landscape: 'Horizontal',
			orientationSelect: 'Seleccione la Orientación',
			permissionDenied: 'No tiene permiso para ver este informe.',
			accrual: 'Base de Devengo/Efectivo',
			paidStatus: 'Estado de Pago',
			billToCustomer: 'Tipo de Cliente Facturado',
			licenseTypes: 'Tipos de Licencia',
			accountingPackage: 'Paquete Contable',
			noResults: 'No hay resultados para los filtros seleccionados',
			noGenerated: "Presione 'Generar' para obtener un informe",
			csrs: 'CSR`S',
			salesources: 'Fuente de Venta',
			salesreps: 'Representante de Ventas',
			techs: 'Técnico',
			locations: 'Ubicación',
			install_date: 'Fecha de Instalación',
			invoice_date: 'Fecha de Factura',
			work_order_date: 'Fecha de Orden de Trabajo',
			quote_date: 'Fecha de Cotización',
			warranty_date: 'Fecha de Garantía',
			warranty_complete_date: 'Fecha de Garantía Completa',
			recal_date: 'Fecha de Recalibración',
			recal_complete_date: 'Fecha de Recalibración Completa',
			call_back_date: 'Fecha de Llamada de Retorno',
			call_back_complete_date: 'Fecha de Llamada de Retorno Completa',
			r_and_i_date: 'Fecha de R & I',
			r_and_i_complete_date: 'Fecha de R & I Completa',
			tint_date: 'Fecha de Polarizado',
			tint_complete_date: 'Fecha de Polarizado Completo',
			pick_up_date: 'Fecha de Recogida',
			pick_up_complete_date: 'Fecha de Recogida Completa',
			drop_off_date: 'Fecha de Entrega',
			drop_off_complete_date: 'Fecha de Entrega Completa',
			unscheduled: 'No Programado',
			scheduled_date: 'Fecha Programada',
			void_date: 'Fecha de Anulación',
			filterGroup: 'Grupo de Filtros',
			filterBy: {
				csrs: 'CSR`S',
				salesources: 'fuentes de venta',
				salesreps: 'representantes de ventas',
				techs: 'técnicos',
				locations: 'ubicaciones'
			},
			tags: {
				exempt: 'Exento',
				poReq: 'PO Requerido',
				flagged: 'Marcado'
			},
			dateType: 'Tipo de Fecha'
		},

		textMessagesTemplate: {
			templates: 'Plantillas',
			selectTemplate: 'Seleccionar plantilla de correo electrónico (opcional)',
			manageTemplates: 'Gestionar plantillas de correo electrónico',
			emailTemplates: 'Plantillas de correo electrónico',
			updateTemplate: 'Actualizar una plantilla',
			createTemplate: 'Crear una plantilla',
			subject: 'Ingresar un asunto',
			enterAName: 'Ingresar un nombre',
			templateBody: 'Ingresar el cuerpo de la plantilla',
			default: 'Establecer como predeterminado para la tienda',
			body: 'Cuerpo de la plantilla',
			note: 'El texto "Por favor responda SÍ para confirmar o responda NO para cambiar" se añadirá automáticamente al final del mensaje.',
			errors: {
				name: 'El nombre es demasiado corto',
				body: 'El cuerpo debe tener al menos 10 caracteres',
				type_id: 'Por favor seleccione un tipo',
				shop_id: 'Por favor seleccione una tienda',
				subject: 'El asunto es demasiado corto',
				emailReq: 'El correo electrónico es obligatorio',
				emailInvalid: 'El correo electrónico es incorrecto'
			},
			created: 'Plantilla creada con éxito.',
			updated: 'Plantilla actualizada con éxito.',
			deleted: 'La plantilla fue eliminada con éxito',
			fillRequired: 'Complete los campos obligatorios',
			addTemplates: 'Agregar nueva plantilla',
			shopName: 'Nombre de la tienda',
			typeName: 'Nombre del tipo',
			deleteQuestion:
				'Esto eliminará permanentemente la plantilla. ¿Desea continuar?',
			sent: 'Enviado',
			destination: 'Para',
			sent_documents: 'Documentos adicionales',
			status: 'Estado',
			quickAddresses: 'Direcciones rápidas',
			typeAndEnter: 'Escriba y presione Enter',
			emailSended: 'Correo electrónico enviado.'
		},

		table: {
			outstanding: 'Pendiente',
			statement: 'Estado de cuenta',
			franchiseId: 'ID de franquicia',
			account: 'Cuenta',
			address: 'Dirección',
			license: 'Licencia',
			anniversaryDate: 'Fecha de aniversario',
			renewalDate: 'Fecha de renovación',
			ofUsers: 'De usuarios',
			fee: 'Tarifa anual de licencia',
			assets: 'Activos',
			liabilities: 'Pasivos',
			liabilitiesAndEquity: 'Pasivos y patrimonio',
			equity: 'Patrimonio',
			netIncome: 'Ingreso neto',
			income: 'Ingresos',
			costOfGoodsSold: 'Costo de los bienes vendidos',
			profitMargin: 'Margen de beneficio',
			expenses: 'Gastos',
			netProfit: 'Beneficio neto (pérdida)',
			pp: 'Parcialmente pagado',
			fp: 'Pagado en su totalidad',
			unpaid: 'No pagado',
			call_back_date: 'Fecha de llamada de vuelta',
			call_back_complete_date: 'Fecha de llamada de vuelta completada',
			draft_date: 'Fecha de borrador',
			drop_off_date: 'Fecha de entrega',
			drop_off_complete_date: 'Fecha de entrega completada',
			install_date: 'Fecha de instalación',
			invoice_date: 'Fecha de factura',
			pick_up_date: 'Fecha de recogida',
			pick_up_complete_date: 'Fecha de recogida completada',
			quote_date: 'Fecha de cotización',
			r_and_i_date: 'Fecha de R&I',
			r_and_i_complete_date: 'Fecha de R&I completada',
			recal_date: 'Fecha de recalificación',
			recal_complete_date: 'Fecha de recalificación completada',
			tint_date: 'Fecha de tinte',
			tint_complete_date: 'Fecha de tinte completada',
			void_date: 'Fecha de anulación',
			work_order_date: 'Fecha de orden de trabajo',
			warranty_date: 'Fecha de garantía',
			warranty_complete_date: 'Fecha de garantía completada',
			summary: 'Resumen'
		},
		multipleShops: 'Múltiples tiendas',
		customer: 'Cliente',
		contractor: 'Contratista',
		xType: 'Tipo de acción X',
		daily: 'Diario',
		total: 'Total',
		requests: 'Solicitudes',
		totals: 'Totales',
		grand: 'Gran total',
		requestDetails: {
			title: 'Detalles de la solicitud',
			reqTime: 'Hora de la solicitud',
			vinInvalid: 'El número de VIN es inválido',
			helpReqSend:
				'La solicitud de ayuda fue enviada. Por favor, dános un poco de tiempo para procesarla y te responderemos de inmediato.',
			glassType: 'Tipo de vidrio',
			windshields: 'Parabrisas',
			calibrations: 'Calibraciones',
			dealerNum: 'Números de parte del distribuidor',
			noGlassFound: 'No se encontraron resultados de vidrio'
		}
	},
	filePreview: {
		notAvailable: 'Vista previa no disponible',
		fileName: 'Nombre del archivo',
		fileType: 'Tipo de archivo',
		docType: 'Tipo de documento',
		fileSize: 'Tamaño del archivo',
		created: 'Creado',
		openInNewTab: 'Abrir en una nueva pestaña',
		preview: 'Vista previa'
	}
};

export default ES;
