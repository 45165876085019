export const formatNumber = (num: number, fixed?: boolean) => {
	let formattedNumber;

	if (num >= 1_000_000) {
		formattedNumber = Math.round(num / 10_000) / 100 + 'm';
	} else if (num >= 1_000) {
		formattedNumber = Math.round(num / 100) / 10 + 'k';
	} else {
		formattedNumber = fixed ? num.toFixed(0) : Math.round(num * 100) / 100;
	}

	return formattedNumber.toString();
};

export const formatPrice = (num: number | string) => {
	return `$${num}`;
};
