<template>
	<Tag
		severity="secondary"
		:style="{
			'border-left': '10px solid black',
			'border-color': tagSettings.color
		}"
	>
		<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
			<span style="font-size: 0.9rem">{{ tagSettings.text }}</span>
		</div>
	</Tag>
	<Tag v-if="taxExempt" severity="secondary">
		<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
			<span style="font-size: 0.9rem">
				{{ t('reportsPage.filters.tags.exempt') }}
			</span>
		</div>
	</Tag>
	<Tag
		v-if="poRequired"
		severity="secondary"
		:style="{
			'border-left': '10px solid black',
			'border-color': 'sky-blue'
		}"
	>
		<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
			<span style="font-size: 0.9rem">
				{{ t('reportsPage.filters.tags.poReq') }}
			</span>
		</div>
	</Tag>
	<Tag
		v-if="flagged"
		severity="secondary"
		:style="{
			'border-left': '10px solid black',
			'border-color': 'dark'
		}"
	>
		<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
			<span style="font-size: 0.9rem">
				{{ t('reportsPage.filters.tags.flagged') }}
			</span>
		</div>
	</Tag>
</template>

<script setup lang="ts">
import Tag from 'primevue/tag';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { CustomerSearchItem } from '../../api/types';

const { t } = useI18n();
const props = defineProps<{
	item: CustomerSearchItem;
	taxExempt?: boolean;
	poRequired?: boolean;
	flagged?: boolean;
}>();

const tagSettings = computed(() => {
	const tagProps = props.item;
	if (tagProps.status === 'disabled') {
		return {
			color: 'red',
			text: 'Disabled'
		};
	} else if (tagProps.is_insurance) {
		return {
			color: '#6366f1',
			text: 'Ins'
		};
	} else if (tagProps.is_commercial) {
		return {
			color: '#eab308',
			text: 'Com'
		};
	} else {
		return {
			color: '#06b6d4',
			text: 'Retail'
		};
	}
});
</script>
