import { i18n } from '@/shared/i18n';

export const JOB_TABLE_CONFIG = [
	{
		key: 'shop.name',
		label: i18n.global.t('shopName'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'job_id',
		label: i18n.global.t('job') + ' #',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'status',
		label: i18n.global.t('jobType'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'referral_number',
		label: i18n.global.t('referral') + ' #',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.name.last',
		label: i18n.global.t('lastName'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.name.first',
		label: i18n.global.t('firstName'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'parts',
		label: i18n.global.t('parts'),
		sortable: false,
		hidden: false,
		dont_export: false
	},
	{
		key: 'consumer.email.email',
		label: i18n.global.t('customerEmail'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'ediqueue.status',
		label: i18n.global.t('ediRemitDetail'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.is_commercial',
		label: i18n.global.t('commercialAccount'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'shop_id',
		label: i18n.global.t('shop') + ' #',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'quote_date',
		label: i18n.global.t('quoteDate'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'work_order_date',
		label: i18n.global.t('workOrderDate'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'dispatch_date',
		label: i18n.global.t('dispatchDate'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'invoice_date',
		label: i18n.global.t('invoiceDate'),
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'follow_up_date',
		label: 'Follow Up Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'tags',
		label: 'Tags',
		sortable: false,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'taxes',
		label: 'Shop Tax Names',
		sortable: false,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'documents',
		label: 'Documents',
		sortable: false,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'notes',
		label: 'Notes',
		sortable: false,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_materials',
		label: 'Materials',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_labor',
		label: 'Labor',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_subtotal',
		label: 'Sub-Total',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_taxes',
		label: 'Sales Tax',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_after_taxes',
		label: 'Total Invoice',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'deductible',
		label: 'Deductible',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_balance_after_payments',
		label: 'Balance Due',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'job_csr',
		label: 'CSR Name',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'job_salesource',
		label: 'Sales Source Name',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_sale_source_commission',
		label: 'Sales Source Commission',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'job_salesrep',
		label: 'Sales Rep Name',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_sales_rep_commission',
		label: 'Sales Rep Commission',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'job_tech',
		label: 'Installer Name',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_tech_commission',
		label: 'Installer Commission',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'job_location',
		label: 'Location Name',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_location_commission',
		label: 'Location Commission',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'commercialaccount_name',
		label: 'Commercial Account Name',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'internal_number',
		label: 'Internal Number',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_cost',
		label: 'Parts Cost',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'customer_rebate',
		label: 'Customer Rebate',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'other_costs',
		label: 'Other Costs',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'total_margin',
		label: 'Total Margin',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.phone.number',
		label: 'Phone 1',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.address.address',
		label: 'Address',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.address.zip',
		label: 'Zip',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.address.city',
		label: 'City',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.address.state',
		label: 'State',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer.address.unit',
		label: 'Unit',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.year',
		label: 'Year',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.make',
		label: 'Make',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.model',
		label: 'Model',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.sub_model',
		label: 'Sub-Model',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.style',
		label: 'Style',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.vin',
		label: 'VIN',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'insurance_fleet_name',
		label: 'Bill To',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'bill_to.consumer_edi.trading_partner',
		label: 'Trading Partner',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'loss_date',
		label: 'Loss Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'scheduled_date',
		label: 'Schedule Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_date',
		label: 'Install Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'scheduled_time_start',
		label: 'Scheduled Start Time',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'scheduled_time_end',
		label: 'Scheduled End Time',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_g_address.address',
		label: 'Schedule Address',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_g_address.city',
		label: 'Schedule City',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_g_address.state',
		label: 'Schedule State',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_g_address.zip',
		label: 'Schedule Zip',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_g_address.unit',
		label: 'Schedule Unit',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'contact_name',
		label: 'Contact Name',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'contact_phone1',
		label: 'Contact Phone 1',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'contact_phone2',
		label: 'Contact Phone 2',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'purchase_order_number',
		label: 'PO Number',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'requisition_order_number',
		label: 'RO Number',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'dot_number',
		label: 'DOT Number',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'lot_number',
		label: 'LOT Number',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_context',
		label: 'Mobile/In Shop',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'policy_number',
		label: 'Policy #',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.unit_number',
		label: 'Vehicle Unit',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'install_notes',
		label: 'Install Notes',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'consumer_id',
		label: 'Customer ID',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'pending_schedule',
		label: 'Pending Schedule',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'pending_inspection',
		label: 'Pending Inspection',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'full_job_number',
		label: 'Full Job #',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'technician_notes',
		label: 'Tech Notes',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle.plate_number',
		label: 'Plate #',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'vehicle_mileage',
		label: 'Mileage',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'last_updated',
		label: 'Last Updated',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'salesidejob_id',
		label: 'Mobile #',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'warranty_date',
		label: 'Warranty Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'warranty_complete_date',
		label: 'Warranty Complete Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'recal_date',
		label: 'Recal Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'recal_complete_date',
		label: 'Recal Complete Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'call_back_date',
		label: 'Call Back Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'call_back_complete_date',
		label: 'Call Back Complete Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'r_and_i_date',
		label: 'R&I Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'r_and_i_complete_date',
		label: 'R&I Complete Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'tint_date',
		label: 'Tint Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'tint_complete_date',
		label: 'Tint Complete Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'pick_up_date',
		label: 'Pick Up Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'pick_up_complete_date',
		label: 'Pick Up Complete Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'drop_off_date',
		label: 'Drop Off Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	},
	{
		key: 'drop_off_complete_date',
		label: 'Drop Off Complete Date',
		sortable: true,
		hidden: false,
		dont_export: false,
		backgroundColor: ''
	}
];

export const DEFAULT_CONFIG = [
	'invoice_date',
	'shop.name',
	'job_id',
	'status',
	'referral_number',
	'total_after_taxes',
	'consumer.name.last',
	'consumer.name.first',
	'commercialaccount_name',
	'consumer.phone.number',
	'insurance_fleet_name',
	'vehicle.year',
	'vehicle.make',
	'vehicle.model'
];

export const allColumnsTableConf = {
	id: -1,
	name: 'All Columns',
	columns: JOB_TABLE_CONFIG
};

export const defaultTableConf = {
	id: -2,
	name: 'Default',
	columns: JOB_TABLE_CONFIG.map(v => ({
		...v,
		hidden: !DEFAULT_CONFIG.includes(v.key)
	}))
};
