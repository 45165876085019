<template>
	<div class="report-table-title tw3-w-max tw3-min-w-full">
		<div
			class="title-row tw3-text-[14px] tw3-flex tw3-justify-between tw3-items-center tw3-px-0 tw3-p-3 tw3-pt-0 tw3-w-full tw3-text-center tw3-px-16 lg:tw3-px-32"
		>
			<div class="table-shop-name tw3-py-8 tw3-w-4/12">
				<div v-if="isShopSingle" class="title-nested">
					<strong>{{ shopNames }}</strong>
				</div>
				<div
					v-else-if="!isShopSingle && currentReportType.group !== 'right-part'"
					v-html="shopNames"
				></div>
				<div
					v-else-if="!isShopSingle && currentReportType.group === 'right-part'"
				>
					<b>{{ t('reportsPage.multipleShops') }}</b>
				</div>
			</div>
			<div class="table-logo tw3-py-8 tw3-w-4/12">
				<img
					v-if="currentReportType.id === 'nags-license'"
					class="shopLogo title-nested"
					src="https://www.filepicker.io/api/file/HutESuxTn6flddjsNLqL"
				/>
				<img
					v-if="
						shopNameWithLogo.logo !== null &&
						isShopSingle &&
						currentReportType.id !== 'nags-license'
					"
					class="shopLogo title-nested"
					:src="shopNameWithLogo.logo"
				/>
			</div>
			<div class="table-date tw3-py-8 tw3-w-4/12">
				<div class="title-nested">
					<strong>{{ currentReportType.label }}</strong>
					<div>{{ dates }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/store';
import useReportsStore from '@/widgets/reportsElements/model/store';
dayjs.extend(advancedFormat);

const { t } = useI18n();
const { user } = storeToRefs(useUserStore());

const allShops = computed(() => user.value.shops);

const reportStore = useReportsStore();
const { selectedShop, currentReportType, staticDate, reportData } =
	storeToRefs(reportStore);
const isShopSingle = ref(selectedShop.value?.length === 1);

const shopNames = ref(
	allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(shop => shop.name)
		.join('<br/>')
);

const shopNameWithLogo = ref(
	allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(({ name, logo }) => {
			return { name, logo };
		})[0]
);

const dates = computed(() => {
	if (currentReportType.value.id === 'balance-sheet') {
		return dayjs(staticDate.value?.endDate).format('MMM Do, YYYY');
	} else {
		return `${dayjs(staticDate.value?.startDate).format('MMM Do, YYYY')} - ${dayjs(staticDate.value?.endDate).format('MMM Do, YYYY')}`;
	}
});

watch(reportData, () => {
	isShopSingle.value = selectedShop.value?.length === 1;
	shopNames.value = allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(shop => shop.name)
		.join('<br/>');
	shopNameWithLogo.value = allShops.value
		.filter(({ id }) => selectedShop.value?.includes(id))
		.map(({ name, logo }) => {
			return { name, logo };
		})[0];
});
</script>

<style scoped>
.table-date {
	text-align: center;
	font-size: 14px;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.table-shop-name {
	text-align: center;
	font-size: 14px;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.table-logo {
	text-align: center;
	max-height: 200px;
	max-width: 350px;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.shopLogo {
	max-height: 150px;
	height: auto;
	max-width: 100%;
}
</style>
