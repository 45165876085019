<template>
	<div id="customer-summary-report">
		<table>
			<thead>
				<tr class="table-head">
					<th class="customer">{{ t('reportsPage.customer') }}</th>
					<th class="customer-details-head">{{ t('details') }}</th>
					<th class="job-totals right-cell">
						{{ t('job') }} {{ t('reportsPage.totals') }}
					</th>
					<th class="dist right-cell"><span>Dist. %</span></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, key) in data" :key="key">
					<td>
						<span class="customer-name">{{ row.consumer.company_name }}</span>
					</td>
					<td>
						<span class="customer-details">{{
							consumerDetails(row.consumer)
						}}</span>
					</td>
					<td class="right-cell">
						({{ row.jobs_count }})
						{{
							reportsGetters.formatNumber(row.totals_sum, {
								style: 'dollar'
							})
						}}
					</td>
					<td class="right-cell">{{ consumerDistribution(row.totals_sum) }}</td>
				</tr>
				<tr>
					<td></td>
					<td class="right-cell">
						<span class="total-key">{{ t('reportsPage.total') }}:</span>
					</td>
					<td class="right-cell">
						({{ rangeJobs }})
						{{
							reportsGetters.formatNumber(rangeTotal, {
								style: 'dollar'
							})
						}}
					</td>
					<td></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

const { t } = useI18n();

const props = defineProps<{
	data: object[] | null;
}>();

const consumerDetails = (consumer: {
	g_address: {
		full_address: string;
	};
	phone: {
		number: string;
	};
}) => {
	let detailsString = '';
	if (consumer.g_address && consumer.g_address.full_address) {
		detailsString += `${consumer.g_address.full_address} - `;
	}
	if (consumer.phone) {
		detailsString += `${consumer.phone.number}`;
	}
	return detailsString;
};

const rangeTotal = computed(() => {
	let total = 0;
	if (props.data) {
		for (let i = 0; i < props.data.length; i++) {
			total += props.data[i].totals_sum;
		}
	}
	return total;
});

const rangeJobs = computed(() => {
	let jobs = 0;
	if (props.data) {
		for (let i = 0; i < props.data.length; i++) {
			jobs += props.data[i].jobs_count;
		}
	}
	return jobs;
});

const consumerDistribution = (rowTotal: number) => {
	const dist = ((rowTotal / rangeTotal.value) * 100).toFixed(1);
	return `${dist}%`;
};
</script>

<style scoped>
#customer-summary-report {
	width: 65vw;
	margin: auto;
	table-layout: fixed;
}

#customer-summary-report .table-head,
#customer-summary-report .table-foot {
	background: #d0e5ff;
	color: #3e94ff;
}

#customer-summary-report .right-cell {
	text-align: right;
}

#customer-summary-report table {
	width: 99%;
}

#customer-summary-report .customer-name,
#customer-summary-report .total-key {
	font-weight: bold;
}

#customer-summary-report .customer-details {
	color: gray;
}

#customer-summary-report .customer {
	width: 35%;
}

#customer-summary-report .customer-details {
	width: 40%;
}

#customer-summary-report .job-totals {
	width: 17%;
}

#customer-summary-report .dist {
	width: 8%;
}
</style>
