import { computed } from 'vue';

export const useWindowSizes = () => {
	const isMobile = computed(() => window.innerWidth <= 768);
	const isTablet = computed(
		() => window.innerWidth <= 1024 && window.innerWidth >= 768
	);
	const isPc = computed(() => window.innerWidth > 1024);

	return { isMobile, isTablet, isPc };
};
