import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { JobTag } from '@/entities/job/model/types';
import { permissionCheck, permissions } from '@/entities/user/api/util';
import { useUserStore } from '@/entities/user/store';
import {
	createOrUpdateJobTag,
	getJobTags,
	getShopTags
} from '@/features/tagsDialog/api';
import { useMessages } from '@/shared/composables';

import {
	createJobDocumentApi,
	createJobNoteApi,
	deleteJobDocumentApi,
	deleteJobNoteApi,
	getJobDocuments,
	getJobEdiqueue,
	getJobNote,
	updateJobEdiqueue
} from '../../api';
import { EdiStatus } from '../enums/edi-status.enum';
import { JobDocument, JobEditCellEvent, JobEdiUpdateRequest } from '../types';
import { JobNote } from '../types/note';

import { useJobsStore } from './jobsStore';

export const useJobEditCellStore = defineStore('pagesJobEditCell', () => {
	const { t } = useI18n();
	const message = useMessages();

	const userStore = useUserStore();
	const jobsStore = useJobsStore();

	const currentJobId = ref(-1);
	const currentShopId = ref(-1);

	const editJobViewDialog = ref(false);
	const editJobEdiDialog = ref(false);
	const editJobNotesDialog = ref(false);
	const editJobTagDialog = ref(false);
	const editJobDocumentsDialog = ref(false);

	const updateJobEdiLoading = ref(false);
	const updateJobNoteLoading = ref(false);
	const updateJobTagLoading = ref(false);
	const updateJobDocumentLoading = ref(false);

	const editPermission = computed(
		() =>
			permissionCheck(permissions.JOB, userStore.user) &&
			!permissionCheck(permissions.JOBVIEWONLY, userStore.user)
	);

	const updateJobEvent = (jobId: number) => {
		jobsStore.updateJobById(jobId);
	};

	const toggleJobViewDialog = () => {
		editJobViewDialog.value = !editJobViewDialog.value;
	};

	const toggleJobEdiDialog = async (data?: JobEditCellEvent) => {
		if (!editPermission.value) return;
		if (data) {
			currentJobId.value = data.job_id;
			currentShopId.value = data.shop_id;

			await jobEdi.execute(0, data.job_id, data.shop_id);
		}

		editJobEdiDialog.value = !editJobEdiDialog.value;
	};

	const jobEdi = useAsyncState(
		async (jobId: number, shopId: number) => {
			try {
				const data = await getJobEdiqueue(jobId, shopId);
				return { ...data, shop_id: shopId };
			} catch (e) {
				message.showError(e);
				return {
					id: 0,
					submit_date_time: null,
					response_date_time: null,
					status: EdiStatus.Unsubmitted,
					job_edi_message: '',
					job_id: 0,
					edi_creation_date_time: new Date(),
					service: '',
					edi_id: null,
					remit_received: 0,
					glaxis_id: null,
					shop_id: 0
				};
			}
		},
		{
			id: 0,
			submit_date_time: null,
			response_date_time: null,
			status: EdiStatus.Unsubmitted,
			job_edi_message: '',
			job_id: 0,
			edi_creation_date_time: new Date(),
			service: '',
			edi_id: null,
			remit_received: 0,
			glaxis_id: null,
			shop_id: 0
		},
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const updateJobEdi = async (data: JobEdiUpdateRequest) => {
		try {
			updateJobEdiLoading.value = true;
			await updateJobEdiqueue(currentJobId.value, data);

			updateJobEvent(currentJobId.value);
			toggleJobEdiDialog();
			message.showSuccess(t('edi.updatedMsg'));
		} catch (e) {
			message.showError(e);
		} finally {
			updateJobEdiLoading.value = false;
		}
	};

	const toggleJobNotesDialog = async (data?: JobEditCellEvent) => {
		if (!editPermission.value) return;
		if (data) {
			currentJobId.value = data.job_id;
			currentShopId.value = data.shop_id;

			await jobNotes.execute(0, data.job_id, data.shop_id);
		}

		editJobNotesDialog.value = !editJobNotesDialog.value;
	};

	const jobNotes = useAsyncState(
		async (jobId: number, shopId: number) => {
			try {
				const data = await getJobNote(jobId, shopId);
				return data;
			} catch (e) {
				message.showError(e);
				return {
					users: [],
					notes: []
				};
			}
		},
		{
			users: [],
			notes: []
		},
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const createJobNote = async (data: Partial<JobNote>) => {
		try {
			updateJobNoteLoading.value = true;
			await createJobNoteApi(currentShopId.value, {
				...data,
				job_id: currentJobId.value
			});
			await jobNotes.execute(0, currentJobId.value, currentShopId.value);

			updateJobEvent(currentJobId.value);
			message.showSuccess(t('note.createdMsg'));
		} catch (e) {
			message.showError(e);
			throw e;
		} finally {
			updateJobNoteLoading.value = false;
		}
	};

	const deleteJobNote = async (data: JobNote) => {
		try {
			updateJobNoteLoading.value = true;
			await deleteJobNoteApi(currentShopId.value, data);
			await jobNotes.execute(0, currentJobId.value, currentShopId.value);

			updateJobEvent(currentJobId.value);
			message.showSuccess(t('note.deletedMsg'));
		} catch (e) {
			message.showError(e);
		} finally {
			updateJobNoteLoading.value = false;
		}
	};

	const toggleJobTagsDialog = async (data?: JobEditCellEvent) => {
		if (!editPermission.value) return;
		if (data) {
			currentJobId.value = data.job_id;
			currentShopId.value = data.shop_id;

			await jobTags.execute(0, data.job_id, data.shop_id);
		}

		editJobTagDialog.value = !editJobTagDialog.value;
	};

	const shopTags = ref<JobTag[]>([]);
	const jobTags = useAsyncState(
		async (jobId: number, shopId: number) => {
			const [d1, d2] = await Promise.all([
				getShopTags(shopId),
				getJobTags(shopId, jobId)
			]);
			shopTags.value = d1;
			return d2.map(v => ({ ...v, id: v.shop_tag_id as number }));
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const updateJobTag = async (tags: JobTag[]) => {
		try {
			updateJobTagLoading.value = true;
			const preparedJobTags: JobTag[] = tags.map(item => {
				return {
					created: item.created ? item.created : -1,
					modified: item.modified ? item.modified : -1,
					id: item.id,
					job_id: currentJobId.value,
					shop_tag_id: item?.shop_tag_id
				};
			});
			await createOrUpdateJobTag({
				jobID: currentJobId.value,
				shopID: currentShopId.value,
				tags: preparedJobTags
			});

			updateJobEvent(currentJobId.value);
			toggleJobTagsDialog();
			message.showSuccess(t('tagUpdatedMsg'));
		} catch (e) {
			message.showError(e);
			throw e;
		} finally {
			updateJobTagLoading.value = false;
		}
	};

	const toggleJobDocumentsDialog = async (data?: JobEditCellEvent) => {
		if (!editPermission.value) return;
		if (data) {
			currentJobId.value = data.job_id;
			currentShopId.value = data.shop_id;

			await jobDocuments.execute(0, data.job_id, data.shop_id);
		}

		editJobDocumentsDialog.value = !editJobDocumentsDialog.value;
	};

	const jobDocuments = useAsyncState(
		async (jobId: number, shopId: number) => {
			try {
				const data = await getJobDocuments(jobId, shopId);
				return data;
			} catch (e) {
				message.showError(e);
				return [];
			}
		},
		[],
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const createJobDocument = async (data: Partial<JobDocument>) => {
		try {
			updateJobDocumentLoading.value = true;
			await createJobDocumentApi(currentShopId.value, {
				...data,
				job_id: currentJobId.value
			});
			await jobDocuments.execute(0, currentJobId.value, currentShopId.value);

			updateJobEvent(currentJobId.value);
			message.showSuccess(t('document.createdMsg'));
		} catch (e) {
			message.showError(e);
			throw e;
		} finally {
			updateJobDocumentLoading.value = false;
		}
	};

	const deleteJobDocument = async (data: JobDocument) => {
		try {
			updateJobDocumentLoading.value = true;
			await deleteJobDocumentApi(currentShopId.value, data);
			await jobDocuments.execute(0, currentJobId.value, currentShopId.value);

			updateJobEvent(currentJobId.value);
			message.showSuccess(t('document.deletedMsg'));
		} catch (e) {
			message.showError(e);
		} finally {
			updateJobDocumentLoading.value = false;
		}
	};

	return {
		editPermission,

		currentJobId,
		currentShopId,

		editJobViewDialog,
		toggleJobViewDialog,

		editJobEdiDialog,
		toggleJobEdiDialog,
		jobEdi,
		updateJobEdi,
		updateJobEdiLoading,

		editJobNotesDialog,
		toggleJobNotesDialog,
		jobNotes,
		createJobNote,
		deleteJobNote,
		updateJobNoteLoading,

		editJobTagDialog,
		toggleJobTagsDialog,
		shopTags,
		jobTags,
		updateJobTag,
		updateJobTagLoading,

		editJobDocumentsDialog,
		toggleJobDocumentsDialog,
		jobDocuments,
		createJobDocument,
		deleteJobDocument,
		updateJobDocumentLoading
	};
});
