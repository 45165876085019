<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useConfirm } from 'primevue/useconfirm';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';

import { JobNote, JobNoteResponse } from '../../../model/types';
import JobNoteCell from '../../cells/JobNoteCell.vue';

const { t } = useI18n();

const props = defineProps<{
	data: JobNoteResponse;
	show: boolean;
	loading: boolean;
}>();
const emit = defineEmits<{
	(e: 'on-close'): void;
	(e: 'on-create', v: Partial<JobNote>): void;
	(e: 'on-delete', v: JobNote): void;
}>();

const message = useMessages();
const userStore = useUserStore();

const noteInput = ref('');

const confirm = useConfirm();

const deletePressed = (data: JobNote) => {
	confirm.require({
		accept: () => {
			emit('on-delete', data);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('note.delete'),
		message: t('note.deleteConfirm'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const save = async () => {
	if (!props.data) return;
	if (!noteInput.value || !noteInput.value.length) {
		message.showError(t('note.emptyError'));
		return;
	}
	emit('on-create', {
		id: -1,
		created: -1,
		modified: -1,
		note_date: getCurrentDate(),
		note_text: noteInput.value,
		user_id: +userStore.user.user.id,
		note_sequence: props.data.notes.length
			? props.data.notes[0].note_sequence + 1
			: 0
	});
	noteInput.value = '';
};

const getCurrentDate = () => {
	const now = new Date();

	return now
		.toLocaleString('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			hour12: true
		})
		.replace(',', '');
};

const getUser = (userId: number) => {
	return props.data.users.find(v => v.id === userId);
};

watch(
	() => props.show,
	() => {
		noteInput.value = '';
	}
);
</script>

<template>
	<Dialog
		id="vue3JobEditNotesDialog"
		:header="$t('jobNotes')"
		modal
		:visible="show"
		@update:visible="emit('on-close')"
	>
		<div class="tw3-flex tw3-flex-col tw3-w-[500px] tw3-h-[313px]">
			<div
				class="tw3-relative tw3-flex tw3-flex-col tw3-gap-2 tw3-pb-[0.75rem] tw3-px-[0.25rem] tw3-pt-[0.25rem] tw3-flex-1 tw3-overflow-y-auto notes-container"
			>
				<div v-if="!data.notes.length" class="tw3-m-auto">
					{{ $t('note.empty') }}
				</div>
				<template v-else>
					<JobNoteCell
						v-for="item in data.notes"
						:key="item.id"
						class="tw3-w-fit"
						:note="item"
						:showDelete="item.user_id === +userStore.user.user.id"
						:user="getUser(item.user_id)"
						@delete="deletePressed(item)"
					/>
				</template>
			</div>
			<div
				class="tw3-flex tw3-flex-col sm:tw3-flex-row tw3-items-center tw3-pt-[0.75rem] tw3-border-t tw3-border-surface-200 tw3-gap-4"
			>
				<InputText
					v-model="noteInput"
					autofocus
					class="tw3-w-full tw3-rounded"
					:disabled="loading"
					:placeholder="$t('note.placeholder')"
					type="text"
					@keyup.enter="save"
				/>
				<Button
					class="tw3-w-full sm:tw3-w-auto"
					icon="pi pi-send"
					:loading="loading"
					pt:root:class="tw3-rounded-t-none"
					size="small"
					@click="save"
				/>
			</div>
		</div>
	</Dialog>
</template>

<style lang="scss" scoped>
.notes-container {
	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}

	scrollbar-width: none;
}
</style>
