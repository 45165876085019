<template>
	<div class="table-container">
		<table v-if="data">
			<tbody>
				<tr>
					<th
						v-for="col in currentColumns"
						:key="col.prop"
						class="cell"
						scope="col"
					>
						{{ col.label }}
					</th>
				</tr>
				<tr v-for="row in data" :key="row.id">
					<td v-for="col in currentColumns" :key="col.prop" class="cell">
						{{ row[col.prop] }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps<{
	data: any;
}>();

const { t } = useI18n();

const currentColumns = [
	{
		label: `${t('reportsPage.table.license')} #`,
		prop: 'id'
	},
	{
		label: t('reportsPage.table.account'),
		prop: 'account'
	},
	{
		label: t('reportsPage.table.address'),
		prop: 'address'
	},
	{
		label: t('reportsPage.table.anniversaryDate'),
		prop: 'anniversary_date'
	},
	{
		label: t('reportsPage.table.renewalDate'),
		prop: 'date'
	},
	{
		label: `# ${t('reportsPage.table.ofUsers')}`,
		prop: 'users'
	},
	{
		label: t('reportsPage.table.fee'),
		prop: 'fee'
	}
];
</script>

<style scoped>
.table-container {
	box-sizing: content-box;
}

.table-container .cell {
	display: table-cell;
	height: 30px;
	vertical-align: top;
	border-width: 1px 1px 0 0;
	border-color: #dcdcdc;
	border-style: solid;
	padding: 6px 12px;
	font-size: 12px;
	white-space: nowrap;
}
</style>
