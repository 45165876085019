<template>
	<Dialog
		id="EmailGenericWindow"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-max-w-[1200px] tw3-h-full tw3-w-full tw3-pb-4"
		:header="t('email')"
		modal
		:pt:content="'tw3-flex tw3-flex-col'"
	>
		<Tabs :value="currentTab" @update:value="updateDataInTab">
			<TabList>
				<Tab :pt:root:class="'!tw3-pt-[16px]'" value="email">
					<span>{{ t('email') }}</span>
				</Tab>
				<Tab :pt:root:class="'!tw3-pt-[16px]'" value="history">
					<span>{{ t('History') }}</span>
				</Tab>
			</TabList>
			<TabPanels class="!tw3-p-0" style="height: calc(100% - 42px)">
				<TabPanel
					class="tw3-min-h-full tw3-p-6 tw3-flex tw3-flex-col tw3-gap-4"
					value="email"
				>
					<div class="tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-items-start">
						<div class="tw3-flex tw3-col-span-6 tw3-flex-col tw3-gap-2">
							<div class="tw3-text-sm">
								{{ t('reportsPage.textMessagesTemplate.quickAddresses') }}
							</div>
							<div class="tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2">
								<Button
									v-for="shortcut in emailShortcuts"
									:key="shortcut.label"
									severity="secondary"
									size="small"
									@click="addEmail(shortcut.address)"
								>
									{{ shortcut.label }}
								</Button>
							</div>
						</div>
						<div class="tw3-flex tw3-col-span-6 tw3-flex-col tw3-gap-2">
							<span class="tw3-text-sm">
								{{ t('reportsPage.textMessagesTemplate.templates') }}
							</span>
							<Select
								v-model="selectedTemplate"
								class="w-full md:w-56"
								optionLabel="name"
								:options="emailTemplates"
								:placeholder="`${t('reportsPage.textMessagesTemplate.selectTemplate')}...`"
								showClear
								@update:model-value="fillFormWithTemplate"
							>
								<template #footer>
									<div class="tw3-flex tw3-w-full tw3-p-1">
										<Button
											class="tw3-w-full"
											:label="
												t('reportsPage.textMessagesTemplate.manageTemplates')
											"
											severity="secondary"
											@click="showTemplatesDialog = true"
										/>
									</div>
								</template>
							</Select>
						</div>
					</div>
					<form
						class="tw3-grid tw3-grid-cols-12 tw3-gap-4 tw3-items-start"
						@submit.prevent="submitForm"
					>
						<div class="tw3-col-span-4 tw3-flex-col tw3-flex tw3-gap-2">
							<span class="tw3-text-sm">{{ t('to') }} *</span>
							<InputText
								v-model="destinationInput"
								:invalid="destinationError ? true : false"
								:placeholder="`${t('reportsPage.textMessagesTemplate.typeAndEnter')}...`"
								@keydown.enter.prevent="addEmail(destinationInput)"
							/>
							<div
								v-if="destination.length"
								class="tags tw3-flex tw3-flex tw3-gap-2 tw3-flex-wrap"
							>
								<Tag v-for="tag in destination" :key="tag" severity="secondary">
									<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-text-sm">
										<span> {{ tag.value }} </span>
										<i
											class="pi pi-times-circle tw3-cursor-pointer"
											style="font-size: 0.7rem"
											@click="deleteEmail(tag.value)"
										></i>
									</div>
								</Tag>
							</div>
							<span v-if="destinationError" class="p-error">
								{{ destinationError }}
							</span>
						</div>
						<div class="tw3-col-span-4 tw3-flex-col tw3-flex tw3-gap-2">
							<span class="tw3-text-sm">{{ t('replyTo') }}</span>
							<InputText
								v-model="replyTo"
								:invalid="replyToError ? true : false"
								:placeholder="`${t('replyTo')}...`"
							/>
							<span v-if="replyToError" class="p-error">
								{{ replyToError }}
							</span>
						</div>

						<div
							class="tw3-col-span-4 tw3-flex tw3-gap-2 tw3-items-center tw3-pt-[30px]"
						>
							<Checkbox
								v-model="ccReplyTo"
								binary
								:disabled="!replyTo"
								inputId="ccReplyTo"
							/>
							<label class="tw3-select-none" for="ccReplyTo">
								CC {{ t('replyTo') }}
							</label>
						</div>
						<div class="tw3-col-span-12 tw3-flex-col tw3-flex tw3-gap-2">
							<span class="tw3-text-sm">{{ t('subject') }} *</span>
							<InputText
								v-model="subject"
								:invalid="subjectError ? true : false"
								:placeholder="`${t('subject')}...`"
							/>
							<span v-if="subjectError" class="p-error">{{
								subjectError
							}}</span>
						</div>

						<div class="tw3-col-span-12 tw3-flex-col tw3-flex tw3-gap-2">
							<Editor
								ref="bodyEditor"
								v-model="body"
								class="tw3-col-span-12"
								editorStyle="height: 250px"
								@load="onEditorLoad"
							/>
							<span v-if="bodyError" class="p-error">{{ bodyError }}</span>
						</div>

						<div
							class="tw3-flex-col tw3-flex tw3-gap-4 tw3-col-span-12 md:tw3-col-span-4"
						>
							<div class="tw3-flex tw3-flex-col tw3-gap-2">
								<span class="tw3-text-sm"> {{ t('attachments') }} </span>
								<div class="tw3-flex tw3-items-center tw3-gap-2">
									<ToggleSwitch
										v-model="reportIsAttaching"
										@update:model-value="togglePreparedAttachment"
									/>
									<p
										class="tw3-select-none tw3-cursor-pointer"
										@click="
											() => {
												togglePreparedAttachment(!reportIsAttaching);
												reportIsAttaching = !reportIsAttaching;
											}
										"
									>
										{{ data.fileObj.name }}
									</p>
								</div>
							</div>
							<div class="tw3-flex tw3-flex-col tw3-flex tw3-gap-2">
								<span class="tw3-text-sm">
									{{ t('additionalAttachments') }}
								</span>

								<Button
									:label="t('faxDialog.uploadFax.chooseFile')"
									severity="secondary"
									type="button"
									@click="openFilePicker"
								/>
							</div>
						</div>

						<div
							class="tw3-col-span-12 md:tw3-col-span-8 tw3-gap-4 tw3-flex tw3-flex-col tw3-justify-between tw3-items-end tw3-h-full"
						>
							<div
								class="tw3-flex tw3-gap-2 tw3-flex-wrap tw3-items-end tw3-justify-end"
							>
								<template v-for="item in attachmentUrls" :key="item.value.url">
									<Tag
										v-if="item.value.url !== data.fileObj.url"
										class="tw3-flex tw3-gap-2 tw3-items-center tw3-max-w-full"
										severity="secondary"
									>
										<span
											class="tw3-max-w-full tw3-overflow-hidden tw3-text-ellipsis tw3-whitespace-nowrap tw3-justify-start"
										>
											{{ item.value.name }}
										</span>
										<i
											class="pi pi-times-circle tw3-cursor-pointer"
											style="font-size: 0.7rem"
											@click="deleteFile(item.value.url)"
										></i>
									</Tag>
								</template>
							</div>

							<div class="tw3-flex tw3-gap-4 tw3-items-end tw3-w-8/12">
								<Button
									class="tw3-w-6/12"
									:label="t('cancel')"
									severity="secondary"
									@click="showModal = false"
								/>
								<Button class="tw3-w-6/12" label="Send" type="submit" />
							</div>
						</div>
					</form>
				</TabPanel>
				<TabPanel class="tw3-min-h-full" value="history">
					<EmailHistory />
				</TabPanel>
			</TabPanels>
		</Tabs>
		<TextMessagesTemplates
			v-model:visible="showTemplatesDialog"
			@hide="refreshEmailTemplates"
		/>
	</Dialog>
</template>

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import * as filestack from 'filestack-js';
import { PickerFileMetadata, PickerResponse } from 'filestack-js';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Editor from 'primevue/editor';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import Tag from 'primevue/tag';
import ToggleSwitch from 'primevue/toggleswitch';
import { useToast } from 'primevue/usetoast';
import { useField, useFieldArray, useForm } from 'vee-validate';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import useAppStore from '@/entities/app/lib/store';
import { sendEmailReport } from '@/widgets/reportsElements/api/api';
import useEmailStore from '@/widgets/reportShareDialog/model/store';

import { EmailTemplate, TextMessageTemplate } from '../model/types';
import TextMessagesTemplates from '../textMessagesTemplates/TextMessagesTemplates.vue';

import EmailHistory from './EmailHistory.vue';

const { t } = useI18n();

const props = defineProps<{
	data: {
		fileObj: {
			name: string;
			url: string;
		};
		fileType: string;
		shops: number[];
	};
}>();

const appStore = useAppStore();
const emailStore = useEmailStore();
const emailTemplates = ref<EmailTemplate[]>([]);
const showModal = defineModel<boolean>('visible');
const currentTab = ref<string>('email');
const bodyEditor = ref();
const emailShortcuts = [
	{
		label: 'SGC',
		address: 'invoicingfax@safelite.com'
	},
	{
		label: 'SGC-POP',
		address: 'mailbox.sgcpop@safelite.com'
	},
	{
		label: 'SGC-Shop Care',
		address: 'shopcare@sgcnetwork.com'
	},
	{
		label: 'SGC Research',
		address: 'researchfax@safelite.com'
	},
	{
		label: 'Lynx',
		address: 'fieldsupport@lynxservices.com'
	}
];
const selectedTemplate = ref<EmailTemplate>();
const toast = useToast();

const refreshEmailTemplates = async () => {
	emailTemplates.value = await emailStore.getEmailTemplatesHandler(8);
};

const updateDataInTab = (tabValue: string | number) => {
	if (tabValue === 'history') {
		emailStore.emailHistory.execute(0, props.data.fileType);
	}
};

onMounted(() => {
	refreshEmailTemplates();
});

const openFilePicker = () => {
	const client = filestack.init(appStore.appConfig.state.filePickerKey);
	const options = {
		accept: ['application/pdf'],
		maxFiles: 1,
		onUploadDone: addFile
	};
	client.picker(options).open();
};

let quillInstance: any = null;
const onEditorLoad = ({ instance }: { instance: any }) => {
	quillInstance = instance;
};
const fillFormWithTemplate = (value: TextMessageTemplate | null) => {
	if (value) {
		subject.value = value.subject;
		updateEditorContent(value.body);
	} else {
		clearPreparedTemplate();
	}
};

const updateEditorContent = (value: string) => {
	if (quillInstance) {
		quillInstance.setContents(
			quillInstance.clipboard.convert({
				html: value
			})
		);
	}
};

const clearPreparedTemplate = () => {
	subject.value = '';
	updateEditorContent('');
};

const showTemplatesDialog = ref(false);

const validationSchema = toTypedSchema(
	zod.object({
		destination: zod
			.array(
				zod
					.string()
					.email(t('reportsPage.textMessagesTemplate.errors.emailInvalid'))
			)
			.min(1, t('reportsPage.textMessagesTemplate.errors.emailReq')),
		replyTo: zod
			.string()
			.email(t('reportsPage.textMessagesTemplate.errors.emailInvalid'))
			.optional()
			.or(zod.literal('')),
		ccReplyTo: zod.boolean(),

		subject: zod
			.string()
			.min(3, t('reportsPage.textMessagesTemplate.errors.subject')),
		body: zod
			.string()
			.min(10, t('reportsPage.textMessagesTemplate.errors.body')),
		attachmentUrls: zod.array(zod.object({})).optional().default([])
	})
);

const formObject = useForm({
	validationSchema,
	initialValues: {
		destination: [],
		replyTo: '',
		ccReplyTo: false,
		subject: '',
		body: '',
		attachmentUrls: []
	}
});

const {
	fields: destination,
	push: addDestination,
	remove: removeDestination
} = useFieldArray<string>('destination');
const { value: replyTo, errorMessage: replyToError } =
	useField<string>('replyTo');
const { value: ccReplyTo } = useField<boolean>('ccReplyTo');
const { value: subject, errorMessage: subjectError } =
	useField<string>('subject');
const { value: body, errorMessage: bodyError } = useField<string>('body');
const {
	fields: attachmentUrls,
	push: addAttachment,
	remove: removeAttachment
} = useFieldArray<{ url: string; name: string }>('attachmentUrls');

const destinationInput = ref();
const addEmail = (email: string) => {
	if (!email.trim() || destination.value.some(field => field.value === email))
		return;
	destinationError.value = null;

	addDestination(email);
	destinationInput.value = '';
};

const deleteEmail = (email: string) => {
	const index = destination.value.findIndex(field => field.value === email);

	destinationError.value = null;
	if (index !== -1) {
		removeDestination(index);
	}
};

const customFiles = ref<PickerFileMetadata[]>([]);
const addFile = (result: PickerResponse) => {
	const uploadedFile = {
		name: result.filesUploaded[0].filename,
		url: result.filesUploaded[0].url
	};
	addAttachment(uploadedFile);
};

const deleteFile = (url: string) => {
	const index = attachmentUrls.value.findIndex(item => item.value.url === url);

	if (index !== -1) {
		customFiles.value.splice(index, 1);
		removeAttachment(index);
	}
};

const reportIsAttaching = ref(true);
const togglePreparedAttachment = (value: boolean) => {
	if (value) {
		addAttachment(props.data.fileObj);
	} else {
		deleteFile(props.data.fileObj.url);
	}
};

onMounted(() => {
	togglePreparedAttachment(reportIsAttaching.value);
});

const destinationError = ref<string | null>();

const isFormLoading = ref(false);
const submitForm = async () => {
	const result = await formObject.validate();

	if (result.valid) {
		const files = formObject.values.attachmentUrls?.map(item => item.url);

		try {
			await sendEmailReport({
				data: {
					to: formObject.values.destination,
					body: formObject.values.body,
					subject: formObject.values.subject,
					replyTo: formObject.values.replyTo,
					ccReplyTo: formObject.values.ccReplyTo ? 1 : null
				},
				fileType: 'report',
				shops: props.data.shops,
				attachmentUrls: files
			});
			isFormLoading.value = false;
			closeModal();
			toast.add({
				life: 3000,
				severity: 'success',
				detail: t('reportsPage.textMessagesTemplate.emailSended'),
				summary: t('copyTextToast.succesSummary')
			});
		} catch (error) {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: t('defaultToast.errorDetails'),
				summary: `${t('defaultToast.errorSummary')} ${error}`
			});
			isFormLoading.value = false;
		}
	} else {
		const pattern = /^destination(\[\d+\])?$/;

		const firstMatchingKey = Object.keys(result.errors).find(key =>
			pattern.test(key)
		);
		destinationError.value = result.errors[firstMatchingKey as string];
	}
};

const closeModal = () => {
	showModal.value = false;
};
</script>

<style scoped>
:deep(.input-error) {
	border-color: red !important;
}
:deep(.p-error) {
	color: red !important;
	font-size: 12px !important;
	line-height: 1 !important;
}
</style>
