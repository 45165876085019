<script setup lang="ts">
import { FilterMatchMode } from '@primevue/core';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import { computed, ref } from 'vue';

type DateFilter = {
	val?: { val_1?: string | null; val_2?: string | null } | null;
	type: string;
};

const props = defineProps<{
	modelValue: DateFilter;
	name: string;
}>();
const emit = defineEmits<{ (e: 'update:modelValue', v: DateFilter): void }>();

const formattedPrice1 = computed(() =>
	props.modelValue.val?.val_1 ? +props.modelValue.val.val_1 : null
);
const formattedPrice2 = computed(() =>
	props.modelValue.val?.val_2 ? +props.modelValue.val.val_2 : null
);

const options = ref([
	{ name: 'Single Value', id: FilterMatchMode.EQUALS },
	{ name: 'Value Range', id: FilterMatchMode.BETWEEN }
]);

const updateMatchMode = (v: string) => {
	emit('update:modelValue', { type: v, val: null });
};

const updatePrice1 = (v: number) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_2
				? {
						val_1: v ? v.toString() : null,
						val_2: props.modelValue.val?.val_2
					}
				: null
	});
};

const updatePrice2 = (v: number) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_1
				? {
						val_1: props.modelValue.val?.val_1,
						val_2: v ? v.toString() : null
					}
				: null
	});
};
</script>

<template>
	<Select
		appendTo="self"
		class="p-column-filter"
		:modelValue="modelValue.type"
		optionLabel="name"
		:options="options"
		optionValue="id"
		@update:model-value="updateMatchMode"
	/>
	<InputText
		:modelValue="formattedPrice1"
		:placeholder="
			modelValue.type === FilterMatchMode.BETWEEN
				? `${name} from`
				: `Search by ${name}`
		"
		type="number"
		@update:model-value="updatePrice1"
	/>
	<InputText
		v-if="modelValue.type === FilterMatchMode.BETWEEN"
		:modelValue="formattedPrice2"
		:placeholder="`${name} to`"
		type="number"
		@update:model-value="updatePrice2"
	/>
</template>
