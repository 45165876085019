export const blackOrWhiteFromRGBA = (rgbOrRgba: string) => {
	const backgroundColorParts = rgbOrRgba
		.replace('rgb(', '')
		.replace('rgba(', '')
		.replace(')', '')
		.split(',')
		.map(Number);
	if (backgroundColorParts.length < 3) return 'black';

	const [r, g, b] = backgroundColorParts;
	const contrast = (r * 299 + g * 587 + b * 114) / 1000;

	return contrast >= 128 ? 'black' : 'white';
};
