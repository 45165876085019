<template>
	<div
		v-if="customShopDataFilterOptions"
		class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
	>
		<p
			class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
		>
			{{ capitalizeStr(shopDataLabel) }}
			<Badge
				v-if="reportsStore.isRequired(customShopDataFilterValue.type)"
				v-tooltip.top="t('required')"
				class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
				severity="danger"
			/>
		</p>
		<ReportsSelectFilter
			v-model="customShopDataFilterValue.value"
			v-tooltip.top="
				!reportsStore.selectedShop || !reportsStore.selectedShop.length
					? 'Shop is required'
					: null
			"
			:disabled="
				!reportsStore.selectedShop || !reportsStore.selectedShop.length
			"
			:dropdownOptions="customShopDataFilterOptions"
			filter
			:loading="customShopDataFilterOptionsLoading"
			:optionGroupChildren="currentReportType.filter"
			optionGroupLabel="name"
			optionLabel="name"
			optionValue="id"
			:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
			@before-show="
				() => {
					if (!customShopDataFilterOptions.length)
						reportsStore.refetchCustomShopFilter();
				}
			"
		/>
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Badge from 'primevue/badge';
import { useI18n } from 'vue-i18n';

import { capitalizeStr } from '@/shared/helpers';
import useReportsStore from '@/widgets/reportsElements/model/store';

// import { customFiltersTypes } from '../../api/types';

import ReportsSelectFilter from './ReportsSelectFilter.vue';

const { t } = useI18n();

const reportsStore = useReportsStore();

const {
	currentReportType,
	shopDataLabel,
	customShopDataFilterOptions,
	customShopDataFilterOptionsLoading,
	customShopDataFilterValue
} = storeToRefs(reportsStore);
</script>
