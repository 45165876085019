<template>
	<div class="tw3-w-full">
		<div v-if="!singleSelection && dropdownOptions">
			<MultiSelect
				v-model="internalValue"
				class="tw3-w-full"
				:disabled="disabled"
				display="chip"
				:filter="filter"
				:invalid="!!errMsg && required"
				:optionGroupChildren="optionGroupChildren"
				:optionGroupLabel="optionGroupLabel"
				:optionLabel="optionLabel"
				:optionValue="optionValue"
				:options="dropdownOptions"
				:placeholder="placeholder"
				@before-show="$emit('before-show')"
				@update:model-value="handleUpdate"
			>
			</MultiSelect>
		</div>
		<Select
			v-else-if="dropdownOptions"
			v-model="internalSingleValue"
			class="tw3-w-full"
			:disabled="disabled"
			display="chip"
			:filter="filter"
			:invalid="!!errMsg && required"
			:optionLabel="optionLabel"
			:optionValue="optionValue"
			:options="dropdownOptions"
			:placeholder="placeholder"
			@before-show="$emit('before-show')"
			@update:model-value="handleSingleUpdate"
		/>
	</div>
</template>

<script setup lang="ts">
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import { ref, watch } from 'vue';

const props = withDefaults(
	defineProps<{
		modelValue?: (string | number)[] | null | string | number; // Always an array
		placeholder?: string;
		errMsg?: string | null;
		disabled?: boolean;
		optionLabel?: string;
		optionValue?: string;
		dropdownOptions: Record<string, any>[] | string[] | null;
		required?: boolean;
		filter?: boolean;
		singleSelection?: boolean;
		optionGroupChildren?: string;
		optionGroupLabel?: string;
		invalid?: boolean;
	}>(),
	{
		errMsg: undefined,
		modelValue: null,
		placeholder: undefined,
		optionLabel: '',
		optionValue: '',
		required: false,
		filter: true,
		singleSelection: false,
		optionGroupChildren: undefined,
		optionGroupLabel: undefined
	}
);

const emit = defineEmits<{
	(e: 'update:modelValue', value: any[]): void;
	(e: 'before-show'): void;
}>();

const internalValue = ref(props.modelValue);

const internalSingleValue = ref(props.modelValue?.[0] || null);

// const readableValue = computed(() => {
// 	let preparedString = '';
// 	if (
// 		!props.dropdownOptions ||
// 		props.singleSelection ||
// 		!Array.isArray(internalValue.value)
// 	)
// 		return;

// 	let isFirst = true;
// 	let count = 0;
// 	props.dropdownOptions.forEach(item => {
// 		if (props.optionGroupChildren && item[props.optionGroupChildren]) {
// 			item[props.optionGroupChildren].forEach(element => {
// 				if (count >= 25) {
// 					if (count == 25) {
// 						preparedString += `

// 						---- More ----

// 						`;
// 						count++;
// 					}

// 					return;
// 				}
// 				if (
// 					internalValue.value &&
// 					internalValue.value.includes(element[props.optionValue])
// 				) {
// 					preparedString += isFirst
// 						? ` ${element[props.optionLabel]}`
// 						: `, ${element[props.optionLabel]} `;
// 					isFirst = false;
// 					count++;
// 				}
// 			});
// 		} else {
// 			if (count >= 25) {
// 				if (count == 25) {
// 					preparedString += ', And more...';
// 					count++;
// 				}

// 				return;
// 			}
// 			if (
// 				internalValue.value &&
// 				internalValue.value.includes(item[props.optionValue])
// 			) {
// 				preparedString += isFirst
// 					? `${item[props.optionLabel]}`
// 					: `, ${item[props.optionLabel]}`;
// 				isFirst = false;
// 				count++;
// 			}
// 		}
// 	});

// 	return preparedString;
// });

watch(
	() => props.modelValue,
	newValue => {
		internalValue.value = newValue;
		internalSingleValue.value = newValue?.[0] || null;
	}
);

watch(
	() => props.singleSelection,
	single => {
		if (single) {
			internalSingleValue.value = internalValue.value?.[0] || null;
			if (props.modelValue && props.modelValue.length) {
				handleUpdate([internalSingleValue.value]);
			}
		} else {
			internalValue.value = internalSingleValue.value
				? [internalSingleValue.value]
				: [];
		}
	}
);

// Update handler for MultiSelect
const handleUpdate = (value: any[]) => {
	internalValue.value = value;
	emit('update:modelValue', value);
};

// Update handler for Select
const handleSingleUpdate = (value: any) => {
	internalSingleValue.value = value;
	emit('update:modelValue', value ? [value] : []);
};
</script>
