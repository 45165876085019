<script setup lang="ts">
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DatePicker from 'primevue/datepicker';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import SplitButton from 'primevue/splitbutton';
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import { Quote } from '@/entities/quote/lib/types';
import { isEmptyValue } from '@/shared/helpers';
import { RefreshButton } from '@/shared/ui';

import { header } from './lib/constants';
import { useQuotesStore } from './lib/store';

import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';

const { t } = useI18n();

const confirm = useConfirm();

const quotesStore = useQuotesStore();

const bulkActions = [
	{
		label: 'Void',
		command: () => {
			handleVoid();
		}
	}
];

const handlePage = (state: DataTablePageEvent) => {
	quotesStore.pagination.page = state.page + 1;
};

const handleSort = (state: DataTableSortEvent) => {
	quotesStore.pagination.page = 1;

	quotesStore.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};

const getFilterIconClass = (field: string) => {
	const filter = quotesStore.filters[field];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const refreshQuotes = async () => {
	quotesStore.quotes.execute(0, await quotesStore.composeGetQuotesArgs());
};

const handleVoid = async () => {
	confirm.require({
		accept: async () => {
			const ids = quotesStore.selectedQuotes.map((row: Quote) => row.id);
			await quotesStore.voidQuotesAndRefetchQuotes(ids);
			quotesStore.selectedQuotes = [];
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('voidJobs.title'),
		message: t('voidJobs.message'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const handleFollowupDate = async (id: string, newDate: Date | null) => {
	confirm.require({
		accept: async () => {
			await quotesStore.updateQuoteAndRefetchQuotes(id, newDate);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('changeFollowupDate.title'),
		message: t('changeFollowupDate.message'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};
</script>

<template>
	<DataTable
		v-model:filters="quotesStore.filters"
		v-model:selection="quotesStore.selectedQuotes"
		dataKey="id"
		filterDisplay="menu"
		lazy
		:loading="quotesStore.quotes.isLoading"
		paginator
		:rows="quotesStore.pagination.pageSize"
		scrollable
		:sortField="quotesStore.sort.field"
		:sortOrder="quotesStore.sort.desc === false ? 1 : -1"
		tableClass="tw3-max-w-full"
		:totalRecords="quotesStore.quotes.state.numberOfRowsFound"
		:value="quotesStore.quotes.state.rows"
		@page="handlePage"
		@sort="handleSort"
	>
		<template #header>
			<div
				class="tw3-flex tw3-justify-between tw3-gap-2"
				style="padding-right: 2.5px"
			>
				<div>
					<Select
						v-model="quotesStore.selectedShop"
						optionLabel="name"
						:options="quotesStore.shops"
						:placeholder="`${t('selectShop')}`"
					/>
					<SplitButton
						v-if="quotesStore.selectedQuotes.length"
						class="customized-split-button tw3-ml-5"
						label="Bulk Actions"
						:model="bulkActions"
						outlined
					/>
				</div>
				<RefreshButton @click="refreshQuotes()" />
			</div>
		</template>

		<Column headerStyle="width: 3rem" selectionMode="multiple"></Column>
		<Column
			v-for="column of header"
			:key="column.key"
			:field="column.key"
			:filterField="column.key"
			:header="column.title"
			headerClass="tw3-whitespace-nowrap"
			:showFilterMatchModes="false"
			:showFilterMenu="column.key !== 'actions'"
			:sortable="column.key !== 'actions'"
			:style="{
				width:
					column.key === 'customerPhone'
						? '12%'
						: column.key === 'followupDate'
							? '13%'
							: column.key === 'quoteDate'
								? '8%'
								: 'auto'
			}"
		>
			<template #body="{ data }">
				<div class="cell-container">
					<RouterLink
						v-if="column.key === 'id'"
						target="_blank"
						:to="`/jobs/${data[column.key]}`"
					>
						{{ data[column.key] }}
					</RouterLink>
					<DatePicker
						v-else-if="column.key === 'followupDate'"
						appendTo="#vue3app"
						dateFormat="m/d/yy"
						mask="9/9/9999"
						:modelValue="data[column.key] ? new Date(data[column.key]) : null"
						placeholder="M/D/YYYY"
						showIcon
						@update:model-value="
							v => handleFollowupDate(data.id, v as Date | null)
						"
					/>
					<div
						v-else
						:class="{
							'tw3-text-center': column.key === 'quoteDate',
							'tw3-text-right': column.key === 'amount'
						}"
					>
						{{ data[column.key] }}
					</div>
				</div>
			</template>
			<template #filtericon>
				<i :class="getFilterIconClass(column.key)"></i>
			</template>
			<template #filter="{ filterModel }">
				<DatePicker
					v-if="column.key === 'quoteDate' || column.key === 'followupDate'"
					v-model="filterModel.value"
					appendTo="#vue3app"
					dateFormat="m/d/yy"
					mask="9/9/9999"
					placeholder="M/D/YYYY"
				/>
				<InputNumber
					v-else-if="column.key === 'customerPhone' || column.key === 'id'"
					allowEmpty
					class="p-column-filter"
					:modelValue="filterModel.value"
					:placeholder="`Search by ${column.title}`"
					type="number"
					:useGrouping="false"
					@update:model-value="v => (filterModel.value = v ? String(v) : v)"
				/>
				<InputNumber
					v-else-if="column.key === 'amount'"
					allowEmpty
					class="p-column-filter"
					currency="USD"
					locale="en-US"
					mode="currency"
					:modelValue="filterModel.value"
					:placeholder="`Search by ${column.title}`"
					@update:model-value="v => (filterModel.value = v ? String(v) : v)"
				/>
				<Select
					v-else-if="column.key === 'gbCsrId'"
					v-model="filterModel.value"
					class="p-column-filter"
					:loading="quotesStore.csrs.isLoading"
					optionLabel="name"
					:options="quotesStore.csrs.state"
					optionValue="id"
					:placeholder="`Search by ${column.title}`"
				/>
				<InputText
					v-else
					v-model="filterModel.value"
					class="p-column-filter"
					:placeholder="`Search by ${column.title}`"
					type="text"
				/>
			</template>
		</Column>

		<template v-if="!quotesStore.quotes.isLoading" #empty>
			<div
				class="tw3-w-full tw3-h-[600px] tw3-flex tw3-justify-center tw3-items-center"
			>
				<p class="tw3-text-center tw3-text-3xl">{{ t('noData') }}</p>
			</div>
		</template>
	</DataTable>
</template>

<style lang="scss" scoped>
:deep(.customized-split-button) {
	.p-splitbutton-button {
		color: var(--p-select-color);
		border-color: var(--p-form-field-border-color);
	}

	.p-splitbutton-button:hover {
		color: var(--p-select-color);
		border-color: var(--p-form-field-hover-border-color);
		background: transparent;
	}

	.p-splitbutton-button:focus {
		color: var(--p-select-color);
		border-color: var(--p-form-field-focus-border-color);
	}

	.p-splitbutton-dropdown {
		color: var(--p-select-dropdown-color);
		border-color: var(--p-form-field-border-color);
	}

	.p-splitbutton-dropdown:hover {
		color: var(--p-select-dropdown-color);
		border-color: var(--p-form-field-hover-border-color);
		background: transparent;
	}

	.p-splitbutton-dropdown:focus {
		color: var(--p-select-dropdown-color);
		border-color: var(--p-form-field-focus-border-color);
	}
}
</style>
