import { ComposerTranslation } from 'vue-i18n';

import { defaultClaimDetailsState } from '@/entities/claim/lib/constants';
import { ClaimDetails } from '@/entities/claim/lib/types';
import { JobDetails } from '@/entities/job/model/types';
import { useInsuranceStore } from '@/widgets/insurance/lib/store';

export const validateJob = (
	jobDetails: JobDetails,
	t: ComposerTranslation
): string[] => {
	const requiredFields = [];
	if (!jobDetails.customer?.address?.city) {
		requiredFields.push(t('customerCity'));
	}
	if (!jobDetails.customer?.name?.first) {
		requiredFields.push(t('customerFirstName'));
	}
	if (!jobDetails.customer?.name?.last) {
		requiredFields.push(t('customerLastName'));
	}
	if (!jobDetails.customer?.address?.zip) {
		requiredFields.push(t('customerPostalCode'));
	}
	if (!jobDetails.customer?.address?.state) {
		requiredFields.push(t('customerState'));
	}
	if (!jobDetails.customer?.address?.address) {
		requiredFields.push(t('customerStreetAddress'));
	}
	if (jobDetails.customer?.phones.length === 0) {
		requiredFields.push(t('customerPhone'));
	}
	if (!jobDetails.job.loss_date) {
		requiredFields.push(t('lossDate'));
	}
	if (!jobDetails.job.policy_number) {
		requiredFields.push(t('policyNumber'));
	}
	if (!jobDetails.vehicle?.make) {
		requiredFields.push(t('vehicleMake'));
	}
	if (!jobDetails.vehicle?.model) {
		requiredFields.push(t('vehicleModel'));
	}
	if (!jobDetails.vehicle?.vin) {
		requiredFields.push(t('vehicleVin'));
	}
	if (jobDetails.vehicle?.year === 0) {
		requiredFields.push(t('vehicleYear'));
	}
	if (jobDetails.vehicle?.nags_id === 0) {
		requiredFields.push(t('vehicleNagsId'));
	}
	if (!jobDetails.vehicle?.style) {
		requiredFields.push(t('vehicleStyle'));
	}
	return requiredFields;
};

export const newClaimFromJob = (jobDetails: JobDetails): ClaimDetails => {
	const insuranceStore = useInsuranceStore();

	// Try to guess the insurer based on the bill to name
	const insurers = insuranceStore.insurances.state;
	let insurerId = '';
	const billToName = jobDetails.billTo.company_name.toLowerCase();
	for (let i = 0; i < insurers.length; i++) {
		const name = insurers[i].name.toLowerCase();
		if (billToName === name) {
			insurerId = insurers[i].externalId;
			break;
		}
		if (billToName.includes(name) || name.includes(billToName)) {
			insurerId = insurers[i].externalId;
		}
	}

	return {
		...defaultClaimDetailsState,
		jobId: jobDetails.job.id,
		shopId: jobDetails.job.shop_id,
		...{
			ackDetails: {
				...defaultClaimDetailsState.ackDetails
			},
			additionalInfo: { ...defaultClaimDetailsState.additionalInfo },
			agent: { ...defaultClaimDetailsState.agent },
			claimResult: { ...defaultClaimDetailsState.result },
			customer: {
				...defaultClaimDetailsState.customer,
				city: jobDetails.customer?.address?.city,
				firstName: (jobDetails.customer?.name?.first || '').substring(0, 20),
				id: jobDetails.customer?.id,
				lastName: (jobDetails.customer?.name?.last || '').substring(0, 20),
				phone1:
					jobDetails.customer?.phones.length > 0
						? jobDetails.customer?.phones[0].number
						: '',
				phone2:
					jobDetails.customer?.phones.length > 1
						? jobDetails.customer?.phones[1].number
						: '',
				postalCode: jobDetails.customer?.address?.zip,
				state: jobDetails.customer?.address?.state,
				streetAddress: jobDetails.customer?.address?.address
			},
			insurance: {
				...defaultClaimDetailsState.insurance,
				dateOfLoss: jobDetails.job.loss_date,
				insurerId: insurerId,
				policyState: jobDetails.customer?.address?.state,
				policyNumber: jobDetails.job.policy_number
			},
			vehicle: {
				...defaultClaimDetailsState.vehicle,
				id: jobDetails.vehicle?.id,
				imageUrl: jobDetails.vehicle?.image_url,
				make: (jobDetails.vehicle?.make || '').substring(0, 20),
				makeId: jobDetails.vehicle?.make_id,
				model: (jobDetails.vehicle?.model || '').substring(0, 20),
				nagsId: jobDetails.vehicle?.nags_id
					? '' + jobDetails.vehicle?.nags_id
					: '',
				plateNumber: jobDetails.vehicle?.plate_number,
				style: (jobDetails.vehicle?.style || '').substring(0, 25),
				thumbUrl: jobDetails.vehicle?.thumb_url,
				vin: jobDetails.vehicle?.vin,
				year: jobDetails.vehicle?.year
			}
		}
	};
};
