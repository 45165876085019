import {
	createRouter,
	createWebHistory,
	NavigationFailure,
	RouteLocationRaw,
	type RouteRecordRaw
} from 'vue-router';

export const routes: RouteRecordRaw[] = [
	{
		component: async () =>
			await import(
				/* webpackChunkName: "accounting" */ '@/pages/customers/CustomersPage.vue'
			),
		path: '/customers',
		name: 'Customers'
	},
	{
		component: async () =>
			await import(
				/* webpackChunkName: "dashboardPage" */ '@/pages/dashboard/DashboardPage.vue'
			),
		path: '/dashboard',
		name: 'Home'
	}
];

export const router = createRouter({
	history: createWebHistory(`${process.env.BASE_URL ?? ''}`),
	routes
});

// Redirect navigation requests to the vue2 micro frontend
(router => {
	const changeRoute = (detail: any): void => {
		window.dispatchEvent(new CustomEvent('changeRoute', { detail: detail }));
	};

	router.push = (
		to: RouteLocationRaw
	): Promise<NavigationFailure | void | undefined> => {
		const path = router.resolve(to).href;
		changeRoute({ action: 'push', path });
		return Promise.resolve();
	};

	router.replace = (
		to: RouteLocationRaw
	): Promise<NavigationFailure | void | undefined> => {
		const path = router.resolve(to).href;
		changeRoute({ action: 'replace', path });
		return Promise.resolve();
	};

	router.back = (): void => {
		changeRoute({ action: 'back' });
	};

	router.forward = (): void => {
		changeRoute({ action: 'forward' });
	};

	router.go = (delta: number): void => {
		changeRoute({ action: 'go', delta });
	};
})(router);

export default router;
