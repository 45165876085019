<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Card from 'primevue/card';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

import ReportTableTitle from './reportsHelpers/ReportTableTitle.vue';
import TableGrid from './reportsHelpers/TableGrid.vue';
import AgingReportTable from './reportsTables/AgingReportTable.vue';
import BalanceSheetTable from './reportsTables/BalanceSheetTable.vue';
import CustomerSummaryTable from './reportsTables/CustomerSummaryTable.vue';
import EdiReportTable from './reportsTables/EdiReportTable.vue';
import InstallReportTable from './reportsTables/InstallReportTable.vue';
import NagsLicenseReportTable from './reportsTables/NagsLicenseReportTable.vue';
import ProfitAndLossTable from './reportsTables/ProfitAndLossTable.vue';
import RightPartReportTable from './reportsTables/RightPartReportTable.vue';
import RoyaltySummaryReportTable from './reportsTables/RoyaltySummaryReportTable.vue';
import SchedulerReportTable from './reportsTables/SchedulerReportTable.vue';
import StatementReportTable from './reportsTables/StatementReportTable.vue';
import TaxDetailReportTable from './reportsTables/TaxDetailReportTable.vue';
import TaxSummaryTable from './reportsTables/TaxSummaryTable.vue';

const { t } = useI18n();

const reportsStore = useReportsStore();

const {
	currentReportType,
	reportData,
	tableMode,
	currentColumns,
	selectedShop,
	expandAllAgingPayments
} = storeToRefs(reportsStore);
</script>

<template>
	<div
		class="reports-body-container tw3-flex tw3-w-full tw3-h-full tw3-justify-center"
	>
		<div
			v-if="
				reportsGetters.dataIsNull(reportData) ||
				reportsGetters.emptyData(reportData)
			"
			class="reports-no-data tw3-min-h-[500px] tw3-m-auto tw3-flex tw3-flex-col tw3-items-center tw3-justify-center tw3-gap-3 tw3-w-fit tw3-text-4xl"
		>
			<i
				class="pi pi-calendar-times"
				style="font-size: 2.25rem; line-height: 2.5rem"
			></i>
			<div v-if="reportsGetters.emptyData(reportData)" class="tw3-text-center">
				{{ t('reportsPage.filters.noResults') }}
			</div>
			<div v-else class="tw3-text-center">
				{{ t('reportsPage.filters.noGenerated') }}
			</div>
		</div>

		<Card
			v-else
			class="tw3-h-fit tw3-bg-white !tw3-p-0 lg:!tw3-p-3 tw3-pt-0 tw3-rounded table-container tw3-w-full tw3-max-h-[600px] tw3-overflow-auto"
			:pt="{
				body: '!tw3-p-0 lg:!tw3-p-5 tw3-w-fit lg:tw3-w-full',
				content: 'tw3-min-w-full tw3-w-max'
			}"
		>
			<template #content>
				<ReportTableTitle
					v-if="
						currentReportType.id !== 'account-statement' &&
						currentReportType.id !== 'open-invoice' &&
						currentReportType.id !== 'aging'
					"
				/>
				<div
					v-if="reportData"
					class="mobile-table-wrapper tw3-pb-5 tw3-px-16 lg:tw3-pb-0 lg:tw3-px-20 tw3-box-border tw3-min-w-full tw3-w-max"
				>
					<SchedulerReportTable
						v-if="currentReportType.group === 'scheduler'"
						:data="reportData"
					/>
					<CustomerSummaryTable
						v-else-if="currentReportType.id === 'customer-summary'"
						:data="reportData"
					/>

					<RoyaltySummaryReportTable
						v-else-if="currentReportType.group === 'royalty'"
						:data="reportData"
					/>

					<TaxDetailReportTable
						v-else-if="currentReportType.id == 'tax-detail'"
						:data="reportData"
					/>

					<TaxSummaryTable
						v-else-if="currentReportType.id === 'tax-summary'"
						:data="reportData"
					/>

					<InstallReportTable
						v-else-if="currentReportType.group === 'install'"
						:data="reportData"
					/>

					<TaxSummaryTable
						v-else-if="currentReportType.id == 'summary'"
						:data="reportData"
					/>
					<template v-else-if="currentReportType.group === 'statements'">
						<StatementReportTable
							v-for="(item, index) in reportData"
							:key="index"
							:data="item"
						/>
					</template>

					<EdiReportTable
						v-else-if="currentReportType.id === 'edi'"
						:data="reportData"
					/>

					<!-- Accounting reports -->
					<ProfitAndLossTable
						v-else-if="currentReportType.id === 'profit-and-loss'"
						:data="reportData"
					/>
					<BalanceSheetTable
						v-else-if="currentReportType.id === 'balance-sheet'"
						:data="reportData"
					/>

					<AgingReportTable
						v-else-if="currentReportType.id === 'aging'"
						:data="reportData"
						:expandPayments="expandAllAgingPayments"
						:groupBy="tableMode ?? 'none'"
						:shopId="selectedShop ? selectedShop[0] : 0"
					/>

					<RightPartReportTable
						v-else-if="
							currentReportType.group === 'right-part' &&
							currentReportType.id === 'requests'
						"
						:data="reportData"
					/>

					<NagsLicenseReportTable
						v-else-if="currentReportType.group === 'nags-license'"
						:data="reportData"
					/>

					<TableGrid
						v-else
						:class="currentReportType.group + '-' + currentReportType.id"
						:columns="currentColumns"
						:data="reportData"
						:reportType="currentReportType"
						:splitBy="tableMode ?? 'none'"
					/>
				</div>
			</template>
		</Card>
	</div>
</template>

<style scoped>
:deep(.table-head > .cell) {
	font-weight: 600;
	border-width: 0 1px 1px 0;
}

:deep(.table-row .cell) {
	background: #fff;
	border-width: 0 1px 1px 0;
}

:deep(td:not(.fake-cell)) {
	width: auto;
	padding: 10px 20px;
}

:deep(.fake-cell) {
	width: 30px;
}

:deep(.table-container) {
	box-sizing: content-box;
}

:deep(.table-container .cell) {
	display: table-cell;
	min-height: 30px;
	vertical-align: top;
	border-width: 1px 1px 0 0;
	border-color: #dcdcdc;
	border-style: solid;
	padding: 6px 12px;
	font-size: 12px;
	white-space: nowrap;
}

:deep(.table-container .cell:last-child) {
	border-right: none;
}

:deep(.table-container .cell.dollar) {
	text-align: right;
}

:deep(.table-container .cell.strong) {
	font-weight: 600;
}

:deep(.table-container .cell ul) {
	margin: 0;
	padding-left: 15px;
}

:deep(.table-container .fake-cell) {
	display: table-cell;
	width: 30px;
	min-width: 30px;
	height: 30px;
}

:deep(.table-container .sales-margin .wrapped) {
	max-width: 20px;
	word-break: break-word;
	white-space: normal;
}

:deep(.table-container .sales-sales .wrapped) {
	max-width: 150px;
	word-break: break-word;
	white-space: normal;
}
/*
:deep(.border-left-customer_name tr > .fake-cell:first-child),
:deep(.border-left-customerName tr > .fake-cell:first-child),
:deep(.border-left-name tr > .fake-cell:first-child),
:deep(.border-left-shop_name tr > .fake-cell:first-child),
:deep(.border-left-x_action_type tr > .fake-cell:first-child) {
	border-right: 2px solid #00a736;
}*/

:deep(.border-left-date tr.table-row > .fake-cell:first-child),
:deep(.border-left-date tr.table-head > .fake-cell:first-child) {
	border-right: 2px solid #3e94ff;
}

:deep(.border-left-date tr.table-totals > .cell) {
	border-top: 2px solid #3e94ff;
}

:deep(.border-left-customer_name tr.table-row > .fake-cell:first-child),
:deep(.border-left-customerName tr.table-row > .fake-cell:first-child),
:deep(.border-left-name tr.table-row > .fake-cell:first-child),
:deep(.border-left-shop_name tr.table-row > .fake-cell:first-child),
:deep(.border-left-x_action_type tr.table-row > .fake-cell:first-child),
:deep(.border-left-customer_name tr.table-head > .fake-cell:first-child),
:deep(.border-left-customerName tr.table-head > .fake-cell:first-child),
:deep(.border-left-name tr.table-head > .fake-cell:first-child),
:deep(.border-left-shop_name tr.table-head > .fake-cell:first-child),
:deep(.border-left-x_action_type tr.table-head > .fake-cell:first-child),
:deep(.border-left-customer_name tr.subhead > .fake-cell:first-child),
:deep(.border-left-customerName tr.subhead > .fake-cell:first-child),
:deep(.border-left-name tr.subhead > .fake-cell:first-child),
:deep(.border-left-shop_name tr.subhead > .fake-cell:first-child),
:deep(.border-left-x_action_type tr.subhead > .fake-cell:first-child) {
	border-right: 2px solid #00a736;
}

:deep(.border-left-customer_name tr.table-row > .fake-cell:nth-child(2)),
:deep(.border-left-customerName tr.table-row > .fake-cell:nth-child(2)),
:deep(.border-left-name tr.table-row > .fake-cell:nth-child(2)),
:deep(.border-left-shop_name tr.table-row > .fake-cell:nth-child(2)),
:deep(.border-left-x_action_type tr.table-row > .fake-cell:nth-child(2)),
:deep(.border-left-customer_name tr.table-head > .fake-cell:nth-child(2)),
:deep(.border-left-customerName tr.table-head > .fake-cell:nth-child(2)),
:deep(.border-left-name tr.table-head > .fake-cell:nth-child(2)),
:deep(.border-left-shop_name tr.table-head > .fake-cell:nth-child(2)),
:deep(.border-left-x_action_type tr.table-head > .fake-cell:nth-child(2)),
:deep(.border-left-customer_name tr.subhead > .fake-cell:nth-child(2)),
:deep(.border-left-customerName tr.subhead > .fake-cell:nth-child(2)),
:deep(.border-left-name tr.subhead > .fake-cell:nth-child(2)),
:deep(.border-left-shop_name tr.subhead > .fake-cell:nth-child(2)),
:deep(.border-left-x_action_type tr.subhead > .fake-cell:nth-child(2)) {
	border-right: 2px solid #3e94ff;
}

:deep(.border-left-none tr.table-head > .cell:first-child),
:deep(.border-left-none tr.table-row > .cell:first-child) {
	border-left: 2px solid #9e9e9e;
}
/*
.table-grid .table-cell[data-v-7ebf4cae]:first-child {
	border-left: 2px solid #9e9e9e;
}
	*/

/*$report-date-bg: #d0e5ff;
$report-date-color: #3e94ff;
$report-shop-bg: #d1ffd0;
$report-shop-color: #00a736;
$report-customer-color: #928403;
$report-customer-bg: #fdfae0;
$report-contractor-color: #928403;
$report-contractor-bg: #fdfae0;
$report-tech-bg: #d2d2d2;
$report-tech-color: #5f5f5f; 
*/
</style>
