export const getDocumentColorByType = (document_type: string) => {
	if (document_type === 'swo') {
		return '#cd0a0a';
	} else if (document_type === 'dis') {
		return '#1c94c4';
	} else if (document_type === 'pop') {
		return '#00008b';
	} else if (document_type === 'fiv') {
		return '#a577a6';
	} else if (document_type === 'quo') {
		return '#b38a7f';
	} else if (document_type === 'dl') {
		return '#a10000';
	} else if (document_type === 'ssmpic') {
		return '#005AA3';
	} else if (document_type === 'tsmpic') {
		return '#08A300';
	} else if (document_type === 'ssign') {
		return '#6db3f2';
	} else if (document_type === 'tsign') {
		return 'rgb(132, 201, 127)';
	} else if (document_type === 'oth') {
		return '#cbbce1';
	} else if (document_type === 'pic') {
		return '#6f3d8b';
	} else if (document_type === 'app') {
		return '#0086ad';
	} else if (document_type === 'auth') {
		return '#99d8d8';
	} else if (document_type === 'recal') {
		return '#ffb62b';
	} else if (document_type === 'bill') {
		return '#8CA568';
	} else if (document_type === 'bill_payment') {
		return '#407E88';
	} else if (document_type === 'expense') {
		return '#884A40';
	} else if (document_type === 'journal_entry') {
		return '#488840';
	} else if (document_type === 'esign') {
		return '#ff8800';
	} else if (document_type === 'smssign') {
		return '#0ec9b7';
	} else if (document_type === 'payment') {
		return '#33c90e';
	} else if (document_type === 'presign') {
		return '#3D5AFE';
	} else if (document_type === 'tsmcnp') {
		return '#547E51';
	}
	return '';
};
