<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import ToggleButton from 'primevue/togglebutton';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { EdiStatus } from '../../../model/enums';
import { JobEdi, JobEdiUpdateRequest } from '../../../model/types';

const { t } = useI18n();

const ediOptions = Object.values(EdiStatus).map(v => ({
	id: v,
	text: t(`edi.${v}`)
}));

const props = defineProps<{ data: JobEdi; show: boolean; loading: boolean }>();
const emit = defineEmits<{
	(e: 'on-close'): void;
	(e: 'on-update', v: JobEdiUpdateRequest): void;
}>();

const historyPopup = ref(false);
const localValue = ref<EdiStatus>(EdiStatus.Unsubmitted);
const msg = ref('');

const history = ref<{ serverMsg: string; userMsg?: string }[]>([]);

const save = async () => {
	if (!props.data) return;
	emit('on-update', {
		status: localValue.value ? localValue.value : EdiStatus.Unsubmitted,
		msg: msg.value
	});
};

const parseMessages = (input: string) => {
	return input.split(/<br><br>/).map(entry => {
		const parts = entry.split(/<br>/);
		return {
			serverMsg: parts[0].trim(),
			userMsg: parts[1] ? parts[1].trim() : undefined
		};
	});
};

watch(
	() => props.show,
	async () => {
		historyPopup.value = false;
		if (props.show) {
			localValue.value = props.data.status
				? props.data.status
				: EdiStatus.Unsubmitted;
			msg.value = '';
			history.value = props.data.job_edi_message
				? parseMessages(props.data.job_edi_message)
				: [];
		}
	}
);
</script>

<template>
	<Dialog
		id="vue3JobEditEdiDialog"
		:header="$t('edi.status')"
		modal
		:visible="show"
		@update:visible="emit('on-close')"
	>
		<template #header>
			<div class="tw3-flex tw3-items-center tw3-gap-2">
				<span class="p-dialog-title">{{ $t('edi.status') }}</span>
				<ToggleButton
					v-if="history.length"
					v-model="historyPopup"
					binary
					:disabled="loading"
					:offLabel="$t('history')"
					:onLabel="$t('history')"
					size="small"
				/>
			</div>
		</template>
		<div
			class="tw3-relative tw3-flex tw3-flex-col tw3-w-[250px] tw3-h-[313px] tw3-gap-[0.75rem] tw3-overflow-hidden"
		>
			<Transition name="slide-menu">
				<div
					v-if="historyPopup"
					class="tw3-absolute tw3-right-0 tw3-top-0 tw3-z-10 tw3-bg-white tw3-w-full tw3-h-full tw3-overflow-auto tw3-flex tw3-flex-col tw3-gap-4 history-container"
				>
					<div
						v-for="(item, index) in history"
						:key="index"
						class="tw3-bg-white tw3-rounded-2xl tw3-p-4 tw3-border tw3-border-gray-200"
					>
						<p
							v-if="item.userMsg"
							class="tw3-mb-2 tw3-font-semibold tw3-text-gray-800"
						>
							{{ $t('message') }}: {{ item.userMsg }}
						</p>
						<p class="tw3-text-gray-500 tw3-text-sm">
							{{ item.serverMsg }}
						</p>
					</div>
				</div>
			</Transition>
			<Listbox
				v-model="localValue"
				checkmark
				class="w-full md:w-56"
				:disabled="loading"
				listStyle="max-height:250px"
				optionLabel="text"
				:options="ediOptions"
				optionValue="id"
				pt:root:class="!tw3-border-0"
			>
				<template #option="slotProps">
					<div class="flex items-center">
						<div
							class="tw3-flex tw3-items-center tw3-capitalize tw3-px-2 tw3-py-0.5 tw3-gap-2 tw3-text-sm"
						>
							{{ slotProps.option.text }}
						</div>
					</div>
				</template>
			</Listbox>
			<div class="tw3-relative tw3-w-full">
				<InputText v-model="msg" class="tw3-w-full" :loading="loading" />
				<span
					class="tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-[-7px] tw3-px-[0.25rem]"
				>
					{{ $t('message') }} ({{ $t('optional') }})
				</span>
			</div>
			<Button
				class="w-full md:w-14rem"
				:label="t('save')"
				:loading="loading"
				pt:root:class="tw3-rounded-t-none"
				size="small"
				@click="save"
			/>
		</div>
	</Dialog>
</template>

<style lang="scss" scoped>
.slide-menu-enter-active,
.slide-menu-leave-active {
	transition:
		transform 0.4s ease,
		opacity 0.4s ease;
}
.slide-menu-enter-from,
.slide-menu-leave-to {
	transform: translateX(100%);
	opacity: 0;
}
.slide-menu-enter-to,
.slide-menu-leave-from {
	transform: translateX(0);
	opacity: 1;
}

.history-container {
	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}

	scrollbar-width: none;
}
</style>
