import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { storeToRefs } from 'pinia';
import { computed, onMounted, reactive, ref, watch } from 'vue';

import { useUserStore } from '@/entities/user/lib/store';
import { filterEmptyFields } from '@/shared/helpers';
import { TableFiltersWithMetadata } from '@/shared/types/tables';

import {
	addJobTag,
	deleteJobTag,
	getEdiQueueStatusCounts,
	getInvoicesByStatus,
	getQueuedInvoices,
	getRejectedInvoices,
	getShopTags,
	getUnsubmittedInvoices
} from '../api/api';

import { tableFilter } from './constants';

import type {
	invoiceDetails,
	JobTag,
	RejectedInvoice,
	RejectedInvoicesFilters
} from './types';

const useRejectedInvoicesStore = defineStore('rejectedInvoices', () => {
	const userStore = useUserStore();

	const { user } = storeToRefs(userStore);
	const shops = reactive(user.value.shops || []);
	const isUserAdmin = user.value.user.admin;
	const selectedShop = ref(shops[0]);
	const currentTab = ref('rejected');
	const sort = reactive({ sortBy: '', sortDesc: true });
	const includeVoided = ref(false);
	const filters = ref<TableFiltersWithMetadata<RejectedInvoicesFilters>>({
		...tableFilter
	});

	const pagination = reactive({
		limit: 10,
		page: 1
	});
	const invoiceDetailsData = ref<invoiceDetails>({
		message: '',
		status: ''
	});
	const showInvoiceDetail = ref<boolean>(false);
	const isFilteredByShop = computed(() => {
		return ['queued', 'rejected', 'unsubmitted'].includes(currentTab.value);
	});

	const rejectedInvoicesData = useAsyncState(
		() => {
			const preparedFilters = prepareFilterForRequest();
			let response;
			if (currentTab.value === 'rejected') {
				response = getRejectedInvoices({
					shop: selectedShop.value,
					...pagination,
					...sort,
					filters: preparedFilters
				});
			} else if (currentTab.value === 'queued') {
				response = getQueuedInvoices({
					shop: selectedShop.value,
					...pagination,
					...sort,
					filters: preparedFilters
				});
			} else if (currentTab.value === 'unsubmitted') {
				response = getUnsubmittedInvoices({
					shop: selectedShop.value,
					...pagination,
					...sort,
					filters: preparedFilters
				});
			} else {
				response = getInvoicesByStatus({
					statusFilter: currentTab.value,
					...pagination,
					...sort,
					...preparedFilters
				});
			}
			return response;
		},
		{ data: [], totalCount: 0 },
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);
	watch(rejectedInvoicesData.state, () => {
		if (isFilteredByShop.value) {
			reactiveInvoicesData.value = paginateTable(
				rejectedInvoicesData.state.value.data,
				pagination.page,
				pagination.limit
			);
		} else {
			reactiveInvoicesData.value = rejectedInvoicesData.state.value.data;
		}
	});

	function paginateTable(
		array: RejectedInvoice[],
		page: number,
		limit: number
	): RejectedInvoice[] {
		const startIndex = (page - 1) * limit;
		const endIndex = startIndex + limit;
		return array.slice(startIndex, endIndex);
	}

	const reactiveInvoicesData = ref<RejectedInvoice[]>([
		...rejectedInvoicesData.state.value.data
	]);

	const ediQueueStatusCounts = useAsyncState(
		() => {
			return getEdiQueueStatusCounts();
		},
		{},
		{
			throwError: true
		}
	);

	const changeCurrentTab = (tabKey: string) => {
		currentTab.value = tabKey;
		setDefaultRequestProperties();
	};

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') return;

		sort.sortBy = sortData.field;
		sort.sortDesc = sortData.desc;
	};

	function resetFilters<T>(filters: TableFiltersWithMetadata<T>) {
		Object.keys(filters).forEach(key => {
			filters[key as keyof T].value = '';
		});
	}

	const setDefaultRequestProperties = () => {
		resetFilters(filters.value);

		pagination.page = 1;
		pagination.limit = 10;

		sort.sortDesc = true;
		sort.sortBy = '';
	};

	const prepareFilterForRequest = () => {
		const preparedValue = filterEmptyFields(
			filters?.value as Record<string, any>,
			'value'
		);

		let result;

		if (isFilteredByShop.value) {
			result = {
				amount: preparedValue.amount,
				customerName: preparedValue.customer_name,
				id: preparedValue.id,
				includeVoid: includeVoided.value,
				referral: preparedValue.referral,
				tradingPartner: preparedValue.trading_partner
			};
		} else {
			result = {
				amountFilter: preparedValue?.amount || '',
				customerFilter: preparedValue?.customer_name || '',
				invoiceFilter: preparedValue?.id || '',
				referralNumberFilter: preparedValue?.referral || '',
				tradingPartnerFilter: preparedValue?.trading_partner || ''
			};
		}

		return result;
	};

	const shopTagsData = useAsyncState(
		() => {
			return getShopTags(selectedShop.value.id);
		},
		[],
		{
			throwError: true
		}
	);

	const addJobTagHandler = async (params: {
		jobID: number;
		oldTags: JobTag[];
		newTags: JobTag[];
	}) => {
		const preparedJobTags: JobTag[] = params.oldTags.map(item => {
			return {
				created: item.created ? item.created : -1,
				modified: item.modified ? item.modified : -1,
				id: item.id,
				job_id: item?.jobtag?.job_id,
				shop_tag_id: item?.jobtag?.shop_tag_id
			};
		});
		preparedJobTags.push(...params.newTags);

		const response = await addJobTag({
			jobID: params.jobID,
			shopID: selectedShop.value.id,
			tags: preparedJobTags
		});

		if (response !== 'error') {
			params.newTags.forEach(newTag => {
				const createdTag: JobTag | undefined = response.find(
					item => item.shop_tag_id === newTag.shop_tag_id
				);
				const tagProperties: JobTag | undefined = shopTagsData.state.value.find(
					item => item.id === newTag.shop_tag_id
				);
				const job = reactiveInvoicesData.value.find(
					item => item.id === params.jobID
				);
				if (createdTag && tagProperties && job) {
					job.tags.push({
						color: tagProperties.color,
						created: createdTag.created,
						details: tagProperties.details,
						id: createdTag.id,
						jobtag: {
							...createdTag
						},
						modified: createdTag.modified,
						shop_id: selectedShop.value.id,
						status: tagProperties.status,
						text: tagProperties.text
					});
				}
			});
			reactiveInvoicesData.value = [...reactiveInvoicesData.value];
		}

		return response;
	};

	const deleteJobTagHandler = async (params: {
		jobID: number;
		jobTagID: number;
	}) => {
		const response = await deleteJobTag({
			shopID: selectedShop.value.id,
			...params
		});
		if (response !== 'error') {
			const jobIndex = reactiveInvoicesData.value.findIndex(
				item => item.id === params.jobID
			);
			if (jobIndex !== -1) {
				const updatedJob = {
					...reactiveInvoicesData.value[jobIndex],
					tags: reactiveInvoicesData.value[jobIndex].tags.filter(
						item => item.jobtag?.id !== params.jobTagID
					)
				};

				reactiveInvoicesData.value.splice(jobIndex, 1, updatedJob);
			}
		}
		return response;
	};

	function setInvoiceDetailsData(params: {
		message: string | undefined;
		status: string | undefined;
	}) {
		showInvoiceDetail.value = true;
		invoiceDetailsData.value = {
			...params
		};
	}

	const refreshData = async () => {
		await rejectedInvoicesData.execute(0);
	};

	watch(
		[pagination, filters, sort, currentTab, selectedShop, includeVoided],
		async () => {
			await refreshData();
		}
	);

	onMounted(async () => {
		await rejectedInvoicesData.execute(0);
	});

	watch(selectedShop, async () => {
		await shopTagsData.execute(0);
	});

	return {
		addJobTagHandler,
		changeCurrentTab,
		currentTab,
		deleteJobTagHandler,
		ediQueueStatusCounts: ediQueueStatusCounts.state,
		filters,
		handleSort,
		includeVoided,
		invoiceDetailsData,
		showInvoiceDetail,
		isFilteredByShop,
		isUserAdmin,
		pagination,
		reactiveInvoicesData,
		refreshData,
		rejectedInvoicesData: rejectedInvoicesData.state,
		rejectedInvoicesDataLoading: rejectedInvoicesData.isLoading,
		selectedShop,
		setDefaultRequestProperties,
		setInvoiceDetailsData,
		shopTagsData: shopTagsData.state,
		shops,
		sort
	};
});

export default useRejectedInvoicesStore;
