<template>
	<div
		:class="[
			'tw3-flex tw3-flex-col tw3-gap-5 tw3-w-full',
			{
				'tw3-col-span-1 md:tw3-col-span-2 md:tw3-grid md:tw3-grid-cols-2 ':
					!currentReportType.date
			}
		]"
	>
		<div
			v-if="!currentReportType.date"
			class="tw3-flex tw3-flex-col tw3-w-full tw3-col-span-1"
		>
			<p
				class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
			>
				{{ capitalizeStr(t('reportsPage.filters.dateType')) }}

				<Badge
					v-tooltip.top="t('required')"
					class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
					severity="danger"
				/>
			</p>
			<Select
				v-model="dateTypeFilter"
				filter
				optionLabel="name"
				:options="dateTypes"
				:placeholder="`${t('select')} ${t('reportsPage.filters.dateType')}...`"
			/>
		</div>

		<div class="tw3-flex tw3-flex-col tw3-w-full tw3-col-span-1">
			<p
				class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
			>
				{{ capitalizeStr(currentReportType?.date || dateTypeFilter.name) }}
				<Badge
					v-if="reportsStore.isRequired('date')"
					v-tooltip.top="t('required')"
					class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
					severity="danger"
				/>
			</p>
			<DatePicker
				v-model="datePickerValue"
				dateFormat="mm/dd/yy"
				:disabled="dateTypeFilter.id === 'unscheduled'"
				hideOnDateTimeSelect
				hideOnRangeSelection
				:manualInput="false"
				:placeholder="`${isDateRange ? t('pickDates') : t('pickDate')}...`"
				:selectionMode="isDateRange ? 'range' : 'single'"
				showIcon
				@update:model-value="handleDateChange"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import Badge from 'primevue/badge';
import DatePicker from 'primevue/datepicker';
import Select from 'primevue/select';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { capitalizeStr } from '@/shared/helpers';
import useReportsStore from '@/widgets/reportsElements/model/store';

import { defaultDateTypes } from '../../model/constants';

const { t } = useI18n();

const reportsStore = useReportsStore();

const { currentReportType } = storeToRefs(reportsStore);

const dateTypes = computed(() => {
	if (currentReportType.value.group === 'sales') {
		const dateTypes = [...defaultDateTypes];

		return dateTypes;
	} else {
		const { group, id } = currentReportType.value;
		if (group === 'install') {
			const baseDates = [
				{ name: t('reportsPage.filters.invoice_date'), id: 'invoice_date' },
				{ name: t('reportsPage.filters.install_date'), id: 'install_date' }
			];

			if (id === 'tech-collections') {
				return baseDates;
			}

			return [
				...baseDates,
				{ name: 'Payment Date', id: 'payment_date' },
				{ name: 'Last Status Date', id: 'status_date' }
			];
		}

		if (group === 'scheduler' && id === 'schedule') {
			return [
				{ name: t('reportsPage.filters.scheduled_date'), id: 'scheduled_date' },
				{ name: t('reportsPage.filters.unscheduled'), id: 'unscheduled' }
			];
		}

		return [
			{ name: t('reportsPage.filters.invoice_date'), id: 'invoice_date' },
			{ name: t('reportsPage.filters.install_date'), id: 'install_date' }
		];
	}
});
const isDateRange = computed(
	() => currentReportType.value.id !== 'balance-sheet'
);
const dateTypeFilter = ref(dateTypes.value[0]);
const datePickerValue = ref();

watch(dateTypeFilter, () => {
	reportsStore.dateType = dateTypeFilter.value.id;
});
watch(dateTypes, () => {
	dateTypeFilter.value = dateTypes.value[0];
});
watch(currentReportType, () => {
	reportsStore.dateType = currentReportType.value.default_date_type
		? currentReportType.value.default_date_type
		: dateTypeFilter.value.id;
});
watch(
	() => reportsStore.dateFilterValue,
	() => {
		if (!reportsStore.dateFilterValue) {
			datePickerValue.value = null;
		}
	}
);
onMounted(() => {
	reportsStore.dateType = currentReportType.value.default_date_type
		? currentReportType.value.default_date_type
		: dateTypeFilter.value.id;
});

function handleDateChange(event: any) {
	let formattedValue;
	if (isDateRange.value) {
		const [startDate, endDate] = event;
		const formattedStartDate = startDate
			? dayjs(startDate).format('YYYY-MM-DD')
			: null;
		const formattedEndDate = endDate
			? dayjs(endDate).format('YYYY-MM-DD')
			: null;
		formattedValue = {
			startDate: formattedStartDate,
			endDate: formattedEndDate
		};
	} else {
		formattedValue = dayjs(event).format('YYYY-MM-DD');
	}

	reportsStore.dateFilterValue = formattedValue;
}
</script>
