import { toTypedSchema } from '@vee-validate/zod';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { InvalidSubmissionHandler, useFieldArray, useForm } from 'vee-validate';
import { computed, Ref, ref } from 'vue';
import { z } from 'zod';

import { Account } from '@/entities/accounting/accounts/lib/types';
import useTransactionStore from '@/entities/accounting/transactions/lib/transactionStore';
import {
	Transaction,
	TRANSACTION_TYPE_ID_LEDGER_ENTRY,
	TransactionItem
} from '@/entities/accounting/transactions/lib/types';
import { useUserStore } from '@/entities/user/store';
import useAccountingStore from '@/features/accounting/lib/store';
import { useMessages } from '@/shared/composables';

export enum LedgerEntryAction {
	INCREASE = 'INCREASE',
	DECREASE = 'DECREASE'
}

type LedgerEntryItem = {
	accountId?: number;
	amount: number;
	description: string;
};

type LedgerEntry = {
	mainAccountId: number;
	transactionDt: DateTime;
	description: string;
	mainItemDescription: string;
	shopId: number;
	items: LedgerEntryItem[];
	action: LedgerEntryAction;
	amount: number;
};

export const useLedgerEntryForm = (account: Ref<Account | undefined>) => {
	const { showFieldValidationError } = useMessages();

	const { user } = storeToRefs(useUserStore());

	const accountingStore = useAccountingStore();
	const { typesById, accounts } = storeToRefs(accountingStore);

	const transactionStore = useTransactionStore();
	const { selectedTransaction } = storeToRefs(transactionStore);

	const saving = ref(false);

	const { defineField, handleSubmit, resetForm, errors, meta } =
		useForm<LedgerEntry>({
			validationSchema: toTypedSchema(
				z.object({
					mainAccountId: z.number().min(1, 'required'),
					transactionDt: z.any().refine(value => value instanceof DateTime, {
						message: 'required'
					}),
					description: z.string().trim(),
					mainItemDescription: z.string().trim(),
					shopId: z.number().min(1, 'required'),
					action: z.nativeEnum(LedgerEntryAction),
					items: z.array(
						z.object({
							accountId: z
								.number({ message: 'Select Account' })
								.min(1, 'Select Account'),
							amount: z.number().gt(0, 'required'),
							description: z.string().trim()
						})
					)
				})
			)
		});

	const fieldLabels: Record<string, string> = {
		transactionDt: 'Transaction Date',
		description: 'Transaction Description',
		shopId: 'Shop',
		items: 'Transaction items',
		action: 'Action'
	};

	const [mainAccountId] = defineField('mainAccountId');
	const [transactionDt] = defineField('transactionDt');
	const [description] = defineField('description');
	const [mainItemDescription] = defineField('mainItemDescription');
	const [shopId] = defineField('shopId');
	const [action] = defineField('action');
	const {
		remove: removeItem,
		push: pushItem,
		fields: items
	} = useFieldArray<LedgerEntryItem>('items');

	const itemsErrors = computed(() => {
		return items.value.map((item: any, index: number) => {
			const errs = errors.value as Record<string, string>;
			return {
				accountId: errs[`items[${index}].accountId`],
				amount: errs[`items[${index}].amount`]
			};
		});
	});

	const onValidationError: InvalidSubmissionHandler = res => {
		let showItemsErrors = false;
		for (const [field, error] of Object.entries(res.errors)) {
			if (field.startsWith('items[')) {
				showItemsErrors = true;
				continue;
			}
			showFieldValidationError(fieldLabels[field] ?? field, error!);
		}
		if (showItemsErrors) {
			showFieldValidationError(
				'Transaction items',
				'Please correct the errors in the items'
			);
		}
	};

	const doSave = async (ledgerEntry: LedgerEntry) => {
		saving.value = true;

		const transaction = convertLedgerEntryToTransaction(ledgerEntry);

		selectedTransaction.value = {
			...selectedTransaction.value,
			...transaction
		};
		await transactionStore.saveSelectedTransaction();
		saving.value = false;
	};

	const init = () => {
		const values = selectedTransaction.value?.id
			? convertTransactionToLedgerEntry(
					selectedTransaction.value!,
					account.value!.id!
				)!
			: initLedgerEntry();
		resetForm({ values: values });
	};

	const reset = () => {
		resetForm({
			values: initLedgerEntry()
		});
	};

	const save = (onSuccess: () => void) => {
		handleSubmit(async (values: LedgerEntry) => {
			await doSave(values);
			onSuccess();
		}, onValidationError)();
	};

	const addItem = () => {
		pushItem({
			accountId: undefined,
			amount: 0,
			description: ''
		});
	};

	const totalAmount = computed(() =>
		items.value.reduce((acc: number, item: any) => acc + item.value.amount, 0)
	);

	const initLedgerEntry = (): LedgerEntry => {
		return {
			mainAccountId: account.value!.id!,
			mainItemDescription: '',
			transactionDt: DateTime.now().startOf('day'),
			description: '',
			shopId: user.value.user.shop_id,
			items: [
				{
					accountId: undefined,
					amount: 0,
					description: ''
				}
			],
			action: LedgerEntryAction.INCREASE,
			amount: 0
		};
	};

	const convertTransactionToLedgerEntry = (
		transaction: Transaction,
		currentAccountId?: number
	): LedgerEntry | null => {
		if (transaction.typeId !== TRANSACTION_TYPE_ID_LEDGER_ENTRY) {
			console.error('Transaction is not a ledger entry');
			return null;
		}

		let mainItem = null;
		// Ledger entry with only one record - transfer between two accounts
		if (transaction.items.length == 2) {
			// Both accounts have the same amount as the transaction
			// We use currently selected account as the main account
			if (transaction.items[1].accountId === currentAccountId) {
				mainItem = transaction.items[1];
			} else {
				mainItem = transaction.items[0];
			}
		} else {
			// Ledger entry with more than two records - transfer between one account and multiple other accounts
			for (const item of transaction.items) {
				// The main account has the same amount as the transaction
				if (
					item.debit == transaction.amount ||
					item.credit == transaction.amount
				) {
					mainItem = item;
					break;
				}
			}
		}

		if (mainItem == null) {
			console.error('Main account not found');
			return null;
		}

		const mainAccount = accounts.value.find(
			(acc: Account) => acc.id === mainItem.accountId
		)!;

		const mainAccountType = typesById.value[mainAccount.typeId];

		const mainItemDebited = mainItem.debit > 0;
		let mainItemIncreased = mainItemDebited;
		if (mainAccountType.creditAccount) {
			mainItemIncreased = !mainItemIncreased;
		}

		const items = [];
		for (const item of transaction.items) {
			if (item.accountId == mainItem.accountId) {
				continue;
			}
			items.push({
				accountId: item.accountId,
				amount: mainItemDebited ? item.credit : item.debit,
				description: item.description
			});
		}

		return {
			mainAccountId: mainAccount.id!,
			transactionDt: transaction.transactionDt,
			description: transaction.description,
			mainItemDescription: mainItem.description,
			shopId: transaction.shopId,
			action: mainItemIncreased
				? LedgerEntryAction.INCREASE
				: LedgerEntryAction.DECREASE,
			amount: transaction.amount!,
			items: items
		};
	};

	const convertLedgerEntryToTransaction = (
		ledgerEntry: LedgerEntry
	): Transaction => {
		const mainAccount = accounts.value.find(
			(acc: Account) => acc.id === ledgerEntry.mainAccountId
		)!;

		const accountType = typesById.value[mainAccount.typeId];

		let mainAccountAmount = 0;

		let mainItemDebited = ledgerEntry.action == LedgerEntryAction.INCREASE;
		if (accountType.creditAccount) {
			mainItemDebited = !mainItemDebited;
		}

		const newItems: TransactionItem[] = [];

		for (const item of ledgerEntry.items) {
			mainAccountAmount += item.amount;
			newItems.push({
				accountId: item.accountId,
				credit: mainItemDebited ? item.amount : 0,
				debit: mainItemDebited ? 0 : item.amount,
				description: item.description,
				quantity: 0
			});
		}

		const mainItem = {
			accountId: ledgerEntry.mainAccountId,
			debit: 0,
			credit: 0,
			description: ledgerEntry.mainItemDescription,
			quantity: 0
		};

		if (mainItemDebited) {
			mainItem.debit = mainAccountAmount;
		} else {
			mainItem.credit = mainAccountAmount;
		}

		newItems.push(mainItem);

		return {
			...selectedTransaction.value,
			typeId: TRANSACTION_TYPE_ID_LEDGER_ENTRY,
			transactionDt: ledgerEntry.transactionDt,
			description: ledgerEntry.description,
			shopId: ledgerEntry.shopId,
			organizationId: account.value!.organizationId,
			items: newItems
		};
	};

	const valid = computed(() => meta.value.valid);

	return {
		init,
		valid,
		mainAccountId,
		transactionDt,
		description,
		mainItemDescription,
		shopId,
		action,
		items,
		addItem,
		removeItem,
		totalAmount,
		save,
		reset,
		itemsErrors,
		fieldLabels,
		saving,
		errors
	};
};
