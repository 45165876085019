<script setup lang="ts">
import { storeToRefs } from 'pinia';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

import { useUserStore } from '@/entities/user/store';

import { customerTypes, ediStatuses, jobTypes } from '../../config';
import {
	JOB_DATE_COLUMNS,
	JOB_PRICE_COLUMNS
} from '../../config/jobs-table-columns';
import { useJobsStore } from '../../model/store';

import JobDateFilter from './JobDateFilter.vue';
import JobPriceFilter from './JobPriceFilter.vue';
import JobTagTaxFilter from './JobTagTaxFilter.vue';

defineProps<{
	id: string;
	name: string;
	modelValue: any;
	type?: string;
}>();
const emit = defineEmits<{
	(e: 'update:modelValue', v: any): void;
	(e: 'update:type', v: string): void;
}>();

const store = useJobsStore();
const userStore = useUserStore();
const { view, jobFiltersData } = storeToRefs(store);
</script>

<template>
	<MultiSelect
		v-if="id === 'status'"
		appendTo="self"
		class="p-column-filter"
		:maxSelectedLabels="2"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobTypes"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		:showToggleAll="false"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'shop.name'"
		appendTo="self"
		class="p-column-filter"
		:maxSelectedLabels="1"
		:modelValue="modelValue"
		optionLabel="name"
		:options="userStore.user.shops"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		:showToggleAll="false"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'shop_id'"
		appendTo="self"
		class="p-column-filter"
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="id"
		:options="userStore.user.shops"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		:showToggleAll="false"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'consumer.is_commercial'"
		appendTo="self"
		class="p-column-filter"
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="customerTypes"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		:showToggleAll="false"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'ediqueue.status'"
		appendTo="self"
		class="p-column-filter"
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="ediStatuses"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		:showToggleAll="false"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'job_csr'"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobFiltersData.csrs"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'job_salesource'"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobFiltersData.salesources"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'job_salesrep'"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobFiltersData.salesreps"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'job_tech'"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobFiltersData.techs"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'job_location'"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobFiltersData.locations"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'commercialaccount_name'"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobFiltersData.commercialaccounts"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<MultiSelect
		v-else-if="id === 'bill_to.consumer_edi.trading_partner'"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="3"
		:modelValue="modelValue"
		optionLabel="name"
		:options="jobFiltersData.ediTradingPartners"
		optionValue="id"
		:placeholder="`Search by ${name}`"
		showClear
		@update:model-value="v => emit('update:modelValue', v)"
	/>
	<JobTagTaxFilter
		v-else-if="id === 'tags'"
		:modelValue="{ val: modelValue, type: type as string }"
		:multiselectOptions="jobFiltersData.tags"
		:name="name"
		:selectedShopsById="view.filters['shop_id'].val"
		:selectedShopsByName="view.filters['shop.name'].val"
		@update:model-value="
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		"
	/>
	<JobTagTaxFilter
		v-else-if="id === 'taxes'"
		:modelValue="{ val: modelValue, type: type as string }"
		:multiselectOptions="jobFiltersData.taxes"
		:name="name"
		:selectedShopsById="view.filters['shop_id'].val"
		:selectedShopsByName="view.filters['shop.name'].val"
		@update:model-value="
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		"
	/>
	<JobDateFilter
		v-else-if="JOB_DATE_COLUMNS.includes(id)"
		:modelValue="{ val: modelValue, type: type as string }"
		:name="name"
		@update:model-value="
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		"
	/>
	<JobPriceFilter
		v-else-if="JOB_PRICE_COLUMNS.includes(id)"
		:modelValue="{ val: modelValue, type: type as string }"
		:name="name"
		@update:model-value="
			v => {
				emit('update:modelValue', v.val);
				emit('update:type', v.type);
			}
		"
	/>
	<InputText
		v-else
		class="p-column-filter"
		:modelValue="modelValue"
		:placeholder="`Search by ${name}`"
		type="text"
		@update:model-value="v => emit('update:modelValue', v)"
	/>
</template>
