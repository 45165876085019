<template>
	<div id="accounting-reports">
		<span class="section-header">{{ t('reportsPage.table.assets') }}</span>
		<table class="co-accounts-table">
			<tbody>
				<tr
					v-for="accountDetail in data.assetAccountDetails"
					:key="accountDetail.id"
				>
					<td class="column-one">{{ accountDetail.co_account_name }}</td>
					<td class="column-two"></td>
					<td align="right" class="column-three">{{ accountDetail.value }}</td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td class="column-two black-text bold-text">
						{{ t('reportsPage.total') }} {{ t('reportsPage.table.assets') }}
					</td>
					<td align="right" class="column-three">
						<div class="border-top-div black-text">{{ data.totalAssets }}</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span class="section-header">
			{{ t('reportsPage.table.liabilitiesAndEquity') }}
		</span>
		<br />
		<span class="sub-section-header">
			{{ t('reportsPage.table.liabilities') }}
		</span>
		<table class="co-accounts-table">
			<tbody>
				<tr
					v-for="accountDetail in data.liabilitiesAccountDetails"
					:key="accountDetail.id"
				>
					<td class="column-one">{{ accountDetail.co_account_name }}</td>
					<td class="column-two"></td>
					<td align="right" class="column-three">{{ accountDetail.value }}</td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td class="column-two">
						{{ t('reportsPage.total') }}
						{{ t('reportsPage.table.liabilities') }}
					</td>
					<td align="right" class="column-three">
						<div class="border-top-div black-text">
							{{ data.totalLiabilities }}
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<span class="sub-section-header">{{ t('reportsPage.table.equity') }}</span>

		<table class="co-accounts-table">
			<tbody>
				<tr
					v-for="accountDetail in data.equityAccountDetails"
					:key="accountDetail.id"
				>
					<td class="column-one">{{ accountDetail.co_account_name }}</td>
					<td class="column-two"></td>
					<td align="right" class="column-three">{{ accountDetail.value }}</td>
				</tr>

				<tr v-if="data.showTotalNetIncome">
					<td class="column-one">{{ t('reportsPage.table.netIncome') }}</td>
					<td class="column-two"></td>
					<td align="right" class="column-three">{{ data.totalNetIncome }}</td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td class="column-two">
						{{ t('reportsPage.total') }} {{ t('reportsPage.table.equity') }}
					</td>
					<td align="right" class="column-three">
						<div class="border-top-div black-text">{{ data.totalEquity }}</div>
					</td>
				</tr>
				<tr>
					<td class="column-one"><br /></td>
					<td class="column-two"></td>
					<td class="column-three"></td>
				</tr>
				<tr>
					<td class="column-one"></td>
					<td class="column-two black-text bold-text">
						{{ t('reportsPage.total') }}
						{{ t('reportsPage.table.liabilitiesAndEquity') }}
					</td>
					<td align="right" class="column-three black-text">
						{{ data.totalLiabilitiesAndEquity }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

defineProps<{
	data: any;
}>();

const { t } = useI18n();
</script>

<style scoped>
#accounting-reports .section-header {
	font-weight: bold;
	text-decoration: underline;
	font-size: 16px;
}

#accounting-reports .sub-section-header {
	font-weight: bold;
	font-size: 14px;
	margin-left: 20px;
}

#accounting-reports .co-accounts-table {
	width: 93%;
	margin-left: 40px;
	margin-right: auto;
	color: #6b6b6b;
}

#accounting-reports .column-one {
	width: 68%;
}

#accounting-reports .column-two {
	width: 20%;
}

#accounting-reports .column-three {
	width: 12%;
}

#accounting-reports .red-text {
	color: red;
}

#accounting-reports .green-text {
	color: #32c600;
}

#accounting-reports .black-text {
	color: black;
}

#accounting-reports .bold-text {
	font-weight: bold;
}

#accounting-reports .border-top-div {
	border-top: 1px solid black;
}
</style>
