import { JobTag } from '@/entities/job/model/types';
import { CORE_API } from '@/shared/api';

import {
	CreateJobTagRequest,
	DeleteJobTagRequest,
	DeleteJobTagResponse
} from '../model/types';

export const getShopTags = async (shopId: number): Promise<JobTag[]> => {
	const response = await CORE_API.get<JobTag[]>(`/api/shop/${shopId}/tag`);

	return response.data;
};

export const getJobTags = async (
	shopId: number,
	jobId: number
): Promise<JobTag[]> => {
	const response = await CORE_API.get<JobTag[]>(
		`/api/shop/${shopId}/job/${jobId}/jobtag`
	);

	return response.data;
};

export const createOrUpdateJobTag = async (
	params: CreateJobTagRequest
): Promise<JobTag[] | 'error'> => {
	try {
		const response = await CORE_API.post<JobTag[]>(
			`/api/shop/${params.shopID}/job/${params.jobID}/jobtag`,
			params.tags
		);

		return response.data;
	} catch (error) {
		console.log(error);
		return 'error';
	}
};

export const deleteJobTag = async (
	params: DeleteJobTagRequest
): Promise<DeleteJobTagResponse | 'error'> => {
	try {
		const response = await CORE_API.delete<DeleteJobTagResponse | 'error'>(
			`/api/shop/${params.shopID}/job/${params.jobID}/jobtag/${params.jobTagID}`
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return 'error';
	}
};
