import { Component, markRaw, Raw } from 'vue';

import AccountingReportsIcon from '../ui/AccountingReportsIcon.vue';
import InstallReportsIcon from '../ui/InstallReportsIcon.vue';
import MiscellaneousIcon from '../ui/MiscellaneousIcon.vue';
import PaymentIcon from '../ui/PaymentIcon.vue';
import SalesReportsIcon from '../ui/SalesReportsIcon.vue';
import SalesTaxIcon from '../ui/SalesTaxIcon.vue';
import ScheduleReportsIcon from '../ui/ScheduleReportsIcon.vue';
import StatementsIcon from '../ui/StatementsIcon.vue';

const images = {
	'accounting-reports': markRaw(AccountingReportsIcon),
	'install-reports': markRaw(InstallReportsIcon),
	miscellaneous: markRaw(MiscellaneousIcon),
	payment: markRaw(PaymentIcon),
	'sales-reports': markRaw(SalesReportsIcon),
	'sales-tax': markRaw(SalesTaxIcon),
	'schedule-reports': markRaw(ScheduleReportsIcon),
	statements: markRaw(StatementsIcon)
};

type MenuConfig = {
	id: string;
	label: string;
	desc: string;
	img: Raw<Component>;
	items: {
		id: string;
		label: string;
		filters: number;
		desc: string;
		to?: string;
		permissionId?: string;
		commingSoon?: boolean;
		command?: string;
	}[];
}[];

export const reportsMenuConfig: MenuConfig = [
	{
		id: 'sales-reports',
		label: 'Sales Reports',
		desc: 'Track and analyze sales performance with detailed metrics.',
		img: images['sales-reports'],
		items: [
			{
				id: 'sales',
				label: 'Sales Report',
				filters: 6,
				desc: 'A detailed sales report that can be filtered by date type, job type, customer and vendor type.  The report can be enhanced further by grouping jobs by different entities such as vendor or customer type.  Data for job counts and revenue totals are summed at the sub-group and whole report level.',
				to: '/reports',
				permissionId: 'reportsales'
			},
			{
				id: 'margin',
				label: 'Margin Report',
				filters: 7,
				desc: 'A report that details a list of all revenue for a given period with the associated direct costs of each job.  The report further sums all totals and calculates the profitability on a job by job basis and an on the entire report level.  The report can be enhanced further with several different filters and groupings.',
				to: '/reports?tab=margin',
				permissionId: 'reportmargin'
			},
			{
				id: 'customer-summary',
				label: 'Customer Summary',
				filters: 4,
				desc: "Summary of all customer balances and each customer's current balance distribution.  Data on this report will show how sales and balance concentrations with specific customers can affect bottom line business metrics.  Having too much concentration in a small handful of customers can lead to large and uneven swings in collections and profits.",
				to: '/reports?tab=customer-summary',
				permissionId: 'reportcustomersummary'
			},
			{
				id: 'royalty-summary',
				label: 'Royalty Summary',
				filters: 2,
				to: '/reports?tab=royalty-summary',
				desc: 'Royalty tracking is vital to any franchisee/franchisor business model.  Set and track royalties that are due to a franchisor or due from and franchisee over a given period of time.  The report with provide sales totals, job type information, and royalites due within a given period.',
				permissionId: 'reportroyaltysummary'
			},
			{
				id: 'sales-summary',
				label: 'Sales Summary',
				filters: 0,
				commingSoon: true,
				command: 'This feature is coming soon!',
				desc: 'A summary of all sales activity within a given period of time.  All sales are agregated and displayed within a variety of metrics including labor vs material, sales allocated to vendor type, nature of work completed, by shop and location, customer type, collected vs uncollected, and more.'
			}
		]
	},
	{
		id: 'sales-tax',
		label: 'Sales Tax',
		desc: 'Ensure accurate tax calculations and compliance.',
		img: images['sales-tax'],
		items: [
			{
				id: 'tax-detail',
				label: 'Accrual Detail',
				filters: 4,
				desc: 'Accrual tax reports gather data by invoice date, not invoice payment date.  This reports lists every taxable transaction within the specified date range and displays the tax amount for each invoice, as well as the renenue and tax totals for the entire period.  In some instances accrual sales tax accounting may be a more simple reporting process, but may require you to pay sales tax sooner if you generally get paid after delivering goods or services.  It is generally a good practice to run a sales tax report for each entity or authority to which you are required to remit a tax payment.',
				to: '/reports?tab=tax-detail',
				permissionId: 'reporttaxdetail'
			},
			{
				id: 'tax-summary',
				label: 'Accrual Summary',
				filters: 4,
				desc: "Takes all the data contained in the accrual detail report and condenses it to a 'totals only' report.  When reporting sales tax you may only need summary totals and not every transaction level detail.  If generated with the same parameters the overall totals will be the same between the summary and detail reports.",
				to: '/reports?tab=tax-summary',
				permissionId: 'reporttaxsummary'
			},
			{
				id: 'detail',
				label: 'Cash Basis Detail',
				filters: 3,
				desc: 'A cash basis report will gather data based on the payment date(s) from invoice transactions, not on the invoice date.   You may apply a payment to an invoice in a different month or even year from the date of the invoice.  In this scenario the sales tax from an invoice will only show up in a date range to which the invoice payment is dated.  If only a portion of the invoice is received, then only a portion of the sales tax will be collected and be reported.  Keep in mind you must be up-to-date in recording your payments properly to have accurate cash basis sales tax reports.',
				to: '/reports?tab=detail',
				permissionId: 'reportdetail'
			},
			{
				id: 'summary',
				label: 'Cash Basis Summary',
				filters: 3,
				desc: "Takes all data in the cash basis detail report and condenses it to a 'totals only' report.  When reporting sales tax you may only need summary totals and not every transaction level detail. If generated with the same parameters the overall totals will be the same between the summary and detail reports.",
				to: '/reports?tab=summary',
				permissionId: 'reportsummary'
			}
		]
	},
	{
		id: 'schedule-reports',
		label: 'Schedule Reports',
		desc: 'Manage report timelines and delivery schedules.',
		img: images['schedule-reports'],
		items: [
			{
				id: 'schedule',
				label: 'Schedule Report',
				filters: 5,
				desc: 'Detailed view of all scheduled jobs in the past and future.  Details include dates and times, technicians assigned, job types, vehicles, parts, notes, etc.',
				to: '/reports?tab=schedule',
				permissionId: 'reportschedule'
			},
			{
				id: 'manifest',
				label: 'Manifest',
				filters: 0,
				desc: 'A summary document containing detailed information about planned deliveries, routes and related data for logistics coordination.',
				command: 'This feature is coming soon!',
				commingSoon: true,
				permissionId: 'reportmanifest'
			}
		]
	},
	{
		id: 'install-reports',
		label: 'Install Reports',
		desc: 'Monitor and document installation processes efficiently.',
		img: images['install-reports'],
		items: [
			{
				id: 'technicians',
				label: 'Technicians',
				filters: 7,
				desc: `A detailed list view of all jobs, both past and future based on vendors.  Install reports for technicians can be filtered by job type, date, date type, paid status, techs and/or groups of techs.  Install reports show job numbers, paid status, customer names, referral numbers, and commissions.  Commissions totals are shown for individual technicians as well as for date ranges and single or grouped shops.`,
				to: '/reports?tab=technicians',
				permissionId: 'reportinstallers'
			},
			{
				id: 'salesreps',
				label: 'Sales Reps',
				filters: 7,
				desc: `A detailed list view of all jobs, both past and future based on vendors.  Install reports for sales reps can be filtered by job type, date, date type, paid status, techs and/or groups of techs.  Install reports show job numbers, paid status, customer names, referral numbers, and commissions.  Commissions totals are shown for individual sales rep as well as for date ranges and single or grouped shops.`,
				to: '/reports?tab=salesreps',
				permissionId: 'reportsalesreps'
			},
			{
				id: 'salesources',
				label: 'Sales Sources',
				filters: 7,
				desc: `A detailed list view of all jobs, both past and future based on vendors.  Install reports for sales sources can be filtered by job type, date, date type, paid status, techs and/or groups of techs.  Install reports show job numbers, paid status, customer names, referral numbers, and commissions.  Commissions totals are shown for individual sales source as well as for date ranges and single or grouped shops.`,
				to: '/reports?tab=salesources',
				permissionId: 'reportsalesources'
			},
			{
				id: 'locations',
				label: 'Locations',
				filters: 7,
				desc: `A detailed list view of all jobs, both past and future based on vendors.  Install reports for locations can be filtered by job type, date, date type, paid status, techs and/or groups of techs.  Install reports show job numbers, paid status, customer names, referral numbers, and commissions.  Commissions totals are shown for individual location as well as for date ranges and single or grouped shops.`,
				to: '/reports?tab=locations',
				permissionId: 'reportlocations'
			},
			{
				id: 'tech-collections',
				label: 'Tech Collections',
				filters: 7,
				desc: `A detailed list view of all jobs, both past and future based on vendors.  Install reports for activities of technicians can be filtered by job type, date, date type, paid status, techs and/or groups of techs.  Install reports show job numbers, paid status, customer names, referral numbers, and commissions.  Commissions totals are shown for individual activity as well as for date ranges and single or grouped shops.`,
				to: '/reports?tab=tech-collections',
				permissionId: 'reporttechcollections'
			},
			{
				id: 'csrs',
				label: 'Customer Service Reps',
				filters: 7,
				desc: `A detailed list view of all jobs, both past and future based on vendors.  Install reports for Customer Service Reps can be filtered by job type, date, date type, paid status, techs and/or groups of techs.  Install reports show job numbers, paid status, customer names, referral numbers, and commissions.  Commissions totals are shown for individual Customer Service Rep as well as for date ranges and single or grouped shops.`,
				to: '/reports?tab=csrs',
				permissionId: 'reportcsrs'
			}
		]
	},
	{
		id: 'payment',
		label: 'Payment',
		desc: 'Simplify payment tracking and processing tasks.',
		img: images['payment'],
		items: [
			{
				id: 'receipt',
				label: 'Payment Receipt',
				filters: 5,
				desc: 'Detailed record of payment transactions, providing confirmation of received payments along with associated details like date, amount, and payer information.',
				to: '/reports?tab=receipt',
				permissionId: 'reportreceipt'
			}
		]
	},
	{
		id: 'statements',
		label: 'Statements',
		desc: 'Generate clear and concise financial statements.',
		img: images['statements'],
		items: [
			{
				id: 'account-statement',
				label: 'Account Statement',
				filters: 3,
				desc: 'A comprehensive summary of account activity, including transactions, balances, and any outstanding amounts for the selected period.',
				to: '/reports?tab=account-statement',
				permissionId: 'reportaccountstatement'
			},
			{
				id: 'open-invoice',
				label: 'Open Invoice Report',
				filters: 3,
				desc: 'An organized list of all unpaid invoices, helping to track outstanding payments and manage cash flow effectively.',
				to: '/reports?tab=open-invoice',
				permissionId: 'reportopeninvoice'
			},
			{
				id: 'aging',
				label: 'Aging Report',
				filters: 1,
				desc: 'A breakdown of outstanding invoices categorized by the length of time they have been overdue, offering insights into collection priorities.',
				to: '/reports?tab=aging',
				permissionId: 'reportaging'
			}
		]
	},
	{
		id: 'accounting-reports',
		label: 'Accounting Reports',
		desc: 'Review financial data with comprehensive accounting insights.',
		img: images['accounting-reports'],
		items: [
			{
				id: 'profit-and-loss',
				label: 'Profit and Loss',
				filters: 2,
				desc: 'A financial statement summarizing revenues, expenses, and net profit or loss over a specific time period.',
				to: '/reports?tab=profit-and-loss',
				permissionId: 'reportprofitandloss'
			},
			{
				id: 'balance-sheet',
				label: 'Balance Sheet',
				filters: 2,
				desc: "A snapshot of the company's financial position, showcasing assets, liabilities, and equity at a specific point in time.",
				to: '/reports?tab=balance-sheet',
				permissionId: 'reportbalancesheet'
			}
		]
	},
	{
		id: 'miscellaneous',
		label: 'Miscellaneous Reports',
		desc: 'Access a variety of reports for unique business needs.',
		img: images['miscellaneous'],
		items: [
			{
				id: 'salesreps',
				label: 'Sales Rep/Source',
				filters: 0,
				desc: 'A report analyzing sales performance by representatives or sources, providing insights into contribution to overall revenue.',
				command: 'This feature is coming soon!',
				commingSoon: true,
				permissionId: 'reportsalesrepsource'
			},
			{
				id: 'requests',
				label: 'Right Part',
				filters: 2,
				desc: 'Detailed report focusing on parts or inventory items, helping to track availability and usage trends.',
				to: '/reports?tab=requests',
				permissionId: 'reportrequests'
			},
			{
				id: 'nags-license',
				label: 'Nags License',
				filters: 3,
				desc: 'A specialized report providing information on licensing and compliance with NAGS (National Auto Glass Specifications).',
				to: '/reports?tab=nags-license',
				permissionId: 'admin'
			},
			{
				id: 'edi',
				label: 'EDI Report',
				filters: 2,
				desc: 'A report summarizing electronic data interchange transactions, ensuring smooth and accurate data exchange between systems.',
				to: '/reports?tab=edi',
				permissionId: 'admin'
			},
			{
				id: 'install-list',
				label: 'Install List',
				filters: 3,
				desc: 'A comprehensive list of installation jobs, including details on status, assigned personnel, and scheduling.',
				to: '/reports?tab=install-list',
				permissionId: 'reportinstalllist'
			}
		]
	}
];
