import { isObject, useAsyncState } from '@vueuse/core';
import dayjs from 'dayjs';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';
import { i18n } from '@/shared/i18n';

import {
	getCustomerBySearch,
	getCustomShopData,
	getPrintVersion,
	getReportByType,
	getRequestDetails,
	getUserConnectedAccountingPackages,
	postReportByType
} from '../../api/api';
import {
	CustomShopData,
	ReportMenu,
	ReportType,
	TableColumn
} from '../../api/types';
import { CustomerSearchItem, customFiltersTypes } from '../../api/types';
import {
	agingTableModes,
	allFiltersTypes,
	AllTableColumns,
	customFilterDisplayConfig,
	customFilterLabels,
	jobTypes,
	licenseTypes,
	paidStatus,
	paymentTableModes,
	reportColumns,
	reportsMenuConfig,
	reportsURLs,
	requiredFiltersByGroup,
	salesTableModes,
	salesTablesColumns,
	tableModes,
	variableDateColumns,
	workTypeOptions,
	xActionTypeOptions
} from '../constants';

const useReportsStore = defineStore('reports', () => {
	const userStore = useUserStore();
	const { user } = storeToRefs(userStore);
	const route = useRoute();
	const shops = computed(() => user.value.shops);

	const organizations = computed(() =>
		shops.value.length ? shops.value.map(item => item.organization_id) : null
	);

	const selectedShop = ref(
		shops.value.length ? shops.value.map(item => item.id) : null
	);

	const messages = useMessages();
	const { t } = i18n.global;

	// consumer
	const isConsumer = computed(() => {
		if (
			!selectedShop.value ||
			(Array.isArray(selectedShop.value) && selectedShop.value.length === 0)
		)
			return false;

		const checkConsumer = (shopId: number) => {
			return shops.value.some(
				rootShop =>
					rootShop.id === shopId && rootShop.shop_services_config?.consumer
			);
		};

		return Array.isArray(selectedShop.value)
			? selectedShop.value.some(checkConsumer)
			: checkConsumer(selectedShop.value);
	});

	// accounting
	const showAccountingFilter = computed(
		() => currentReportType.value.group === 'accountingReports'
	);
	const accountingFilterValue = ref();
	const accountingPackages = useAsyncState(
		() => {
			return getUserConnectedAccountingPackages();
		},
		[],
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);

	const reportTypesList = computed(() => {
		const preparedArray = reportsMenuConfig.reduce<ReportMenu[]>(
			(configArray, reportConfig) => {
				if (reportConfig.id === 'accountingReports') {
					if (accountingPackages.state.value?.length) {
						configArray.push(reportConfig);
					}
				} else {
					configArray.push(reportConfig);
				}
				return configArray;
			},
			[]
		);

		return preparedArray.flatMap(item => item.children);
	});

	const currentReportType = computed<ReportType>(() => {
		const item = reportTypesList.value.find(
			item => item.id === route.params.reportId
		);

		return item as ReportType;
	});

	const jobTypesList = computed(() => {
		const prepArray = [...jobTypes];
		if (currentReportType.value.id === 'sales') {
			prepArray.push({ id: 'draft', name: 'Draft' });
		}

		return prepArray;
	});
	const selectedJobType = ref<(string | number)[] | null>(null);
	const showJobFilter = computed(() => {
		return (
			currentReportType.value.group === 'sales' ||
			currentReportType.value.group === 'install' ||
			currentReportType.value.id === 'customer-summary' ||
			currentReportType.value.id === 'install-list' ||
			currentReportType.value.id === 'schedule'
		);
	});

	const selectedCustomerType = ref<(string | number)[] | null>(null);
	const showCustomerTypeFilter = computed(
		() =>
			currentReportType.value.group === 'sales' &&
			currentReportType.value.id !== 'customer-summary'
	);

	const selectedLicenseTypes = ref<(number | string)[] | null>(
		licenseTypes.map(item => item.id)
	);
	const showLicenseTypesFilter = computed(
		() => currentReportType.value.group === 'nags-license'
	);

	const selectedPaidStatus = ref<string[] | null>(
		paidStatus.map(item => item.id)
	);
	const showPaidStatusFilter = computed(
		() => currentReportType.value.group === 'install'
	);

	const selectedWorkType = ref<string | null>(workTypeOptions[0].value);
	const showWorkTypeFilter = computed(
		() => currentReportType.value.group === 'install'
	);

	const dateFilterValue = ref<
		{ startDate: string | null; endDate: string | null } | string | null
	>(null);
	const showDateFilter = computed(() => currentReportType.value.id !== 'aging');
	const dateType = ref<string>();
	const preparedDateFilter = computed(() => {
		const date = dateFilterValue.value;
		const currentDate = dayjs(new Date()).format('YYYY-MM-DD');
		if (
			dateType.value !== 'unscheduled' &&
			(!date || (isObject(date) && (!date.startDate || !date.endDate)))
		)
			return null;

		if (typeof date === 'object') {
			return {
				startDate: date?.startDate || currentDate,
				endDate: date?.endDate || currentDate
			};
		} else {
			return {
				startDate: date,
				endDate: date
			};
		}
	});

	const showShopDataFilter = computed(
		() =>
			!!customFilterDisplayConfig[
				currentReportType.value.filter as customFiltersTypes
			]?.includes(currentReportType.value.id)
	);
	const shopDataLabel = computed(() => {
		return customFilterLabels[
			currentReportType.value.filter as keyof typeof customFilterLabels
		];
	});
	const customShopDataFilterOptions = useAsyncState(
		(type: keyof typeof customFilterLabels) => {
			if (selectedShop.value?.length) {
				return getCustomShopData(type, selectedShop.value, isConsumer.value);
			} else {
				return Promise.resolve([]);
			}
		},
		[],
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);
	const customShopDataFilterValue = ref<{
		type: customFiltersTypes;
		value: (number | string)[] | null;
	}>({
		type: currentReportType.value.filter
			? (currentReportType.value.filter as customFiltersTypes)
			: 'taxes',
		value: null
	});

	const preparedShopDataOptions = computed(() => {
		const preparedArr: number[] = [];
		customShopDataFilterOptions.state.value.forEach(shop => {
			if (
				currentReportType.value?.filter &&
				currentReportType.value?.filter in shop
			) {
				const arr = shop[currentReportType.value.filter as keyof typeof shop];
				if (Array.isArray(arr)) {
					preparedArr.push(...arr.map(item => item.id));
				}
			}
		});
		return preparedArr;
	});

	const getShopDataFilterResult = (filterName: string) => {
		if (filterName !== customShopDataFilterValue.value.type) return [];
		if (
			customShopDataFilterValue.value.value &&
			customShopDataFilterValue.value.value.length
		) {
			return customShopDataFilterValue.value.value;
		} else {
			return preparedShopDataOptions.value;
		}
	};

	const showPaymentsFilter = computed(
		() => currentReportType.value.group === 'payment'
	);
	const paymentsFilterValue = ref<string[]>(xActionTypeOptions);

	const showContractorFilter = computed(() => {
		return (
			['sales', 'payment'].includes(
				currentReportType.value?.group || 'false'
			) && currentReportType.value.id !== 'customer-summary'
		);
	});
	const additionalFilterIsVisible = ref<boolean>(false);
	const contractorFilterTypes = ref<string[]>([]);
	const contractorFilterValue = ref();
	const contractorFilterOptions = useAsyncState(
		(type: string[]) => {
			if (selectedShop.value?.length && type.length) {
				return getCustomShopData(
					type.join(),
					selectedShop.value,
					isConsumer.value
				);
			} else {
				return Promise.resolve([]);
			}
		},
		[],
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);

	const showAcrualCashFilter = computed(
		() => currentReportType.value.group === 'accountingReports'
	);
	const acrualCashFilterValue = ref(['accrual']);

	const showCustomerFilter = computed(() => {
		return (
			(customShopDataFilterValue.value !== null &&
				currentReportType.value.group === 'statements') ||
			currentReportType.value.group === 'payment'
		);
	});
	const customerFilterValue = ref<CustomerSearchItem | null>(null);
	const customerFilterOptions = ref<CustomerSearchItem[]>([]);
	const getCustomerFilterOptions = useAsyncState(
		async (params: { s: string; offset: number }) => {
			if (selectedShop.value?.length && organizations.value?.length) {
				const response = await getCustomerBySearch({
					term: params.s,
					organization_ids: organizations.value,
					shop_ids: selectedShop.value,
					offset: params.offset
				});

				const filteredCustomers = response.rows.filter(
					item =>
						item.status !== 'disabled' &&
						organizations.value?.includes(item.organization_id)
				);

				if (params.offset === 0) {
					customerFilterOptions.value = filteredCustomers;
				} else {
					customerFilterOptions.value.push(...filteredCustomers);
				}

				return response;
			} else {
				return Promise.resolve({
					rows: [],
					count: 0
				});
			}
		},
		{
			rows: [],
			count: 0
		},
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);

	// Fetch shopData filter
	watch([currentReportType, selectedShop], () => {
		if (!selectedShop.value || !selectedShop.value.length) {
			customShopDataFilterValue.value.type =
				currentReportType.value && currentReportType.value.filter
					? (currentReportType.value.filter as customFiltersTypes)
					: customShopDataFilterValue.value.type;
			customShopDataFilterValue.value.value = null;
		} else {
			refetchCustomShopFilter();
		}
	});

	const refetchCustomShopFilter = async () => {
		if (currentReportType.value.filter) {
			await customShopDataFilterOptions.execute(
				0,
				currentReportType.value.filter as customFiltersTypes
			);
			if (customShopDataFilterValue.value.value) {
				const options = customShopDataFilterOptions.state.value.map(item => {
					if (currentReportType.value.filter) {
						return item[currentReportType.value.filter as keyof CustomShopData];
					}
				});
				customShopDataFilterValue.value = {
					type: currentReportType.value.filter as customFiltersTypes,
					value: customShopDataFilterValue.value.value.filter(num =>
						options.includes(num)
					)
				};
			}
		}
	};

	// Fetch cotractor filter
	watch([currentReportType, selectedShop], () => {
		if (
			showContractorFilter.value &&
			selectedShop.value &&
			selectedShop.value.length
		) {
			contractorFilterOptions.execute(0, allFiltersTypes);
		}
	});

	const resetStoreData = () => {
		staticReportData.value = null;
		setDefaultFilterValue();
	};

	const setDefaultFilterValue = () => {
		selectedShop.value = null;

		dateFilterValue.value = null;
		accountingFilterValue.value = null;
		selectedJobType.value = null;
		selectedCustomerType.value = null;
		selectedLicenseTypes.value = null;
		selectedPaidStatus.value = null;
		contractorFilterTypes.value = [];
		customShopDataFilterValue.value = {
			type: currentReportType.value.filter
				? (currentReportType.value.filter as customFiltersTypes)
				: customShopDataFilterValue.value.type,
			value: []
		};
		paymentsFilterValue.value = xActionTypeOptions;
		contractorFilterValue.value = [];
		acrualCashFilterValue.value = ['accrual'];
		customerFilterValue.value = null;

		includeAdjustments.value = false;
	};

	const tableModeOptions = computed(() => {
		if (!currentReportType.value) return [];
		let preparedTableMode = tableModes;

		const { id, group } = currentReportType.value;

		if (id === 'receipt' && group === 'payment') {
			preparedTableMode = paymentTableModes;
		} else if (id === 'aging') {
			preparedTableMode = agingTableModes;
		} else if (group === 'sales') {
			preparedTableMode = salesTableModes;
			if (
				contractorFilterTypes.value &&
				(contractorFilterValue.value?.csrs ||
					contractorFilterValue.value?.salesreps ||
					contractorFilterValue.value?.locations ||
					contractorFilterValue.value?.salesources ||
					contractorFilterValue.value?.techs)
			) {
				preparedTableMode = preparedTableMode.filter(
					mode => mode.id !== 'name'
				);
			}
		}

		return preparedTableMode;
	});
	const showTableMode = computed(() => {
		const showTypes = ['sales', 'payment', 'cash-basis', 'aging'];

		return (
			(showTypes.find(item => item === currentReportType.value.group) ||
				showTypes.find(item => item === currentReportType.value.id)) &&
			currentReportType.value.id !== 'customer-summary' &&
			currentReportType.value.id !== 'summary'
		);
	});
	const tableMode = ref<string | null>(tableModeOptions.value[0].id);

	watch(currentReportType, () => {
		if (!currentReportType.value) return;
		tableMode.value = tableModeOptions.value[0].id;
	});

	const includeAdjustments = ref<boolean>(false);
	async function prepareReportFilter(allowRightPartFilter?: boolean) {
		const customFilters: {
			shopIds: number[] | null;
			dateFrom: string;
			dateTo: string;
			date_start?: string;
			date_end?: string;
			include_adjustments: null | boolean;
		} = {
			shopIds: [],
			dateFrom: '',
			dateTo: '',
			include_adjustments: null
		};

		if (currentReportType.value.group == 'right-part') {
			if (selectedShop?.value?.length && !allowRightPartFilter) {
				customFilters.shopIds = selectedShop?.value || null;
			}
			if (preparedDateFilter.value && preparedDateFilter.value.startDate) {
				customFilters.dateFrom = preparedDateFilter.value.startDate;
			}
			if (preparedDateFilter.value && preparedDateFilter.value.endDate) {
				customFilters.dateTo = preparedDateFilter.value.endDate;
			}
		}
		if (currentReportType.value.id == 'install-list') {
			if (preparedDateFilter.value && preparedDateFilter.value.startDate) {
				customFilters.date_start = preparedDateFilter.value.startDate;
			}
			if (preparedDateFilter.value && preparedDateFilter.value.endDate) {
				customFilters.date_end = preparedDateFilter.value.endDate;
			}
		}

		if (currentReportType.value.id === 'margin') {
			customFilters.include_adjustments = includeAdjustments.value;
		}

		if (currentReportType.value.id === 'aging') {
			return {
				filters: {
					shopId: selectedShop.value ? selectedShop.value[0] : 0,
					groupBy: tableMode.value
				}
			};
		} else {
			if (!customShopDataFilterValue.value.value && showShopDataFilter.value) {
				await customShopDataFilterOptions.execute(
					0,
					customShopDataFilterValue.value.type
				);
			}

			return {
				type: {
					label: currentReportType.value.label,
					id: currentReportType.value.id,
					group: currentReportType.value.group,
					total_key: currentReportType.value.total_key
				},
				filters: {
					...customFilters,
					shops: selectedShop?.value || null,
					job_types:
						selectedJobType?.value || jobTypesList.value.map(item => item.id),
					customer_types: selectedCustomerType?.value || null,
					license_types: selectedLicenseTypes?.value || null,
					paid_statuses: selectedPaidStatus?.value || null,
					date_start: preparedDateFilter.value?.startDate,
					date_end: preparedDateFilter.value?.endDate,
					date_type: dateType?.value || null,
					account_type: contractorFilterTypes?.value || null,
					sales: JSON.stringify({
						csrs: contractorFilterValue.value?.csrs || null,
						salesreps: contractorFilterValue.value?.salesreps || null,
						locations: contractorFilterValue.value?.locations || null,
						salesources: contractorFilterValue.value?.salesources || null,
						techs: contractorFilterValue.value?.techs || null
					}),
					status: selectedJobType?.value || null,
					mode: tableMode.value,
					x_action_types: paymentsFilterValue.value,
					work_type: selectedWorkType.value,
					accrual_or_cash_basis: acrualCashFilterValue?.value || null,
					isConsumer: isConsumer.value,
					taxes: getShopDataFilterResult('taxes'),
					techs: getShopDataFilterResult('techs'),
					csrs: getShopDataFilterResult('csrs'),
					locations: getShopDataFilterResult('locations'),
					salesources: getShopDataFilterResult('salesources'),
					salesreps: getShopDataFilterResult('salesreps'),
					consumers: customerFilterValue.value
						? customerFilterValue.value.id
						: null,
					consumer: customerFilterValue.value
						? customerFilterValue.value.id
						: null
				}
			};
		}
	}

	// Get report
	const staticReportData = ref<any | null>(null);
	const staticDate = ref<{
		startDate: string | null;
		endDate: string | null;
	} | null>(null);
	const staticDateType = ref<string>();

	const getReportHandler = useAsyncState(
		async (rightPartAdmin?: boolean) => {
			const prepareOption = prepareApiParamsForReport();
			let result;
			const filters = (await prepareReportFilter(rightPartAdmin)).filters;

			try {
				if (prepareOption.isPostRequest) {
					result = await postReportByType({
						isFullyCustom: prepareOption.isFullyCustom,
						reportEndpoint: prepareOption.url,
						filters: filters,
						isConsumer: isConsumer.value
					});
				} else {
					result = await getReportByType({
						isFullyCustom: prepareOption.isFullyCustom,
						reportEndpoint: prepareOption.url,
						filters: filters,
						isConsumer: isConsumer.value
					});
				}
				staticReportData.value = result;
				if (isObject(dateFilterValue.value)) {
					staticDate.value = {
						startDate: dateFilterValue.value?.startDate,
						endDate: dateFilterValue.value?.endDate
					};
					staticDateType.value = dateType.value;
				}

				return result;
			} catch (error) {
				if (result && 'error' in result) {
					messages.showError(result.error);
				} else {
					messages.showError(t('defaultToast.errorDetails'));
				}
			}
		},
		null,
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);

	const currentColumns = computed((): TableColumn[] => {
		const group = currentReportType.value?.group || 'sales';
		const id = currentReportType.value.id;

		const data =
			reportColumns[group as keyof typeof reportColumns][
				id as keyof AllTableColumns
			];

		if (typeof data == 'function' && dateType.value) {
			return salesTablesColumns.sales(
				staticDateType.value as keyof typeof variableDateColumns
			);
		}

		return data;
	});

	const prepareApiParamsForReport = (): {
		url: string;
		isFullyCustom: boolean;
		isPostRequest: boolean;
	} => {
		const type = currentReportType.value;
		let url = type.group ? `/${type.group}/${type.id}` : `/${type.id}`;
		let isFullyCustom = false;
		let isPostRequest = false;

		if (type.group === 'right-part') {
			url = 'api/right-parts/report';
			isFullyCustom = true;
		} else if (type.id === 'aging') {
			url = `api/reports/aging`;
			isFullyCustom = true;
		} else if (type.id === 'technicians') {
			url = `api/reporting/install/installers`;
			isFullyCustom = true;
			isPostRequest = true;
		} else if (type.group === 'install') {
			isPostRequest = true;
		} else if (Object.hasOwn(reportsURLs, type.id)) {
			url = `/${reportsURLs[type.id as keyof typeof reportsURLs]}`;
		}

		return {
			url: url,
			isFullyCustom: isFullyCustom,
			isPostRequest: isPostRequest
		};
	};

	const rightPartRequestDetails = useAsyncState(
		async (id: number) => {
			return getRequestDetails(id);
		},
		{
			id: 0,
			shopId: 0,
			jobId: null,
			jobDraftId: 0,
			userId: 0,
			username: '',
			vin: '',
			status: '',
			createdAt: '',
			result: null,
			help_request_status: null
		},
		{
			immediate: false,
			resetOnExecute: false,
			throwError: true
		}
	);

	const expandAllAgingPayments = ref<boolean>(false);
	const currentRequiredFilters = computed(() => {
		const prepArr: string[] = [];
		if (currentReportType.value.group in requiredFiltersByGroup) {
			const filterByGroup = [
				...requiredFiltersByGroup[
					currentReportType.value.group as keyof typeof requiredFiltersByGroup
				]
			];
			if (
				dateType.value == 'unscheduled' &&
				filterByGroup.indexOf('date') > -1
			) {
				filterByGroup.splice(filterByGroup.indexOf('date'), 1);
			}
			prepArr.push(...filterByGroup);
		}
		// Uncomment if we need required vendor on installers report
		// if (currentReportType.value.id in requiredFiltersById) {
		// 	const filterById = [
		// 		...requiredFiltersById[
		// 			currentReportType.value.id as keyof typeof requiredFiltersById
		// 		]
		// 	];
		// 	if (dateType.value == 'unscheduled' && filterById.indexOf('date') > -1) {
		// 		filterById.splice(filterById.indexOf('date'), 1);
		// 	}
		// 	prepArr.push(...filterById);
		// }
		return prepArr;
	});

	const checkFilterSelection = (
		data: {
			filter: null | any[];
			filterOptions: any[];
		},
		allowAllSelection?: boolean
	) => {
		if (allowAllSelection) {
			return !!(data.filter && data.filter.length > 0);
		} else {
			return !!(
				data.filter &&
				data.filter.length > 0 &&
				data.filter.length < data.filterOptions.length
			);
		}
	};

	const checkFilters = (allowAllSelection: boolean = false) => {
		const filters = {
			shop: !!(selectedShop.value && selectedShop.value.length),

			date: !!(
				showDateFilter.value &&
				preparedDateFilter.value &&
				preparedDateFilter.value.startDate &&
				preparedDateFilter.value.endDate
			),

			taxes:
				showShopDataFilter.value &&
				customShopDataFilterValue.value.type === 'taxes'
					? !!(
							customShopDataFilterValue.value.value &&
							customShopDataFilterValue.value.value.length
						)
					: false,
			techs:
				showShopDataFilter.value &&
				customShopDataFilterValue.value.type === 'techs'
					? !!(
							customShopDataFilterValue.value.value &&
							customShopDataFilterValue.value.value.length
						)
					: false,
			csrs:
				showShopDataFilter.value &&
				customShopDataFilterValue.value.type === 'csrs'
					? !!(
							customShopDataFilterValue.value.value &&
							customShopDataFilterValue.value.value.length
						)
					: false,
			locations:
				showShopDataFilter.value &&
				customShopDataFilterValue.value.type === 'locations'
					? !!(
							customShopDataFilterValue.value.value &&
							customShopDataFilterValue.value.value.length
						)
					: false,
			salesources:
				showShopDataFilter.value &&
				customShopDataFilterValue.value.type === 'salesources'
					? !!(
							customShopDataFilterValue.value.value &&
							customShopDataFilterValue.value.value.length
						)
					: false,
			salesreps:
				showShopDataFilter.value &&
				customShopDataFilterValue.value.type === 'salesreps'
					? !!(
							customShopDataFilterValue.value.value &&
							customShopDataFilterValue.value.value.length
						)
					: false,

			isAccountingFilterActive: !!(
				showAccountingFilter.value && accountingFilterValue.value
			),

			jobTypes: !!(
				selectedJobType.value &&
				selectedJobType.value.length > 0 &&
				showJobFilter.value
			),

			isCustomerTypeFilterActive: !!(
				selectedCustomerType.value &&
				selectedCustomerType.value.length &&
				showCustomerTypeFilter.value
			),

			isSelectedPaidStatusActive: !!(
				checkFilterSelection(
					{
						filter: selectedPaidStatus.value,
						filterOptions: paidStatus
					},
					allowAllSelection
				) && showPaidStatusFilter.value
			),

			license: !!(
				checkFilterSelection(
					{
						filter: selectedLicenseTypes.value,
						filterOptions: licenseTypes
					},
					allowAllSelection
				) && showLicenseTypesFilter.value
			),

			x_action_types: !!(
				checkFilterSelection(
					{
						filter: paymentsFilterValue.value,
						filterOptions: xActionTypeOptions
					},
					allowAllSelection
				) && showPaymentsFilter.value
			),

			isContractorActive: !!(
				contractorFilterValue.value &&
				contractorFilterValue.value.length &&
				showContractorFilter.value
			),

			isContractorTypesActive: !!(
				contractorFilterTypes.value &&
				contractorFilterTypes.value.length &&
				showContractorFilter.value
			),

			customer: !!(customerFilterValue.value && showCustomerFilter.value)
		};

		return {
			isActive: Object.values(filters).some(Boolean),
			filters
		};
	};

	const isSomeFilterActive = computed(() => checkFilters().isActive);

	const requiredFieldsStatus = computed(() => {
		const filterStatus = checkFilters(true).filters;
		return Object.fromEntries(
			currentRequiredFilters.value
				.filter(key => key in filterStatus)
				.map(key => [key, filterStatus[key as keyof typeof filterStatus]])
		);
	});

	const allowedToGenerateReport = computed(() => {
		return !Object.values(requiredFieldsStatus.value).includes(false);
	});

	const isRequired = (name: string) => {
		return Object.keys(requiredFieldsStatus.value).includes(name);
	};

	const generatePdf = async (params: { html: string; orientation: string }) => {
		try {
			return await getPrintVersion(params);
		} catch (error) {
			messages.showError('Error while generating pdf, try again later.');
		}
	};

	return {
		selectedShop,
		shops,
		currentReportType,
		reportTypesList,

		// Accounting filters
		accountingPackages: accountingPackages.state,
		accountingPackagesLoading: accountingPackages.isLoading,
		accountingFilterValue,
		showAccountingFilter,

		// Job filters
		jobTypesList,
		selectedJobType,
		showJobFilter,

		// Customer filters
		selectedCustomerType,
		showCustomerTypeFilter,

		// licesnse filters
		selectedLicenseTypes,
		showLicenseTypesFilter,

		// paid filters
		selectedPaidStatus,
		showPaidStatusFilter,

		// dateFilters
		showDateFilter,
		dateFilterValue,
		dateType,
		preparedDateFilter,

		// table modes
		tableModeOptions,
		tableMode,
		showTableMode,

		// shop custom filter
		showShopDataFilter,
		shopDataLabel,
		customShopDataFilterOptions: customShopDataFilterOptions.state,
		customShopDataFilterValue,
		customShopDataFilterOptionsLoading: customShopDataFilterOptions.isLoading,
		refetchCustomShopFilter,

		// paymentsFilter
		showPaymentsFilter,
		paymentsFilterValue,

		// contractor filter
		showContractorFilter,
		contractorFilterTypes,
		contractorFilterValue,
		contractorFilterOptionsHandler: contractorFilterOptions,
		contractorFilterOptions: contractorFilterOptions.state,
		contractorFilterOptionsLoading: contractorFilterOptions.isLoading,

		//acrual cash filter
		acrualCashFilterValue,
		showAcrualCashFilter,

		// customer
		showCustomerFilter,
		customerFilterOptions,
		getCustomerFilterOptions,
		customerFilterValue,

		// Get report
		getReportHandler,
		reportData: staticReportData,
		reportDataIsLoading: getReportHandler.isLoading,
		currentColumns,

		rightPartRequestDetails,
		rightPartDetails: rightPartRequestDetails.state,
		rightPartDetailsLoading: rightPartRequestDetails.isLoading,

		selectedWorkType,
		showWorkTypeFilter,
		expandAllAgingPayments,

		isConsumer,
		resetStoreData,
		setDefaultFilterValue,
		isSomeFilterActive,
		additionalFilterIsVisible,
		allowedToGenerateReport,
		staticDate,
		requiredFieldsStatus,
		isRequired,
		currentRequiredFilters,
		includeAdjustments,
		generatePdf
	};
});

export default useReportsStore;
