<template>
	<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<title />
		<rect
			fill="none"
			height="16.5"
			rx="1"
			ry="1"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
			transform="translate(-9 27) rotate(-90)"
			width="10.5"
			x="3.75"
			y="9.75"
		/>
		<path
			d="M23.25,15.75a1.5,1.5,0,0,1-1.5,1.5"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
		/>
		<path
			d="M23.25,2.25a1.5,1.5,0,0,0-1.5-1.5"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
		/>
		<line
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
			x1="17.25"
			x2="18.75"
			y1="17.25"
			y2="17.25"
		/>
		<line
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
			x1="17.25"
			x2="18.75"
			y1="0.75"
			y2="0.75"
		/>
		<line
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
			x1="23.25"
			x2="23.25"
			y1="12.75"
			y2="10.5"
		/>
		<line
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
			x1="23.25"
			x2="23.25"
			y1="7.5"
			y2="5.25"
		/>
		<path
			d="M12.75,2.25a1.5,1.5,0,0,1,1.5-1.5"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
		/>
		<line
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
			x1="12.75"
			x2="12.75"
			y1="12.75"
			y2="10.5"
		/>
		<line
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
			x1="12.75"
			x2="12.75"
			y1="7.5"
			y2="5.25"
		/>
		<path
			d="M1.5,8.561,3.22,10.28a.749.749,0,0,0,1.06,0L6,8.561"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
		/>
		<path
			d="M8.561,6,10.28,4.28a.749.749,0,0,0,0-1.06L8.561,1.5"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
		/>
		<path
			d="M3.75,10.5V6.75a3,3,0,0,1,3-3H10.5"
			fill="none"
			stroke="#64748b"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5px"
		/>
	</svg>
</template>
