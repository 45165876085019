<template>
	<div id="right-part-report">
		<div class="link expand-collapse">
			<span @click="toggleAll"
				>{{ isAllOpen ? t('collapse') : t('expand') }} {{ t('all') }}</span
			>
		</div>
		<Accordion multiple :value="activeAccordions">
			<AccordionPanel
				v-for="(requests, shopName) in splitByShop"
				:key="shopName"
				:name="shopName"
				:value="shopName"
			>
				<AccordionHeader>
					<div class="header">
						<span
							><b
								>{{ shopName }}
								{{ requests.length ? `(${requests[0].shopId})` : `` }}</b
							></span
						>
						<span>
							{{ t('reportsPage.total') }} {{ t('reportsPage.requests') }}:
							<b>{{ requests.length }}</b>
						</span>
						<span>
							{{ t('success') }} {{ t('reportsPage.requests') }}:
							<b>{{ countSuccessRequests(requests) }}</b>
						</span>
					</div>
				</AccordionHeader>
				<AccordionContent>
					<table class="font-size-xs">
						<thead>
							<tr>
								<th>
									<b> {{ t('vin') }} </b>
								</th>
								<th>
									<b>{{ t('date') }} </b>
								</th>
								<th>
									<b>{{ t('user') }}</b>
								</th>
								<th>
									<b>{{ t('job') }}</b>
								</th>
								<th>
									<b>{{ t('result') }}</b>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="request in requests" :key="request.id">
								<td>
									<div class="link" @click="openRequestDetails(request.id)">
										{{ request.vin }}
									</div>
								</td>
								<td>{{ formatDate(request.createdAt) }}</td>
								<td>{{ request.username }} ({{ request.userId }})</td>
								<td>
									<div v-if="request.jobId">
										#
										<a
											class="link"
											:href="'/jobs/' + request.jobId"
											target="_blank"
										>
											{{ request.jobId }}
										</a>
									</div>
									<div v-else-if="request.jobDraftId">
										#
										<a
											class="link"
											:href="'/jobs/new/' + request.jobDraftId"
											target="_blank"
										>
											{{ request.jobDraftId }}
										</a>
									</div>
								</td>
								<td>
									<span v-if="request.partNumber" class="success-result">
										{{ request.partNumber.slice(0, -1) }}
									</span>
									<span v-else>
										<span v-if="request.invalid_vin === 1" class="error-result">
											{{ t('invalid') }} {{ t('vin') }}
										</span>
										<span v-else>
											<span
												v-if="
													request.help_request_status === 'error' ||
													request.help_request_status === 'sent'
												"
												:class="
													request.help_request_status === 'error'
														? 'error-result'
														: 'warning-result'
												"
											>
												{{ capitalize(request.help_request_status) }}
											</span>
										</span>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</AccordionContent>
			</AccordionPanel>
		</Accordion>
		<div class="total">
			<span>
				{{ t('reportsPage.total') }} {{ t('requests') }}: {{ data.length }}
			</span>
			<span class="success-total">
				{{ t('reportsPage.total') }} {{ t('success') }} {{ t('requests') }}:
				{{ countSuccessRequests(data) }}
			</span>
		</div>
		<ReportsRequestDetails v-model:visible="showRequestModal" />
	</div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import { splitReportDataByAttr } from '@/widgets/reportsElements/model/utils/getters';

import ReportsRequestDetails from './ReportsRequestDetails.vue';

const { t } = useI18n();

const props = defineProps<{
	data: any;
}>();

const splitByShop = computed(() =>
	splitReportDataByAttr('shopName')(props.data)
);

const countSuccessRequests = (requests: any[]) => {
	return requests.filter(
		request => request.partNumber !== null && request.partNumber !== ''
	).length;
};

dayjs.extend(advancedFormat);
const formatDate = (date: string) => dayjs(date).format('lll');

const capitalize = (value: string) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

const activeAccordions = ref<string[]>([]);
const isAllOpen = ref(false);
const toggleAll = () => {
	if (isAllOpen.value) {
		activeAccordions.value = [];
		isAllOpen.value = false;
	} else {
		activeAccordions.value = Object.keys(splitByShop.value);
		isAllOpen.value = true;
	}
};

const reportStore = useReportsStore();
const showRequestModal = ref<boolean>(false);
const openRequestDetails = (id: number) => {
	reportStore.rightPartRequestDetails.execute(0, id);
	showRequestModal.value = true;
};
</script>

<style scoped>
#right-part-report .expand-collapse {
	margin-bottom: 10px;
	font-size: 12px;
}
#right-part-report .link {
	color: blue !important;
}
#right-part-report .link:hover {
	cursor: pointer;
}
#right-part-report .header {
	font-size: 14px;
	width: 100%;
	display: flex;
}
#right-part-report .header span:first-child {
	flex-grow: 1;
}
#right-part-report .header span:not(:first-child) {
	margin-right: 20px;
}
#right-part-report .font-size-xs {
	font-size: 13px;
}
#right-part-report .error-result {
	color: #f56c6c;
}
#right-part-report .success-result {
	color: #67c23a;
}
#right-part-report .warning-result {
	color: #e6a23c;
}
#right-part-report table {
	border-collapse: collapse;
	display: table;
	width: 100%;
}
#right-part-report table,
#right-part-report table th,
#right-part-report table td {
	border: 1px solid #c0c4cc;
}
#right-part-report table th,
#right-part-report table td {
	padding-left: 7px;
}
#right-part-report .total {
	font-size: 20px;
	margin-top: 20px;
}
#right-part-report .total .success-total {
	float: right;
	margin-right: 15px;
}
</style>
