<template>
	<Dialog
		id="TextTemplatesListWindow"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-max-w-[800px] tw3-h-full tw3-w-full"
		:header="t('reportsPage.textMessagesTemplate.emailTemplates')"
		modal
		:pt:content="'tw3-h-full tw3-flex tw3-flex-col'"
	>
		<DataTable
			class="tw3-h-full"
			dataKey="name"
			lazy
			scrollable
			scrollHeight="flex"
			tableClass="tw3-w-full tw3-h-full tw3-max-h-full"
			:totalRecords="templatesData.length"
			:value="templateIsLoading ? skeletonArray : templatesData"
		>
			<Column
				v-for="column of currentHeaders"
				:key="column.key"
				:field="column.key"
				:header="column.title"
			>
				<template #body="{ data }">
					<div class="cell-container">
						<Skeleton v-if="templateIsLoading" />
						<template v-else>
							<div
								v-if="column.key === 'default'"
								class="tw3-flex tw3-w-full tw3-items-center tw3-justify-center"
								:class="{
									'tw3-text-green-600': data[column.key] === 1,
									'tw3-text-red-600': data[column.key] !== 1
								}"
							>
								<i v-if="data[column.key] === 1" class="pi pi-check"></i>
								<i v-else class="pi pi-times"></i>
							</div>
							<span v-else>{{ data[column.key] }}</span>
						</template>
					</div>
				</template>
			</Column>
			<Column header="Actions" style="max-width: 120px">
				<template #body="{ data }">
					<div class="cell-container">
						<Skeleton v-if="templateIsLoading" />
						<div v-else class="tw3-flex tw3-gap-2 tw3-items-center">
							<Button
								icon="pi pi-pencil"
								rounded
								severity="secondary"
								size="small"
								text
								@click="editTemplate(data)"
							/>
							<Button
								class="ml-0"
								icon="pi pi-trash"
								rounded
								severity="danger"
								size="small"
								text
								@click="deleteEmailTemplate(data)"
							/>
						</div>
					</div>
				</template>
			</Column>
		</DataTable>

		<template #footer>
			<div class="tw3-flex tw3-justify-end">
				<Button
					icon="pi pi-plus-circle"
					:label="t('reportsPage.textMessagesTemplate.addTemplates')"
					severity="secondary"
					size="small"
					type="button"
					@click="addNewTemplate"
				/>
			</div>
		</template>

		<TemplateEditor
			v-model:visible="showEditor"
			:template="editableTemplate"
			:templatesTypes="templatesTypes"
			@update-templates="closeTemplateEditor"
		/>
	</Dialog>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	deleteTextMessageTemplate,
	getEmailTemplates,
	getTextMessagesTypes
} from '@/widgets/reportsElements/api/api';

import { EmailTemplate } from '../model/types';

import TemplateEditor from './TemplateEditor.vue';

const confirm = useConfirm();
const toast = useToast();
const { t } = useI18n();
const showModal = defineModel<boolean>('show');
const templatesData = ref<EmailTemplate[]>([]);
const templateIsLoading = ref(false);
const skeletonArray = Array(10).fill({});
const currentHeaders = [
	{
		title: t('name'),
		key: 'name'
	},
	{
		title: t('shopName'),
		key: 'shop_name'
	},
	{
		title: t('reportsPage.textMessagesTemplate.typeName'),
		key: 'type_name'
	},
	{
		title: t('reportsPage.textMessagesTemplate.default'),
		key: 'default'
	}
];

onMounted(() => {
	refreshTemplates();
});

const refreshTemplates = async () => {
	templateIsLoading.value = true;

	templatesData.value = await getEmailTemplates();
	templateIsLoading.value = false;
};

const closeTemplateEditor = () => {
	showEditor.value = false;
	refreshTemplates();
};

const deleteEmailTemplate = (data: EmailTemplate) => {
	confirm.require({
		accept: async () => {
			const response = await deleteTextMessageTemplate(data.id);

			if (response.status === 200) {
				toast.add({
					life: 3000,
					severity: 'success',
					detail: t('reportsPage.textMessagesTemplate.deleted'),
					summary: t('copyTextToast.succesSummary')
				});

				const index = templatesData.value.findIndex(
					item => item.id === data.id
				);
				templatesData.value.splice(index, 1);
			} else {
				toast.add({
					life: 3000,
					severity: 'error',
					detail: t('copyTextToast.errorDetails'),
					summary: t('copyTextToast.errorSummary')
				});
			}
		},
		acceptProps: {
			label: t('yes')
		},
		header: t('warning'),
		icon: 'pi pi-info-circle',
		message: t('reportsPage.textMessagesTemplate.deleteQuestion'),
		rejectProps: {
			label: t('no'),
			outlined: true,
			severity: 'secondary'
		}
	});
};

const showEditor = ref(false);
const templatesTypes = ref([]);
const editableTemplate = ref();
const addNewTemplate = async () => {
	showEditor.value = true;
	editableTemplate.value = null;
	templatesTypes.value = await getTextMessagesTypes('email');
};

const editTemplate = async (data: EmailTemplate) => {
	showEditor.value = true;
	templatesTypes.value = await getTextMessagesTypes('email');
	editableTemplate.value = data;
};
</script>
