export enum VendorSortBy {
	id = 'id',
	notes = 'notes',
	company_name = 'company_name',
	fax = 'fax',
	full_address = 'full_address',
	name_first = 'name_first',
	name_last = 'name_last',
	email = 'email',
	phone = 'phone',
	status = 'status',
	flagged = 'flagged',
	tax_exempt = 'tax_exempt',
	po_required = 'po_required',
	customer_type = 'customer_type'
}
