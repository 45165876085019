import { DateTime } from 'luxon';

// Format a number to a currency string
export const formatMoney = (value: number) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	}).format(value);
};

export const formatDate = (date: DateTime) => {
	return date.toFormat('LLL dd, yyyy');
};

export const formatAsPhoneNumber = (value: string): string => {
	const digits = value.replace(/\D/g, '');
	let preparedValue = '';
	if (digits.length <= 3) {
		preparedValue = `(${digits})`;
	} else if (digits.length <= 6) {
		preparedValue = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
	} else {
		preparedValue = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
	}
	return preparedValue;
};

export const formatFileSize = (bytes: number): string => {
	const k = 1024;
	const dm = 3;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	if (bytes === 0) {
		return `0 ${sizes[0]}`;
	}

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

	return `${formattedSize} ${sizes[i]}`;
};

export const capitalizeStr = (value: string) => {
	return value.replace(/\b\w/g, char => char.toUpperCase());
};
