<template>
	<div
		style="
			display: flex;
			align-items: center;
			padding: 40px 16px 0 16px;
			display: flex;
			margin-bottom: 32px;
		"
	>
		<div v-if="shop && shop.logo.length > 0">
			<img
				alt="Shop's logo"
				class="tw3-object-contain"
				:src="shop.logo"
				style="max-height: 70px; max-width: 200px; height: auto"
			/>
		</div>
		<div style="flex-grow: 1; text-align: right">
			<div style="font-size: 1.25rem; color: #4a5568; margin-bottom: 0.25rem">
				Aging Report
			</div>
			<div style="color: #718096">as of {{ date }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';

import { useUserStore } from '@/entities/user/store';

const props = defineProps<{
	shopId: number;
}>();

const userStore = useUserStore();
const date = computed(() => dayjs().format('MMMM D, YYYY'));
const shop = computed(() =>
	userStore.user.shops.find(i => i.id === props.shopId)
);
</script>
