<script setup lang="ts">
import Card from 'primevue/card';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Address } from '@/entities/address/lib/types';
import { getAddress } from '@/entities/customer/lib/api';
import { useCustomerEditStore } from '@/entities/customer/lib/store';
import GbDefaultBreadcrumb from '@/shared/ui/GbDefaultBreadcrumb.vue';
import AddressDialog from '@/widgets/addressDialog/AddressDialog.vue';
import CustomersList from '@/widgets/customers/CustomersList.vue';
import EditCustomerDialog from '@/widgets/editCustomerDialog/editCustomerDialog.vue';

const { t } = useI18n();

const tabs = [{ label: t('data') }, { label: t('customers') }];

const editStore = useCustomerEditStore();

const address = ref<Address>();
const showAddress = ref(false);

const showEditDialog = ref(false);

const toggleAddress = async (id: number, addressId: number) => {
	if (address.value && address.value.id === addressId) return;
	address.value = await getAddress(id, addressId);
	showAddress.value = true;
};

const toggleEdit = async (id: string) => {
	await editStore.customer.execute(0, { id });
	showEditDialog.value = true;
};
</script>

<template>
	<div
		class="tw3-w-full tw3-h-full tw3-max-w-[1440px] tw3-flex tw3-flex-col tw3-gap-[1rem] tw3-overflow-hidden tw3-mx-auto"
	>
		<GbDefaultBreadcrumb :tabs="tabs" />
		<Card
			pt:body:class="tw3-overflow-auto tw3-h-full"
			pt:content:class="tw3-overflow-auto tw3-h-full"
			pt:root:class="tw3-w-full tw3-h-full tw3-overflow-auto"
		>
			<template #content>
				<EditCustomerDialog v-model:show="showEditDialog" />
				<AddressDialog
					v-if="address"
					v-model:show="showAddress"
					:fullAddress="address.full_address"
					:lat="address.lat"
					:lng="address.lng"
				/>
				<CustomersList @on-edit="toggleEdit" @on-open-address="toggleAddress" />
			</template>
		</Card>
	</div>
</template>
