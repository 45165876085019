<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Listbox from 'primevue/listbox';
import MultiSelect from 'primevue/multiselect';
import Popover from 'primevue/popover';
import Select from 'primevue/select';
import { useConfirm } from 'primevue/useconfirm';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useJobEditCellStore, useJobsStore } from '../model/store';

const { t } = useI18n();
const store = useJobsStore();
const editStore = useJobEditCellStore();
const {
	viewOptions,
	view,
	selectedFilters,
	selectedFiltersLocal,
	loading,
	loadingMore,
	loadingFiltersData,
	updateViewApiLoading,
	updateViewLoading,
	exportingLoading
} = storeToRefs(store);

const confirm = useConfirm();
const popupFilters = ref<any>();

const toggleFilter = (event: MouseEvent) => {
	popupFilters.value.toggle(event);
};

const exportButtonPressed = async () => {
	confirm.require({
		accept: async () => {
			await store.exportData();
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('export'),
		message: t('exportConfirm'),
		// exportMode.value && !exportAllRowsSelected.value
		// 	? t('exportConfirm', { n: exportRows.value.length })
		// 	: t('exportConfirm', { n: store.jobsAsync.state.numberOfRowsFound }),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};
</script>

<template>
	<div
		class="tw3-flex tw3-items-center tw3-justify-between tw3-gap-4 tw3-flex-col lg:tw3-flex-row"
	>
		<div
			class="tw3-flex tw3-items-center tw3-gap-4 tw3-flex-col sm:tw3-flex-row tw3-w-full lg:tw3-w-auto"
		>
			<div class="tw3-flex tw3-items-center tw3-gap-4 tw3-w-full sm:tw3-w-auto">
				<Button
					:disabled="
						loading || loadingFiltersData || updateViewLoading || loadingMore
					"
					:label="$t('export')"
					:loading="exportingLoading"
					raised
					text
					@click="exportButtonPressed"
				/>
				<!-- <ToggleButton
					v-model="exportMode"
					binary
					:disabled="exportingLoading"
					:offLabel="$t('exportMode')"
					:onLabel="$t('exportMode')"
				/> -->
				<div
					v-if="selectedFilters.length"
					class="tw3-flex tw3-justify-center tw3-w-full sm:tw3-w-auto"
				>
					<div class="tw3-relative tw3-w-full sm:tw3-w-auto">
						<MultiSelect
							v-model="selectedFilters"
							aria-controls="overlay_popover_selected_filters"
							class="tw3-w-full sm:tw3-w-56"
							:disabled="loading || loadingFiltersData || updateViewLoading"
							display="chip"
							:loading="loading || loadingFiltersData || updateViewLoading"
							optionLabel="name"
							:options="selectedFilters"
							overlayClass="!tw3-hidden"
							@click="toggleFilter"
						/>
						<span
							class="tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-[-7px] tw3-px-[0.25rem] tw3-rounded"
						>
							{{ $t('selectedFilters') }}
						</span>
					</div>
					<Popover
						id="overlay_popover_selected_filters"
						ref="popupFilters"
						class="tw3-max-h-96 tw3-overflow-auto"
						@hide="store.onSelectedFiltersMultiselectHide"
					>
						<Listbox
							v-model="selectedFiltersLocal"
							class="w-full"
							:disabled="loading"
							listStyle="min-width: 172px"
							multiple
							optionLabel="name"
							:options="selectedFiltersLocal"
							pt:root:class="!tw3-border-0"
						>
							<template #option="{ option }">
								<div
									class="tw3-w-full tw3-flex tw3-items-center tw3-justify-between tw3-gap-4"
								>
									<span>{{ option.name }}</span>
									<i class="pi pi-times"></i>
								</div>
							</template>
						</Listbox>
					</Popover>
				</div>
			</div>
		</div>
		<div class="tw3-flex tw3-items-center tw3-gap-4 tw3-w-full lg:tw3-w-auto">
			<div class="tw3-relative tw3-w-full lg:tw3-w-56">
				<Select
					id="selected_view"
					:key="'change-view_' + updateViewLoading"
					class="tw3-w-full lg:tw3-w-56"
					:disabled="loading || loadingFiltersData || updateViewLoading"
					:loading="loading || updateViewLoading || updateViewApiLoading"
					:modelValue="view.selectedView"
					optionLabel="name"
					:options="viewOptions"
					optionValue="id"
					@update:model-value="v => store.handleChangeView(v)"
				>
					<template #option="{ option }">
						<div
							class="tw3-flex tw3-justify-between tw3-items-center tw3-gap-2 tw3-w-full"
						>
							<span class="tw3-max-w-36 tw3-truncate">{{ option.name }}</span>
							<span
								v-if="option.id === view.defaultView"
								class="tw3-rounded-2xl tw3-p-1 tw3-bg-gray-200 tw3-text-xs"
								style="color: var(--p-select-option-color)"
							>
								{{ $t('default') }}
							</span>
						</div>
					</template>
				</Select>
				<span
					class="tw3-absolute tw3-text-xs tw3-left-2 tw3-bg-white tw3-top-[-7px] tw3-px-[0.25rem] tw3-rounded"
				>
					{{ $t('myViews') }}
				</span>
			</div>
			<Button
				:key="'change-view_' + updateViewLoading"
				:disabled="loading || updateViewLoading || updateViewApiLoading"
				icon="pi pi-objects-column"
				severity="secondary"
				size="small"
				@click="editStore.toggleJobViewDialog"
			/>
		</div>
	</div>
</template>
