<template>
	<table v-if="data" class="tax-summary-table">
		<tbody>
			<tr v-for="col in currentColumns" :key="col.prop">
				<td class="cell" v-html="col.label"></td>
				<td v-if="col.prop !== 'empty'" class="cell">
					{{
						reportsGetters.formatNumber(data[col.prop], {
							style: 'dollar'
						})
					}}
				</td>
				<td v-else class="cell">&nbsp;</td>
			</tr>
			<template v-if="currentReportType.id === 'tax-summary'">
				<tr v-for="col in taxExtraColumns" :key="col">
					<td class="cell">{{ col }}</td>
					<td class="cell">
						{{
							reportsGetters.formatNumber(data[col], {
								style: 'dollar'
							})
						}}
					</td>
				</tr>
				<tr v-for="col in taxSummaryLastCols" :key="col.prop">
					<td class="cell" v-html="col.label"></td>
					<td class="cell">
						{{
							reportsGetters.formatNumber(data[col.prop], {
								style: 'dollar'
							})
						}}
					</td>
				</tr>
			</template>
		</tbody>
	</table>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { taxSummaryLastCols } from '@/widgets/reportsElements/model/constants';
import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

const props = defineProps<{
	data: object[] | null;
}>();

const reportStore = useReportsStore();

const { currentReportType, currentColumns } = storeToRefs(reportStore);

const taxExtraColumns = computed(() => {
	const allCol = [...currentColumns.value, ...taxSummaryLastCols];
	const mappedColumns = allCol.map(col => col.prop);
	if (props.data) {
		return Object.keys(props.data).filter(key => !mappedColumns.includes(key));
	}
	return [];
});
</script>

<style scoped>
.tax-summary-table {
	display: table;
	margin: 0 auto;
	width: auto !important;
	margin-top: 30px;
}

.tax-summary-table tr td {
	background: #fff;
	font-size: 16px !important;
}

.tax-summary-table tr td:first-child {
	font-weight: bold;
}

.tax-summary-table tr td:last-child {
	color: #00a736;
}

.tax-summary-table tr:first-child td {
	border-top: none;
}
</style>
