import { useAsyncState } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { computed, reactive, ref, watchEffect } from 'vue';

import { useTableLoadingMore } from '@/shared/composables';
import { filterEmptyFields } from '@/shared/helpers';

import { exportMany, getMany } from '../../api';
import { ITEMS_PER_REQUEST, TABLE_ID, vendorsFilters } from '../../config';
import { VendorsFiltersWithMetadata, VendorTable } from '../types';

export const useVendorsStore = defineStore('pagesVendors', () => {
	const toast = useToast();

	const loadingMore = ref(false);
	const exportingLoading = ref(false);
	const preventFetchMore = ref(false);
	const sort = reactive({ desc: 'desc', field: 'id' });
	const filters = ref<VendorsFiltersWithMetadata<VendorTable>>({
		...vendorsFilters
	});

	const tableLoading = computed(() => vendors.isLoading.value);

	const { items, shownItems, initCb, moreCb } = useTableLoadingMore(
		tableLoading,
		preventFetchMore
	);

	const vendors = useAsyncState(
		async (args: any) => {
			const searchFilters = filterEmptyFields(
				filters?.value as Record<string, any>,
				'val',
				true
			);

			const table = document.getElementById(TABLE_ID);
			(table as HTMLElement).scrollTop = 0;

			const data = await getMany({
				...args,
				getCount: true,
				filters: searchFilters
			}).catch(error => {
				toast.add({
					detail: error,
					life: 3000,
					severity: 'error',
					summary: 'Error Message'
				});
				return { count: 0, vendors: [] };
			});
			preventFetchMore.value = data.vendors.length < ITEMS_PER_REQUEST;
			initCb(data.vendors);
			return data;
		},
		{ count: 0, vendors: [] },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const fetchMore = async () => {
		loadingMore.value = true;
		const args = composeGetCustomersArgs(items.value.length - 3);
		const searchFilters = filterEmptyFields(
			filters?.value as Record<string, any>,
			'val',
			true
		);

		const data = await getMany({
			...args,
			filters: searchFilters
		}).catch(error => {
			toast.add({
				detail: error,
				life: 3000,
				severity: 'error',
				summary: 'Error Message'
			});
			return { vendors: [] };
		});
		preventFetchMore.value = data.vendors.length < ITEMS_PER_REQUEST;
		moreCb(data.vendors);
		loadingMore.value = false;
	};

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') {
			return;
		}

		sort.field = sortData.field;
		sort.desc = sortData.desc ? 'desc' : 'asc';
	};

	const composeGetCustomersArgs = (offset: number) => {
		return {
			filters: filters.value,
			offset,
			getCount: false,
			sort: {
				sortBy: sort.field,
				sortOrder: sort.desc
			}
		};
	};

	const exportData = async () => {
		exportingLoading.value = true;
		const args = composeGetCustomersArgs(0);
		const searchFilters = filterEmptyFields(
			filters?.value as Record<string, any>,
			'val',
			true
		);
		try {
			const data = await exportMany({
				...args,
				filters: searchFilters
			});

			if (data.csvExport === 'yes' && data.csvUrl && data.csvUrl.length > 0) {
				window.open(data.csvUrl[0], '_blank');
			}
		} catch (error) {
			toast.add({
				detail: error,
				life: 3000,
				severity: 'error',
				summary: 'Error exporting customer data'
			});
		}
		exportingLoading.value = false;
	};

	watchEffect(async () => {
		await vendors.execute(0, composeGetCustomersArgs(0));
	});

	return {
		composeGetCustomersArgs,
		exportData,
		exportingLoading,
		filters,
		fetchMore,
		preventFetchMore,
		handleSort,
		loadingMore,
		vendors,
		sort,
		items,
		shownItems,
		tableLoading
	};
});
