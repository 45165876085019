<template>
	<svg
		enable-background="new 0 0 48 48"
		fill="none"
		version="1.0"
		viewBox="0 0 48 48"
		xml:space="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g id="Layer_1" />
		<g id="_x32_3_x5F_Financial_x5F_Report">
			<path
				d="M40,3H8C7.448,3,7,3.448,7,4v4c0,0.552,0.448,1,1,1h2v23c0,0.553,0.448,1,1,1h12v4.586l-4.707,4.707   c-0.391,0.391-0.391,1.023,0,1.414C18.488,43.902,18.744,44,19,44s0.512-0.098,0.707-0.293L23,40.414V43c0,0.553,0.448,1,1,1   s1-0.447,1-1v-2.586l3.293,3.293C28.488,43.902,28.744,44,29,44s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414   L25,37.586V33h12c0.553,0,1-0.447,1-1V9h2c0.553,0,1-0.448,1-1V4C41,3.448,40.553,3,40,3z M36,31H12V9h24V31z M39,7h-2H11H9V5h30V7   z M15,27v-2c0-0.552,0.448-1,1-1s1,0.448,1,1v2c0,0.553-0.448,1-1,1S15,27.553,15,27z M21,25v2c0,0.553-0.448,1-1,1s-1-0.447-1-1   v-2c0-0.552,0.448-1,1-1S21,24.448,21,25z M23,27v-4c0-0.552,0.448-1,1-1s1,0.448,1,1v4c0,0.553-0.448,1-1,1S23,27.553,23,27z    M29,19v8c0,0.553-0.447,1-1,1s-1-0.447-1-1v-8c0-0.552,0.447-1,1-1S29,18.448,29,19z M33,17v10c0,0.553-0.447,1-1,1s-1-0.447-1-1   V17c0-0.552,0.447-1,1-1S33,16.448,33,17z M19,17.5c0-0.276-0.224-0.5-0.5-0.5h-1c-1.378,0-2.5-1.122-2.5-2.5   c0-1.207,0.86-2.217,2-2.449V11c0-0.552,0.448-1,1-1s1,0.448,1,1v1h1c0.552,0,1,0.448,1,1s-0.448,1-1,1h-2.5   c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h1c1.378,0,2.5,1.122,2.5,2.5c0,1.207-0.86,2.217-2,2.449V21c0,0.552-0.448,1-1,1   s-1-0.448-1-1v-1h-1c-0.552,0-1-0.448-1-1s0.448-1,1-1h2.5C18.776,18,19,17.776,19,17.5z M25,13c0-0.552,0.447-1,1-1h6   c0.553,0,1,0.448,1,1s-0.447,1-1,1h-6C25.447,14,25,13.552,25,13z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
