<template>
	<svg
		fill="none"
		style="enable-background: new 0 0 512 512"
		version="1.1"
		viewBox="0 0 512 512"
		xml:space="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g id="_x30_6_x2C__Do_x2C__done_x2C__list_x2C__tasks_x2C__list">
			<g id="XMLID_858_">
				<g id="XMLID_214_">
					<path
						id="XMLID_732_"
						d="M177.837,219.251c-1.28,0-2.559-0.488-3.536-1.464l-15.199-15.199c-1.953-1.953-1.953-5.119,0-7.071     c1.953-1.952,5.118-1.952,7.071,0l11.664,11.664l31.93-31.93c1.953-1.952,5.118-1.952,7.071,0c1.953,1.953,1.953,5.119,0,7.071     l-35.465,35.465C180.396,218.763,179.117,219.251,177.837,219.251z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_213_">
					<path
						id="XMLID_731_"
						d="M349.185,218.997h-93.146c-2.761,0-5-2.239-5-5s2.239-5,5-5h93.146c2.762,0,5,2.239,5,5     S351.946,218.997,349.185,218.997z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_212_">
					<path
						id="XMLID_730_"
						d="M299.029,190.336h-42.991c-2.761,0-5-2.239-5-5s2.239-5,5-5h42.991c2.762,0,5,2.239,5,5     S301.791,190.336,299.029,190.336z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_211_">
					<path
						id="XMLID_729_"
						d="M177.837,290.902c-1.28,0-2.559-0.488-3.536-1.465l-15.199-15.199c-1.953-1.952-1.953-5.118,0-7.07     c1.953-1.953,5.118-1.953,7.071,0l11.664,11.663l31.93-31.93c1.953-1.952,5.118-1.952,7.071,0c1.953,1.953,1.953,5.119,0,7.071     l-35.465,35.465C180.396,290.414,179.117,290.902,177.837,290.902z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_210_">
					<path
						id="XMLID_728_"
						d="M349.185,290.648h-93.146c-2.761,0-5-2.238-5-5s2.239-5,5-5h93.146c2.762,0,5,2.238,5,5     S351.946,290.648,349.185,290.648z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_209_">
					<path
						id="XMLID_715_"
						d="M299.029,261.988h-42.991c-2.761,0-5-2.239-5-5s2.239-5,5-5h42.991c2.762,0,5,2.239,5,5     S301.791,261.988,299.029,261.988z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_208_">
					<path
						id="XMLID_714_"
						d="M177.837,362.554c-1.326,0-2.598-0.526-3.536-1.465l-15.199-15.199c-1.953-1.952-1.953-5.118,0-7.07     c1.953-1.953,5.118-1.953,7.071,0l11.664,11.663l31.93-31.93c1.953-1.953,5.118-1.953,7.071,0c1.953,1.952,1.953,5.118,0,7.07     l-35.465,35.466C180.435,362.027,179.163,362.554,177.837,362.554z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_207_">
					<path
						id="XMLID_713_"
						d="M349.185,362.299h-93.146c-2.761,0-5-2.238-5-5s2.239-5,5-5h93.146c2.762,0,5,2.238,5,5     S351.946,362.299,349.185,362.299z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_206_">
					<path
						id="XMLID_712_"
						d="M299.029,333.639h-42.991c-2.761,0-5-2.238-5-5s2.239-5,5-5h42.991c2.762,0,5,2.238,5,5     S301.791,333.639,299.029,333.639z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_205_">
					<path
						id="XMLID_711_"
						d="M177.837,434.205L177.837,434.205c-1.326,0-2.598-0.526-3.536-1.465l-15.199-15.2     c-1.953-1.952-1.953-5.118,0-7.071c1.954-1.951,5.119-1.952,7.071,0.001l11.664,11.664l31.93-31.93     c1.953-1.953,5.118-1.953,7.071,0c1.953,1.952,1.953,5.118,0,7.07l-35.465,35.466     C180.435,433.679,179.163,434.205,177.837,434.205z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_204_">
					<path
						id="XMLID_710_"
						d="M349.185,433.95h-93.146c-2.761,0-5-2.238-5-5s2.239-5,5-5h93.146c2.762,0,5,2.238,5,5     S351.946,433.95,349.185,433.95z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_203_">
					<path
						id="XMLID_709_"
						d="M299.029,405.29h-42.991c-2.761,0-5-2.238-5-5s2.239-5,5-5h42.991c2.762,0,5,2.238,5,5     S301.791,405.29,299.029,405.29z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_202_">
					<path
						id="XMLID_706_"
						d="M401,474.205H111c-2.761,0-5-2.238-5-5v-390c0-2.761,2.239-5,5-5h290c2.762,0,5,2.239,5,5v390     C406,471.967,403.762,474.205,401,474.205z M116,464.205h280v-380H116V464.205z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_201_">
					<path
						id="XMLID_705_"
						d="M169.074,106.762c-2.761,0-5-2.239-5-5s2.239-5,5-5c13.5,0,24.483-10.983,24.483-24.483     s-10.983-24.483-24.483-24.483c-13.5,0-24.484,10.983-24.484,24.483c0,2.761-2.239,5-5,5s-5-2.239-5-5     c0-19.014,15.469-34.483,34.484-34.483c19.014,0,34.483,15.469,34.483,34.483S188.088,106.762,169.074,106.762z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_200_">
					<path
						id="XMLID_704_"
						d="M227.58,106.762c-2.761,0-5-2.239-5-5s2.239-5,5-5c13.5,0,24.483-10.983,24.483-24.483     S241.08,47.795,227.58,47.795s-24.483,10.983-24.483,24.483c0,2.761-2.239,5-5,5s-5-2.239-5-5     c0-19.014,15.469-34.483,34.483-34.483s34.483,15.469,34.483,34.483S246.594,106.762,227.58,106.762z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_199_">
					<path
						id="XMLID_703_"
						d="M286.087,106.762c-2.762,0-5-2.239-5-5s2.238-5,5-5c13.5,0,24.483-10.983,24.483-24.483     s-10.983-24.483-24.483-24.483s-24.483,10.983-24.483,24.483c0,2.761-2.239,5-5,5s-5-2.239-5-5     c0-19.014,15.469-34.483,34.484-34.483s34.483,15.469,34.483,34.483S305.102,106.762,286.087,106.762z"
						fill="currentColor"
					/>
				</g>
				<g id="XMLID_198_">
					<path
						id="XMLID_702_"
						d="M344.593,106.762c-2.762,0-5-2.239-5-5s2.238-5,5-5c13.5,0,24.483-10.983,24.483-24.483     s-10.983-24.483-24.483-24.483s-24.483,10.983-24.483,24.483c0,2.761-2.238,5-5,5s-5-2.239-5-5     c0-19.014,15.469-34.483,34.483-34.483s34.483,15.469,34.483,34.483S363.607,106.762,344.593,106.762z"
						fill="currentColor"
					/>
				</g>
			</g>
		</g>
		<g id="Layer_1" />
	</svg>
</template>
