import { startCase, upperCase } from 'lodash-es';

import { VendorType } from '../model/enums';

export const getVendorColorByType = (document_type: string) => {
	if (document_type === upperCase(VendorType.csr)) {
		return '#00897b';
	} else if (document_type === startCase(VendorType.salesrep)) {
		return '#299fd7';
	} else if (document_type === startCase(VendorType.tech)) {
		return '#54a955';
	} else if (document_type === startCase(VendorType.salesource)) {
		return '#eab308';
	} else if (document_type === startCase(VendorType.location)) {
		return '#6b7280';
	} else if (document_type === startCase(VendorType.supplier)) {
		return '#805ad5';
	}
};
