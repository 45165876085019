import { i18n } from '@/shared/i18n';

export const calculateSubrogationData = (code: string) => {
	if (code === 'CAUSE_OF_LOSS_CODE_105') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_CODE_105'
			),
			SubrogationData: i18n.global.t(
				'subrogationDataKeys.CAUSE_OF_LOSS_CODE_105'
			)
		};
	} else if (code === 'CAUSE_OF_LOSS_CODE_111') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_CODE_111'
			),
			SubrogationData: i18n.global.t(
				'subrogationDataKeys.CAUSE_OF_LOSS_CODE_111'
			)
		};
	} else if (code === 'CAUSE_OF_LOSS_CODE_199') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_CODE_199'
			),
			SubrogationData: i18n.global.t(
				'subrogationDataKeys.CAUSE_OF_LOSS_CODE_199'
			)
		};
	} else if (code === 'CAUSE_OF_LOSS_CODE_201') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_CODE_201'
			),
			SubrogationData: i18n.global.t(
				'subrogationDataKeys.CAUSE_OF_LOSS_CODE_201'
			)
		};
	} else if (code === 'CAUSE_OF_LOSS_CODE_301') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_CODE_301'
			),
			SubrogationData: i18n.global.t(
				'subrogationDataKeys.CAUSE_OF_LOSS_CODE_301'
			)
		};
	} else if (code === 'CAUSE_OF_LOSS_CODE_341') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_CODE_341'
			),
			SubrogationData: i18n.global.t(
				'subrogationDataKeys.CAUSE_OF_LOSS_CODE_341'
			)
		};
	} else if (code === 'CAUSE_OF_LOSS_CODE_499') {
		return {
			SubrogationContactName: i18n.global.t(
				'subrogationContactName.CAUSE_OF_LOSS_CODE_499'
			),
			SubrogationData: i18n.global.t(
				'subrogationDataKeys.CAUSE_OF_LOSS_CODE_499'
			)
		};
	}
	return {
		SubrogationContactName: null,
		SubrogationData: null
	};
};
