import { CORE_API } from '@/shared/api';
import {
	EmailHistoryRow,
	EmailTemplate
} from '@/widgets/reportShareDialog/model/types';

import { CustomerSearchResult } from './types';
import { AccountingPackages, CustomShopData, RequestDetails } from './types';

const reportsBaseURL = '/api/reporting';

export const getUserConnectedAccountingPackages = async (): Promise<
	AccountingPackages[]
> => {
	try {
		const response = await CORE_API.get<AccountingPackages[]>(
			`/api/accounting_packages/user`
		);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getCustomShopData = async (
	type: string,
	shops: number[],
	isConsumer: boolean
): Promise<CustomShopData[]> => {
	try {
		const response = await CORE_API.get<CustomShopData[]>(
			`${reportsBaseURL}/shop_data/${type}`,
			{
				params: {
					shops,
					isConsumer
				}
			}
		);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getCustomerBySearch = async (params: {
	term: string;
	organization_ids: number[];
	shop_ids: number[];
	offset: number;
}): Promise<CustomerSearchResult> => {
	const response = await CORE_API.get<CustomerSearchResult>(
		`/unum/consumer/search/q`,
		{
			params: {
				...params,
				type: 'all'
			}
		}
	);
	return response.data;
};

export const getReportByType = async (params: {
	isFullyCustom?: boolean;
	reportEndpoint: string;
	filters: any;
	isConsumer: boolean;
}): Promise<object[] | { error: string }> => {
	const url = !params.isFullyCustom
		? `${reportsBaseURL}${params.reportEndpoint}`
		: params.reportEndpoint;
	const response = await CORE_API.get<object[] | { error: string }>(url, {
		params: {
			...params.filters
		}
	});
	return response.data;
};

export const postReportByType = async (params: {
	isFullyCustom?: boolean;
	reportEndpoint: string;
	filters: any;
	isConsumer: boolean;
}): Promise<object[]> => {
	const url = !params.isFullyCustom
		? `${reportsBaseURL}${params.reportEndpoint}`
		: params.reportEndpoint;
	const response = await CORE_API.post<object[]>(url, {
		...params.filters
	});
	return response.data;
};

export const getPrintVersion = async (params: {
	html: string;
	orientation: string;
}): Promise<ArrayBuffer> => {
	const response = await CORE_API.post<ArrayBuffer>(
		`${reportsBaseURL}/print`,
		params,
		{
			responseType: 'arraybuffer'
		}
	);
	return response.data;
};

export const getRequestDetails = async (
	id: number
): Promise<RequestDetails> => {
	const response = await CORE_API.get<RequestDetails>(
		`/api/right-parts/requests/${id}`
	);
	return response.data;
};

export const getEmailTemplates = async (
	type?: number
): Promise<EmailTemplate[]> => {
	const response = await CORE_API.get<EmailTemplate[]>(
		`unum/job-details/textMessageTemplate/allByType/email`,
		{
			params: {
				type: type
			}
		}
	);
	return response.data;
};

export const getEmailHistory = async (
	docType: string
): Promise<EmailHistoryRow[]> => {
	const response = await CORE_API.get<EmailHistoryRow[]>(
		`/unum/documents/share/history/email/${docType}`
	);
	return response.data;
};

export const deleteTextMessageTemplate = async (templateId: number) => {
	const response = await CORE_API.delete(
		`/api/textMessageTemplates/${templateId}`
	);
	return response;
};

export const getTextMessagesTypes = async (type: 'email' | 'sms') => {
	const response = await CORE_API.get(
		`/api/textMessageTemplates/getTypes/${type}`
	);
	return response.data;
};

export const updateMessageTemplate = async (
	templateId: number,
	payload: {
		body?: string;
		default?: number;
		name?: string;
		shop_id?: number;
		subject?: string;
		type_id?: number;
	}
) => {
	const response = await CORE_API.put(
		`/api/textMessageTemplates/${templateId}`,
		payload
	);
	return response;
};

export const createMessageTemplate = async (payload: {
	body?: string;
	default?: number;
	name?: string;
	shop_id?: number;
	subject?: string;
	type_id?: number;
}) => {
	const response = await CORE_API.post(`/api/textMessageTemplates/`, payload);
	return response;
};

export const sendEmailReport = async (payload: {
	attachmentUrls: any;
	data: {
		body?: string;
		subject?: string;
		to?: string[];
		ccReplyTo?: number | null;
		replyTo?: string;
	};
	fileType: string;
	shops: number[];
}) => {
	const response = await CORE_API.post(`/unum/documents/share/email`, payload);
	return response;
};
