// Notification messages helpers
import { useToast } from 'primevue/usetoast';

export const useMessages = () => {
	const toast = useToast();

	const showSuccess = (msg: string) => {
		toast.add({
			detail: msg,
			life: 3000,
			severity: 'success',
			summary: 'Success'
		});
	};

	const showInfo = (msg: string) => {
		toast.add({
			detail: msg,
			life: 3000,
			severity: 'info',
			summary: 'Info'
		});
	};

	const showError = (error: any) => {
		toast.add({
			detail: error,
			life: 3000,
			severity: 'error',
			summary: 'Error'
		});
	};

	const showWarning = (message: any) => {
		toast.add({
			detail: message,
			life: 3000,
			severity: 'warn',
			summary: 'Warning'
		});
	};

	const showFieldValidationError = (field: string, msg: string) => {
		toast.add({
			detail: field + ' - ' + msg,
			life: 3000,
			severity: 'error',
			summary: 'Validation Error'
		});
	};

	return {
		showSuccess,
		showInfo,
		showError,
		showFieldValidationError,
		showWarning
	};
};
