import { FilterMatchMode } from '@primevue/core';

import {
	JOB_DATE_COLUMNS,
	JOB_MULTISELECT_COLUMNS,
	JOB_PRICE_COLUMNS
} from '../config/jobs-table-columns';
import { JobListViewColumn } from '../model/types/job-list-view.interface';

export const composeFilterByKeyFromApi = (view: JobListViewColumn) => {
	if (JOB_DATE_COLUMNS.includes(view.key)) {
		return {
			type:
				view.filter_option === 'dateRange'
					? FilterMatchMode.BETWEEN
					: FilterMatchMode.EQUALS,
			val:
				view.filter || view.filter_2
					? {
							date_1: view.filter && view.filter !== '' ? view.filter : null,
							date_2:
								view.filter_2 && view.filter_2 !== '' ? view.filter_2 : null
						}
					: null
		};
	} else if (view.key === 'tags' || view.key === 'taxes') {
		return {
			type:
				view.filter_option === 'filteredAndSelected'
					? FilterMatchMode.IN
					: view.filter_option === 'filtered'
						? FilterMatchMode.BETWEEN
						: FilterMatchMode.EQUALS,
			val:
				view.filter || view.filter_2
					? {
							val_1:
								view.filter && view.filter !== ''
									? view.filter.split('|**|')
									: null,
							val_2:
								view.filter_2 && view.filter_2 !== ''
									? view.filter_2.split('|**|')
									: null
						}
					: null
		};
	} else if (JOB_PRICE_COLUMNS.includes(view.key)) {
		return {
			type:
				view.filter_option === 'valueRange'
					? FilterMatchMode.BETWEEN
					: FilterMatchMode.EQUALS,
			val:
				view.filter || view.filter_2
					? {
							val_1: view.filter && view.filter !== '' ? view.filter : null,
							val_2:
								view.filter_2 && view.filter_2 !== '' ? view.filter_2 : null
						}
					: null
		};
	} else if (JOB_MULTISELECT_COLUMNS.includes(view.key)) {
		return {
			type: FilterMatchMode.EQUALS,
			val: view.filter && view.filter !== '' ? view.filter.split('|**|') : null
		};
	} else {
		return {
			type: FilterMatchMode.EQUALS,
			val: view.filter && view.filter !== '' ? view.filter : null
		};
	}
};

export const composeFilterByKeyForApi = (
	key: string,
	filter: Record<string, any>
) => {
	if (JOB_DATE_COLUMNS.includes(key)) {
		return {
			filter_option: filter.type === FilterMatchMode.BETWEEN ? 'dateRange' : '',
			filter: filter.val?.date_1 ?? '',
			filter_2: filter.val?.date_2 ?? ''
		};
	} else if (key === 'tags' || key === 'taxes') {
		return {
			filter_option:
				filter.type === FilterMatchMode.IN
					? 'filteredAndSelected'
					: filter.type === FilterMatchMode.BETWEEN
						? 'filtered'
						: '',
			filter: filter.val?.val_1?.join('|**|') ?? '',
			filter_2: filter.val?.val_2?.join('|**|') ?? ''
		};
	} else if (JOB_PRICE_COLUMNS.includes(key)) {
		return {
			filter_option:
				filter.type === FilterMatchMode.BETWEEN ? 'valueRange' : '',
			filter: filter.val?.val_1 ?? '',
			filter_2: filter.val?.val_2 ?? ''
		};
	} else if (JOB_MULTISELECT_COLUMNS.includes(key)) {
		return {
			filter_option: FilterMatchMode.EQUALS,
			filter: filter.val?.join('|**|') ?? '',
			filter_2: ''
		};
	} else {
		return {
			filter_option: FilterMatchMode.EQUALS,
			filter: filter.val ?? '',
			filter_2: ''
		};
	}
};
