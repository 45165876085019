<template>
	<div class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1">
		<p
			class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
		>
			{{ capitalizeStr(t('customer')) }}
			<Badge
				v-if="reportsStore.isRequired('customer')"
				v-tooltip.top="t('required')"
				class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
				severity="danger"
			/>
		</p>

		<SearchDropdown
			v-model="reportsStore.customerFilterValue"
			:dropdownOptions="reportsStore.customerFilterOptions"
			dropdownContainerClass="customWidth"
			:loading="reportsStore.getCustomerFilterOptions.isLoading"
			optionLabel="full_name"
			:placeholder="`${t('searchBy')} ${t('customers').toLocaleLowerCase()}...`"
			@load-more="loadMoreCustomer"
			@search="searchForCustomer"
		>
			<template #selected="{ item }">
				<div
					v-if="item"
					class="tw3-flex tw3-gap-2 tw3-items-center tw3-justify-between tw3-w-full"
				>
					<span>{{ item.full_name }}</span>
					<ReportsCustomerTag :item="item" />
				</div>
			</template>
			<template #dropdownItem="{ item }">
				<div v-if="item" class="tw3-flex tw3-gap-2 tw3-items-center tw3-w-max">
					<span
						style="text-overflow: ellipsis; max-width: 47%; overflow: hidden"
						v-html="item.full_name"
					></span>
					<span
						v-if="item.phone_number"
						class="tw3-block tw3-w-1.5 tw3-h-1.5 tw3-bg-slate-800 tw3-rounded-full"
					></span>
					<span v-if="item.phone_number">{{ item.phone_number }}</span>
					<div class="tw3-ml-auto tw3-mr-0">
						<ReportsCustomerTag :item="item as CustomerSearchItem" />
					</div>
				</div>
			</template>
		</SearchDropdown>
	</div>
</template>

<script setup lang="ts">
import Badge from 'primevue/badge';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { capitalizeStr } from '@/shared/helpers';
import SearchDropdown from '@/shared/ui/SearchDropdown.vue';
import useReportsStore from '@/widgets/reportsElements/model/store';

import { CustomerSearchItem } from '../../api/types';

import ReportsCustomerTag from './ReportsCustomerTag.vue';

const { t } = useI18n();

const reportsStore = useReportsStore();

const dropdownOptions = ref();
const currentOffset = ref<number>(0);
const previousSearchString = ref();

const loadMoreCustomer = async () => {
	if (currentOffset.value > reportsStore.getCustomerFilterOptions.state.count)
		return;
	currentOffset.value += 20;
	dropdownOptions.value = await reportsStore.getCustomerFilterOptions.execute(
		0,
		{
			s: previousSearchString.value,
			offset: currentOffset.value
		}
	);
};

const searchForCustomer = async (searchString: string) => {
	if (searchString === previousSearchString.value) return;

	previousSearchString.value = searchString;
	currentOffset.value = 0;

	dropdownOptions.value = await reportsStore.getCustomerFilterOptions.execute(
		0,
		{
			s: searchString,
			offset: currentOffset.value
		}
	);
};
</script>

<style scoped>
:deep(.customWidth) {
	width: 120%;
}
</style>
