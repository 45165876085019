import print from 'print-js';
import { nextTick } from 'vue';

export const base64ToArrayBuffer = (data: string): Uint8Array => {
	const binaryString = window.atob(data);
	const binaryLen = binaryString.length;
	const bytes = new Uint8Array(binaryLen);
	for (let i = 0; i < binaryLen; i++) {
		const ascii = binaryString.charCodeAt(i);
		bytes[i] = ascii;
	}
	return bytes;
};

export const getFileUrlFromArrayBuffer = (
	fileData: Uint8Array | ArrayBuffer
): string => {
	const blob = new Blob([fileData], { type: 'application/pdf;' });
	const URL = window.URL || window.webkitURL;
	const downloadUrl = URL.createObjectURL(blob);
	return downloadUrl;
};

export const getFileObjectFromBase64 = (base64: string, fileName: string) => {
	const arrayBuffer = base64ToArrayBuffer(base64);
	const fileObj = {
		name: fileName,
		url: getFileUrlFromArrayBuffer(arrayBuffer)
	};
	return fileObj;
};

export const downloadFile = (url: string, fileName: string) => {
	const link = document.createElement('a');
	link.href = url;
	link.download = fileName;
	link.target = '_blank';
	document.body.appendChild(link);
	link.click();

	nextTick(() => {
		document.body.removeChild(link);
	});
};

export const printFile = (url: string) => {
	print({ printable: url });
};
