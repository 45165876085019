<script setup lang="ts">
import { Client, PickerResponse } from 'filestack-js';
import { upperCase } from 'lodash-es';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import Tag from 'primevue/tag';
import { useConfirm } from 'primevue/useconfirm';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useAppStore from '@/entities/app/lib/store';

import { DocumentType } from '../../../model/enums';
import { JobDocument } from '../../../model/types';
import { getDocumentColorByType } from '../../../utils';

const types = Object.values(DocumentType).map(v => ({
	id: v,
	text: upperCase(v)
}));

const { t } = useI18n();
const appStore = useAppStore();

const props = defineProps<{
	data: JobDocument[];
	show: boolean;
	loading: boolean;
}>();
const emit = defineEmits<{
	(e: 'on-close'): void;
	(e: 'on-create', v: Partial<JobDocument>): void;
	(e: 'on-delete', v: JobDocument): void;
}>();

const selectedType = ref<DocumentType>();

const confirm = useConfirm();

const deletePressed = (data: JobDocument) => {
	confirm.require({
		accept: () => {
			emit('on-delete', data);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('document.delete'),
		message: t('document.deleteConfirm'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const openFilePicker = (v: DocumentType) => {
	selectedType.value = v;
	const client = new Client(appStore.appConfig.state.filePickerKey);
	const options = {
		maxFiles: 1,
		onUploadDone: handleUploadDone
	};

	client.picker(options).open();
};

const handleUploadDone = (result: PickerResponse) => {
	const uploadedFile = result.filesUploaded[0];

	emit('on-create', {
		id: -1,
		created: -1,
		modified: -1,
		document_type: selectedType.value,
		url: uploadedFile.url,
		document_name: uploadedFile.filename
	});
	selectedType.value = undefined;
};

const documentsByType = (v: DocumentType) => {
	return props.data.filter(d => d.document_type === v);
};
</script>

<template>
	<Dialog
		id="vue3JobEditDocumentsDialog"
		:header="$t('jobDocuments')"
		modal
		:visible="show"
		@update:visible="emit('on-close')"
	>
		<div class="tw3-flex tw3-flex-col tw3-w-[500px] tw3-h-[500px]">
			<div
				class="tw3-relative tw3-flex tw3-flex-col tw3-gap-2 tw3-flex-1 tw3-overflow-y-auto documents-container"
			>
				<Panel
					v-for="item in types"
					:key="item.id"
					:header="item.text"
					pt:content:class="!tw3-pb-0"
				>
					<template #header>
						<Tag
							severity="secondary"
							:style="{
								'border-left': '10px solid black',
								'border-color': getDocumentColorByType(item.id)
							}"
						>
							<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
								<span
									style="font-size: 1rem; font-weight: 600; white-space: nowrap"
								>
									{{ item.text }}
								</span>
							</div>
						</Tag>
					</template>
					<template #icons>
						<Button
							icon="pi pi-plus"
							:loading="loading"
							rounded
							severity="secondary"
							text
							@click="openFilePicker(item.id)"
						/>
					</template>
					<div
						v-if="documentsByType(item.id).length"
						class="tw3-flex tw3-flex-col tw3-gap-2 tw3-pb-[18px]"
					>
						<a
							v-for="document in documentsByType(item.id)"
							:key="document.id"
							class="tw3-w-full tw3-flex tw3-justify-between tw3-items-center tw3-gap-2 tw3-p-2 tw3-rounded tw3-border !tw3-no-underline tw3-transition-all hover:tw3-opacity-70"
							:href="document.url"
							target="_blank"
						>
							<span class="text-gray-800 tw3-truncate">{{
								document.document_name
							}}</span>
							<Button
								:disabled="loading"
								icon="pi pi-trash"
								severity="danger"
								size="small"
								text
								@click.prevent="deletePressed(document)"
							/>
						</a>
					</div>
				</Panel>
			</div>
		</div>
	</Dialog>
</template>

<style lang="scss" scoped>
.documents-container {
	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}

	scrollbar-width: none;
}
</style>
