<template>
	<Dialog
		id="vue3JobTagsAdding"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-h-[450px] tw3-w-[400px]"
		:draggable="false"
		:header="dialogTitle ? dialogTitle : t('rejectedInvoices.addTagsTitle')"
		modal
		:pt:content="'tw3-h-full tw3-flex tw3-flex-col'"
		:pt:root:class="'!tw3-border-0 tw3-overflow-hidden'"
	>
		<div class="tw3-flex tw3-flex-col tw3-h-full tw3-gap-2">
			<Listbox
				v-model="selectedtags"
				checkmark
				class="w-full md:w-56 tw3-h-full"
				:disabled="isLoading"
				filter
				listStyle="max-height:280px"
				multiple
				optionLabel="text"
				:options="tagsList"
				optionValue="id"
				pt:root:class="!tw3-border-0"
			>
				<template #option="slotProps">
					<div class="flex items-center">
						<div
							class="tw3-flex tw3-items-center tw3-capitalize tw3-px-2 tw3-py-0.5 tw3-gap-2 tw3-text-sm"
						>
							<Badge
								class="!tw3-w-2.5 !tw3-h-2.5 !tw3-min-w-2.5"
								:pt:root:style="`background-color: ${slotProps.option.color}`"
								size="small"
							></Badge>
							{{ slotProps.option.text.toLowerCase() }}
						</div>
					</div>
				</template>
			</Listbox>
			<Button
				class="w-full md:w-14rem"
				:label="updateLabel ? updateLabel : t('rejectedInvoices.applyTags')"
				:loading="isLoading"
				pt:root:class="tw3-rounded-t-none"
				size="small"
				@click="handleUpdate"
			/>
		</div>
	</Dialog>
</template>

<script lang="ts" setup>
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { JobTag } from '@/entities/job/model/types';

const showModal = defineModel<boolean>('visible');

const { t } = useI18n();

const props = withDefaults(
	defineProps<{
		jobID: number;
		tagsList: JobTag[];
		currentTagsList: JobTag[];
		isLoading: boolean;
		dialogTitle?: string;
		updateLabel?: string;
	}>(),
	{
		jobID: 0,
		tagsList: () => [],
		currentTagsList: () => [],
		isLoading: false,
		dialogTitle: undefined,
		updateLabel: undefined
	}
);

const emit = defineEmits<{
	(e: 'updateTags', tags: JobTag[]): void;
}>();

const selectedtags = ref<number[]>([]);

const handleUpdate = () => {
	if (!selectedtags.value || !selectedtags.value.length) return;

	const result: JobTag[] = [];

	selectedtags.value.forEach((item: number) => {
		const wasPreviously = props.currentTagsList.find(
			element => element.id === item
		);

		if (wasPreviously) {
			result.push({ ...wasPreviously, shop_tag_id: item });
		} else {
			result.push({
				created: -1,
				modified: -1,
				id: -1,
				job_id: props.jobID,
				shop_tag_id: item
			});
		}
	});
	emit('updateTags', result);
};

watch(
	() => showModal.value,
	() => {
		selectedtags.value = props.currentTagsList.map(tag => tag.id);
	}
);
</script>
