<template>
	<div v-if="data">
		<div id="royalty-report-franchisee-data">
			<div>{{ t('reportsPage.table.franchiseId') }}: 237</div>
			<div>{{ t('reportsPage.table.account') }}: 950217</div>
		</div>
		<table id="royalty-report-summary-table">
			<tbody>
				<tr v-for="col in currentColumns" :key="col.id">
					<td
						v-if="col.prop"
						class="cell"
						:style="{ 'font-weight': col.bold ? 'bold' : 'normal' }"
						v-html="col.label"
					></td>
					<td v-else class="cell">&nbsp;</td>

					<td v-if="col.dollar" class="cell">
						{{
							reportsGetters.formatNumber(data[col.prop], {
								style: 'dollar'
							})
						}}
					</td>
					<td v-else-if="col.percent" class="cell">
						{{ `${data[col.prop]}%` }}
					</td>
					<td v-else-if="col.prop" class="cell">{{ data[col.prop] }}</td>
					<td v-else class="cell">&nbsp;</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

defineProps<{
	data: any;
}>();

const { t } = useI18n();

const reportStore = useReportsStore();
const { currentColumns } = storeToRefs(reportStore);
</script>

<style scoped>
#royalty-report-franchisee-data {
	font-size: 14px;
	text-align: center;
	width: 33.3%;
	margin-top: -4em;
	margin-bottom: 3em;
}

#royalty-report-summary-table {
	display: table;
	margin: 0 auto;
	width: auto !important;
}

#royalty-report-summary-table tr td {
	border: none;
	background: #fff;
	font-size: 16px;
}
</style>
