<script setup lang="ts">
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable, { DataTableRowReorderEvent } from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { defineModel, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { TableHeader } from './model/types';

const { t } = useI18n();

const props = defineProps<{
	modelValue: TableHeader[];
	options: TableHeader[];
}>();
const emit = defineEmits<{
	(e: 'save', c: TableHeader[]): void;
}>();
const toast = useToast();
const showModal = defineModel<boolean>('show');
const columns = ref<TableHeader[]>([]);
const selectedColumns = ref<TableHeader[]>([]);

const onRowReorder = (event: DataTableRowReorderEvent) => {
	columns.value = event.value;
};

const save = () => {
	const cols: TableHeader[] = [];
	for (const item of columns.value) {
		const index = selectedColumns.value.findIndex(v => v.key === item.key);
		if (index !== -1) cols.push(item);
	}
	emit('save', cols);
	showModal.value = false;
};

const update = (v: any[]) => {
	if (v.length && v.length > 1) {
		selectedColumns.value = v;
	} else {
		toast.add({
			detail: t('columnRequiredMsg'),
			life: 3000,
			severity: 'warn',
			summary: 'Warn Message'
		});
	}
};

watch(
	() => showModal.value,
	() => {
		if (showModal.value) {
			columns.value = [
				...props.modelValue,
				...props.options.filter(
					v => !props.modelValue.some(m => m.key === v.key)
				)
			];
			selectedColumns.value = props.modelValue;
		}
	}
);
</script>

<template>
	<Dialog
		id="vue3UpdateColumns"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-w-full tw3-max-w-lg"
		:contentStyle="{ padding: '0 0 1rem 0' }"
		:header="$t('columnSettings')"
		modal
	>
		<DataTable
			scrollable
			scrollHeight="400px"
			:selection="selectedColumns"
			:value="columns"
			@row-reorder="onRowReorder"
			@update:selection="update"
		>
			<Column headerStyle="width: 3rem" rowReorder />
			<Column headerStyle="width: 3rem" selectionMode="multiple">
				<template #body="{ data }">
					<Checkbox
						v-model="selectedColumns"
						:disabled="data.key === 'edit'"
						:value="data"
					/>
				</template>
			</Column>
			<Column field="title" :header="$t('column')"></Column>
		</DataTable>
		<template #footer>
			<Button
				:label="$t('cancel')"
				severity="secondary"
				size="small"
				type="button"
				@click="showModal = false"
			/>
			<Button
				:label="$t('save')"
				severity="info"
				size="small"
				type="button"
				@click="save"
			/>
		</template>
	</Dialog>
</template>
