import { FilterMatchMode } from '@primevue/core';

const getDateOption = (val: Record<string, any>, key: string) => {
	return {
		[`${key}_option`]:
			val[key]?.type === FilterMatchMode.BETWEEN ? 'dateRange' : '',
		[`range_${key}_1`]: val[key]?.val?.date_1 ?? '',
		[`range_${key}_2`]: val[key]?.val?.date_2 ?? ''
	};
};

const getTagsTaxesOption = (val: Record<string, any>, key: string) => {
	return {
		[`${key}_option`]:
			val[key]?.type === FilterMatchMode.IN
				? 'filteredAndSelected'
				: val[key]?.type === FilterMatchMode.BETWEEN
					? 'filtered'
					: '',
		[`${key}_1`]: val[key]?.val?.val_1?.join('|**|') ?? '',
		[`${key}_2`]: val[key]?.val?.val_2?.join('|**|') ?? ''
	};
};

const getPriceRangeOption = (val: Record<string, any>, key: string) => {
	return {
		[`${key}_option`]:
			val[key]?.type === FilterMatchMode.BETWEEN ? 'valueRange' : '',
		[`${key}_1`]: val[key]?.val?.val_1 ?? '',
		[`${key}_2`]: val[key]?.val?.val_2 ?? ''
	};
};

export const composeFilterForApiRequest = (val: Record<string, any>) => {
	return {
		shop_name: val['shop.name']?.val?.join('|**|') ?? '',
		job_id: val['job_id']?.val ?? '',
		status: val['status']?.val?.join('|**|') ?? '',
		referral_number: val['referral_number']?.val ?? '',
		customer_last_name: val['consumer.name.last']?.val ?? '',
		customer_first_name: val['consumer.name.first']?.val ?? '',
		parts: val['parts']?.val ?? '',
		customer_email: val['consumer.email.email']?.val ?? '',
		edi_status: val['ediqueue.status']?.val?.join('|**|') ?? '',
		customer_type: val['consumer.is_commercial']?.val?.join('|**|') ?? '',
		shop_id: val['shop_id']?.val?.join('|**|') ?? '',

		...getDateOption(val, 'quote_date'),
		...getDateOption(val, 'work_order_date'),
		...getDateOption(val, 'dispatch_date'),
		...getDateOption(val, 'invoice_date'),
		...getDateOption(val, 'follow_up_date'),

		...getTagsTaxesOption(val, 'tags'),
		...getTagsTaxesOption(val, 'taxes'),

		documents: val['documents']?.val ?? '',
		notes: val['notes']?.val ?? '',

		...getPriceRangeOption(val, 'total_materials'),
		...getPriceRangeOption(val, 'total_labor'),
		...getPriceRangeOption(val, 'total_subtotal'),
		...getPriceRangeOption(val, 'total_taxes'),
		...getPriceRangeOption(val, 'total_after_taxes'),
		...getPriceRangeOption(val, 'deductible'),
		...getPriceRangeOption(val, 'total_balance_after_payments'),

		csr_name: val['job_csr']?.val?.join('|**|') ?? '',

		salesource_name: val['job_salesource']?.val?.join('|**|') ?? '',
		...getPriceRangeOption(val, 'total_sale_source_commission'),

		salesrep_name: val['job_salesrep']?.val?.join('|**|') ?? '',
		...getPriceRangeOption(val, 'total_sales_rep_commission'),

		tech_name: val['job_tech']?.val?.join('|**|') ?? '',
		...getPriceRangeOption(val, 'total_tech_commission'),

		location_name: val['job_location']?.val?.join('|**|') ?? '',
		...getPriceRangeOption(val, 'total_location_commission'),

		commercialaccount_name:
			val['commercialaccount_name']?.val?.join('|**|') ?? '',
		internal_number: val['internal_number']?.val ?? '',

		...getPriceRangeOption(val, 'total_cost'),
		...getPriceRangeOption(val, 'customer_rebate'),
		...getPriceRangeOption(val, 'other_costs'),
		...getPriceRangeOption(val, 'total_margin'),

		customer_phone1: val['consumer.phone.number']?.val ?? '',
		customer_address: val['consumer.address.address']?.val ?? '',
		customer_address_zip: val['consumer.address.zip']?.val ?? '',
		customer_address_city: val['consumer.address.city']?.val ?? '',
		customer_address_state: val['consumer.address.state']?.val ?? '',
		customer_address_unit: val['consumer.address.unit']?.val ?? '',
		vehicle_year: val['vehicle.year']?.val ?? '',
		vehicle_make: val['vehicle.make']?.val ?? '',
		vehicle_model: val['vehicle.model']?.val ?? '',
		vehicle_sub_model: val['vehicle.sub_model']?.val ?? '',
		vehicle_style: val['vehicle.style']?.val ?? '',
		vehicle_vin: val['vehicle.vin']?.val ?? '',

		insurance_fleet_name: val['insurance_fleet_name']?.val ?? '',
		edi_trading_partner:
			val['bill_to.consumer_edi.trading_partner']?.val?.join('|**|') ?? '',

		...getDateOption(val, 'loss_date'),
		...getDateOption(val, 'scheduled_date'),
		...getDateOption(val, 'install_date'),

		scheduled_time_start: val['scheduled_time_start']?.val ?? '',
		scheduled_time_end: val['scheduled_time_end']?.val ?? '',
		install_address_line1: val['install_g_address.address']?.val ?? '',
		install_address_city: val['install_g_address.city']?.val ?? '',
		install_address_state: val['install_g_address.state']?.val ?? '',
		install_address_zip: val['install_g_address.zip']?.val ?? '',
		install_address_unit: val['install_g_address.unit']?.val ?? '',
		contact_name: val['contact_name']?.val ?? '',
		contact_phone1: val['contact_phone1']?.val ?? '',
		contact_phone2: val['contact_phone2']?.val ?? '',
		purchase_order_number: val['purchase_order_number']?.val ?? '',
		requisition_order_number: val['requisition_order_number']?.val ?? '',
		dot_number: val['dot_number']?.val ?? '',
		lot_number: val['lot_number']?.val ?? '',
		install_context: val['install_context']?.val?.join('|**|') ?? '',
		policy_number: val['policy_number']?.val ?? '',
		unit_number: val['vehicle.unit_number']?.val ?? '',
		install_notes: val['install_notes']?.val ?? '',
		consumer_id: val['consumer_id']?.val ?? '',
		pending_schedule: val['pending_schedule']?.val ?? '',
		pending_inspection: val['pending_inspection']?.val ?? '',
		full_job_number: val['full_job_number']?.val ?? '',
		technician_notes: val['technician_notes']?.val ?? '',
		vehicle_plate_number: val['vehicle.plate_number']?.val ?? '',
		vehicle_mileage: val['vehicle_mileage']?.val ?? '',
		salesidejob_id: val['salesidejob_id']?.val ?? '',

		...getDateOption(val, 'last_updated'),

		...getDateOption(val, 'warranty_date'),
		...getDateOption(val, 'warranty_complete_date'),

		...getDateOption(val, 'recal_date'),
		...getDateOption(val, 'recal_complete_date'),

		...getDateOption(val, 'call_back_date'),
		...getDateOption(val, 'call_back_complete_date'),

		...getDateOption(val, 'r_and_i_date'),
		...getDateOption(val, 'r_and_i_complete_date'),

		...getDateOption(val, 'tint_date'),
		...getDateOption(val, 'tint_complete_date'),

		...getDateOption(val, 'pick_up_date'),
		...getDateOption(val, 'pick_up_complete_date'),

		...getDateOption(val, 'drop_off_date'),
		...getDateOption(val, 'drop_off_complete_date')
	};
};
