import { startCase } from 'lodash-es';

import { InstallContext } from '../model/enums';

export const installContexts = [
	{ name: startCase(InstallContext.inShop), id: InstallContext.inShop },
	{ name: startCase(InstallContext.mobile), id: InstallContext.mobile },
	{ name: startCase(InstallContext.delivery), id: InstallContext.delivery },
	{ name: startCase(InstallContext.willCall), id: InstallContext.willCall }
];
