import { i18n } from '@/shared/i18n';

import { CustomerType, JobType, LicenseType, PaidStatus } from '../api/types';
import { ReportMenu } from '../api/types';

const { t } = i18n.global;

export const reportsMenuConfig: ReportMenu[] = [
	{
		label: 'Sales Reports',
		id: 'sales',
		children: [
			{
				label: 'Sales report',
				id: 'sales',
				group: 'sales',
				// filter: 'contractors',
				groupBy: 'contractorsGroup',
				total_key: 'total_after_taxes',
				permissionId: 'reportsales'
			},
			{
				label: 'Margin report',
				id: 'margin',
				group: 'sales',
				total_key: 'total_margin',
				// filter: 'contractors',
				groupBy: 'contractorsGroup',
				permissionId: 'reportmargin'
			},
			{
				label: 'Customer Summary',
				id: 'customer-summary',
				group: 'sales',
				permissionId: 'reportcustomersummary'
			},
			{
				label: 'Royalty Summary',
				id: 'royalty-summary',
				group: 'royalty',
				date: 'Payment Date',
				permissionId: 'reportroyaltysummary'
			}
		]
	},
	{
		label: 'Sales tax',
		id: 'tax',
		children: [
			{
				label: 'Accrual Detail',
				id: 'tax-detail',
				group: 'tax',
				filter: 'taxes',
				permissionId: 'reporttaxdetail'
			},
			{
				label: 'Accrual Summary',
				id: 'tax-summary',
				group: 'tax',
				filter: 'taxes',
				permissionId: 'reporttaxsummary'
			},
			{
				label: 'Cash Basis detail',
				id: 'detail',
				group: 'cash-basis',
				filter: 'taxes',
				total_key: 'total_tax_collected',
				date: 'Payment Date',
				permissionId: 'reportdetail'
			},
			{
				label: 'Cash Basis summary',
				id: 'summary',
				group: 'cash-basis',
				filter: 'taxes',
				date: 'Payment Date'
			}
		]
	},
	{
		label: 'Schedule Reports',
		id: 'scheduler',
		children: [
			{
				label: 'Schedule report',
				id: 'schedule',
				group: 'scheduler',
				filter: 'techs',
				default_date_type: 'scheduled_date',
				permissionId: 'reportschedule'
			},
			// {
			//   label: 'In/Out Report',
			//   id: 'in-out',
			//   group: 'in-out',
			//   groupBy: 'listBy',
			//   filter: 'tags'
			// },
			{
				label: 'Manifest',
				id: 'manifest',
				group: 'scheduler',
				disabled: true,
				permissionId: 'reportmanifest'
			}
		]
	},
	{
		label: 'Install Reports',
		id: 'install',
		children: [
			{
				label: 'Technicians',
				id: 'technicians',
				group: 'install',
				filter: 'techs',
				total_key: 'total_tech_commission',
				groupBy: 'tech',
				permissionId: 'reportinstallers'
			},
			{
				label: 'Sales reps',
				id: 'salesreps',
				group: 'install',
				filter: 'salesreps',
				total_key: 'total_sales_rep_commission',
				groupBy: 'salesrep',
				permissionId: 'reportsalesreps'
			},
			{
				label: 'Sales sources',
				id: 'salesources',
				group: 'install',
				filter: 'salesources',
				total_key: 'total_sale_source_commission',
				groupBy: 'salesource',
				permissionId: 'reportsalesources'
			},
			{
				label: 'Locations',
				id: 'locations',
				group: 'install',
				filter: 'locations',
				total_key: 'total_locations_commission',
				groupBy: 'location',
				permissionId: 'reportlocations'
			},
			{
				label: 'Tech collections',
				id: 'tech-collections',
				group: 'install',
				filter: 'techs',
				total_key: 'total_tech_commission',
				groupBy: 'tech',
				permissionId: 'reporttechcollections'
			},
			{
				label: 'Customer Service Reps',
				id: 'csrs',
				group: 'install',
				filter: 'csrs',
				total_key: 'total_csr_commission',
				groupBy: 'csr',
				permissionId: 'reportcsrs'
			}
		]
	},
	{
		label: 'Payments',
		id: 'payment',
		children: [
			{
				label: 'Payment receipt',
				id: 'receipt',
				group: 'payment',
				date: 'Payment Date',
				total_key: 'amount',
				permissionId: 'reportreceipt'
			}
		]
	},
	{
		label: 'Statements',
		id: 'statements',
		children: [
			{
				label: 'Account statement',
				id: 'account-statement',
				date: 'Statement Date',
				group: 'statements',
				permissionId: 'reportaccountstatement'
			},
			{
				label: 'Open invoice report',
				id: 'open-invoice',
				date: 'Statement Date',
				group: 'statements',
				permissionId: 'reportopeninvoice'
			},
			{
				label: 'Aging Report',
				id: 'aging',
				group: 'aging',
				permissionId: 'reportaging'
			}
		]
	},
	{
		label: 'Accounting Reports',
		id: 'accountingReports',
		children: [
			{
				label: 'Profit and Loss',
				id: 'profit-and-loss',
				date: 'Date Range',
				group: 'accountingReports',
				hideShopSelect: false,
				permissionId: 'reportprofitandloss'
			},
			{
				label: 'Balance Sheet',
				id: 'balance-sheet',
				date: 'Date',
				group: 'accountingReports',
				hideShopSelect: false,
				permissionId: 'reportbalancesheet'
			}
		]
	},
	{
		label: 'Miscellaneous Reports',
		id: 'miscellaneous',
		children: [
			{
				label: 'Sales Rep/Source',
				id: 'sales-rep-source',
				group: 'miscellaneous',
				disabled: true,
				permissionId: 'reportsalesrepsource'
			},
			{
				label: 'Right Part',
				id: 'requests',
				group: 'right-part',
				date: 'Request date',
				permissionId: 'reportrequests'
			},
			{
				label: 'Nags License',
				id: 'nags-license',
				group: 'nags-license',
				date: 'Renewal Date',
				permissionId: 'admin',
				hideShopSelect: true
			},
			{
				label: 'EDI Report',
				id: 'edi',
				group: 'edi',
				date: 'Submit Date',
				permissionId: 'admin',
				hideShopSelect: true
			},
			{
				label: 'Install List',
				id: 'install-list',
				group: 'install-list',
				date: 'Install Date',
				default_date_type: 'install_date',
				permissionId: 'reportinstalllist'
			}
		]
	}
];

export const jobTypes: JobType[] = [
	{ id: 'quote', name: t('reportsPage.filters.jobTypes.quote') },
	{ id: 'workOrder', name: t('reportsPage.filters.jobTypes.workOrder') },
	{ id: 'invoice', name: t('reportsPage.filters.jobTypes.invoice') },
	{ id: 'warranty', name: t('reportsPage.filters.jobTypes.warranty') },
	{
		id: 'warrantyComplete',
		name: t('reportsPage.filters.jobTypes.warrantyComplete')
	},
	{ id: 'recal', name: t('reportsPage.filters.jobTypes.recal') },
	{
		id: 'recalComplete',
		name: t('reportsPage.filters.jobTypes.recalComplete')
	},
	{ id: 'callBack', name: t('reportsPage.filters.jobTypes.callBack') },
	{
		id: 'callBackComplete',
		name: t('reportsPage.filters.jobTypes.callBackComplete')
	},
	{ id: 'rAndI', name: t('reportsPage.filters.jobTypes.rAndI') },
	{
		id: 'rAndIComplete',
		name: t('reportsPage.filters.jobTypes.rAndIComplete')
	},
	{ id: 'tint', name: t('reportsPage.filters.jobTypes.tint') },
	{ id: 'tintComplete', name: t('reportsPage.filters.jobTypes.tintComplete') },
	{ id: 'pickUp', name: t('reportsPage.filters.jobTypes.pickUp') },
	{
		id: 'pickUpComplete',
		name: t('reportsPage.filters.jobTypes.pickUpComplete')
	},
	{ id: 'dropOff', name: t('reportsPage.filters.jobTypes.dropOff') },
	{
		id: 'dropOffComplete',
		name: t('reportsPage.filters.jobTypes.dropOffComplete')
	},
	{ id: 'void', name: t('reportsPage.filters.jobTypes.void') }
];

export const customerTypes: CustomerType[] = [
	{ id: 'cash', name: t('reportsPage.filters.customerTypes.cash') },
	{ id: 'commercial', name: t('reportsPage.filters.customerTypes.commercial') },
	{ id: 'insurance', name: t('reportsPage.filters.customerTypes.insurance') }
];

export const licenseTypes: LicenseType[] = [
	{ id: 1, name: 'United States' },
	{ id: 2, name: 'Canada' }
];

export const paidStatus: PaidStatus[] = [
	{ id: 'UP', name: t('reportsPage.table.unpaid') },
	{ id: 'PP', name: t('reportsPage.table.pp') },
	{ id: 'FP', name: t('reportsPage.table.fp') }
];

/// GROUP BY:
export const tableModes = [
	{ id: 'none', label: t('reportsPage.filters.groupBy.none') },
	{ id: 'date', label: t('reportsPage.filters.groupBy.date') },
	{ id: 'shop_name', label: t('reportsPage.filters.groupBy.shop_name') }
];

export const paymentTableModes = [
	{ id: 'none', label: t('reportsPage.filters.groupBy.none') },
	{ id: 'date', label: t('reportsPage.filters.groupBy.date') },
	{ id: 'shop_name', label: t('reportsPage.filters.groupBy.shop_name') },
	{
		id: 'customer_name',
		label: t('reportsPage.filters.groupBy.customer_name')
	},
	{ id: 'x_action_type', label: t('reportsPage.filters.groupBy.x_action_type') }
	// { label: 'By Contractor', id: 'name' }
];

export const agingTableModes = [
	{ id: 'consumer', label: t('reportsPage.filters.groupBy.customer_name') },
	{ id: 'age', label: t('reportsPage.filters.groupBy.age') }
	// {
	// 	id: 'insurancefleet_name',
	// 	label: t('reportsPage.filters.groupBy.insurancefleet_name')
	// }
];

export const salesTableModes = [
	{ id: 'none', label: t('reportsPage.filters.groupBy.none') },
	{ id: 'date', label: t('reportsPage.filters.groupBy.date') },
	{ id: 'shop_name', label: t('reportsPage.filters.groupBy.shop_name') },
	{ id: 'name', label: t('reportsPage.filters.groupBy.vendor') }
];

export const customFilterDisplayConfig = {
	taxes: ['tax-detail', 'tax-summary', 'detail', 'summary'],
	techs: ['technicians', 'schedule', 'tech-collections'],
	salesreps: ['salesreps'],
	salesources: ['salesources'],
	locations: ['locations'],
	csrs: ['csrs']
};

export const customFilterLabels = {
	techs: t('reportsPage.filters.customFilterLabels.techs'),
	salesreps: t('reportsPage.filters.customFilterLabels.salesreps'),
	salesources: t('reportsPage.filters.customFilterLabels.salesources'),
	locations: t('reportsPage.filters.customFilterLabels.locations'),
	taxes: t('reportsPage.filters.customFilterLabels.taxes'),
	csrs: t('reportsPage.filters.customFilterLabels.csrs')
};

export const customFilterDataScopes = {
	techs: 'techs',
	technicians: 'techs',
	'tech-collections': 'techs',
	'tax-detail': 'taxes',
	'tax-summary': 'taxes',
	detail: 'taxes',
	summary: 'taxes',
	salesreps: 'salesreps',
	salesources: 'salesources',
	locations: 'locations',
	commercialaccounts: 'commercialaccounts',
	insurancefleets: 'insurancefleets',
	schedule: 'techs',
	csrs: 'csrs'
};

export const xActionTypeOptions = [
	'Card',
	'Manual Card',
	'Cash',
	'Check',
	'Bank Transfer',
	'Other',
	'Promo',
	'Coupon',
	'Credit',
	'Short Pay',
	'Write Off',
	'Fee',
	'Deductible Discount',
	'Doubtful Account'
];

export const acrualCashOptions = [
	{
		value: 'accrual',
		label: t('reportsPage.filters.accrualCashOptions.accrual')
	},
	{
		value: 'cashBasis',
		label: t('reportsPage.filters.accrualCashOptions.cashBasis')
	}
];

export const reportsURLs = {
	'profit-and-loss': `profit-and-loss`,
	'balance-sheet': `balance-sheet`,
	'nags-license': `nags-license`,
	'customer-summary': `customer-summary`,
	edi: `edi`,
	'install-list': `install-list`
};

// Defining columns
const invoiceNumCol = {
	label: `${t('invoice')} #`,
	prop: 'invoice_num',
	link: true
};
const laborCol = { label: t('labor'), prop: 'total_labor', dollar: true };
const materialsCol = {
	label: t('materials'),
	prop: 'total_materials',
	dollar: true
};
const subtotalCol = {
	label: t('subtotal'),
	prop: 'total_subtotal',
	dollar: true
};
const taxesCol = {
	label: t('reportsPage.colNames.total_taxes'),
	prop: 'total_taxes',
	dollar: true
};
const grossCol = {
	label: t('reportsPage.colNames.total_after_taxes'),
	prop: 'total_after_taxes',
	class: 'strong',
	dollar: true
};
const commissionsCol = {
	label: t('reportsPage.colNames.total_commission'),
	prop: 'total_commission',
	dollar: true
};
const rebateCol = {
	label: t('reportsPage.colNames.customer_rebate'),
	prop: 'customer_rebate',
	dollar: true
};
const otherCol = {
	label: t('reportsPage.colNames.other_costs'),
	prop: 'other_costs',
	dollar: true
};
const marginCol = {
	label: t('reportsPage.colNames.total_margin'),
	prop: 'total_margin',
	dollar: true,
	class: 'strong'
};
const costCol = {
	label: t('reportsPage.colNames.total_cost'),
	prop: 'total_cost',
	dollar: true,
	class: 'strong'
};
const customerNameCol = {
	label: t('reportsPage.colNames.customer_name'),
	prop: 'customer_name'
};
const referralNumCol = {
	label: t('reportsPage.colNames.referral_number'),
	prop: 'referral_number'
};
const techComCol = {
	label: t('reportsPage.colNames.total_tech_commission'),
	prop: 'total_tech_commission',
	dollar: true
};
const salesrepComCol = {
	label: t('reportsPage.colNames.total_sales_rep_commission'),
	prop: 'total_sales_rep_commission',
	dollar: true
};
const salesourseComCol = {
	label: t('reportsPage.colNames.total_sale_source_commission'),
	prop: 'total_sale_source_commission',
	dollar: true
};
const locationComCol = {
	label: t('reportsPage.colNames.total_location_commission'),
	prop: 'total_location_commission',
	dollar: true
};
const csrComCol = {
	label: t('reportsPage.colNames.total_csr_commission'),
	prop: 'total_csr_commission',
	dollar: true
};
const payerCol = {
	label: t('reportsPage.colNames.payer'),
	prop: 'payer',
	dollar: false,
	wrap: true
};
const quoteDateColumn = {
	label: t('reportsPage.colNames.quote_date'),
	prop: 'quote_date'
};
const workOrderDateColumn = {
	label: t('reportsPage.colNames.work_order_date'),
	prop: 'work_order_date'
};
const invoiceDateColumn = {
	label: t('reportsPage.colNames.invoice_date'),
	prop: 'invoice_date'
};
const installDateColumn = {
	label: t('reportsPage.colNames.install_date'),
	prop: 'install_date'
};
const warrantyDateColumn = {
	label: t('reportsPage.colNames.warranty_date'),
	prop: 'warranty_date'
};
const warrantyCompleteDateColumn = {
	label: t('reportsPage.colNames.warranty_complete_date'),
	prop: 'warranty_complete_date'
};
const recalDateColumn = {
	label: t('reportsPage.colNames.recal_date'),
	prop: 'recal_date'
};
const recalCompleteDateColumn = {
	label: t('reportsPage.colNames.recal_complete_date'),
	prop: 'recal_complete_date'
};
const callBackDateColumn = {
	label: t('reportsPage.colNames.call_back_date'),
	prop: 'call_back_date'
};
const callBackCompleteDateColumn = {
	label: t('reportsPage.colNames.call_back_complete_date'),
	prop: 'call_back_complete_date'
};
const rAndIDateColumn = {
	label: t('reportsPage.colNames.r_and_i_date'),
	prop: 'r_and_i_date'
};
const rAndICompleteDateColumn = {
	label: t('reportsPage.colNames.r_and_i_complete_date'),
	prop: 'r_and_i_complete_date'
};
const tintDateColumn = {
	label: t('reportsPage.colNames.tint_date'),
	prop: 'tint_date'
};
const tintCompleteDateColumn = {
	label: t('reportsPage.colNames.tint_complete_date'),
	prop: 'tint_complete_date'
};
const pickUpDateColumn = {
	label: t('reportsPage.colNames.pick_up_date'),
	prop: 'pick_up_date'
};
const pickUpCompleteDateColumn = {
	label: t('reportsPage.colNames.pick_up_complete_date'),
	prop: 'pick_up_complete_date'
};
const dropOffDateColumn = {
	label: t('reportsPage.colNames.drop_off_date'),
	prop: 'drop_off_date'
};
const dropOffCompleteDateColumn = {
	label: t('reportsPage.colNames.drop_off_complete_date'),
	prop: 'drop_off_complete_date'
};
const voidDateColumn = {
	label: t('reportsPage.colNames.void_date'),
	prop: 'void_date'
};

export const variableDateColumns = {
	quote_date: quoteDateColumn,
	work_order_date: workOrderDateColumn,
	invoice_date: invoiceDateColumn,
	install_date: installDateColumn,
	warranty_date: warrantyDateColumn,
	warranty_complete_date: warrantyCompleteDateColumn,
	recal_date: recalDateColumn,
	recal_complete_date: recalCompleteDateColumn,
	call_back_date: callBackDateColumn,
	call_back_complete_date: callBackCompleteDateColumn,
	r_and_i_date: rAndIDateColumn,
	r_and_i_complete_date: rAndICompleteDateColumn,
	tint_date: tintDateColumn,
	tint_complete_date: tintCompleteDateColumn,
	pick_up_date: pickUpDateColumn,
	pick_up_complete_date: pickUpCompleteDateColumn,
	drop_off_date: dropOffDateColumn,
	drop_off_complete_date: dropOffCompleteDateColumn,
	void_date: voidDateColumn
};

// associating columns with tables
export const salesTablesColumns = {
	sales: (date_type: keyof typeof variableDateColumns) => {
		return [
			invoiceNumCol,
			...(variableDateColumns[date_type]
				? [variableDateColumns[date_type]]
				: []),
			payerCol,
			referralNumCol,
			materialsCol,
			laborCol,
			subtotalCol,
			taxesCol,
			grossCol
		];
	},
	margin: [
		invoiceNumCol,
		customerNameCol,
		materialsCol,
		laborCol,
		subtotalCol,
		costCol,
		commissionsCol,
		rebateCol,
		otherCol,
		marginCol
	]
};

const dateColumn = { label: t('date'), prop: 'date' };

const installDefaultCols = [
	invoiceNumCol,
	customerNameCol,
	dateColumn,
	referralNumCol
];

const paymentsListColumn = {
	label: t('reportsPage.colNames.payment_list'),
	prop: 'payment_list',
	wrap: true
};

const installDateCol = {
	label: t('reportsPage.colNames.install_date'),
	prop: 'install_date'
};

const invoiceDateCol = {
	label: t('reportsPage.colNames.invoice_date'),
	prop: 'invoice_date'
};

export const installTablesColumns = {
	technicians: [...installDefaultCols, techComCol],
	salesreps: [...installDefaultCols, salesrepComCol],
	salesources: [...installDefaultCols, salesourseComCol],
	locations: [...installDefaultCols, locationComCol],
	csrs: [...installDefaultCols, csrComCol],
	'tech-collections': [
		invoiceNumCol,
		customerNameCol,
		installDateCol,
		invoiceDateCol,
		referralNumCol,
		paymentsListColumn,
		techComCol
	]
};

const timeCol = {
	label: t('reportsPage.colNames.time'),
	prop: 'time'
};

const partsColumn = {
	label: t('reportsPage.colNames.part_list'),
	prop: 'part_list'
};

export const jobNotesCol = {
	label: t('reportsPage.colNames.install_notes'),
	prop: 'install_notes',
	wrap: true
};

export const yearMakeModelCol = {
	label: t('reportsPage.colNames.vehicle'),
	prop: 'vehicle'
};

export const schedulerTableCols = {
	schedule: [
		invoiceNumCol,
		timeCol,
		{
			...customerNameCol,
			wrap: true
		},
		{
			...partsColumn,
			wrap: true
		},
		yearMakeModelCol,
		jobNotesCol
	]
};

const invTotalCol = {
	label: t('reportsPage.colNames.total_after_taxes2'),
	prop: 'total_after_taxes',
	dollar: true
};

const paymentTypeCol = {
	label: t('reportsPage.colNames.type'),
	prop: 'type'
};

const tpaCol = {
	label: t('reportsPage.colNames.tpa'),
	prop: 'tpa'
};

const paymentAmountCol = {
	label: t('reportsPage.colNames.amount'),
	prop: 'amount',
	class: 'strong',
	dollar: true
};

const xActionTypeCol = {
	label: t('reportsPage.colNames.x_action_type'),
	prop: 'x_action_type'
};

const xActionNumberCol = {
	label: t('reportsPage.colNames.x_action_number'),
	prop: 'x_action_number'
};

const memoCol = {
	label: t('reportsPage.colNames.memo'),
	prop: 'memo',
	class: 'memo-col'
};

export const paymentCols = {
	receipt: [
		dateColumn,
		invoiceNumCol,
		invoiceDateColumn,
		invTotalCol,
		customerNameCol,
		paymentTypeCol,
		xActionTypeCol,
		xActionNumberCol,
		memoCol,
		tpaCol,
		paymentAmountCol
	]
};

const selectedTaxTotalCol = {
	label: t('reportsPage.colNames.total'),
	prop: 'total',
	dollar: true
};

const taxTotalCol = {
	label: t('reportsPage.colNames.total_taxes2'),
	prop: 'total_taxes',
	dollar: true
};

const grossCostCol = {
	label: t('reportsPage.colNames.total_after_taxes3'),
	prop: 'total_after_taxes',
	class: 'strong',
	dollar: true
};

export const taxDetailLastCols = [selectedTaxTotalCol, taxTotalCol, grossCol];
const summaryTaxTotalCol = {
	label: t('reportsPage.colNamesSecondary.total_taxes'),
	prop: 'total_taxes',
	dollar: true
};

export const taxSummaryLastCols = [summaryTaxTotalCol, grossCol];

const totalTaxableCol = {
	label: t('reportsPage.colNamesSecondary.total_taxable'),
	prop: 'total_taxable',
	dollar: true
};

const totalMaterialExempt = {
	label: t('reportsPage.colNamesSecondary.material_exempt'),
	prop: 'material_exempt',
	dollar: true
};

const totalLaborExempt = {
	label: t('reportsPage.colNamesSecondary.labor_exempt'),
	prop: 'labor_exempt',
	dollar: true
};

const netTaxable = {
	label: t('reportsPage.colNamesSecondary.total_taxable'),
	prop: 'total_taxable',
	dollar: true
};

const totalExempt = {
	label: t('reportsPage.colNamesSecondary.total_exempt'),
	class: 'strong',
	prop: 'total_exempt',
	dollar: true
};

const summarySubtotalCol = {
	label: t('reportsPage.colNamesSecondary.total_subtotal'),
	prop: 'total_subtotal',
	dollar: true,
	class: 'strong'
};

const totalCollectedCol = {
	label: t('reportsPage.colNamesSecondary.amount'),
	prop: 'amount',
	dollar: true
};

export const totalMaterialTaxesCol = {
	label: t('reportsPage.colNamesSecondary.total_materials_taxes'),
	prop: 'total_materials_taxes',
	dollar: true
};

export const totalLaborTaxesCol = {
	label: t('reportsPage.colNamesSecondary.total_labor_taxes'),
	prop: 'total_labor_taxes',
	dollar: true
};

const totalTaxCollectedCol = {
	label: t('reportsPage.colNamesSecondary.total_tax_collected'),
	prop: 'total_tax_collected',
	dollar: true
};

const emptyCol = {
	label: '',
	prop: 'empty'
};

const royaltyRepairSalesCol = {
	label: t('reportsPage.colNamesSecondary.repairSales'),
	prop: 'repairSales',
	dollar: true
};

const royaltyRepairPercentageCol = {
	label: t('reportsPage.colNamesSecondary.royaltyPercentage'),
	id: 'repairRoyaltyPercentage',
	prop: 'royaltyPercentage',
	percent: true
};

const royaltyRepairRoyaltyCol = {
	label: t('reportsPage.colNamesSecondary.repairRoyalty'),
	prop: 'repairRoyalty',
	bold: true,
	dollar: true
};

const royaltyRepairSpacerCol = {
	id: 'repairSpacer'
};

const royaltyReplaceSalesCol = {
	label: t('reportsPage.colNamesSecondary.replaceSales'),
	id: 'replaceSales',
	prop: 'replaceSales',
	dollar: true
};

const royaltyReplacePercentageCol = {
	label: t('reportsPage.colNamesSecondary.replaceRoyaltyPercentage'),
	id: 'replaceRoyaltyPercentage',
	prop: 'royaltyPercentage',
	percent: true
};

const royaltyReplaceRoyaltyCol = {
	label: t('reportsPage.colNamesSecondary.replaceRoyalty'),
	id: 'replaceRoyalty',
	prop: 'replaceRoyalty',
	bold: true,
	dollar: true
};

const royaltyReplaceSpacerCol = {
	id: 'replaceSpacer'
};

const royaltyAdditionalSalesCol = {
	label: t('reportsPage.colNamesSecondary.additionalSales'),
	id: 'additionalSales',
	prop: 'additionalSales',
	dollar: true
};

const royaltyAdditionalPercentageCol = {
	label: t('reportsPage.colNamesSecondary.additionalRoyaltyPercentage'),
	id: 'additionalRoyaltyPercentage',
	prop: 'royaltyPercentage',
	percent: true
};

const royaltyAdditionalRoyaltyCol = {
	label: t('reportsPage.colNamesSecondary.additionalRoyalty'),
	id: 'additionalRoyalty',
	prop: 'additionalRoyalty',
	bold: true,
	dollar: true
};

const royaltyAdditionalSpacerCol = {
	id: 'additionalSpacer'
};

const royaltyRepairVehiclesCol = {
	label: t('reportsPage.colNamesSecondary.repairVehicles'),
	id: 'repairVehicles',
	prop: 'repairVehicles',
	bold: true
};

const royaltyReplaceVehiclesCol = {
	label: t('reportsPage.colNamesSecondary.replaceVehicles'),
	id: 'replaceVehicles',
	prop: 'replaceVehicles',
	bold: true
};

const royaltyAdditionalInvoicesCol = {
	label: t('reportsPage.colNamesSecondary.additionalInvoices'),
	id: 'additionalInvoices',
	prop: 'additionalInvoices'
};

const royaltyVehicleSpacerCol = {
	id: 'vehicleSpacer'
};

const royaltySubtotalCol = {
	label: t('reportsPage.colNamesSecondary.royaltySubtotal'),
	id: 'royaltySubtotal',
	prop: 'royaltySubtotal',
	bold: true,
	dollar: true
};

export const taxColumns = {
	'tax-detail': [invoiceNumCol, materialsCol, laborCol, subtotalCol],
	'tax-summary': [
		materialsCol,
		laborCol,
		summarySubtotalCol,
		emptyCol,
		totalMaterialExempt,
		totalLaborExempt,
		totalExempt,
		emptyCol,
		// totalMaterialTaxesCol,
		// totalLaborTaxesCol,
		netTaxable
		// totalTaxableCol
	]
};

export const cashBasisColumns = {
	detail: [
		invoiceNumCol,
		subtotalCol,
		totalTaxableCol,
		taxTotalCol,
		grossCostCol,
		totalCollectedCol,
		totalTaxCollectedCol
	],
	summary: [totalCollectedCol, totalTaxCollectedCol]
};

const installListColumns = {
	'install-list': [
		invoiceNumCol,
		{
			label: t('reportsPage.filters.jobTypes.jobType'),
			prop: 'status'
		},
		customerNameCol,
		{
			label: t('reportsPage.filters.customFilterLabels.salesreps'),
			prop: 'job_salesreps',
			isArray: true
		},
		{
			label: t('reportsPage.filters.customFilterLabels.installer'),
			prop: 'job_techs',
			isArray: true
		},
		{
			...payerCol,
			wrap: false
		},
		{
			label: t('reportsPage.filters.install_date'),
			prop: 'date'
		}
	]
};

export const royaltyColumns = {
	'royalty-summary': [
		royaltyRepairSalesCol,
		royaltyRepairPercentageCol,
		royaltyRepairRoyaltyCol,
		royaltyRepairSpacerCol,
		royaltyReplaceSalesCol,
		royaltyReplacePercentageCol,
		royaltyReplaceRoyaltyCol,
		royaltyReplaceSpacerCol,
		royaltyAdditionalSalesCol,
		royaltyAdditionalPercentageCol,
		royaltyAdditionalRoyaltyCol,
		royaltyAdditionalSpacerCol,
		royaltyRepairVehiclesCol,
		royaltyReplaceVehiclesCol,
		royaltyAdditionalInvoicesCol,
		royaltyVehicleSpacerCol,
		royaltySubtotalCol
	]
};

export const reportColumns = {
	sales: salesTablesColumns,
	install: installTablesColumns,
	scheduler: schedulerTableCols,
	payment: paymentCols,
	tax: taxColumns,
	'cash-basis': cashBasisColumns,
	'install-list': installListColumns,
	royalty: royaltyColumns
};

export type AllTableColumns =
	| typeof salesTablesColumns
	| typeof installTablesColumns
	| typeof schedulerTableCols
	| typeof paymentCols
	| typeof taxColumns
	| typeof cashBasisColumns
	| typeof installListColumns
	| typeof royaltyColumns;

export const csvColumns = {
	sales: {
		sales: {
			invoice_num: { label: `${t('invoice')} #`, dollar: false },
			quote_date: { label: t('reportsPage.filters.quote_date'), dollar: false },
			work_order_date: {
				label: t('reportsPage.filters.quote_date'),
				dollar: false
			},
			invoice_date: {
				label: t('reportsPage.filters.invoice_date'),
				dollar: false
			},
			install_date: {
				label: t('reportsPage.filters.install_date'),
				dollar: false
			},
			warranty_date: {
				label: t('reportsPage.filters.warranty_date'),
				dollar: false
			},
			warranty_complete_date: {
				label: t('reportsPage.filters.warranty_complete_date'),
				dollar: false
			},
			recal_date: { label: t('reportsPage.filters.recal_date'), dollar: false },
			recal_complete_date: {
				label: t('reportsPage.filters.recal_complete_date'),
				dollar: false
			},
			call_back_date: {
				label: t('reportsPage.filters.call_back_date'),
				dollar: false
			},
			call_back_complete_date: {
				label: t('reportsPage.filters.call_back_complete_date'),
				dollar: false
			},
			r_and_i_date: {
				label: t('reportsPage.filters.r_and_i_date'),
				dollar: false
			},
			r_and_i_complete_date: {
				label: t('reportsPage.filters.r_and_i_complete_date'),
				dollar: false
			},
			tint_date: { label: t('reportsPage.filters.tint_date'), dollar: false },
			tint_complete_date: {
				label: t('reportsPage.filters.tint_complete_date'),
				dollar: false
			},
			void_date: {
				label: t('reportsPage.filters.void_date'),
				dollar: false
			},
			payer: { label: t('reportsPage.colNames.payer'), dollar: false },
			referral_number: {
				label: t('reportsPage.colNames.referral_number'),
				dollar: false
			},
			total_materials: { label: t('materials'), dollar: true },
			total_labor: { label: t('labor'), dollar: true },
			total_subtotal: { label: t('subtotal'), dollar: true },
			total_taxes: {
				label: t('reportsPage.colNames.total_taxes'),
				dollar: true
			},
			total_after_taxes: {
				label: t('reportsPage.colNames.total_after_taxes'),
				dollar: true
			}
		},
		margin: {
			invoice_num: { label: `${t('invoice')} #`, dollar: false },
			customer_name: {
				label: t('reportsPage.colNames.customer_name'),
				dollar: false
			},
			total_materials: { label: t('materials'), dollar: true },
			total_labor: { label: t('labor'), dollar: true },
			total_subtotal: { label: t('subtotal'), dollar: true },
			total_cost: { label: t('reportsPage.colNames.partCost'), dollar: true },
			total_commission: {
				label: t('reportsPage.colNames.total_commission'),
				dollar: true
			},
			customer_rebate: {
				label: t('reportsPage.colNames.customer_rebate'),
				dollar: true
			},
			other_costs: {
				label: t('reportsPage.colNames.other_costs'),
				dollar: true
			},
			total_margin: {
				label: t('reportsPage.colNames.total_margin'),
				dollar: true
			}
		}
	},
	payment: {
		receipt: {
			date: { label: t('date'), dollar: false },
			invoice_num: { label: `${t('invoice')} #`, dollar: false },
			invoice_date: {
				label: t('reportsPage.colNames.invoice_date'),
				dollar: false
			},
			total_after_taxes: {
				label: t('reportsPage.colNames.total_after_taxes2'),
				dollar: true
			},
			customer_name: {
				label: t('reportsPage.colNames.customer_name'),
				dollar: false
			},
			type: { label: t('reportsPage.colNames.type'), dollar: false },
			x_action_type: {
				label: t('reportsPage.colNames.x_action_type'),
				dollar: false
			},
			x_action_number: {
				label: t('reportsPage.colNames.x_action_number'),
				dollar: false
			},
			memo: { label: t('reportsPage.colNames.memo'), dollar: false },
			tpa: { label: 'TPA', dollar: false },
			amount: { label: t('reportsPage.colNames.amount'), dollar: true }
		}
	},
	'install-list': {
		'install-list': {
			invoice_num: { label: `${t('invoice')} #`, dollar: false },
			status: {
				label: t('reportsPage.filters.jobTypes.jobType'),
				dollar: false
			},
			customer_name: {
				label: t('reportsPage.colNames.customer_name'),
				dollar: false
			},
			salesrep: { label: t('reportsPage.filters.salesreps'), dollar: false },
			tech: {
				label: t('reportsPage.filters.customFilterLabels.installer'),
				dollar: false
			},
			payer: { label: t('reportsPage.colNames.payer'), dollar: false },
			date: { label: t('reportsPage.colNames.install_date'), dollar: false }
		}
	}
};

export const statementAgingColumns = [
	'Current',
	'0-30',
	'31-60',
	'61-90',
	'91+'
];

export const textMssageTemplatestabList = [
	{
		title: 'Customer',
		key: 'customer',
		data: [
			{ key: 'Company name', value: '{customer company name}' },
			{ key: 'First', value: '{customer first}' },
			{ key: 'Last', value: '{customer last}' },
			{ key: 'Phone', value: '{customer phone}' },
			{ key: 'Email', value: '{customer email}' },
			{ key: 'Address', value: '{customer address}' }
		]
	},
	{
		title: 'Bill-to',
		key: 'bill-to',
		data: [
			{ key: 'Company name', value: '{bill-to company name}' },
			{ key: 'First', value: '{bill-to first}' },
			{ key: 'Last', value: '{bill-to last}' },
			{ key: 'Phone', value: '{bill-to phone}' },
			{ key: 'Email', value: '{bill-to email}' },
			{ key: 'Address', value: '{bill-to address}' }
		]
	},
	{
		title: 'Schedule',
		key: 'schedule',
		data: [
			{ key: 'Date', value: '{schedule date}' },
			{ key: 'Day', value: '{schedule day}' },
			{ key: 'Start time', value: '{schedule start time}' },
			{ key: 'End time', value: '{schedule end time}' },
			{ key: 'Phone', value: '{schedule contact phone}' },
			{ key: 'Contact name', value: '{schedule contact name}' },
			{ key: 'Address', value: '{schedule customer address}' }
		]
	},
	{
		title: 'Billing info',
		key: 'billing-info',
		data: [
			{ key: 'Job number', value: '{job-id #}' },
			{ key: 'Referral', value: '{referral #}' },
			{ key: 'Policy number', value: '{policy #}' },
			{ key: 'Loss date', value: '{loss date}' },
			{ key: 'Dispatch date', value: '{dispatch date}' },
			{ key: 'Install date', value: '{install date}' },
			{ key: 'Deductible', value: '{deductible $}' },
			{ key: 'PO number', value: '{PO #}' },
			{ key: 'RO number', value: '{RO #}' },
			{ key: 'DOT number', value: '{DOT #}' },
			{ key: 'Lot number', value: '{lot #}' },
			{ key: 'Stock number', value: '{stock #}' }
		]
	},
	{
		title: 'Vehicle',
		key: 'vehicle',
		data: [
			{ key: 'Year', value: '{veh year}' },
			{ key: 'Make', value: '{veh make}' },
			{ key: 'Model', value: '{veh model}' },
			{ key: 'Style', value: '{veh style}' },
			{ key: 'Unit number', value: '{veh unit #}' },
			{ key: 'Plate number', value: '{veh plate #}' },
			{ key: 'VIN number', value: '{veh vin #}' },
			{ key: 'License plate', value: '{veh license plate}' },
			{ key: 'Mileage', value: '{veh mileage}' }
		]
	},
	{
		title: 'Totals',
		key: 'totals',
		data: [
			{ key: 'Invoice total', value: '{job total $}' },
			{ key: 'Balance due customer', value: '{customer balance $}' },
			{ key: 'Balance due bill to', value: '{bill to balance $}' }
		]
	},
	{
		title: 'Vendors',
		key: 'vendors',
		data: [
			{ key: 'Csr name', value: '{csr name}' },
			{ key: 'Sale source name', value: '{sale source name}' },
			{ key: 'Sales rep name', value: '{sales rep name}' },
			{ key: 'Tech name', value: '{tech name}' },
			{ key: 'Location name', value: '{location name}' }
		]
	},
	{
		title: 'Shop',
		key: 'shop',
		data: [
			{ key: 'Name', value: '{shop name}' },
			{ key: 'Email', value: '{shop email}' },
			{ key: 'Address', value: '{shop address}' },
			{ key: 'Address city', value: '{shop address city}' },
			{ key: 'Address state', value: '{shop address state}' },
			{ key: 'Address ZIP', value: '{shop address zip}' },
			{ key: 'Full address', value: '{shop full address}' },
			{ key: 'Logo', value: '{shop logo}' }
		]
	}
];

export const workTypeOptions = [
	{ value: 'all', label: t('all') },
	{ value: 'repair', label: t('reportsPage.repair') },
	{ value: 'replace', label: t('reportsPage.replace') }
];

export const contractorOptions = [
	{
		id: 'csrs',
		name: t('reportsPage.filters.csrs')
	},
	{
		id: 'salesources',
		name: t('reportsPage.filters.salesources')
	},
	{
		id: 'salesreps',
		name: t('reportsPage.filters.salesreps')
	},
	{
		id: 'techs',
		name: t('reportsPage.filters.techs')
	},
	{
		id: 'locations',
		name: t('reportsPage.filters.locations')
	}
];

export const allFiltersTypes = [
	'csrs',
	'salesources',
	'salesreps',
	'techs',
	'locations'
];

export const defaultDateTypes = [
	{ name: t('reportsPage.filters.invoice_date'), id: 'invoice_date' },
	{ name: t('reportsPage.filters.install_date'), id: 'install_date' },
	{ name: t('reportsPage.filters.work_order_date'), id: 'work_order_date' },
	{ name: t('reportsPage.filters.quote_date'), id: 'quote_date' },
	{ name: t('reportsPage.filters.warranty_date'), id: 'warranty_date' },
	{
		name: t('reportsPage.filters.warranty_complete_date'),
		id: 'warranty_complete_date'
	},
	{ name: t('reportsPage.filters.recal_date'), id: 'recal_date' },
	{
		name: t('reportsPage.filters.recal_complete_date'),
		id: 'recal_complete_date'
	},
	{ name: t('reportsPage.filters.call_back_date'), id: 'call_back_date' },
	{
		name: t('reportsPage.filters.call_back_complete_date'),
		id: 'call_back_complete_date'
	},
	{ name: t('reportsPage.filters.r_and_i_date'), id: 'r_and_i_date' },
	{
		name: t('reportsPage.filters.r_and_i_complete_date'),
		id: 'r_and_i_complete_date'
	},
	{ name: t('reportsPage.filters.tint_date'), id: 'tint_date' },
	{
		name: t('reportsPage.filters.tint_complete_date'),
		id: 'tint_complete_date'
	},
	{ name: t('reportsPage.filters.pick_up_date'), id: 'pick_up_date' },
	{
		name: t('reportsPage.filters.pick_up_complete_date'),
		id: 'pick_up_complete_date'
	},
	{ name: t('reportsPage.filters.drop_off_date'), id: 'drop_off_date' },
	{
		name: t('reportsPage.filters.drop_off_complete_date'),
		id: 'drop_off_complete_date'
	},
	{ name: t('reportsPage.filters.void_date'), id: 'void_date' }
];

export const dateFilterMap = {
	call_back_date: t('reportsPage.filters.call_back_date'),
	call_back_complete_date: t('reportsPage.filters.call_back_complete_date'),
	draft_date: t('reportsPage.table.draft_date'),
	drop_off_date: t('reportsPage.filters.drop_off_date'),
	drop_off_complete_date: t('reportsPage.filters.drop_off_complete_date'),
	install_date: t('reportsPage.filters.install_date'),
	invoice_date: t('reportsPage.filters.invoice_date'),
	pick_up_date: t('reportsPage.filters.pick_up_date'),
	pick_up_complete_date: t('reportsPage.filters.pick_up_complete_date'),
	quote_date: t('reportsPage.filters.quote_date'),
	r_and_i_date: t('reportsPage.filters.r_and_i_date'),
	r_and_i_complete_date: t('reportsPage.filters.r_and_i_complete_date'),
	recal_date: t('reportsPage.filters.recal_date'),
	recal_complete_date: t('reportsPage.filters.recal_complete_date'),
	tint_date: t('reportsPage.filters.tint_date'),
	tint_complete_date: t('reportsPage.filters.tint_complete_date'),
	void_date: t('reportsPage.filters.void_date'),
	work_order_date: t('reportsPage.filters.work_order_date'),
	warranty_date: t('reportsPage.filters.warranty_date'),
	warranty_complete_date: t('reportsPage.filters.warranty_complete_date')
};

export const requiredFiltersByGroup = {
	sales: ['shop', 'date'],
	royalty: ['shop', 'date'],
	'cash-basis': ['shop', 'date', 'taxes'],
	tax: ['shop', 'date', 'taxes'],
	scheduler: ['shop', 'date', 'jobTypes'],
	install: ['shop', 'date'],
	'install-list': ['shop', 'date', 'jobTypes'],
	edi: ['shop', 'date'],
	'right-part': ['shop', 'date'],
	miscellaneous: ['shop', 'date'],
	accountingReports: ['shop', 'date'],
	aging: ['shop'],
	statements: ['shop', 'date', 'customer'],
	payment: ['shop', 'date', 'x_action_types'],
	'nags-license': ['shop', 'date', 'license']
};

export const requiredFiltersById = {
	salesreps: ['salesreps'],
	salesources: ['salesources'],
	locations: ['locations'],
	csrs: ['csrs'],
	'tech-collections': ['techs']
};
