<script setup lang="ts">
import Badge from 'primevue/badge';
import DatePicker from 'primevue/datepicker';
import Fieldset from 'primevue/fieldset';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';
import { defineModel } from 'vue';
import { useI18n } from 'vue-i18n';

import { isEmptyValue } from '@/shared/helpers';

const { t } = useI18n();

const ackDetails = defineModel<{
	mobileIndicator: boolean;
	requestedAppointmentDt: string;
	workLocationPostalCode: string;
	acceptanceContact: string;
}>('ackDetails', { required: true });

defineProps<{ status: boolean }>();
</script>

<template>
	<Fieldset :legend="t('autoAccept')" toggleable>
		<div class="tw3-grid tw3-basis-2/3 tw3-gap-2">
			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					<span>{{ t('acceptanceContact') }}</span>
					<Badge
						v-if="isEmptyValue(ackDetails.acceptanceContact)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>
				<InputText
					v-model="ackDetails.acceptanceContact"
					class="tw3-w-full"
					:disabled="status"
					type="text"
				/>
			</div>
			<div>
				<div
					class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
				>
					<span>{{ t('workLocation') }}</span>
					<Badge
						v-if="isEmptyValue(ackDetails.workLocationPostalCode)"
						v-tooltip.top="t('required')"
						class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
						severity="danger"
					/>
				</div>
				<InputText
					v-model="ackDetails.workLocationPostalCode"
					class="tw3-w-full"
					:disabled="status"
					type="text"
				/>
			</div>
			<div class="tw3-flex tw3-gap-4 tw3-justify-between">
				<div>
					<div
						class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
					>
						<span>{{ t('appointmentDate') }}</span>
						<Badge
							v-if="isEmptyValue(ackDetails.requestedAppointmentDt)"
							v-tooltip.top="t('required')"
							class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
							severity="danger"
						/>
					</div>

					<DatePicker
						v-model="ackDetails.requestedAppointmentDt"
						class="tw3-w-full"
						dateFormat="yy/mm/dd"
						:disabled="status"
						hourFormat="12"
						showTime
					/>
				</div>
				<div style="min-width: 3.5rem">
					<div
						class="tw3-h-8 tw3-relative tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
					>
						<span>{{ t('mobileIndicator') }}</span>
						<Badge
							v-if="isEmptyValue(ackDetails.mobileIndicator)"
							v-tooltip.top="t('required')"
							class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.4rem] !tw3-w-[0.3rem]"
							severity="danger"
						/>
					</div>

					<ToggleButton
						v-model="ackDetails.mobileIndicator"
						:disabled="status"
						:offLabel="t('no')"
						:onLabel="t('yes')"
					/>
				</div>
			</div>
		</div>
	</Fieldset>
</template>
