<template>
	<svg
		id="Layer_1"
		fill="none"
		style="enable-background: new 0 0 32 32"
		version="1.1"
		viewBox="0 0 32 32"
		xml:space="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g>
			<path
				d="M23.3,12.6v16.6c0,1-0.8,1.8-1.8,1.8H4c-1,0-1.8-0.8-1.8-1.8V5.5c0-1,0.8-1.8,1.8-1.8h4.7L8.4,5.1h1.7l1.5-2.7   c0.2-0.4,0.7-0.7,1.2-0.7l0,0c0.5,0,0.9,0.3,1.2,0.7l1.5,2.7h1.7l-0.4-1.4H19c-0.6,0.9-0.9,2-0.9,3.1C18.1,9.8,20.4,12.3,23.3,12.6   z"
				:style="{
					fill: 'none',
					stroke: 'currentColor',
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10
				}"
			/>
			<g>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="6.5"
					x2="13.8"
					y1="9.4"
					y2="9.4"
				/>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="6.5"
					x2="19"
					y1="12.9"
					y2="12.9"
				/>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="6.5"
					x2="19"
					y1="16.3"
					y2="16.3"
				/>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="6.5"
					x2="19"
					y1="19.8"
					y2="19.8"
				/>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="6.5"
					x2="19"
					y1="23.2"
					y2="23.2"
				/>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="6.5"
					x2="19"
					y1="26.7"
					y2="26.7"
				/>
			</g>
			<g>
				<g>
					<path
						d="M25.5,4.7h-1.2h-0.8c-0.6,0-1.1,0.5-1.1,1.1l0,0c0,0.6,0.5,1.1,1.1,1.1h1"
						:style="{
							fill: 'currentColor',
							stroke: 'currentColor',
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: 10
						}"
					/>
					<path
						d="M24.4,6.8c0.6,0,1.1,0.5,1.1,1.1l0,0C25.5,8.5,25,9,24.4,9h-2.1"
						:style="{
							fill: 'currentColor',
							stroke: 'currentColor',
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: 10
						}"
					/>
				</g>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="24.2"
					x2="24.2"
					y1="3.5"
					y2="4.7"
				/>
				<line
					:style="{
						fill: 'currentColor',
						stroke: 'currentColor',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}"
					x1="24.2"
					x2="24.2"
					y1="9"
					y2="10.1"
				/>
			</g>
			<circle
				cx="23.9"
				cy="6.8"
				r="5.8"
				:style="{
					fill: 'none',
					stroke: 'currentColor',
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10
				}"
			/>
		</g>
	</svg>
</template>
