<script setup lang="ts">
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import DatePicker from 'primevue/datepicker';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

import ClaimStatus from '@/entities/claim/ClaimStatus.vue';
import { useClaimDetailsStore } from '@/entities/claim/lib/store';
import { dispatchEvent, isEmptyValue } from '@/shared/helpers';
import { RefreshButton } from '@/shared/ui';

import { archiveOptions, statusOptions, tableHeaders } from './lib/constants';
import { useClaimsStore } from './lib/store';

import type {
	DataTablePageEvent,
	DataTableSortEvent
} from 'primevue/datatable';

const { t } = useI18n();

const confirm = useConfirm();

const claimsStore = useClaimsStore();
const claimDetailsStore = useClaimDetailsStore();

const handlePage = (state: DataTablePageEvent) => {
	claimsStore.pagination.page = state.page + 1;
};
const handleSort = (state: DataTableSortEvent) => {
	claimsStore.pagination.page = 1;

	claimsStore.handleSort({
		desc: state.sortOrder === -1,
		field: state.sortField as string
	});
};
//TODO: re-check in the stable release of primevue
const getFilterIconClass = (field: string) => {
	const filter = claimsStore.filters[field];

	return isEmptyValue(filter.value) || filter.value === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const handleArchive = async (id: number, archived: boolean) => {
	const isArchived = archived === true;

	confirm.require({
		accept: async () => {
			await claimsStore.updateClaimAndRefetchClaims(
				id,
				!archived,
				await claimsStore.composeGetClaimsArgs()
			);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: isArchived ? t('unarchive') : t('archive'),
		message: isArchived
			? t('archiveDialog.unarchiveTitle')
			: t('archiveDialog.archiveTitle'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};

const openClaimDialog = async (jobId: number) => {
	await claimDetailsStore.claim.execute(0, { id: jobId });

	dispatchEvent('openClaimDetailsDialog');
};

const refreshClaims = async () => {
	claimsStore.claims.execute(0, await claimsStore.composeGetClaimsArgs());
};
</script>

<template>
	<DataTable
		v-model:filters="claimsStore.filters"
		dataKey="id"
		filterDisplay="menu"
		lazy
		:loading="claimsStore.claims.isLoading"
		paginator
		rowHover
		:rows="claimsStore.pagination.pageSize"
		scrollable
		scrollHeight="flex"
		size="small"
		:sortField="claimsStore.sort.field"
		:sortOrder="claimsStore.sort.desc === false ? 1 : -1"
		tableClass="tw3-max-w-full"
		:totalRecords="claimsStore.claims.state.totalCount"
		:value="claimsStore.claims.state.claim"
		@page="handlePage"
		@sort="handleSort"
	>
		<template #header>
			<div
				class="tw3-flex tw3-justify-between tw3-gap-2"
				style="padding-right: 2.5px"
			>
				<Select
					v-model="claimsStore.selectedShop"
					optionLabel="name"
					:options="claimsStore.shops"
					:placeholder="`${t('selectShop')}`"
				/>
				<RefreshButton @click="refreshClaims()" />
			</div>
		</template>

		<Column
			v-for="column of tableHeaders[claimsStore.selectedChipNumber]"
			:key="column.key"
			:field="column.key"
			:filterField="column.key"
			:header="column.title"
			:showFilterMatchModes="false"
			:showFilterMenu="column.key !== 'actions'"
			:sortable="column.key !== 'actions'"
			:style="{
				width: `${column.width}%`
			}"
		>
			<template #body="{ data }">
				<div class="cell-container">
					<ClaimStatus
						v-if="column.key === 'status'"
						:status="data[column.key]"
					/>
					<RouterLink
						v-else-if="column.key === 'jobId'"
						target="_blank"
						:to="`/jobs/${data[column.key]}`"
					>
						{{ data[column.key] }}
					</RouterLink>
					<span v-else>{{ data[column.key] }}</span>
				</div>
			</template>
			<template #filtericon>
				<i :class="getFilterIconClass(column.key)"></i>
			</template>
			<template #filter="{ filterModel }">
				<DatePicker
					v-if="column.key === 'submittedDt'"
					v-model="filterModel.value"
					appendTo="#vue3app"
					dateFormat="mm/dd/yy"
					mask="99/99/9999"
					placeholder="mm/dd/yyyy"
				/>
				<Select
					v-else-if="column.key === 'status'"
					v-model="filterModel.value"
					optionLabel="label"
					:options="statusOptions"
					optionValue="value"
					placeholder="Select status"
				/>
				<InputText
					v-else
					v-model="filterModel.value"
					class="p-column-filter"
					:placeholder="`Search by ${column.title}`"
					type="text"
				/>
			</template>
		</Column>
		<Column
			bodyClass="text-center"
			dataType="boolean"
			field="archived"
			:pt="{
				filter: {
					style: 'margin: 0 auto !important'
				}
			}"
			:showClearButton="false"
			:style="{
				width: '4%'
			}"
		>
			<template #body="{ data }">
				<Button
					v-tooltip.top="data.archived ? t('unarchive') : t('archive')"
					severity="secondary"
					text
					@click="handleArchive(data.id, data.archived)"
				>
					<template #icon>
						<i
							:class="`material-icons ${data.archived ? 'md-unarchive' : 'md-archive'}`"
						/>
					</template>
				</Button>
			</template>
			<template #filtericon>
				<i :class="getFilterIconClass('archived')"></i>
			</template>
			<template #filter="{ filterModel }">
				<Select
					v-model="filterModel.value"
					class="tw3-max-w-[300px] tw3-w-full"
					optionLabel="label"
					:options="archiveOptions"
					optionValue="value"
				/>
			</template>
		</Column>
		<Column
			:style="{
				width: '4%'
			}"
		>
			<template #body="{ data }">
				<div class="tw3-flex tw3-flex-col tw3-items-center">
					<Button
						v-if="data.jobId"
						v-tooltip.top="t('openClaim')"
						aria-label="Filter"
						severity="secondary"
						text
						@click="openClaimDialog(data.id)"
					>
						<template #icon>
							<i class="material-icons md-edit" />
						</template>
					</Button>
				</div>
			</template>
		</Column>

		<template v-if="!claimsStore.claims.isLoading" #empty>
			<div
				class="tw3-w-full tw3-h-[600px] tw3-flex tw3-justify-center tw3-items-center"
			>
				<p class="tw3-text-center tw3-text-3xl">{{ t('noData') }}</p>
			</div>
		</template>
	</DataTable>
</template>
