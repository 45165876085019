<template>
	<div>
		<table
			v-for="(shop_data, shop) in dataSplitedByShop"
			:key="shop"
			class="tax-detail-report-table"
		>
			<thead>
				<tr class="table-shop-head">
					<td class="cell" :colspan="colCount(shop) + 2">
						{{ shop }}
					</td>
				</tr>
			</thead>
			<tbody>
				<template
					v-for="(date_data, date) in splitReportDataByAttr('date')(shop_data)"
					:key="shop + date"
				>
					<tr class="table-date-head">
						<td class="fake-cell" />
						<td class="cell" :colspan="colCount(shop) + 1">
							{{ date }}
						</td>
					</tr>
					<tr class="table-header">
						<td class="fake-cell" />
						<td class="fake-cell" />
						<td
							v-for="col in currentColumns"
							:key="col.prop"
							class="cell"
							v-html="col.label"
						/>
						<td
							v-for="col in shopTaxColumns(shop)"
							:key="col.id"
							class="cell dollar strong"
						>
							{{ col.name }} <br />
							<span v-if="col.for_labor && col.for_materials"
								>({{ t('both') }} )</span
							>
							<span v-else-if="col.for_labor">({{ t('labor') }})</span>
							<span v-else-if="col.for_materials">({{ t('materials') }})</span>
							<span v-else>({{ t('flat') }})</span>
						</td>
						<td
							v-for="col in taxDetailLastCols"
							:key="col.prop"
							class="cell dollar"
							v-html="col.label"
						/>
					</tr>

					<tr v-for="row in date_data" :key="row.id" class="table-row">
						<td class="fake-cell" />
						<td class="fake-cell" />
						<td
							v-for="col in currentColumns"
							:key="col.prop"
							class="cell"
							:class="{ dollar: col.dollar, strong: col.strong }"
						>
							<span v-if="col.prop !== 'invoice_num'">{{
								col.dollar ? roundedNumbers(row[col.prop]) : row[col.prop]
							}}</span>
							<a
								v-if="col.prop === 'invoice_num'"
								:href="`/jobs/${row.id}`"
								target="_blank"
								>{{ row.id }}</a
							>
						</td>
						<td
							v-for="col in shopTaxColumns(shop)"
							:key="col.id"
							class="cell dollar strong"
						>
							{{ roundedNumbers(row[col.name]) }}
						</td>
						<td
							v-for="col in taxDetailLastCols"
							:key="col.prop"
							class="cell"
							:class="[col.class, { dollar: col.dollar, strong: col.strong }]"
						>
							{{ col.dollar ? roundedNumbers(row[col.prop]) : row[col.prop] }}
						</td>
					</tr>
					<TableTotals
						:columns="[
							...currentColumns,
							...shopTaxColumns(shop),
							...taxDetailLastCols
						]"
						:data="date_data"
						:fake="2"
						isDateMode
						label="Daily"
					/>
					<tr class="row-spacer-date">
						<td class="fake-cell" />
						<td class="fake-cell" />
						<td class="fake-cell" />
					</tr>
				</template>
				<TableTotals
					:columns="[
						...currentColumns,
						...shopTaxColumns(shop),
						...taxDetailLastCols
					]"
					:data="shop_data"
					:fake="2"
					isShopMode
					label="Shop"
				/>
				<tr :key="shop + 'spacer'">
					<td class="fake-cell" />
					<td class="fake-cell" />
					<td class="fake-cell" />
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { taxDetailLastCols } from '@/widgets/reportsElements/model/constants';
import useReportsStore from '@/widgets/reportsElements/model/store';
import {
	reportsGetters,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';

import TableTotals from '../reportsHelpers/TableTotals.vue';

const { t } = useI18n();

const props = defineProps<{
	data: object[] | null;
}>();

const reportStore = useReportsStore();

const {
	customShopDataFilterValue,
	customShopDataFilterOptions,
	currentColumns
} = storeToRefs(reportStore);

const dataSplitedByShop = computed(() => {
	return splitReportDataByAttr('shop_name')(props.data);
});

const colCount = (shop: string) => {
	return (
		shopTaxColumns(shop).length +
		currentColumns.value.length +
		taxDetailLastCols.length
	);
};

const staticTaxFilter = ref(customShopDataFilterValue.value.value);

watch(
	() => props.data,
	() => {
		staticTaxFilter.value = customShopDataFilterValue.value.value;
	}
);

const shopTaxColumns = (shop: string) => {
	const foundShop = customShopDataFilterOptions.value.find(
		(item: any) => item.name === shop
	);

	if (!foundShop) return [];

	const taxes = foundShop.taxes || [];

	const filteredTaxes = taxes.filter((tax: any) =>
		staticTaxFilter.value ? staticTaxFilter.value.includes(tax.id) : tax
	);

	return filteredTaxes.map((tax: any) => ({
		...tax,
		dollar: true
	}));
};

const roundedNumbers = (data: number) => {
	return (
		data &&
		reportsGetters.formatNumber(data, {
			style: 'dollar'
		})
	);
};
</script>

<style scoped>
.tax-detail-report-table {
	width: 100%;
	display: table;
}

.tax-detail-report-table .table-shop-head > .cell {
	background: #d1ffd0;
	color: #00a736;
	font-weight: 600;
}

.tax-detail-report-table .table-date-head .cell {
	background: #d0e5ff;
	color: #3e94ff;
	font-weight: 600;
}

.tax-detail-report-table .table-date-head,
.tax-detail-report-table .row-spacer-date {
	& > *:nth-child(2) {
		border-left: 2px solid #00a736;
	}
}

.tax-detail-report-table .table-header,
.tax-detail-report-table .table-row {
	.cell {
		background: #fff;
	}
	.fake-cell:nth-child(2) {
		border-left: 2px solid #00a736;
	}
	.cell:nth-child(3) {
		border-left: 2px solid #3e94ff;
	}
}

.tax-detail-report-table .row-spacer-date .cell {
	border-bottom: 0;
}
</style>
