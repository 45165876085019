import {
	createRouter,
	createWebHistory,
	NavigationFailure,
	RouteLocationRaw,
	type RouteRecordRaw
} from 'vue-router';

export const routes: RouteRecordRaw[] = [
	{
		component: async () =>
			await import(
				/* webpackChunkName: "customers" */ '@/pages/customers/CustomersPage.vue'
			),
		path: '/customers',
		name: 'Customers'
	},
	{
		component: async () =>
			await import(
				/* webpackChunkName: "vendors" */ '@/pages/vendors/VendorsPage.vue'
			),
		path: '/vendors',
		name: 'Vendors'
	},
	{
		component: async () =>
			await import(/* webpackChunkName: "jobs" */ '@/pages/jobs/JobsPage.vue'),
		path: '/jobs-new',
		name: 'Jobs'
	},
	{
		path: '/accounting',
		component: async () =>
			await import(
				/* webpackChunkName: "accounting" */ '@/pages/accounting/WrapperPage.vue'
			),
		children: [
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/SettingsPage.vue'
					),
				path: 'settings',
				name: 'accounting-settings'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/AccountsPage.vue'
					),
				path: 'accounts',
				name: 'accounting-accounts'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/AccountTransactionsPage.vue'
					),
				path: 'accounts/:id',
				props: route => ({ id: parseInt(route.params.id as string) }),
				name: 'accounting-account-transactions'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/JournalEntriesPage.vue'
					),
				path: 'transactions',
				name: 'accounting-transactions'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/expense/ExpenseList.vue'
					),
				path: 'expenses',
				name: 'accounting-expenses'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/BalanceSheetPage.vue'
					),
				path: 'report/balance-sheet',
				name: 'accounting-balance-sheet'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/IncomeStatementPage.vue'
					),
				path: 'report/income-statement',
				name: 'accounting-income-statement'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/TransactionsByAccountPage.vue'
					),
				path: 'report/transactions-by-account/:id',
				props: route => ({ id: parseInt(route.params.id as string) }),
				name: 'accounting-transactions-by-account'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/CustomerPaymentsPage.vue'
					),
				path: 'customer/payments',
				name: 'accounting-customer-payments'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/CustomerInvoicesPage.vue'
					),
				path: 'customer/invoices',
				name: 'accounting-customer-invoices'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/CustomerCreditsPage.vue'
					),
				path: 'customer/credits',
				name: 'accounting-customer-credits'
			},
			{
				component: async () =>
					await import(
						/* webpackChunkName: "accounting" */ '@/pages/accounting/CustomerBalancesPage.vue'
					),
				path: 'customer/balances',
				name: 'accounting-customer-balances'
			}
		]
	},
	{
		path: '/admin2',
		component: async () =>
			await import(
				/* webpackChunkName: "admin" */ '@/pages/admin/WrapperPage.vue'
			),
		children: [
			{
				path: 'accounting',
				children: [
					{
						component: async () =>
							await import(
								/* webpackChunkName: "admin" */ '@/pages/admin/accounting/TemplatesPage.vue'
							),
						path: 'template',
						name: 'admin-accounting-template'
					},
					{
						component: async () =>
							await import(
								/* webpackChunkName: "admin" */ '@/pages/admin/accounting/ProfilesPage.vue'
							),
						path: 'profiles',
						name: 'admin-accounting-profiles'
					}
				]
			}
		]
	},
	{
		component: async () =>
			await import(
				/* webpackChunkName: "dashboardPage" */ '@/pages/dashboard/DashboardPage.vue'
			),
		path: '/',
		name: 'Home'
	},
	{
		path: '/reports',
		name: 'Reports',
		component: () =>
			import(
				/* webpackChunkName: "/chooseReportsPage" */ '@/pages/reports/ChooseReportsPage.vue'
			),
		meta: { requiresReports: true }
	},
	{
		component: async () =>
			await import(
				/* webpackChunkName: "accounting" */ '@/pages/reports/SingleReport.vue'
			),
		path: '/reports/:reportId',
		name: 'Single report'
	}
];

export const router = createRouter({
	history: createWebHistory(`${process.env.BASE_URL ?? ''}`),
	routes
});

// Redirect navigation requests to the vue2 micro frontend
(router => {
	const changeRoute = (detail: any): void => {
		window.dispatchEvent(new CustomEvent('changeRoute', { detail: detail }));
	};

	router.push = (
		to: RouteLocationRaw
	): Promise<NavigationFailure | void | undefined> => {
		const path = router.resolve(to).href;
		changeRoute({ action: 'push', path });
		return Promise.resolve();
	};

	router.replace = (
		to: RouteLocationRaw
	): Promise<NavigationFailure | void | undefined> => {
		const path = router.resolve(to).href;
		changeRoute({ action: 'replace', path });
		return Promise.resolve();
	};

	router.back = (): void => {
		changeRoute({ action: 'back' });
	};

	router.forward = (): void => {
		changeRoute({ action: 'forward' });
	};

	router.go = (delta: number): void => {
		changeRoute({ action: 'go', delta });
	};
})(router);

export default router;
