<template>
	<Dialog
		id="TextTemplateSettings"
		v-model:visible="templateModalVisible"
		appendTo="#vue3app"
		class="tw3-max-w-[1200px] tw3-h-full tw3-w-full"
		:header="
			props.template
				? t('reportsPage.textMessagesTemplate.updateTemplate')
				: t('reportsPage.textMessagesTemplate.createTemplate')
		"
		modal
		:pt:content="'tw3-h-full tw3-flex tw3-flex-col'"
	>
		<div class="tw3-flex tw3-flex-col tw3-gap-4 tw3-justify-between tw3-h-full">
			<Tabs class="!tw3-h-full" :value="currentTab">
				<TabList>
					<Tab
						v-for="item in textMssageTemplatestabList"
						:key="item.key"
						:pt:root:class="'!tw3-pt-[16px]'"
						:value="item.key"
					>
						<span>{{ item.title }}</span>
					</Tab>
				</TabList>
				<TabPanels
					class="!tw3-p-0 tw3-h-full"
					style="height: calc(100% - 42px)"
				>
					<TabPanel
						v-for="item in textMssageTemplatestabList"
						:key="item.key"
						class="tw3-h-full tw3-p-6 tw3-flex tw3-flex-col tw3-gap-4"
						:value="item.key"
					>
						<div
							class="tw3-flex tw3-h-full tw3-flex-col tw3-justify-start tw3-items-start tw3-flex-wrap tw3-gap-3"
						>
							<p
								v-for="paragraph in item.data"
								:key="paragraph.value"
								class="tw3-w-fil tw3-h-fit tw3-block"
							>
								{{ paragraph.key }}:
								<span
									class="text-message-template-variable"
									@click="copyVariable"
								>
									{{ paragraph.value }}
								</span>
							</p>
						</div>
					</TabPanel>
				</TabPanels>
			</Tabs>
			<form
				class="tw3-flex tw3-flex-col tw3-gap-2"
				@submit.prevent="submitForm"
			>
				<div class="tw3-grid tw3-grid-cols-2 tw3-gap-4 tw3-w-full">
					<!-- Name Field -->
					<div class="tw3-flex-col tw3-flex tw3-gap-2">
						<span class="tw3-text-sm"> {{ t('name') }} </span>
						<InputTextWithError
							v-model="name"
							:invalid="nameError"
							:placeholder="`${t('reportsPage.enterAName')}...`"
							@focus="focusTextarea"
						/>
					</div>

					<!-- Type Field -->
					<div class="tw3-flex tw3-flex-col tw3-gap-2">
						<span class="tw3-text-sm"> {{ t('type') }} </span>
						<Select
							v-model="type_id"
							class="w-full md:w-56"
							:invalid="typeError ? true : false"
							optionLabel="name"
							:options="templatesTypes"
							optionValue="id"
							:placeholder="`${t('select')}...`"
						/>
					</div>
				</div>

				<div class="tw3-grid tw3-grid-cols-2 tw3-gap-4 tw3-w-full">
					<div class="tw3-flex tw3-flex-col tw3-gap-2">
						<span class="tw3-text-sm">
							{{ t('shop') }}
						</span>
						<Select
							v-model="shop_id"
							class="w-full md:w-56"
							:invalid="shopError ? true : false"
							optionLabel="name"
							:options="shops"
							optionValue="id"
							:placeholder="`${t('select')} ${t('shop')}...`"
						/>
					</div>

					<!-- Subject Field -->
					<div class="tw3-flex-col tw3-flex tw3-gap-2">
						<span class="tw3-text-sm">{{ t('subject') }}</span>
						<InputText
							ref="templateSubject"
							v-model="subject"
							:invalid="subjectError ? true : false"
							:placeholder="`${t('reportsPage.textMessagesTemplate.subject')}...`"
							@focus="focusSubject"
						/>
					</div>
				</div>

				<div class="tw3-flex-col tw3-flex tw3-gap-2">
					<span class="tw3-text-sm tw3-flex tw3-justify-between">
						{{ t('reportsPage.textMessagesTemplate.body') }}
						<p>
							<span class="tw3-font-medium tw3-text-red-600">
								{{ t('noteText') }}
							</span>
							{{ t('reportsPage.textMessagesTemplate.note') }}
						</p>
					</span>
					<Editor
						ref="primeEditor"
						v-model="body"
						class="tw3-col-span-12"
						:class="{ 'input-error': bodyError }"
						:defaultValue="template?.body"
						editorStyle="height: 250px"
						:placeholder="`${t('reportsPage.textMessagesTemplate.templateBody')}...`"
						@load="onEditorLoad"
						@selection-change="focusTextarea"
					/>
					<span class="error-message">{{ bodyError }}</span>
				</div>

				<div class="tw3-flex tw3-gap-2 tw3-items-center tw3-justify-end">
					<div
						class="tw3-flex tw3-gap-2 tw3-items-center tw3-justify-self-start tw3-mr-auto tw3-ml-0"
					>
						<ToggleSwitch v-model="defaultField" />
						<p class="tw3-leading-none">
							{{ t('reportsPage.textMessagesTemplate.default') }}
						</p>
						<ErrorMessage class="error-message" name="default" />
					</div>
					<Button
						class="tw3-w-fit"
						label="Cancel"
						severity="secondary"
						@click="closeForm"
					/>
					<Button
						class="tw3-w-fit"
						:label="props.template ? t('update') : t('create')"
						:loading="isFormLoading"
						type="submit"
					/>
				</div>
			</form>
		</div>
	</Dialog>
</template>

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Editor from 'primevue/editor';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import TabPanel from 'primevue/tabpanel';
import TabPanels from 'primevue/tabpanels';
import Tabs from 'primevue/tabs';
import ToggleSwitch from 'primevue/toggleswitch';
import { useToast } from 'primevue/usetoast';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import * as zod from 'zod';

import { useUserStore } from '@/entities/user/store';
import { InputTextWithError } from '@/shared/ui';
import {
	createMessageTemplate,
	updateMessageTemplate
} from '@/widgets/reportsElements/api/api';
import { textMssageTemplatestabList } from '@/widgets/reportsElements/model/constants';

const { t } = useI18n();

const toast = useToast();
const userStore = useUserStore();
const shops = userStore.user.shops;

const props = defineProps<{
	template?: {
		body: string;
		default: number;
		id: number;
		name: string;
		shop_id: number;
		shop_name: string;
		subject: string;
		type_id: number;
		type_name: string;
	} | null;
	templatesTypes: {
		id: number;
		name: string;
		group_id: number;
	}[];
}>();

const emit = defineEmits(['updateTemplates']);

const templateModalVisible = defineModel<boolean>('show');

const currentTab = ref<string>('customer');
const templateSubject = ref<any>(null);
const primeEditor = ref();
const textAreaFocus = ref(false);
const subjectFocus = ref(false);

const forceUpdate = ref(0);

const copyVariable = (event: Event) => {
	const target = event.target as HTMLElement;
	const variableText = target.innerText;

	navigator.clipboard
		.writeText(variableText)
		.then(() => {
			if (subjectFocus.value) {
				const subjectWrapper = templateSubject.value;

				if (!subjectWrapper) {
					return;
				}
				const subjectArea = subjectWrapper.$el;

				const cursorPos = subjectArea.selectionStart || 0;
				const tempText = subject.value || '';

				subject.value =
					tempText.substring(0, cursorPos) +
					variableText +
					tempText.substring(subjectArea.selectionEnd || 0, tempText.length);

				forceUpdate.value++;

				setTimeout(() => {
					subjectArea.focus();
					const newCursorPos = cursorPos + variableText.length;
					subjectArea.selectionStart = subjectArea.selectionEnd = newCursorPos;
				}, 10);
			} else {
				toast.add({
					life: 3000,
					severity: 'success',
					detail: t('copyTextToast.succesDetails'),
					summary: t('copyTextToast.succesSummary')
				});
			}
		})
		.catch(err => {
			toast.add({
				life: 3000,
				severity: 'error',
				summary: t('copyTextToast.errorSummary'),
				detail: `${t('copyTextToast.errorDetails')} ${err}`
			});
		});
};

let quillInstance: any = null;

const onEditorLoad = ({ instance }: { instance: any }) => {
	quillInstance = instance;
};

watch(props, async () => {
	if (props.template) {
		if (primeEditor.value) {
			updateEditorContent(props.template?.body || '');
		}
		name.value = props.template.name;
		type_id.value = props.template.type_id;
		shop_id.value = props.template.shop_id;
		subject.value = props.template.subject;
		defaultField.value = props.template.default === 1;
	}
});

const updateEditorContent = (value: string) => {
	if (quillInstance) {
		quillInstance.setContents(
			quillInstance.clipboard.convert({
				html: value
			})
		);
	}
};

const closeForm = () => {
	templateModalVisible.value = false;

	name.value = '';
	type_id.value = 0;
	shop_id.value = 0;
	subject.value = '';
	defaultField.value = false;
	updateEditorContent('');
};

function focusTextarea() {
	textAreaFocus.value = true;
	subjectFocus.value = false;
}
function focusSubject() {
	textAreaFocus.value = false;
	subjectFocus.value = true;
}

const validationSchema = toTypedSchema(
	zod.object({
		name: zod
			.string()
			.min(3, t('reportsPage.textMessagesTemplate.errors.name')),
		body: zod
			.string()
			.min(10, t('reportsPage.textMessagesTemplate.errors.body')),
		type_id: zod
			.number()
			.int()
			.positive(t('reportsPage.textMessagesTemplate.errors.type_id')),
		shop_id: zod
			.number()
			.int()
			.positive(t('reportsPage.textMessagesTemplate.errors.shop_id')),
		subject: zod
			.string()
			.min(3, t('reportsPage.textMessagesTemplate.errors.subject')),
		defaultField: zod.boolean()
	})
);

const formObject = useForm({
	initialValues: {
		name: '',
		body: '',
		subject: '',
		type_id: 0,
		shop_id: 0,
		defaultField: false
	},
	validationSchema: validationSchema
});

const { value: name, errorMessage: nameError } = useField<string>('name');
const { value: type_id, errorMessage: typeError } = useField<number>('type_id');
const { value: shop_id, errorMessage: shopError } = useField<number>('shop_id');
const { value: subject, errorMessage: subjectError } =
	useField<string>('subject');
const { value: body, errorMessage: bodyError } = useField<string>('body');
const { value: defaultField } = useField<boolean>('default');

const isFormLoading = ref(false);
const submitForm = async () => {
	const result = await formObject.validate();

	if (result.valid) {
		isFormLoading.value = true;
		try {
			if (props.template) {
				await updateMessageTemplate(props.template?.id, {
					...result.values,
					default: 1
				});
			} else {
				await createMessageTemplate({
					...result.values,
					default: 1
				});
			}
			isFormLoading.value = false;
			emit('updateTemplates');
			closeForm();

			toast.add({
				life: 3000,
				severity: 'success',
				detail: !props.template
					? t('reportsPage.textMessagesTemplate.created')
					: t('reportsPage.textMessagesTemplate.updated'),
				summary: t('defaultToast.successSummary')
			});
		} catch (error) {
			toast.add({
				life: 3000,
				severity: 'error',
				detail: `${t('defaultToast.errorDetails')}: ${error}`,
				summary: t('defaultToast.errorSummary')
			});
			isFormLoading.value = false;
		}
		isFormLoading.value = false;
	} else {
		toast.add({
			life: 3000,
			severity: 'error',
			detail: t('reportsPage.textMessagesTemplate.fillRequired'),
			summary: t('defaultToast.errorSummary')
		});
	}
	forceUpdate.value++;
};
</script>

<style scoped>
:deep(.input-error) {
	border-color: red !important;
}
:deep(.error-message) {
	color: red !important;
	font-size: 12px !important;
}
</style>
