import { i18n } from '@/shared/i18n';

export const vendorsHeader = [
	{ key: 'id', title: i18n.global.t('id') },
	{ key: 'edit', title: i18n.global.t('edit') },
	{ key: 'status', title: i18n.global.t('status') },
	{ key: 'vendor_type', title: i18n.global.t('vendorType') },
	{ key: 'name', title: i18n.global.t('name') },
	{ key: 'phone', title: i18n.global.t('primaryPhone') },
	{ key: 'full_address', title: i18n.global.t('fullAddress') },
	{ key: 'email', title: i18n.global.t('email') },
	{ key: 'fax', title: i18n.global.t('faxDialog.fax') },
	{ key: 'notes', title: i18n.global.t('note.index') },
	{ key: 'commission', title: i18n.global.t('commission') },
	{ key: 'chip_commission', title: i18n.global.t('chipCommission') },
	{ key: 'invitation_status', title: i18n.global.t('invitationStatus') }
];
