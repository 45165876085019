<template>
	<template
		v-for="(singleData, splitedLabel) in splitedData"
		:key="splitedLabel"
	>
		<TableSubhead
			:columns="columns"
			:data="singleData"
			:displayFortyPercentHeader="displayFortyPercentHeader(singleData)"
			:label="splitedLabel !== 'undefined' ? splitedLabel : 'Multiple Selected'"
			:reportType="reportType"
			:type="splitBy"
		/>

		<template v-if="splitBy !== 'date'">
			<template
				v-for="(date_data, date) in splitReportDataByAttr('date')(singleData)"
				:key="date + splitedLabel"
			>
				<TableSubhead
					:columns="columns"
					:data="date_data"
					:displayFortyPercentHeader="displayFortyPercentHeader(date_data)"
					:fake="1"
					:label="`${currentDateType}: ${date}`"
					:reportType="reportType"
					type="date"
				/>
				<TableDefaultBody :columns="columns" :data="date_data" :fake="2" />
				<TableTotals
					:columns="columns"
					:data="date_data"
					:fake="2"
					:label="t('reportsPage.daily')"
				/>
				<TableRowSpacer :columns="columns" :fake="2" />
			</template>
		</template>
		<template v-else>
			<TableDefaultBody
				v-if="columns && singleData"
				:columns="columns"
				:data="singleData"
				:fake="1"
				showTotal
			/>
		</template>

		<TableTotals
			v-if="splitBy !== 'date'"
			:columns="columns"
			:data="singleData"
			:fake="2"
			:label="prepareLabel(splitBy)"
		/>
		<TableRowSpacer
			:columns="columns"
			:fake="splitBy !== 'date' ? 2 : 1"
			:shop="splitBy == 'shop_name'"
		/>
	</template>
	<TableTotals
		v-if="splitBy !== 'date'"
		:columns="columns"
		:data="data"
		:fake="2"
		:label="t('reportsPage.grand')"
	/>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import {
	reportsGetters,
	splitByContractor,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';

import { ReportType } from '../../api/types';
import { dateFilterMap } from '../../model/constants';
import useReportsStore from '../../model/store';

import TableDefaultBody from './TableDefaultBody.vue';
import TableRowSpacer from './TableRowSpacer.vue';
import TableSubhead from './TableSubhead.vue';
import TableTotals from './TableTotals.vue';

const { t } = useI18n();

const props = defineProps<{
	data: any;
	splitBy: string;
	reportType: ReportType;
	columns: any;
}>();

const displayFortyPercentHeader = (
	data: {
		forty_percent?: boolean;
	}[]
) => {
	if (data && data.length) {
		if (data[0].forty_percent) {
			return true;
		}
	}
	return false;
};

const splitedData = computed(() => {
	if (props.splitBy == 'name') {
		return splitByContractor(props.data);
	} else {
		return splitReportDataByAttr(props.splitBy)(props.data);
	}
});

const prepareLabel = (value: string): string => {
	let label = t('reportsPage.grand');
	if (value == 'shop_name') {
		label = t('shop');
	} else if (value == 'customerName') {
		label = t('reportsPage.customer');
	} else if (value == 'name') {
		label = t('reportsPage.contractor');
	}
	return label;
};

const reportsStore = useReportsStore();
const { currentReportType, dateType } = storeToRefs(reportsStore);

const currentDateType = computed(() => {
	if (currentReportType.value.date) {
		return currentReportType.value.date;
	} else {
		return reportsGetters.dateColumnLabel(
			dateType.value as keyof typeof dateFilterMap
		);
	}
});
</script>
