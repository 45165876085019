import { JobParts } from '../model/types';

export const getPartsWithColors = (parts: JobParts[]) => {
	return parts
		.filter(v => v.description && v.description.length !== 0)
		.map(part => {
			const is_additional_chip = part.description === "Add'l Chip Repair";
			const color = is_additional_chip
				? '#DBCCAA'
				: part.is_glass === '1'
					? '#C3D9FF'
					: part.is_adhesive === '1'
						? '#FFCEED'
						: part.is_chip === '1'
							? '#DBCCAA'
							: part.is_molding === '1'
								? '#A0FFA6'
								: '#E3E3E3';

			return { color, text: part.part_number };
		});
};
