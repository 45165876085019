<template>
	<svg
		fill="none"
		height="100%"
		style="
			fill-rule: evenodd;
			clip-rule: evenodd;
			stroke-linejoin: round;
			stroke-miterlimit: 2;
		"
		version="1.1"
		viewBox="0 0 32 32"
		width="100%"
		xml:space="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:serif="http://www.serif.com/"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<path
			d="M4.928,18.985c-1.656,0 -3,1.345 -3,3c-0,1.656 1.344,3 3,3c-0,0 2,0 2,0c0.552,0 1,0.448 1,1c-0,0.552 -0.448,1 -1,1l-3,0c-0.552,0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1l1.008,0l-0,1c-0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1l-0,-1c1.652,-0.004 2.992,-1.347 2.992,-3c-0,-1.655 -1.345,-3 -3,-3c-0,0 -2,0 -2,0c-0.552,0 -1,-0.448 -1,-1c-0,-0.552 0.448,-1 1,-1c-0,0 3,0 3,0c0.552,0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-1,0l-0,-0.985c-0,-0.552 -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1l-0,0.985Z"
			fill="currentColor"
		/>
		<path
			d="M7.006,11.008l18.004,0.025l0,16.954c0,0.553 -0.448,1 -1,1l-12.008,0c-0.552,0 -1,0.449 -1,1c-0,0.552 0.448,1 1,1l12.008,0c1.657,0 3,-1.343 3,-3c0,-5.154 0,-18.848 0,-24.003c0,-1.657 -1.343,-3 -3,-3l-16.004,-0c-1.657,-0 -3,1.343 -3,3c-0,3.417 -0,10.016 -0,10.016c-0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1l-0,-2.992Zm18.004,-1.975l0,-5.049c0,-0.552 -0.448,-1 -1,-1c0,-0 -16.004,-0 -16.004,-0c-0.553,-0 -1,0.448 -1,1l-0,5.024l18.004,0.025Z"
			fill="currentColor"
		/>
		<path
			d="M23,26l0,-4.015c0,-0.552 -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1l-0,4.015c0,0.552 0.448,1 1,1c0.552,-0 1,-0.448 1,-1Z"
			fill="currentColor"
		/>
		<circle cx="10.019" cy="13.949" fill="currentColor" r="1.25" />
		<circle cx="14" cy="13.949" fill="currentColor" r="1.25" />
		<circle cx="14" cy="17.949" fill="currentColor" r="1.25" />
		<circle cx="14" cy="21.949" fill="currentColor" r="1.25" />
		<circle cx="14" cy="25.949" fill="currentColor" r="1.25" />
		<circle cx="17.981" cy="13.949" fill="currentColor" r="1.25" />
		<circle cx="17.981" cy="17.949" fill="currentColor" r="1.25" />
		<circle cx="17.981" cy="21.949" fill="currentColor" r="1.25" />
		<circle cx="17.981" cy="25.949" fill="currentColor" r="1.25" />
		<circle cx="21.962" cy="13.949" fill="currentColor" r="1.25" />
		<circle cx="21.962" cy="17.949" fill="currentColor" r="1.25" />
		<g id="Icon" />
	</svg>
</template>
