<template>
	<div v-if="data" id="edi-report">
		<div v-for="(group, index) in data" :key="index">
			<table
				:data="group"
				:header-cell-style="{ background: '#d0e5ff', color: '#3e94ff' }"
				style="width: 100%"
			>
				<thead>
					<tr
						class="alignment"
						style="width: 100%; display: flex; justify-content: flex-start"
					>
						<th
							class="ediTh smallerCell"
							style="
								background: rgb(208, 229, 255);
								color: rgb(62, 148, 255);
								width: 120px !important;
								min-width: 120px !important;
							"
						>
							<div class="cell">Group</div>
						</th>
						<th
							class="ediTh"
							style="
								background: rgb(208, 229, 255);
								color: rgb(62, 148, 255);
								width: 100% !important;
							"
						>
							<div class="cell">Shop</div>
						</th>
						<th
							class="ediTh"
							style="
								background: rgb(208, 229, 255);
								color: rgb(62, 148, 255);
								width: 100% !important;
							"
						>
							<div class="cell">EDI Count ({{ groupTotal(group) }})</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="item in group"
						:key="item.id"
						class="alignment"
						style="width: 100%; display: flex; justify-content: flex-start"
					>
						<td
							class="el-table__cell smallerCell"
							style="width: 120px !important; min-width: 120px !important"
						>
							<div class="cell">
								{{ item.edi_group_id }}
							</div>
						</td>
						<td class="el-table__cell" style="width: 100% !important">
							<div class="cell">({{ item.id }}) {{ item.name }}</div>
						</td>
						<td class="el-table__cell" style="width: 100% !important">
							<div class="cell">
								<span>{{ item.count || 0 }}</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup lang="ts">
defineProps<{
	data: object[][] | null;
}>();

const groupTotal = (group: any) => {
	let total = 0;
	for (let i = 0; i < group.length; i++) {
		total += group[i].count;
	}
	return total;
};
</script>

<style scoped>
:deep(#edi-report .cell) {
	border: none !important;
}

.ediTh {
	border-bottom: 1px solid #ebeef5;
	padding: 6px 0;
	text-overflow: ellipsis;
	vertical-align: middle;
	text-align: left;
}

.cell {
	display: inline-block !important;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	vertical-align: middle;
	padding-left: 10px;
	padding-right: 10px;
	height: 30px;
	border-width: 1px 1px 0 0;
	border-color: #dcdcdc;
	border-style: solid;
	padding: 6px 12px;
	font-size: 12px;
	white-space: nowrap;
	line-height: 23px;
}

.el-table__cell {
	border-bottom: 1px solid #ebeef5;
	padding: 6px 0 !important;
}

.el-table__cell > .cell {
	padding: 6px 12px !important;
}

tbody {
	width: 100%;
}
</style>
