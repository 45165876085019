<script setup lang="ts">
import { FilterMatchMode } from '@primevue/core';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { ref } from 'vue';

import JobTableFilters from '../filters/JobTableFilters.vue';

const ID_PREFIX = 'overlay_menu_column_filter_';

type Filter = {
	val?: any | null;
	type: string;
};

const props = defineProps<{
	id: string;
	name: string;
	icon: string;
	modelValue: Filter;
	loading?: boolean;
}>();
const emit = defineEmits<{
	(e: 'update:modelValue', v: Filter): void;
	(e: 'clear'): void;
}>();

const localFilter = ref<Filter>({ val: null, type: FilterMatchMode.EQUALS });

const menu = ref<any>();

const openMenu = (e: MouseEvent) => {
	localFilter.value = { ...props.modelValue };
	menu.value?.toggle(e);
};

const clear = () => {
	emit('clear');
	menu.value?.hide();
};

const save = () => {
	menu.value?.toggle();
	emit('update:modelValue', localFilter.value);
};
</script>

<template>
	<div class="tw3-m-auto">
		<Button
			:aria-controls="ID_PREFIX + id"
			aria-haspopup="true"
			:disabled="loading"
			:icon="icon"
			:loading="loading"
			rounded
			severity="secondary"
			text
			type="button"
			@click="openMenu"
		/>
		<Menu
			:id="ID_PREFIX + id"
			ref="menu"
			aria-modal="true"
			popup
			role="dialog"
			@hide="() => {}"
		>
			<template #start>
				<div
					class="tw3-flex tw3-flex-col tw3-gap-[0.5rem] tw3-min-w-[11rem] tw3-mb-[0.25rem] tw3-mx-[0.75rem] tw3-mt-[0.75rem]"
				>
					<div class="tw3-flex tw3-flex-col tw3-gap-[0.5rem]">
						<JobTableFilters
							:id="id"
							key="JobTableFiltersModal"
							v-model="localFilter.val"
							v-model:type="localFilter.type"
							:name="name"
						/>
					</div>

					<div class="tw3-flex tw3-items-center tw3-justify-between">
						<Button
							label="Clear"
							outlined
							size="small"
							type="button"
							@click="clear"
						/>
						<Button label="Apply" size="small" type="button" @click="save" />
					</div>
				</div>
			</template>
		</Menu>
	</div>
</template>
