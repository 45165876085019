import { Address } from '@/entities/address/lib/types';
import { CORE_API } from '@/shared/api';

import {
	VendorRequestParams,
	VendorResponse,
	VendorTable
} from '../model/types';

const VENDOR_URL = '/unum/vendor';
const EXPORT_VENDOR_URL = '/unum/vendor/list/export';

export const getMany = async (
	params: VendorRequestParams
): Promise<VendorResponse<VendorTable>> => {
	try {
		const response = await CORE_API.post<VendorResponse<VendorTable>>(
			`${VENDOR_URL}/list`,
			params
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const exportMany = async (
	params: VendorRequestParams
): Promise<{
	csvExport: string;
	csvUrl: string[];
}> => {
	try {
		const response = await CORE_API.post<{
			csvExport: string;
			csvUrl: string[];
		}>(EXPORT_VENDOR_URL, params);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getAddress = async (
	id: number,
	addressId: number
): Promise<Address> => {
	try {
		const response = await CORE_API.get<Address>(
			`${VENDOR_URL}/list/${id}/detail/g_address/${addressId}`
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};
