<script setup lang="ts">
// General message dialog to show user messages that are too long or too important to be shown in a toast
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const visible = defineModel<boolean>('visible', { required: true });
defineProps<{
	header: string;
	message: string;
}>();
</script>

<template>
	<Dialog
		v-model:visible="visible"
		appendTo="#vue3app"
		:header="header"
		modal
		:style="{ width: '25rem' }"
	>
		<div class="flex items-center gap-4 mb-4">
			{{ message }}
		</div>
		<div class="flex justify-end gap-2">
			<Button
				:label="t('Close')"
				severity="secondary"
				type="button"
				@click="visible = false"
			></Button>
		</div>
	</Dialog>
</template>
