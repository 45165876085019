<script setup lang="ts">
import { startCase, upperCase } from 'lodash-es';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable, { DataTableSortEvent } from 'primevue/datatable';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useStorageStore } from '@/features/columnSettings/model/store';
import UpdateColumns from '@/features/columnSettings/UpdateColumns.vue';
import { isEmptyValue } from '@/shared/helpers';
import GbDefaultCell from '@/shared/ui/table/GbDefaultCell.vue';
import StatusBadge from '@/shared/ui/table/StatusBadge.vue';

import { TABLE_ID, vendorsHeader } from '../config';
import {
	VendorInvitationStatus,
	VendorStatus,
	VendorType
} from '../model/enums';
import { useVendorsStore } from '../model/store';
import {
	getVendorColorByInvitationStatus,
	getVendorColorByType
} from '../util';

const invitationStatusOptions = Object.values(VendorInvitationStatus).map(
	v => ({
		id: v,
		name: startCase(v)
	})
);
const statusOptions = Object.values(VendorStatus).map(v => ({
	id: v,
	name: startCase(v)
}));
const typeOptions = Object.values(VendorType).map(v => ({
	id: v,
	name: startCase(v)
}));

const emits = defineEmits<{
	(e: 'on-edit', id: string): void;
	(e: 'on-open-address', id: number, addressId: number): void;
}>();

const { t } = useI18n();
const store = useVendorsStore();
const storage = useStorageStore();
const { tableLoading, shownItems, loadingMore, preventFetchMore } =
	storeToRefs(store);

const showColumnSettings = ref(false);

const selectedColumns = computed(() =>
	storage.vendorColumns.length ? storage.vendorColumns : vendorsHeader
);

const getFilterIconClass = (field: string) => {
	const filter = store.filters[field];

	return isEmptyValue(filter.val) || filter.val === 'all'
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const handleSort = (state: DataTableSortEvent) => {
	store.handleSort({
		desc: state.sortOrder === 1,
		field: state.sortField as string
	});
};

onMounted(() => {
	store.vendors.execute(0, store.composeGetCustomersArgs(0));

	const table = document.getElementById(TABLE_ID);

	if (table) {
		table.addEventListener('scroll', () => {
			if (
				table.scrollTop + table.clientHeight >= table.scrollHeight - 100 &&
				!tableLoading.value &&
				!loadingMore.value &&
				!preventFetchMore.value
			) {
				store.fetchMore();
			}
		});
	}
});

onUnmounted(() => {
	const table = document.getElementById(TABLE_ID);

	if (table) {
		table.removeEventListener('scroll', () => {
			if (
				table.scrollTop + table.clientHeight >= table.scrollHeight - 100 &&
				!tableLoading.value &&
				!loadingMore.value &&
				!preventFetchMore.value
			) {
				store.fetchMore();
			}
		});
	}
});
</script>

<template>
	<DataTable
		v-model:filters="store.filters"
		dataKey="id"
		filterDisplay="menu"
		lazy
		:loading="tableLoading"
		pt:emptymessagecell:class="tw3-h-full"
		pt:mask:class="!tw3-bg-transparent"
		:pt:tableContainer:id="TABLE_ID"
		scrollable
		scrollHeight="flex"
		size="small"
		:sortField="store.sort.field"
		:sortOrder="store.sort.desc === 'desc' ? 1 : -1"
		:tableClass="
			'tw3-max-w-full ' +
			(tableLoading ? 'p-datatable-gb-loading ' : '') +
			(shownItems.length ? '' : '!tw3-h-full')
		"
		:value="shownItems"
		@sort="handleSort"
	>
		<template #loading> </template>

		<template #header>
			<div class="tw3-flex tw3-items-center tw3-justify-between">
				<div
					class="tw3-flex tw3-items-center tw3-gap-4 tw3-w-full sm:tw3-w-auto"
				>
					<Button
						:label="$t('export')"
						:loading="store.exportingLoading"
						raised
						size="small"
						text
						@click="store.exportData"
					/>
					<div
						class="tw3-text-gray-600 tw3-text-xl tw3-font-semibold tw3-ml-0 sm:tw3-ml-auto lg:tw3-ml-0"
					>
						{{ $t('count') }}: {{ store.vendors.state.count }}
					</div>
				</div>
				<Button
					icon="pi pi-objects-column"
					:loading="store.exportingLoading"
					severity="secondary"
					size="small"
					@click="() => (showColumnSettings = !showColumnSettings)"
				/>
			</div>

			<UpdateColumns
				v-model:show="showColumnSettings"
				:modelValue="selectedColumns"
				:options="vendorsHeader"
				@save="storage.updateVendorColumns"
			/>
		</template>
		<Column
			v-for="column of selectedColumns"
			:key="column.key"
			:field="column.key"
			:filterField="column.key"
			:header="column.key === 'edit' ? undefined : column.title"
			headerClass="tw3-whitespace-nowrap tw3-border-b"
			pt:headerCell:class="tw3-border-b"
			:showFilterMatchModes="false"
			:showFilterMenu="column.key !== 'edit'"
			:sortable="column.key !== 'edit'"
		>
			<template #filtericon>
				<i :class="getFilterIconClass(column.key)"></i>
			</template>
			<template #filter="{ filterModel }">
				<InputNumber
					v-if="column.key === 'id'"
					allowEmpty
					class="p-column-filter"
					:modelValue="filterModel.val"
					:placeholder="`Search by ${column.title}`"
					type="number"
					:useGrouping="false"
					@update:model-value="v => (filterModel.val = v ? String(v) : v)"
				/>
				<InputMask
					v-else-if="column.key === 'fax' || column.key === 'phone'"
					allowEmpty
					class="p-column-filter"
					mask="(999) 999-9999"
					:modelValue="filterModel.val"
					:placeholder="`Search by ${column.title}`"
					type="text"
					:useGrouping="false"
					@update:model-value="v => (filterModel.val = v ? String(v) : v)"
				/>
				<MultiSelect
					v-else-if="column.key === 'vendor_type'"
					v-model="filterModel.val"
					class="p-column-filter"
					:maxSelectedLabels="2"
					optionLabel="name"
					:options="typeOptions"
					optionValue="id"
					:placeholder="`Search by ${column.title}`"
					:showToggleAll="false"
				/>
				<Select
					v-else-if="column.key === 'status'"
					class="p-column-filter"
					:modelValue="filterModel.val"
					optionLabel="name"
					:options="statusOptions"
					optionValue="id"
					:placeholder="`Search by ${column.title}`"
					@update:model-value="
						v => (filterModel.val = v === VendorStatus.all ? null : v)
					"
				/>
				<Select
					v-else-if="column.key === 'invitation_status'"
					class="p-column-filter"
					:modelValue="filterModel.val"
					optionLabel="name"
					:options="invitationStatusOptions"
					optionValue="id"
					:placeholder="`Search by ${column.title}`"
					@update:model-value="
						v => (filterModel.val = v === VendorInvitationStatus.all ? null : v)
					"
				/>
				<InputText
					v-else
					v-model="filterModel.val"
					class="p-column-filter"
					:placeholder="`Search by ${column.title}`"
					type="text"
				/>
			</template>
			<template #filterclear="{ filterModel, filterCallback }">
				<Button
					label="Clear"
					outlined
					size="small"
					@click="
						() => {
							filterModel.val = null;
							filterCallback();
						}
					"
				/>
			</template>
			<template #body="{ data }">
				<GbDefaultCell :loading="!data">
					<Button
						v-if="column.key === 'edit'"
						aria-label="Edit"
						severity="secondary"
						size="small"
						text
						type="button"
						@click="emits('on-edit', data['id'])"
					>
						<template #icon>
							<i class="material-icons md-edit" />
						</template>
					</Button>
					<a
						v-else-if="column.key === 'id'"
						href="#"
						@click="emits('on-edit', data[column.key])"
					>
						{{ data[column.key] }}
					</a>
					<div
						v-else-if="
							column.key === 'status' ||
							column.key === 'vendor_type' ||
							column.key === 'invitation_status'
						"
						class="tw3-flex-1 tw3-flex tw3-justify-center tw3-items-center"
					>
						<StatusBadge
							v-if="column.key === 'status'"
							:class="
								!data[column.key] || data[column.key] === VendorStatus.inactive
									? 'tw3-bg-gray-500'
									: 'tw3-bg-green-500'
							"
							:text="data[column.key]"
						/>
						<StatusBadge
							v-else-if="column.key === 'invitation_status' && data[column.key]"
							:style="`background-color: ${getVendorColorByInvitationStatus(data[column.key])};`"
							:text="startCase(data[column.key])"
						/>
						<StatusBadge
							v-else-if="column.key === 'vendor_type' && data[column.key]"
							:style="`background-color: ${getVendorColorByType(data[column.key])};`"
							:text="
								data[column.key] === VendorType.csr
									? upperCase(data[column.key])
									: startCase(data[column.key])
							"
						/>
					</div>
					<div
						v-else-if="column.key === 'notes'"
						v-tooltip.top="data[column.key]"
						class="tw3-whitespace-nowrap tw3-max-w-64 tw3-truncate"
					>
						{{ data[column.key] }}
					</div>
					<a
						v-else-if="column.key === 'email'"
						class="tw3-whitespace-nowrap"
						:href="'mailto:' + data[column.key]"
					>
						{{ data[column.key] }}
					</a>
					<a
						v-else-if="column.key === 'phone'"
						class="tw3-whitespace-nowrap"
						:href="'tel:' + data[column.key]"
					>
						{{ data[column.key] }}
					</a>
					<a
						v-else-if="column.key === 'full_address'"
						class="tw3-whitespace-nowrap"
						href="#"
						@click="emits('on-open-address', data['id'], data['g_address_id'])"
					>
						{{ data[column.key] }}
					</a>
					<div
						v-else
						class="tw3-whitespace-nowrap"
						:class="{
							'tw3-font-bold':
								column.key === 'name_first' || column.key === 'name_last'
						}"
					>
						{{ data[column.key] }}
					</div>
				</GbDefaultCell>
			</template>
		</Column>

		<template #empty>
			<div class="tw3-flex tw3-w-full">
				<p
					v-if="!tableLoading"
					class="tw3-sticky tw3-top-1/2 tw3-left-1/2 tw3-text-3xl tw3-translate-x-[-50%] tw3-translate-y-[-50%]"
				>
					{{ t('noData') }}
				</p>
			</div>
		</template>
	</DataTable>
</template>
