import { useAsyncState } from '@vueuse/core';
import { Settings } from 'luxon';
import { defineStore } from 'pinia';
import { computed, watchEffect } from 'vue';

import { OrganizationShortInfo } from '@/entities/organizations/lib/types';

import { getAccountPackages, getOne } from '../api/api';

export const useUserStore = defineStore('entitiesUser', () => {
	const userState = useAsyncState(
		() => {
			return getOne();
		},
		{
			user: {
				admin: 0,
				id: 0,
				account_id: 0,
				shop_id: 0,
				account_manager: 0,
				username: '',
				picture: null,
				color: null,
				nags_user: null,
				timezone: 'local'
			},
			shops: [],
			organizations: [],
			roles: []
		},
		{
			immediate: true,
			shallow: false
		}
	);

	watchEffect(() => {
		// Use the user's timezone as the default timezone
		Settings.defaultZone = userState.state.value.user.timezone || 'local';
	});

	const accountPackagesState = useAsyncState(
		() => {
			return getAccountPackages();
		},
		[],
		{
			immediate: true,
			shallow: false
		}
	);
	watchEffect(() => {
		// Use the user's timezone as the default timezone
		Settings.defaultZone = userState.state.value.user.timezone || 'local';
	});

	const defaultOrganization = computed(() => {
		const defaultShop = userState.state.value.shops.find(
			(shop: any) => shop.id === userState.state.value.user.shop_id
		);
		const res = userState.state.value.organizations?.find(
			(organization: OrganizationShortInfo) =>
				organization.id == defaultShop?.organization_id
		);
		return res;
	});

	const getDefaultOrganizationShop = (organizationId: number) => {
		// Return default users shop if it belongs to the selected organization
		let shop = userState.state.value.shops.find(
			shop =>
				shop.id === userState.state.value.user.shop_id &&
				shop.organization_id === organizationId
		);

		// If not, return the first shop of the organization
		if (!shop) {
			shop = userState.state.value.shops.find(
				shop => shop.organization_id === organizationId
			)!;
		}

		return shop;
	};

	const isAdmin = computed(() => !!userState.state.value.user.admin);

	const isAccountManager = computed(
		() => !!userState.state.value.user.account_manager
	);

	const checkUserCan = (roleName: string) => {
		if (roleName === 'account_manager') {
			return !!userState.state.value.user.account_manager;
		}
		if (roleName === 'admin') return !!userState.state.value.user.admin;
		if (!userState.state.value.roles.length) return false;
		const index = userState.state.value.roles.findIndex(
			v => v.name === roleName
		);
		return index !== -1;
	};

	const checkUserCanNot = (roleName: string | number) => {
		const cannot = !userState.state.value.roles.find(
			role => role.role_id === roleName || role.name === roleName
		);
		return cannot;
	};

	return {
		accountPackagesState,
		user: userState.state,
		loadUser: userState.execute,
		loading: userState.isLoading,
		defaultOrganization,
		getDefaultOrganizationShop,
		isAdmin,
		isAccountManager,
		checkUserCan,
		checkUserCanNot
	};
});
