<script setup lang="ts">
import Card from 'primevue/card';
import { defineAsyncComponent, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import GbDefaultBreadcrumb from '@/shared/ui/GbDefaultBreadcrumb.vue';

import { useJobEditCellStore } from './model/store';
import JobsList from './ui/JobsList.vue';
import JobEditCellDialogs from './ui/modals/edit/JobEditCellDialogs.vue';

const JobEditTable = defineAsyncComponent(
	() => import('./ui/modals/JobEditTable.vue')
);

const { t } = useI18n();

const tabs = [{ label: t('data') }, { label: t('jobs') }];

const dStore = useJobEditCellStore();

onMounted(() => (dStore.editJobViewDialog = false));
</script>

<template>
	<div
		class="tw3-w-full tw3-h-full tw3-max-w-[1440px] tw3-flex tw3-flex-col tw3-gap-[1rem] tw3-overflow-hidden tw3-mx-auto"
	>
		<GbDefaultBreadcrumb :tabs="tabs" />
		<div class="tw3-h-full tw3-flex tw3-overflow-hidden tw3-relative tw3-gap-2">
			<JobEditCellDialogs />
			<Card
				pt:body:class="tw3-overflow-auto tw3-h-full"
				pt:content:class="tw3-h-full"
				:pt:root:class="
					'tw3-w-full tw3-h-full tw3-overflow-auto table-transition' +
					(dStore.editJobViewDialog ? ' table-collapse' : '')
				"
			>
				<template #content>
					<JobsList />
				</template>
			</Card>
			<Transition name="slide-menu">
				<JobEditTable
					v-show="dStore.editJobViewDialog"
					@on-close="dStore.toggleJobViewDialog"
				/>
			</Transition>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.table-transition {
	transition: margin-right 0.4s ease;
}
.table-collapse {
	@media (min-width: 1280px) {
		margin-right: 524px;
	}
}
.slide-menu-enter-active,
.slide-menu-leave-active {
	transition:
		transform 0.4s ease,
		opacity 0.4s ease;
}
.slide-menu-enter-from,
.slide-menu-leave-to {
	transform: translateX(100%);
	opacity: 0;
}
.slide-menu-enter-to,
.slide-menu-leave-from {
	transform: translateX(0);
	opacity: 1;
}
</style>
