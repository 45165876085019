<script setup lang="ts">
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Fieldset from 'primevue/fieldset';
import InputNumber from 'primevue/inputnumber';
import Select from 'primevue/select';
import { useConfirm } from 'primevue/useconfirm';
import { defineModel, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { DamageInfo } from '@/entities/claim/lib/types';

import {
	locationOfDamage,
	locationOfDamageLables,
	nagsPositionCode,
	nagsPositionCodeLabels,
	nagsRelativeLocationCode,
	nagsRelativeLocationCodeLabels,
	nagsSideCode,
	nagsSideCodeLabels
} from './lib/constants';

import type { DataTableRowEditSaveEvent } from 'primevue/datatable';

const { t } = useI18n();

const confirm = useConfirm();

const damageInfoData = defineModel<DamageInfo[]>('damageInfoData', {
	required: true
});

const { removeClaimDamageInfo, addClaimDamageInfo } = defineProps<{
	addClaimDamageInfo: () => void;
	removeClaimDamageInfo: (index: number) => void;
	status: boolean;
}>();

const editingRows = ref<any[]>([]);

const add = () => {
	addClaimDamageInfo();
	editingRows.value = [
		...editingRows.value,
		damageInfoData.value[damageInfoData.value.length - 1]
	];
};

const onRowEditSave = (event: DataTableRowEditSaveEvent) => {
	const { newData, index } = event;
	damageInfoData.value[index] = newData;
};

const handleRowDelete = (id: number) => {
	confirm.require({
		accept: () => {
			removeClaimDamageInfo(id);
		},
		acceptLabel: t('yes'),
		acceptProps: {
			severity: 'success'
		},
		header: t('removeDamageInfoHeader'),
		message: t('removeDamageInfoMsg'),
		rejectLabel: t('no'),
		rejectProps: {
			severity: 'secondary'
		}
	});
};
</script>

<template>
	<Fieldset
		class="relative !tw3-mb-0"
		:legend="t('damage')"
		:pt="{ content: 'p-1' }"
		toggleable
	>
		<DataTable
			v-model:editingRows="editingRows"
			dataKey="id"
			editMode="row"
			size="small"
			tableStyle="tw3-w-full"
			:value="damageInfoData"
			@row-edit-save="onRowEditSave"
		>
			<template #empty>
				<div class="tw3-p-6">
					<p class="tw3-text-2xl tw3-text-center">
						Please add damage information.
					</p>
				</div>
			</template>
			<Column bodyStyle="border: 0" field="openingTypeCode" style="width: 27%">
				<template #header>
					<div class="tw3-relative">
						<span v-tooltip.top="t('damageTitles.location')"
							>{{ t('location') }}
						</span>

						<Badge
							v-tooltip.top="t('required')"
							class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
							severity="danger"
						/>
					</div>
				</template>
				<template #editor="{ data, field }">
					<Select
						v-model="data[field]"
						class="tw3-w-[195px]"
						optionLabel="label"
						:options="locationOfDamage"
						optionValue="value"
						placeholder="Select a type code"
					/>
				</template>
				<template #body="{ data, field }">
					<div class="tw3-w-[195px] tw3-px-[0.75rem]">
						{{ locationOfDamageLables[data[field]] }}
					</div>
				</template>
			</Column>

			<Column bodyStyle="border: 0" field="positionCode" style="width: 12%">
				<template #header
					><span v-tooltip.top="t('damageTitles.position')">{{
						t('position')
					}}</span></template
				>
				<template #editor="{ data, field }">
					<Select
						v-model="data[field]"
						class="tw3-w-[102px]"
						optionLabel="label"
						:options="nagsPositionCode"
						optionValue="value"
						placeholder="Select a nags position code"
					/>
				</template>
				<template #body="{ data, field }">
					<div class="tw3-w-[102px] tw3-px-[0.75rem]">
						{{ nagsPositionCodeLabels[data[field]] }}
					</div>
				</template>
			</Column>

			<Column
				bodyStyle="border: 0"
				field="relativeLocationCode"
				style="width: 20%"
			>
				<template #header
					><span v-tooltip.top="t('damageTitles.relLocation')">{{
						t('relLocation')
					}}</span></template
				>
				<template #editor="{ data, field }">
					<Select
						v-model="data[field]"
						class="tw3-w-[111px]"
						optionLabel="label"
						:options="nagsRelativeLocationCode"
						optionValue="value"
						placeholder="Select a nags relative location code"
					/>
				</template>
				<template #body="{ data, field }">
					<div class="tw3-w-[111px] tw3-px-[0.75rem]">
						{{ nagsRelativeLocationCodeLabels[data[field]] }}
					</div>
				</template>
			</Column>

			<Column bodyStyle="border: 0" field="sideCode" style="width: 12%">
				<template #header
					><span v-tooltip.top="t('damageTitles.side')">{{
						t('side')
					}}</span></template
				>
				<template #editor="{ data, field }">
					<Select
						v-model="data[field]"
						class="tw3-w-[106px]"
						optionLabel="label"
						:options="nagsSideCode"
						optionValue="value"
						placeholder="Select a nags position code"
					/>
				</template>
				<template #body="{ data, field }">
					<div class="tw3-w-[106px] tw3-px-[0.75rem]">
						{{ nagsSideCodeLabels[data[field]] }}
					</div>
				</template>
			</Column>

			<Column
				bodyStyle="border: 0"
				field="glassDamageQuantity"
				style="width: 10%"
			>
				<template #header>
					<div class="tw3-relative">
						<span v-tooltip.top="t('damageTitles.qty')">{{ t('qty') }} </span>
						<Badge
							v-tooltip.top="t('required')"
							class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
							severity="danger"
						/>
					</div>
				</template>
				<template #editor="{ data, field }">
					<div class="tw3-w-[65px]">
						<InputNumber
							v-model="data[field]"
							:inputStyle="{ width: '65px' }"
							inputId="minmax-buttons"
							:max="9"
							:min="1"
							mode="decimal"
							showButtons
						/>
					</div>
				</template>
				<template #body="{ data, field }">
					<div class="tw3-w-[65px] tw3-text-right">
						{{ data[field] }}
					</div>
				</template>
			</Column>

			<Column
				v-if="!status"
				bodyStyle="display:flex; border: 0; width: 80px"
				rowEditor
			/>

			<Column bodyStyle="border: 0" field="actions" header="" style="width: 5%">
				<template #header>
					<div class="tw3-flex">
						<Button
							v-if="!status"
							aria-label="Settings"
							icon="pi pi-plus"
							rounded
							size="small"
							text
							type="button"
							@click="add"
						/>
					</div>
				</template>

				<template #body="{ index }">
					<div
						class="tw3-flex tw3-flex-col tw3-items-center tw3-justify-center"
					>
						<Button
							v-if="index !== 0 && !status"
							aria-label="Delete"
							class="tw3-w-8 tw3-h-8"
							:disabled="status"
							icon="pi pi-trash"
							rounded
							severity="danger"
							text
							@click="handleRowDelete(index)"
						/>
					</div>
				</template>
			</Column>
		</DataTable>
	</Fieldset>
</template>
