<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import ToggleButton from 'primevue/togglebutton';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/store';
import { useWindowSizes } from '@/shared/composables';
import { capitalizeStr } from '@/shared/helpers';

import {
	acrualCashOptions,
	customerTypes,
	licenseTypes,
	paidStatus,
	workTypeOptions,
	xActionTypeOptions
} from '../model/constants';
import useReportsStore from '../model/store';
import { reportsGetters } from '../model/utils/getters';

import ReportsContractorFilter from './reportsFilters/ReportsContractorFilter.vue';
import ReportsCustomerFilter from './reportsFilters/ReportsCustomerFilter.vue';
import ReportsDateFilter from './reportsFilters/ReportsDateFilter.vue';
import ReportsSelectFilter from './reportsFilters/ReportsSelectFilter.vue';
import ReportsShopDataFilter from './reportsFilters/ReportsShopDataFilter.vue';
import ReportsShareButtons from './ReportsShareButtons.vue';

defineProps<{
	hasPermission: boolean;
}>();

const reportsStore = useReportsStore();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const {
	currentReportType,
	selectedShop,
	jobTypesList,
	selectedJobType,
	showJobFilter,
	selectedCustomerType,
	showCustomerTypeFilter,
	selectedLicenseTypes,
	showLicenseTypesFilter,
	selectedPaidStatus,
	showPaidStatusFilter,
	showDateFilter,
	tableMode,
	tableModeOptions,
	showTableMode,
	showShopDataFilter,
	showPaymentsFilter,
	paymentsFilterValue,
	showContractorFilter,
	acrualCashFilterValue,
	showAcrualCashFilter,
	showAccountingFilter,
	accountingFilterValue,
	accountingPackages,
	accountingPackagesLoading,
	showCustomerFilter,
	selectedWorkType,
	showWorkTypeFilter,
	reportDataIsLoading,
	reportData,
	expandAllAgingPayments,
	allowedToGenerateReport,
	includeAdjustments
} = storeToRefs(reportsStore);

const { isTablet, isMobile, isPc } = useWindowSizes();

function getReport(rightPartAdmin: boolean) {
	reportsStore.getReportHandler.execute(0, rightPartAdmin);
}
const { t } = useI18n();

const breadcrumbs = computed(() => {
	const initialBreadcrumbs = [
		{
			path: '/reports',
			label: t('reports')
		},
		{
			label: currentReportType.value.label
		}
	];

	return initialBreadcrumbs;
});

const showPlaceholder = computed(() => {
	if (currentReportType.value.group === 'tax') {
		return !showShopDataFilter || !currentReportType.value.date;
	} else if (showCustomerFilter) {
		return !currentReportType.value.date;
	}
	return false;
});
</script>

<template>
	<div
		class="tw3-flex tw3-flex-col tw3-w-full"
		:class="{
			'tw3-h-full': !hasPermission
		}"
	>
		<div
			class="tw3-flex tw3-mx-auto tw3-w-full tw3-items-start tw3-flex-col tw3-justify-between tw3-max-w-[1440px] tw3-mb-[1rem] tw3-gap-4 md:tw3-flex-row"
		>
			<nav
				class="layout-breadcrumb tw3-text-gray-500 tw3-flex tw3-items-center tw3-justify-between"
			>
				<ol>
					<li>
						<RouterLink class="tw3-cursor-pointer tw3-transition-all" to="/">
							<i class="pi pi-home"></i>
						</RouterLink>
					</li>
					<li class="layout-breadcrumb-chevron">/</li>
					<template
						v-for="(breadcrumbRoute, i) in breadcrumbs"
						:key="breadcrumbRoute"
					>
						<li>
							<RouterLink
								v-if="breadcrumbRoute.path"
								class="tw3-cursor-pointer tw3-transition-all"
								:to="breadcrumbRoute.path"
							>
								{{ capitalizeStr(breadcrumbRoute.label) }}
							</RouterLink>
							<template v-else>
								{{ capitalizeStr(breadcrumbRoute.label) }}
							</template>
						</li>
						<li
							v-if="i !== breadcrumbs.length - 1"
							class="layout-breadcrumb-chevron"
						>
							/
						</li>
					</template>
				</ol>
			</nav>
		</div>
		<Card
			class="tw3-w-full tw3-relative"
			:class="{
				'tw3-h-full': !hasPermission
			}"
			:pt="{
				body: {
					class: '!tw3-p-4 tw3-h-full tw3-w-full'
				},
				content: {
					class: 'tw3-h-full tw3-w-full'
				}
			}"
		>
			<template #content>
				<div class="tw3-flex tw3-flex-col tw3-gap-6 tw3-h-full">
					<template v-if="hasPermission">
						<Tag
							v-if="
								currentReportType.label === 'Profit and Loss' ||
								currentReportType.label === 'Balance Sheet'
							"
							class="tw3-absolute tw3-right-[-5px] tw3-top-[-10px]"
							severity="info"
							value="Beta!"
						/>
					</template>
					<template v-if="hasPermission">
						<div
							class="filter-container tw3-flex tw3-flex-col lg:tw3-flex-row tw3-w-full"
							:class="{
								'tw3-items-end': !showTableMode
							}"
						>
							<div
								class="tw3-flex tw3-flex-col tw3-w-full lg:tw3-w-19/12 md:tw3-grid md:tw3-grid-cols-2 lg:tw3-grid-cols-4 tw3-gap-5 tw3-h-full"
								:class="{
									'tw3-relative tw3-pb-4': showContractorFilter && isPc
								}"
							>
								<div
									v-if="currentReportType.hideShopSelect !== false"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
									>
										{{ capitalizeStr(t('shops')) }}
										<Badge
											v-if="reportsStore.isRequired('shop')"
											v-tooltip.top="t('required')"
											class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
											severity="danger"
										/>
									</p>
									<ReportsSelectFilter
										v-model="selectedShop"
										:dropdownOptions="user.shops"
										optionLabel="name"
										optionValue="id"
										:placeholder="`${t('select')} shop...`"
										:required="reportsStore.isRequired('shop')"
										:singleSelection="
											currentReportType.group === 'royalty' ||
											currentReportType.id === 'aging'
										"
									/>
								</div>
								<ReportsDateFilter v-if="showDateFilter" />

								<span
									v-if="showPlaceholder && currentReportType.id !== 'aging'"
								></span>

								<div
									v-if="showAccountingFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
									>
										{{
											capitalizeStr(t('reportsPage.filters.accountingPackage'))
										}}
										<Badge
											v-if="reportsStore.isRequired('accounting')"
											v-tooltip.top="t('required')"
											class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
											severity="danger"
										/>
									</p>
									<ReportsSelectFilter
										v-model="accountingFilterValue"
										:dropdownOptions="accountingPackages"
										:loading="accountingPackagesLoading"
										optionLabel="name"
										:placeholder="`${t('select')} ${t('reportsPage.filters.accountingPackage').toLocaleLowerCase()}...`"
									/>
								</div>

								<ReportsShopDataFilter v-if="showShopDataFilter" />

								<div
									v-if="currentReportType.id === 'margin'"
									class="tw3-flex tw3-flex-col"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
									>
										Include Adjustments
									</p>
									<ToggleButton
										v-model="includeAdjustments"
										class="tw3-w-fit"
									/>
								</div>

								<div
									v-if="showJobFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
									>
										{{
											capitalizeStr(t('reportsPage.filters.jobTypes.jobTypes'))
										}}
										<Badge
											v-if="reportsStore.isRequired('jobTypes')"
											v-tooltip.top="t('required')"
											class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
											severity="danger"
										/>
									</p>
									<ReportsSelectFilter
										v-if="showJobFilter"
										v-model="selectedJobType"
										:dropdownOptions="jobTypesList"
										optionLabel="name"
										optionValue="id"
										:placeholder="`${t('select')} ${t('reportsPage.filters.jobTypes.jobTypes').toLocaleLowerCase()}...`"
									/>
								</div>

								<div
									v-if="showWorkTypeFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
									>
										{{ capitalizeStr(t('reportsPage.filters.workType')) }}
									</p>
									<Select
										v-model="selectedWorkType"
										optionLabel="label"
										:options="workTypeOptions"
										optionValue="value"
										:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
										singleSelection
									/>
								</div>

								<div
									v-if="showCustomerTypeFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
									>
										{{ capitalizeStr(t('reportsPage.filters.billToCustomer')) }}
									</p>
									<ReportsSelectFilter
										v-model="selectedCustomerType"
										:dropdownOptions="customerTypes"
										optionLabel="name"
										optionValue="id"
										:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
									/>
								</div>
								<div
									v-if="showLicenseTypesFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
									>
										{{ capitalizeStr(t('reportsPage.filters.licenseTypes')) }}

										<Badge
											v-if="reportsStore.isRequired('license')"
											v-tooltip.top="t('required')"
											class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
											severity="danger"
										/>
									</p>
									<ReportsSelectFilter
										v-model="selectedLicenseTypes"
										:dropdownOptions="licenseTypes"
										optionLabel="name"
										optionValue="id"
										:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
									/>
								</div>
								<div
									v-if="showPaidStatusFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
									>
										{{ capitalizeStr(t('reportsPage.filters.paidStatus')) }}
									</p>
									<ReportsSelectFilter
										v-model="selectedPaidStatus"
										:dropdownOptions="paidStatus"
										optionLabel="name"
										optionValue="id"
										:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
									/>
								</div>

								<div
									v-if="showPaymentsFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm tw3-relative"
									>
										{{ capitalizeStr(t('reportsPage.xType')) }}
										<Badge
											v-if="reportsStore.isRequired('x_action_types')"
											v-tooltip.top="t('required')"
											class="tw3-absolute !tw3-h-[0.3rem] !tw3-min-w-[0.3rem] !tw3-w-[0.3rem]"
											severity="danger"
										/>
									</p>
									<ReportsSelectFilter
										v-model="paymentsFilterValue"
										:dropdownOptions="xActionTypeOptions"
										:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
									/>
								</div>

								<span
									v-if="
										showCustomerFilter &&
										currentReportType.group !== 'statements'
									"
								></span>

								<ReportsCustomerFilter v-if="showCustomerFilter" />

								<div
									v-if="showAcrualCashFilter"
									class="tw3-w-full tw3-flex tw3-flex-col tw3-col-span-1"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
									>
										{{ capitalizeStr(t('reportsPage.filters.accrual')) }}
									</p>
									<ReportsSelectFilter
										v-model="acrualCashFilterValue"
										:dropdownOptions="acrualCashOptions"
										optionValue="value"
										:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
									/>
								</div>

								<div
									v-if="currentReportType.id === 'aging'"
									class="tw3-flex tw3-flex-col"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
									>
										Expand All Payments
									</p>
									<ToggleButton
										v-model="expandAllAgingPayments"
										class="tw3-w-fit"
									/>
								</div>

								<ReportsContractorFilter v-if="showContractorFilter" />
							</div>

							<Divider
								:layout="isMobile || isTablet ? 'horizontal' : 'vertical'"
							/>

							<div
								class="tw3-w-full lg:tw3-min-w-3/12 lg:tw3-w-3/12 tw3-gap-2 tw3-gap-y-4 tw3-flex tw3-flex-wrap tw3-items-end tw3-h-fit"
								:class="{
									'tw3-justify-end': currentReportType.group == 'right-part',
									'tw3-relative tw3-pb-4': showContractorFilter && isPc
								}"
							>
								<div
									v-if="
										currentReportType.group !== 'right-part' && showTableMode
									"
									class="tw3-flex tw3-flex-col tw3-w-4/6"
								>
									<p
										class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
									>
										{{
											capitalizeStr(t('reportsPage.filters.groupBy.groupBy'))
										}}
									</p>
									<Select
										v-model="tableMode"
										optionLabel="label"
										:options="tableModeOptions"
										optionValue="id"
										:placeholder="`${t('select')} ${t('type').toLocaleLowerCase()}...`"
										singleSelection
									/>
								</div>

								<span
									v-if="currentReportType.group == 'right-part'"
									class="tw3-w-1/6 tw3-h-[51px] tw3-flex tw3-flex-col tw3-justify-end"
								>
								</span>

								<span
									v-if="showTableMode"
									class="tw3-w-1/6 tw3-h-[51px] tw3-flex tw3-flex-col tw3-justify-end"
								>
								</span>

								<Button
									v-if="
										userStore.isAdmin &&
										currentReportType.group === 'right-part'
									"
									v-tooltip.top="{
										value: !allowedToGenerateReport
											? 'You need to fill required fields first'
											: null,
										pt: {
											text: 'tw3-text-center'
										}
									}"
									class="tw3-w-4/6"
									:disabled="!allowedToGenerateReport"
									icon="pi pi-play-circle"
									label="All Shops"
									:loading="reportDataIsLoading"
									severity="secondary"
									@click="getReport(true)"
								/>

								<div class="tw3-flex tw3-items-end tw3-gap-2 tw3-w-full">
									<div
										class="tw3-flex tw3-flex-col tw3-h-[51px] tw3-items-end tw3-justify-end"
									>
										<div class="tw3-w-fit">
											<p
												class="tw3-content-end tw3-text-slate-500 tw3-font-medium tw3-text-sm"
											>
												{{ capitalizeStr('Actions') }}
											</p>
											<div class="tw3-flex tw3-gap-2 lg:tw3-justify-end">
												<ReportsShareButtons
													:disabled="
														!(
															reportData &&
															!reportDataIsLoading &&
															!reportsGetters.emptyData(reportData)
														)
													"
												/>
												<Button
													v-tooltip.top="'Clear all filters'"
													:disabled="!reportsStore.isSomeFilterActive"
													icon="pi pi-eraser"
													severity="secondary"
													@click="reportsStore.setDefaultFilterValue()"
												/>
											</div>
										</div>
									</div>

									<Button
										v-tooltip.top="{
											value: !allowedToGenerateReport
												? 'You need to fill required fields first'
												: null,
											pt: {
												text: 'tw3-text-center'
											}
										}"
										class="tw3-w-full tw3-whitespace-nowrap"
										:disabled="!allowedToGenerateReport"
										icon="pi pi-play-circle"
										label="Run Report"
										:loading="reportDataIsLoading"
										severity="info"
										@click="getReport(false)"
									/>
								</div>
							</div>
						</div>
					</template>
					<template v-else>
						<div
							class="tw3-w-full tw3-h-full tw3-flex tw3-flex-col tw3-gap-4 tw3-items-center tw3-justify-center"
						>
							<h5 class="tw3-mt-1 tw3-mx-auto tw3-text-xl tw3-block">
								{{ t('reportsPage.filters.permissionDenied') }}
							</h5>
							<Button
								as="router-link"
								label="Go back"
								severity="contrast"
								size="large"
								to="/reports"
							/>
						</div>
					</template>
				</div>
			</template>
		</Card>
	</div>
</template>

<style lang="scss" scoped>
.layout-breadcrumb {
	ol {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
		gap: 1rem;
		flex-wrap: wrap;

		li {
			font-weight: 600;
		}
	}
}
</style>
