<script setup lang="ts">
import { vInfiniteScroll } from '@vueuse/components';
import { useDebounceFn } from '@vueuse/core'; // for debounce
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

// Props
const props = withDefaults(
	defineProps<{
		modelValue: object | null | number | string;
		dropdownOptions?: { [key: string]: any }[] | null;
		placeholder?: string;
		optionLabel?: string;
		optionValue?: string;
		loading: boolean;
		dropdownContainerClass?: string;
	}>(),
	{
		loading: false,
		modelValue: null,
		dropdownOptions: null,
		optionLabel: 'name',
		optionValue: undefined,
		placeholder: 'Search...',
		dropdownContainerClass: ''
	}
);

const emit = defineEmits(['update:modelValue', 'search', 'loadMore']);

const inputText = ref('');
const dropdownVisible = ref(false);
const dropdownRef = ref();
const selectedVariant = ref();

const debouncedSearch = useDebounceFn(() => {
	emit('search', inputText.value);
}, 500);

const closeDropdown = () => {
	dropdownVisible.value = false;
};

const selectVariant = (variant: any) => {
	let emittedValue = variant;
	if (props.optionValue && variant[props.optionValue]) {
		emittedValue = variant[props.optionValue];
	}
	emit('update:modelValue', emittedValue);
	selectedVariant.value = emittedValue;
	closeDropdown();
};
const clearSelection = () => {
	selectedVariant.value = null;
	emit('update:modelValue', null);
};

const infiniteScrollHandler = () => {
	if (props.loading) return;

	emit('loadMore');
};

const handleClick = (event: MouseEvent) => {
	const target = event.target as HTMLElement;

	if (dropdownRef.value && !dropdownRef.value.contains(target)) {
		closeDropdown();
	}
};

onMounted(() => {
	document.addEventListener('click', handleClick);
});

onBeforeUnmount(() => {
	document.removeEventListener('click', handleClick);
});

watch(inputText, newValue => {
	if (newValue) {
		debouncedSearch();
		dropdownVisible.value = true;
	} else {
		closeDropdown();
	}
});
</script>

<template>
	<div ref="dropdownRef" class="tw3-flex tw3-flex-col tw3-w-full tw3-relative">
		<IconField v-if="!selectedVariant">
			<InputText
				v-model="inputText"
				class="tw3-w-full"
				:placeholder="placeholder"
				@focus="dropdownVisible = true"
			/>
			<InputIcon v-show="loading" class="pi pi-spin pi-spinner" />
		</IconField>

		<div
			v-if="dropdownOptions && dropdownOptions?.length"
			:class="[
				'overflow-container',
				{
					'-active': dropdownVisible
				},
				dropdownContainerClass
			]"
		>
			<div
				v-infinite-scroll="[infiniteScrollHandler, { distance: 10 }]"
				class="dropdown-container"
			>
				<div
					v-for="(variant, index) in dropdownOptions"
					:key="index"
					class="dropdown-item"
					infinite-scroll-distance="10"
					@click="selectVariant(variant)"
				>
					<slot :item="variant" name="dropdownItem">{{ variant.name }}</slot>
				</div>
			</div>
		</div>

		<div v-if="selectedVariant" class="tw3-flex tw3-gap-4 tw3-w-full">
			<slot :item="selectedVariant" name="selected">
				{{ selectedVariant?.[optionLabel] || selectedVariant.name }}
			</slot>
			<i
				v-tooltip.top="'Clear selection'"
				class="pi pi-times-circle tw3-ml-auto tw3-mr-0 tw3-cursor-pointer tw3-flex tw3-items-center"
				style="font-size: 1.1rem; color: var(--p-tag-secondary-color)"
				@click="clearSelection"
			></i>
		</div>
	</div>
</template>

<style scoped>
.overflow-container {
	position: absolute;
	z-index: 99999;
	left: 0;
	top: 100%;
	margin-top: 2px;
	overflow: hidden;
	background: var(--p-multiselect-overlay-background);
	color: var(--p-multiselect-overlay-color);
	border: 1px solid var(--p-multiselect-overlay-border-color);
	border-radius: var(--p-multiselect-overlay-border-radius);
	box-shadow: var(--p-multiselect-overlay-shadow);
	transform: translateY(-5px);
	opacity: 0;
	pointer-events: none;
	width: 100%;
	transition: 0.3s;
}

.overflow-container.-active {
	opacity: 1;
	pointer-events: auto;
	transform: translateY(0);
}

.dropdown-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 14rem;
	border-radius: 5px;
	overflow-y: scroll;
}

.dropdown-item {
	width: auto;
	min-width: 100%;
}
</style>
