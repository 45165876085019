<template>
	<Dialog
		id="RightPartDetails"
		v-model:visible="showModal"
		appendTo="#vue3app"
		class="tw3-max-w-[800px] tw3-w-full"
		:header="t('reportsPage.requestDetails.title')"
		modal
	>
		<div
			v-if="!rightPartDetailsLoading && details"
			class="tw3-flex tw3-flex-col tw3-p-6 tw3-gap-4"
		>
			<div
				class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
			>
				<div
					class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
				>
					{{ t('user') }}:
				</div>
				<div class="tw3-text-lg">
					{{ details.username }}
				</div>
			</div>
			<div
				class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
			>
				<div
					class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
				>
					{{ t('reportsPage.requestDetails.reqTime') }}:
				</div>
				<div class="tw3-text-lg">
					{{ formatDate(details.createdAt) }}
				</div>
			</div>
			<div
				class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
			>
				<div
					class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
				>
					{{ t('vin') }}:
				</div>
				<div class="tw3-text-lg">
					{{ details.vin }}
				</div>
			</div>
			<template v-if="details.status != 'success'">
				<div
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('message') }}:
					</div>
					<div class="tw3-text-lg">
						{{ t('reportsPage.requestDetails.vinInvalid') }}
					</div>
				</div>
			</template>
			<template v-if="details.help_request_status === 'sent'">
				<div
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('message') }}:
					</div>
					<div class="tw3-text-lg">
						{{ t('reportsPage.requestDetails.helpReqSend') }}
					</div>
				</div>
			</template>

			<template v-if="details.result">
				<div
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('time.year') }}:
					</div>
					<div class="tw3-text-lg">
						{{ details.result.year }}
					</div>
				</div>
				<div
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('make') }}:
					</div>
					<div class="tw3-text-lg">
						{{ details.result.make }}
					</div>
				</div>
				<div
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('model') }}:
					</div>
					<div class="tw3-text-lg">
						{{ details.result.model }}
					</div>
				</div>
				<div
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('style') }}:
					</div>
					<div class="tw3-text-lg">
						{{ details.result.body }}
					</div>
				</div>
				<div
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('reportsPage.requestDetails.glassType') }}:
					</div>
					<div class="tw3-text-lg">
						{{ t('reportsPage.requestDetails.windshields') }}
					</div>
				</div>

				<template v-for="g in glass" :key="g.id">
					<div
						class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
					>
						<div
							class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
						>
							{{ t('reportsPage.requestDetails.glassType') }}:
						</div>
						<div class="tw3-flex tw3-justify-end tw3-gap-3 tw3-flex-wrap">
							<span
								v-for="value in [g.partNumber.slice(0, -1)]"
								:key="value"
								class="detail-tag"
								:style="{
									background: '#337ab7',
									color: '#fff'
								}"
							>
								{{ value }}
							</span>
						</div>
					</div>
					<div
						v-if="g.calibrations.length"
						class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
					>
						<div
							class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
						>
							{{ t('reportsPage.requestDetails.calibrations') }}:
						</div>
						<div class="tw3-flex tw3-justify-end tw3-gap-3 tw3-flex-wrap">
							<div v-for="cal in g.calibrations" :key="cal">
								{{ cal.sensor }}
								<span
									class="details-recal-tag"
									:style="{
										background: '#805ad5',
										color: '#fff'
									}"
									>{{ cal.calibrationType }}
								</span>
							</div>
						</div>
					</div>
					<div
						class="tw3-flex tw3-gap-3 tw3-justify-between tw3-pb-3 tw3-border-b tw3-border-gray-300 tw3-w-full"
					>
						<div
							class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
						>
							{{ t('reportsPage.requestDetails.dealerNum') }}:
						</div>
						<div class="tw3-flex tw3-justify-end tw3-gap-3 tw3-flex-wrap">
							<div v-for="value in g.oemPartNumbers" :key="value">
								<span
									class="details-recal-tag"
									:style="{
										background: '#299fd7',
										color: '#fff'
									}"
								>
									{{ value }}
								</span>
							</div>
						</div>
					</div>
				</template>

				<div
					v-if="glass.length == 0"
					class="tw3-flex tw3-gap-3 tw3-justify-center tw3-pb-3 tw3-border-b tw3-font-bold tw3-text-center tw3-border-gray-300 tw3-w-full"
				>
					{{ t('reportsPage.requestDetails.noGlassFound') }}...
				</div>
			</template>
		</div>
		<div v-else class="tw3-flex tw3-flex-col tw3-gap-3">
			<Skeleton v-for="item in 8" :key="item" class="mb-2"></Skeleton>
		</div>
	</Dialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Skeleton from 'primevue/skeleton';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useReportsStore from '@/widgets/reportsElements/model/store';

const { t } = useI18n();

const showModal = defineModel<boolean>('show');

const reportStore = useReportsStore();
const { rightPartDetails: details, rightPartDetailsLoading } =
	storeToRefs(reportStore);

dayjs.extend(localizedFormat);
const formatDate = (date: string) => {
	return dayjs(date).format('lll');
};

const glass = computed(() => {
	return details.value?.result?.windshields || [];
});
</script>

<style scoped>
.right-part-for-label {
	color: #909399;
}

.detail-tag {
	font-size: 14px;
	line-height: 17px;
	color: #fff;
	background: #337ab7;
	border-radius: 20px;
	padding: 0 8px;
	letter-spacing: 0.1em;
	font-weight: bold;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	height: 23px;
	position: relative;
	white-space: nowrap;
	line-height: 100%;
}

.details-recal-tag {
	font-size: 16px;
	line-height: 17px;
	color: #fff;
	background: #5d8aa8;
	border-radius: 20px;
	padding: 0 8px;
	letter-spacing: 0.1em;
	font-weight: bold;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	height: 23px;
	position: relative;
	white-space: nowrap;
	line-height: 100%;
}

.details-recal-tag .el-icon-loading {
	color: #fff;
}

.details-recal-tag span {
	color: #fff;
}

.details-recal-tag.with-click {
	cursor: pointer;
	/* transition: box-shadow 0.2s ease-in-out; */
	/* &:hover {
     box-shadow: 0 0 3px 0 currentColor;
   } */
}

.details-recal-tag.with-click .trigger {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	overflow: hidden;
}

.details-recal-tag:active {
	filter: brightness(95%);
}

.details-recal-tag i.md-playlist_add {
	margin-left: -24px;
	opacity: 0.4;
}

.details-recal-tag:hover i.md-playlist_add {
	margin-left: 0;
	opacity: 1;
}

.details-recal-tag:focus {
	outline: none;
	filter: brightness(95%);
}
</style>
