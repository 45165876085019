import { startCase } from 'lodash-es';

import { JobStatus } from '@/entities/job/model/enums';

export const jobTypes = [
	{ name: startCase(JobStatus.invoice), id: JobStatus.invoice },
	{ name: startCase(JobStatus.quote), id: JobStatus.quote },
	{ name: startCase(JobStatus.void), id: JobStatus.void },
	{ name: startCase(JobStatus.workOrder), id: JobStatus.workOrder },
	{ name: startCase(JobStatus.warranty), id: JobStatus.warranty },
	{
		name: startCase(JobStatus.warrantyComplete),
		id: JobStatus.warrantyComplete
	},
	{ name: startCase(JobStatus.recal), id: JobStatus.recal },
	{ name: startCase(JobStatus.recalComplete), id: JobStatus.recalComplete },
	{ name: startCase(JobStatus.callBack), id: JobStatus.callBack },
	{
		name: startCase(JobStatus.callBackComplete),
		id: JobStatus.callBackComplete
	},
	{ name: 'R & I', id: JobStatus.rAndI },
	{ name: 'R & I Complete', id: JobStatus.rAndIComplete },
	{ name: startCase(JobStatus.tint), id: JobStatus.tint },
	{ name: startCase(JobStatus.tintComplete), id: JobStatus.tintComplete },
	{ name: startCase(JobStatus.pickUp), id: JobStatus.pickUp },
	{ name: startCase(JobStatus.pickUpComplete), id: JobStatus.pickUpComplete },
	{ name: startCase(JobStatus.dropOff), id: JobStatus.dropOff },
	{ name: startCase(JobStatus.dropOffComplete), id: JobStatus.dropOffComplete }
];
