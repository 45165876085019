import { useAsyncState } from '@vueuse/core';
import { cloneDeep, differenceBy, isEqual } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUserStore } from '@/entities/user/store';
import { useMessages, useTableLoadingMore } from '@/shared/composables';

import {
	createOrUpdateJobListView,
	deleteJobListView,
	exportJobs,
	getFiltersForJobs,
	getJobslistViews,
	getMany,
	getOne
} from '../../api';
import {
	allColumnsTableConf,
	defaultTableConf,
	ITEMS_PER_REQUEST,
	JOB_TABLE_CONFIG,
	JOBS_FILTERS,
	TABLE_ID
} from '../../config';
import {
	composeFilterByKeyForApi,
	composeFilterByKeyFromApi,
	composeFilterForApiRequest
} from '../../utils';

type FilterKey = keyof typeof JOBS_FILTERS;

export const useJobsStore = defineStore('pagesJobs', () => {
	const { t } = useI18n();
	const message = useMessages();
	const userStore = useUserStore();

	const initView = ref();
	const view = reactive({
		defaultView: -3,
		selectedView: defaultTableConf.id,
		selectedViewName: defaultTableConf.name,
		sort: { desc: true, field: 'job_id' },
		filters: { ...JOBS_FILTERS },
		selectedColumns: [...defaultTableConf.columns]
	});
	const viewChanges = ref(false);

	const selectedFilters = computed({
		get: () => {
			const filteredFields: { name: string; id: string }[] = [];

			Object.entries(view.filters).forEach(([key, { val }]) => {
				const field = view.selectedColumns.find(f => f.key === key);
				if (val && field) {
					filteredFields.push({
						name: field.label,
						id: field.key
					});
				}
			});

			selectedFiltersLocal.value = filteredFields;
			return filteredFields;
		},
		set: (filteredFields: { name: string; id: string }[]) => {
			const newFilters = { ...JOBS_FILTERS };
			filteredFields.map(v => {
				newFilters[v.id as FilterKey] = view.filters[v.id as FilterKey];
			});

			view.filters = newFilters;
			refreshData();
		}
	});
	const selectedFiltersLocal = ref<{ name: string; id: string }[]>([]);

	const updateViewApiLoading = ref(false);
	const updateViewLoading = ref(false);
	const exportingLoading = ref(false);
	const loadingMore = ref(false);
	const preventFetchMore = ref(false);

	const exportMode = ref(false);
	const exportAllColumnsSelected = computed(() =>
		view.selectedColumns.every(col => !col.dont_export)
	);
	const exportAllRowsSelected = ref(false);
	const exportRows = ref<number[]>([]);

	const viewOptions = computed(() => [
		{ id: defaultTableConf.id, name: defaultTableConf.name },
		{ id: allColumnsTableConf.id, name: allColumnsTableConf.name },
		...jobFiltersData.state.value.views.map(({ id, name }) => ({
			id,
			name: name ?? ''
		}))
	]);

	const tableLoading = computed(
		() =>
			jobsAsync.isLoading.value ||
			jobFiltersData.isLoading.value ||
			updateViewLoading.value
	);

	const { items, shownItems, initCb, moreCb } = useTableLoadingMore(
		tableLoading,
		preventFetchMore
	);

	const onSelectedFiltersMultiselectHide = () => {
		const changed = differenceBy(
			selectedFilters.value,
			selectedFiltersLocal.value,
			'id'
		);
		if (!changed.length) return;

		const newFilters = { ...JOBS_FILTERS };

		selectedFiltersLocal.value.map(v => {
			Object.assign(newFilters, { [v.id]: view.filters[v.id as FilterKey] });
		});

		updateFilters(newFilters);
	};

	const jobsAsync = useAsyncState(
		async () => {
			const table = document.getElementById(TABLE_ID);
			if (table) table.scrollTop = 0;
			const args = composeGetArgs(0);
			const data = await getMany(args).catch(error => {
				message.showError(error);
				return { rows: [] };
			});
			preventFetchMore.value = data.rows.length < ITEMS_PER_REQUEST;
			initCb(data.rows);
			return data;
		},
		{ rows: [] },
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const fetchMore = async () => {
		loadingMore.value = true;

		Promise.resolve()
			.then(() => {
				const args = composeGetArgs(items.value.length - 3);
				return getMany(args);
			})
			.then(data => {
				preventFetchMore.value = data.rows.length < ITEMS_PER_REQUEST;
				moreCb(data.rows);
			})
			.catch(message.showError)
			.finally(() => {
				loadingMore.value = false;
			});
	};

	const updateJobById = async (id: number) => {
		const index = items.value.findIndex(v => v?.job_id === id);
		if (index === -1) return;

		const updated = await getOne(id);
		if (updated?.rows?.length === 1) {
			items.value[index] = updated.rows[0];
		}
	};

	const jobFiltersDataEmpty = computed(() => {
		return isEqual(jobFiltersData.state.value, {
			default_jobslist_view: null,
			commercialaccounts: [],
			insurancefleets: [],
			csrs: [],
			ediTradingPartners: [],
			locations: [],
			salesources: [],
			salesreps: [],
			tags: [],
			taxes: [],
			techs: [],
			views: []
		});
	});

	const jobFiltersData = useAsyncState(
		async () => {
			const data = await getFiltersForJobs().catch(error => {
				message.showError(error);
				return {
					default_jobslist_view: null,
					commercialaccounts: [],
					insurancefleets: [],
					csrs: [],
					ediTradingPartners: [],
					locations: [],
					salesources: [],
					salesreps: [],
					tags: [],
					taxes: [],
					techs: [],
					views: []
				};
			});

			if (data.default_jobslist_view) {
				const index = data.views.findIndex(
					v => v.id === data.default_jobslist_view
				);
				if (index !== -1) {
					view.defaultView = data.default_jobslist_view;
					handleChangeView(data.default_jobslist_view);
				}
			}

			return data;
		},
		{
			default_jobslist_view: null,
			commercialaccounts: [],
			insurancefleets: [],
			csrs: [],
			ediTradingPartners: [],
			locations: [],
			salesources: [],
			salesreps: [],
			tags: [],
			taxes: [],
			techs: [],
			views: []
		},
		{
			immediate: false,
			resetOnExecute: false
		}
	);

	const handleSort = (sortData: { field: string | null; desc: boolean }) => {
		if (sortData.field === null || sortData.field.trim() === '') {
			return;
		}

		view.sort = {
			desc: sortData.desc,
			field: sortData.field
		};
	};

	const composeGetArgs = (offset: number) => {
		return {
			filters: composeFilterForApiRequest(view.filters),
			limit: ITEMS_PER_REQUEST,
			page: offset / ITEMS_PER_REQUEST + 1,
			count_only: 'no',
			sortBy: view.sort.field,
			sortDesc: view.sort.desc
		};
	};

	const exportData = async () => {
		try {
			exportingLoading.value = true;
			const args = composeGetArgs(0);
			const csvColumnNames: string[] = [];
			const csvColumns: string[] = [];
			view.selectedColumns.map(v => {
				// if (!v.dont_export) {
				if (!v.hidden) {
					csvColumnNames.push(v.label);
					csvColumns.push(v.key);
				}
			});
			const filters = {
				csvAllRowsSelected: 'yes',
				csvColumnNames: csvColumnNames.join(','),
				csvColumns: csvColumns.join(','),
				csvRow_export: '',
				exportCSV: 'yes',
				filters: args.filters,
				sortBy: args.sortBy,
				sortDesc: args.sortDesc
			};
			if (exportMode.value && !exportAllRowsSelected.value) {
				Object.assign(filters, { csvRow_export: exportRows.value.join(',') });
			}

			const data = await exportJobs(filters);

			if (data.csvExport === 'yes' && data.csvUrl && data.csvUrl.length > 0) {
				window.open(data.csvUrl[0], '_blank');
			}
		} catch (error) {
			message.showError(error);
		}
		exportingLoading.value = false;
	};

	const exportSelectAllColumns = () => {
		const newValue = !exportAllColumnsSelected.value;
		view.selectedColumns.forEach(col => (col.dont_export = !newValue));
	};

	const exportSelectAllRows = () => {
		exportAllRowsSelected.value = !exportAllRowsSelected.value;
		exportRows.value = exportAllRowsSelected.value
			? items.value.filter(v => v !== undefined).map(v => v.job_id)
			: [];
	};

	const exportToggleRowSelection = (rowId: number) => {
		if (exportRows.value.includes(rowId)) {
			exportRows.value = exportRows.value.filter(id => id !== rowId);
			exportAllRowsSelected.value = false;
		} else {
			exportRows.value.push(rowId);
			exportAllRowsSelected.value =
				items.value.length === exportRows.value.length;
			// items.value.length === jobsAsync.state.value.numberOfRowsFound &&
		}
	};

	const createOrUpdateView = async (id: number) => {
		try {
			updateViewApiLoading.value = true;
			const data = await createOrUpdateJobListView(
				id < 0 ? -1 : id,
				id !== defaultTableConf.id &&
					id !== allColumnsTableConf.id &&
					id === view.defaultView,
				{
					allFilter: '',
					id,
					name: view.selectedViewName,
					sort: view.sort.field,
					sortDesc: +view.sort.desc,
					user_id: userStore.user.user.id,
					jobslistViewColumns: view.selectedColumns
						.filter(v => !v.hidden)
						.map((v, index) => ({
							key: v.key,
							id: -1,
							view_id: id,
							background_color: v.backgroundColor,
							dont_export: v.dont_export ? +v.dont_export : 0,
							order: index,
							...composeFilterByKeyForApi(
								v.key,
								view.filters[v.key as FilterKey]
							)
						}))
				}
			);
			if (!data) return;

			const jobslistViews = await getJobslistViews();
			jobFiltersData.state.value = {
				...jobFiltersData.state.value,
				views: jobslistViews
			} as typeof jobFiltersData.state.value;

			if (id < 0) {
				message.showSuccess(t('currentViewCreated'));
			} else {
				message.showSuccess(t('currentViewUpdated'));
			}

			handleChangeView(data.id);
			checkChange();
		} catch (e) {
			message.showError(e);
		} finally {
			updateViewApiLoading.value = false;
		}
	};

	const handleChangeView = (viewId: number) => {
		view.selectedView = viewId;
		updateViewLoading.value = true;

		setTimeout(() => {
			const index = jobFiltersData.state.value.views.findIndex(
				v => v.id === viewId
			);
			if (index === -1) {
				view.selectedViewName =
					viewId === defaultTableConf.id
						? defaultTableConf.name
						: allColumnsTableConf.name;
				view.selectedColumns = [
					...(viewId === defaultTableConf.id
						? defaultTableConf.columns
						: allColumnsTableConf.columns)
				];
				view.filters = { ...JOBS_FILTERS };
				view.sort = {
					desc: true,
					field: 'job_id'
				};
				initView.value = cloneDeep(view);
				updateViewLoading.value = false;
				return;
			}
			const newColumnsConfig = [];
			const jobTableMap = new Map(
				[...JOB_TABLE_CONFIG].map(config => [config.key, config])
			);

			for (const item of jobFiltersData.state.value.views[index]
				._jobslistViewColumns) {
				const tableConfig = jobTableMap.get(item.key);
				if (!tableConfig) continue;

				newColumnsConfig.push({
					key: tableConfig.key,
					label: tableConfig.label,
					sortable: tableConfig.sortable,
					hidden: false,
					backgroundColor: item.background_color ?? '',
					dont_export: !!item.dont_export
				});

				view.filters[tableConfig.key as FilterKey] = composeFilterByKeyFromApi(
					item
				) as { val: null; type: string };

				jobTableMap.delete(tableConfig.key);
			}

			for (const item of jobTableMap.values()) {
				newColumnsConfig.push({
					...item,
					hidden: true,
					backgroundColor: item.backgroundColor ?? '',
					dont_export: !!item.dont_export
				});

				view.filters[item.key as FilterKey] =
					JOBS_FILTERS[item.key as FilterKey];

				jobTableMap.delete(item.key);
			}

			view.sort = {
				desc: !!jobFiltersData.state.value.views[index].sortDesc,
				field: jobFiltersData.state.value.views[index].sort ?? 'job_id'
			};

			view.selectedViewName = jobFiltersData.state.value.views[index].name;
			view.selectedColumns = newColumnsConfig;
			initView.value = cloneDeep(view);
			checkChange();
			updateViewLoading.value = false;
		}, 100);
	};

	const deleteView = async (id: number) => {
		try {
			if (id === -1) return;

			updateViewApiLoading.value = true;
			const data = await deleteJobListView(id);
			if (!data) return;

			handleChangeView(defaultTableConf.id);
			view.defaultView = view.defaultView === id ? -3 : view.defaultView;
			jobFiltersData.state.value = {
				...jobFiltersData.state.value,
				views: jobFiltersData.state.value.views.filter(v => v.id !== id)
			} as typeof jobFiltersData.state.value;
			message.showSuccess(t('currentViewDeleted'));
		} catch (e) {
			message.showError(e);
		} finally {
			updateViewApiLoading.value = false;
		}
	};

	const checkChange = () => {
		viewChanges.value =
			view.selectedView == undefined || view.selectedView < 0
				? false
				: !isEqual(initView.value, view);
	};

	const refreshData = async () => {
		if (jobsAsync.isLoading.value) return;
		await jobsAsync.execute(0);
	};

	const updateFilters = async (v: any) => {
		view.filters = v;
		await refreshData();
	};

	watch(() => view.sort, refreshData);
	watch(
		() => [
			...view.selectedColumns,
			view.defaultView,
			view.selectedColumns,
			view.selectedViewName,
			view.sort,
			view.filters
		],
		checkChange,
		{ deep: true, immediate: true }
	);

	return {
		tableLoading,
		exportMode,
		exportAllColumnsSelected,
		exportAllRowsSelected,
		exportRows,
		exportingLoading,
		exportData,
		exportSelectAllColumns,
		exportSelectAllRows,
		exportToggleRowSelection,
		viewChanges,
		viewOptions,
		view,
		updateViewApiLoading,
		updateViewLoading,
		createOrUpdateView,
		handleChangeView,
		deleteView,
		jobFiltersDataEmpty,
		jobFiltersData: jobFiltersData.state,
		loadingFiltersData: jobFiltersData.isLoading,
		fetchFiltersData: jobFiltersData.execute,
		items,
		shownItems,
		jobsAsync,
		loading: jobsAsync.isLoading,
		loadingMore,
		preventFetchMore,
		fetchInit: jobsAsync.execute,
		fetchMore,
		refreshData,
		updateJobById,
		handleSort,
		updateFilters,
		composeGetArgs,
		selectedFilters,
		selectedFiltersLocal,
		onSelectedFiltersMultiselectHide
	};
});
