<template>
	<div
		v-if="data"
		class="statement-report-table"
		style="page-break-after: always"
	>
		<div v-if="statementData" class="statement-row">
			<div class="statement-title">
				<p class="bold">{{ data.name }}</p>
				<p>{{ data.address }}{{ ' ' }}</p>
				<p>{{ data.address_2 }}{{ ' ' }}</p>
				<p>{{ data.phones }}{{ ' ' }}</p>
			</div>
			<div class="statement-info">
				<p class="imp bold">
					{{ t('reportsPage.table.account') }}
					{{ t('reportsPage.table.statement') }}
				</p>
				<p>
					<span class="bold">{{ t('date') }}:</span> <span>{{ today }}</span>
				</p>
				<p>
					<span class="bold">{{ t('reportsPage.customer') }} ID:</span>
					<span>{{ statementData.id }}</span>
				</p>
			</div>
		</div>
		<div v-if="statementData" class="statement-row">
			<div class="statement-bill-to">
				<div class="box-header">{{ t('billTo') }}</div>
				<div class="content">
					<p class="bold">{{ statementData.name }}</p>
					<p>{{ statementData.address }}</p>
					<p>{{ statementData.address_2 }}</p>
					<p>{{ t('phone') }}: {{ statementData.phone }}</p>
					<p>{{ t('email') }}: {{ statementData.default_email }}</p>
				</div>
			</div>
			<div class="statement-account-summary">
				<div class="box-header">
					{{ t('reportsPage.table.account') }}
					{{ t('reportsPage.table.summary') }}
				</div>
				<div class="content">
					<p>
						<span class="bold">
							{{ t('reportsPage.table.statement') }} {{ t('period') }}:
						</span>
						<span
							>{{ formatDate(staticDate.startDate) }} -
							{{ formatDate(staticDate.endDate) }}</span
						>
					</p>
					<p>
						<span class="bold">{{ t('balance') }} {{ t('forward') }}:</span>
						<span>{{
							reportsGetters.formatNumber(data.balance_forward, {
								style: 'dollar'
							})
						}}</span>
					</p>
					<p>
						<span class="bold">{{ t('payments') }}:</span>
						<span>{{
							reportsGetters.formatNumber(data.total_payments, {
								style: 'dollar'
							})
						}}</span>
					</p>
					<p>
						<span class="bold">{{ t('invoices') }}:</span>
						<span>{{
							reportsGetters.formatNumber(data.total_invoices, {
								style: 'dollar'
							})
						}}</span>
					</p>
					<p>
						<span class="imp bold">
							{{ t('reportsPage.table.statement') }} {{ t('balance') }}:
						</span>
						<span>{{
							reportsGetters.formatNumber(data.total_balance, {
								style: 'dollar'
							})
						}}</span>
					</p>
				</div>
			</div>
		</div>
		<div class="statement-row">
			<table>
				<tbody>
					<tr class="head-table">
						<td class="cell">{{ t('date') }}</td>
						<td class="cell">{{ t('invoice') }} #</td>
						<td class="cell">RO # / PO #</td>
						<td class="cell">
							{{ t('description') }}
						</td>
						<td v-if="currentReportType.id === 'open-invoice'" class="cell">
							{{ t('vin') }}
						</td>
						<td
							v-if="currentReportType.id === 'open-invoice'"
							class="cell dollar"
						>
							{{ t('amount') }} {{ t('due') }}
						</td>
						<td v-else class="cell dollar">{{ t('charge') }}</td>
						<td class="cell dollar">{{ t('credit') }}</td>
						<td class="cell dollar strong">{{ t('total') }}</td>
					</tr>
					<tr
						v-if="
							data.balance_forward && currentReportType.id !== 'open-invoice'
						"
					>
						<td class="cell date-cell">
							{{ formatDate(staticDate.startDate) }}
						</td>
						<td class="cell"></td>
						<td class="cell"></td>
						<td class="cell">* {{ t('balance') }} {{ t('forward') }} *</td>
						<td class="cell"></td>
						<td class="cell"></td>
						<td class="cell dollar strong">
							{{
								reportsGetters.formatNumber(data.balance_forward, {
									style: 'dollar'
								})
							}}
						</td>
					</tr>
					<tr v-for="row in data.list_data" :key="row.id">
						<td class="cell date-cell">{{ formatDate(row.date) }}</td>
						<td class="cell">
							<a :href="`/jobs/${row.job_id || row.id}`" target="_blank"
								>{{ row.shop_id }}-{{ row.job_id || row.id }}
								{{ row.is_supplemental ? '(SUP)' : '' }}</a
							>
							<span v-if="row.paid" class="paid-label"
								>({{
									row.is_credit_memo === 1
										? translateCreditMemoPaidStatus(row.paid)
										: row.paid
								}})</span
							>
						</td>
						<td
							v-if="row.requisition_order_number && row.purchase_order_number"
							class="cell"
						>
							{{ row.requisition_order_number }} /
							{{ row.purchase_order_number }}
						</td>
						<td
							v-else-if="
								row.requisition_order_number && !row.purchase_order_number
							"
							class="cell"
						>
							{{ row.requisition_order_number }}
						</td>
						<td
							v-else-if="
								!row.requisition_order_number && row.purchase_order_number
							"
							class="cell"
						>
							/ {{ row.purchase_order_number }}
						</td>
						<td v-else class="cell"></td>
						<td class="cell">
							{{
								row.is_credit_memo === 1 ? 'Credit Memo' : validateVehicle(row)
							}}
						</td>
						<td v-if="currentReportType.id === 'open-invoice'" class="cell">
							{{ row.vin }}
						</td>
						<td
							v-if="
								currentReportType.id === 'open-invoice' &&
								statementData &&
								statementData.id === row.consumer_id
							"
							class="cell dollar"
						>
							{{
								row.is_credit_memo !== 1
									? row.total_deductible_after_payments &&
										reportsGetters.formatNumber(
											row.total_deductible_after_payments,
											{ style: 'dollar' }
										)
									: ''
							}}
						</td>
						<td
							v-else-if="
								currentReportType.id === 'open-invoice' &&
								statementData &&
								statementData.id === row.bill_to_id
							"
							class="cell dollar"
						>
							{{
								row.is_credit_memo !== 1
									? row.total_balance_after_payments &&
										reportsGetters.formatNumber(
											row.total_balance_after_payments,
											{ style: 'dollar' }
										)
									: ''
							}}
						</td>
						<td v-else class="cell dollar">
							{{
								row.is_credit_memo !== 1
									? row.total_after_taxes &&
										reportsGetters.formatNumber(row.total_after_taxes, {
											style: 'dollar'
										})
									: ''
							}}
						</td>
						<td class="cell dollar">
							{{
								row.is_credit_memo === 1
									? row.total_after_taxes &&
										reportsGetters.formatNumber(row.total_after_taxes, {
											style: 'dollar'
										})
									: row.amount &&
										reportsGetters.formatNumber(row.amount, { style: 'dollar' })
							}}
						</td>
						<td
							v-if="currentReportType.id === 'open-invoice'"
							class="cell dollar strong"
						>
							{{
								reportsGetters.formatNumber(row.total_outstanding, {
									style: 'dollar'
								})
							}}
						</td>
						<td v-else class="cell dollar strong">
							{{ reportsGetters.formatNumber(row.total, { style: 'dollar' }) }}
						</td>
					</tr>
					<tr v-if="!data.list_data.length">
						<td class="text-center" colspan="6">
							{{ t('noData') }}
						</td>
					</tr>
					<tr class="footer-table">
						<td
							class="cell statement-memo"
							:colspan="currentReportType.id === 'open-invoice' ? '5' : '4'"
						>
							* (FP) - {{ t('reportsPage.table.fp') }}, (PP) -
							{{ t('reportsPage.table.pp') }}, (UP) -
							{{ t('reportsPage.table.unpaid') }}
						</td>
						<td
							v-if="currentReportType.id === 'open-invoice'"
							class="cell dollar"
							colspan="2"
						>
							{{ t('total') }} {{ t('reportsPage.table.outstanding') }}:
						</td>
						<td v-else class="cell dollar" colspan="2">Grand Total:</td>
						<td
							v-if="currentReportType.id === 'open-invoice'"
							class="cell dollar strong"
						>
							{{
								reportsGetters.formatNumber(data.total_outstanding_balance, {
									style: 'dollar'
								})
							}}
						</td>
						<td v-else class="cell dollar strong">
							{{
								reportsGetters.formatNumber(data.total_balance, {
									style: 'dollar'
								})
							}}
						</td>
					</tr>
					<tr class="footer-table">
						<td class="cell">{{ t('aging') }}:</td>
						<td v-for="age in statementAgingColumns" :key="age" class="cell">
							{{ age }}:
							{{
								reportsGetters.formatNumber(data.aging[age] || 0, {
									style: 'dollar'
								})
							}}
						</td>
						<td
							class="cell"
							:colspan="currentReportType.id === 'open-invoice' ? '2' : '1'"
						></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { statementAgingColumns } from '@/widgets/reportsElements/model/constants';
import useReportsStore from '@/widgets/reportsElements/model/store';
import { reportsGetters } from '@/widgets/reportsElements/model/utils/getters';

const { t } = useI18n();

const props = defineProps<{
	data: object | null;
}>();

const reportStore = useReportsStore();

const { staticDate, currentReportType } = storeToRefs(reportStore);
const today = dayjs().format('MM/DD/YYYY');
const statementData = computed(() => props.data.consumer?.[0] ?? null);

const formatDate = (date: string) => {
	return dayjs(date).format('MM/DD/YYYY');
};

const translateCreditMemoPaidStatus = (val: string) => {
	if (val === 'UP') return 'UF';
	if (val === 'FP') return 'FF';
	if (val === 'PP') return 'PF';
};

const validateVehicle = (row: { vehicle: (number | string)[] }) => {
	// payment rows do not have a vehicle property
	if (Object.prototype.hasOwnProperty.call(row, 'vehicle')) {
		if (row.vehicle !== null && !row.vehicle.includes('null')) {
			return row.vehicle;
		} else {
			return t('invoice');
		}
	} else {
		return `${t('invoice')} ${t('payment')}`;
	}
};
</script>

<style scoped>
.statement-report-table {
	background: #fff;
	width: 100%;
}

.statement-report-table .statement-title,
.statement-report-table .statement-info {
	padding: 16px;
	display: inline-block;
}

.statement-report-table p {
	margin-bottom: 4px;
}

.statement-report-table .statement-title p {
	min-height: 22px;
}

.statement-report-table .statement-row {
	margin-bottom: 24px;
}

.statement-report-table .bold {
	font-weight: 600;
}

.statement-report-table .imp {
	text-decoration: underline;
}

.statement-report-table .statement-info {
	min-width: 300px;
	float: right;
}

.statement-report-table .statement-info p span:nth-child(2) {
	float: right;
}

.statement-report-table .box-header,
.statement-report-table .content {
	padding: 8px 16px;
}

.statement-report-table .date-cell {
	color: #00a736;
	font-weight: bold;
}

.statement-report-table .box-header {
	background: #d0e5ff;
	color: #3e94ff;
}

.statement-report-table .statement-bill-to {
	min-width: 300px;
	display: inline-block;
}

.statement-report-table .statement-account-summary {
	min-width: 400px;
	float: right;
}

.statement-report-table .statement-account-summary p span:nth-child(2) {
	float: right;
}

.statement-report-table table {
	width: 100%;
}

.statement-report-table .head-table td,
.statement-report-table .footer-table td {
	background: #d0e5ff;
	color: #3e94ff;
}

.statement-report-table .footer-table {
	font-size: 16px;
	font-weight: bold;
}

.statement-report-table .statement-memo {
	font-size: 10px;
}

@media (max-width: 576px) {
	.statement-report-table {
		min-width: 700px;
		transform: scale(0.8);
		transform-origin: 0 0;
	}
}
</style>
