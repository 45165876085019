export enum JobStatus {
	invoice = 'invoice',
	quote = 'quote',
	rAndI = 'rAndI',
	rAndIComplete = 'rAndIComplete',
	recal = 'recal',
	recalComplete = 'recalComplete',
	tint = 'tint',
	tintComplete = 'tintComplete',
	void = 'void',
	warranty = 'warranty',
	warrantyComplete = 'warrantyComplete',
	callBack = 'callBack',
	callBackComplete = 'callBackComplete',
	pickUp = 'pickUp',
	pickUpComplete = 'pickUpComplete',
	dropOff = 'dropOff',
	dropOffComplete = 'dropOffComplete',
	workOrder = 'workOrder'
}
