<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable, { type DataTableSortEvent } from 'primevue/datatable';
import tinycolor from 'tinycolor2';
import { onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { jobStatusConfig } from '@/entities/job/config';
import { isEmptyValue } from '@/shared/helpers';
import GbDefaultCell from '@/shared/ui/table/GbDefaultCell.vue';
import StatusBadge from '@/shared/ui/table/StatusBadge.vue';

import { JOBS_FILTERS, TABLE_ID } from '../config';
import {
	JOB_DATE_COLUMNS,
	JOB_PRICE_COLUMNS,
	JOB_VENDOR_COLUMNS
} from '../config/jobs-table-columns';
import { EdiStatus } from '../model/enums/edi-status.enum';
import { useJobEditCellStore, useJobsStore } from '../model/store';
import {
	getPartsWithColors,
	jobDollarFormatter,
	jobInstallContextColor
} from '../utils';

import JobDocumentCell from './cells/JobDocumentCell.vue';
import JobEditCell from './cells/JobEditCell.vue';
import JobNoteCell from './cells/JobNoteCell.vue';
import JobTagsCell from './cells/JobTagsCell.vue';
import JobPopoverCell from './cells/layout/JobPopoverCell.vue';
import JobTableFilters from './filters/JobTableFilters.vue';
import JobsListHeader from './JobsListHeader.vue';

const { t } = useI18n();
const store = useJobsStore();
const editStore = useJobEditCellStore();
const {
	exportMode,
	exportAllColumnsSelected,
	exportAllRowsSelected,
	exportRows,
	tableLoading,
	shownItems,
	loadingFiltersData,
	view,
	loading,
	loadingMore,
	preventFetchMore,
	updateViewLoading
} = storeToRefs(store);

const getFilterIconClass = (field: string) => {
	const filter = view.value.filters[field as keyof typeof JOBS_FILTERS];

	return filter && isEmptyValue(filter.val)
		? 'pi pi-filter'
		: 'pi pi-filter-fill';
};

const handleSort = (state: DataTableSortEvent) => {
	store.handleSort({
		desc: state.sortOrder === 1,
		field: state.sortField as string
	});
};

const transformColor = (color: string) => {
	const hsl = tinycolor(color).toHsl();
	hsl.l = 0.95;
	return tinycolor(hsl).toHslString();
};

const statusObject = (status: keyof typeof jobStatusConfig) => {
	return jobStatusConfig[status] || '';
};

onMounted(() => {
	if (store.jobFiltersDataEmpty) store.fetchFiltersData(0);
	store.fetchInit(0);

	const table = document.getElementById(TABLE_ID);

	if (table) {
		table.addEventListener('scroll', () => {
			if (
				table.scrollTop + table.clientHeight >= table.scrollHeight - 100 &&
				!tableLoading.value &&
				!loadingMore.value &&
				!preventFetchMore.value
			) {
				store.fetchMore();
			}
		});
	}
});

onUnmounted(() => {
	const table = document.getElementById(TABLE_ID);

	if (table) {
		table.removeEventListener('scroll', () => {
			if (
				table.scrollTop + table.clientHeight >= table.scrollHeight - 100 &&
				!tableLoading.value &&
				!loadingMore.value &&
				!preventFetchMore.value
			) {
				store.fetchMore();
			}
		});
	}
});
</script>

<template>
	<DataTable
		:key="TABLE_ID + '_vue'"
		dataKey="id"
		filterDisplay="menu"
		:filters="view.filters"
		lazy
		:loading="tableLoading"
		pt:emptymessagecell:class="tw3-h-full"
		pt:mask:class="!tw3-bg-transparent"
		:pt:tableContainer:id="TABLE_ID"
		scrollable
		scrollHeight="flex"
		size="small"
		:sortField="view.sort.field"
		:sortOrder="view.sort.desc ? 1 : -1"
		:tableClass="
			'tw3-max-w-full ' +
			(tableLoading ? 'p-datatable-gb-loading ' : '') +
			(shownItems.length ? '' : '!tw3-h-full')
		"
		:value="shownItems"
		@sort="handleSort"
		@update:filters="store.updateFilters"
	>
		<template #header>
			<JobsListHeader />
		</template>

		<template #loading> </template>

		<Column
			bodyStyle="z-index: 5"
			field="exportSelect"
			frozen
			:hidden="!exportMode"
		>
			<template #header>
				<div class="tw3-flex tw3-flex-col tw3-gap-2">
					<div class="tw3-flex tw3-items-center tw3-gap-2">
						<Checkbox
							binary
							:modelValue="exportAllColumnsSelected"
							@update:model-value="store.exportSelectAllColumns"
						/>
						<p class="tw3-whitespace-nowrap">All Columns</p>
					</div>
					<div class="tw3-flex tw3-items-center tw3-gap-2">
						<Checkbox
							binary
							:modelValue="exportAllRowsSelected"
							@update:model-value="store.exportSelectAllRows"
						/>
						<p class="tw3-whitespace-nowrap">All Rows</p>
					</div>
				</div>
			</template>
			<template #body="{ data }">
				<div class="cell-container">
					<Checkbox
						binary
						class="tw3-m-auto"
						:modelValue="exportRows.includes(data.job_id)"
						@update:model-value="
							() => store.exportToggleRowSelection(data.job_id)
						"
					/>
				</div>
			</template>
		</Column>

		<Column
			v-for="(column, columnIndex) of view.selectedColumns"
			:key="column.key"
			v-memo="[column.hidden, column.backgroundColor, columnIndex]"
			exportable
			:field="column.key"
			:filterField="column.key"
			:header="column.label"
			:headerStyle="
				column.backgroundColor
					? `background-color: ${transformColor(column.backgroundColor)}`
					: undefined
			"
			headerClass="tw3-whitespace-nowrap tw3-border-b tw3-relative"
			:hidden="column.hidden"
			pt:headerCell:class="tw3-border-b"
			:showFilterMatchModes="false"
			:showFilterMenu="column.key !== 'row_number'"
			:sortable="column.sortable"
		>
			<template #header>
				<Checkbox
					v-if="exportMode"
					binary
					:modelValue="!column.dont_export"
					@update:model-value="v => (column.dont_export = !v)"
				/>
				<div
					class="tw3-h-[4px] tw3-absolute tw3-bottom-0 tw3-right-0 tw3-left-0"
					:style="`background-color: ${column.backgroundColor && column.backgroundColor !== '' && column.backgroundColor !== 'rgb(255,255,255)' && column.backgroundColor !== 'rgba(255,255,255,1)' ? column.backgroundColor : 'transparent'}`"
				></div>
			</template>
			<template #filtericon>
				<i
					v-if="column.key !== 'row_number'"
					:class="getFilterIconClass(column.key)"
				></i>
			</template>
			<template #filter="{ filterModel }">
				<JobTableFilters
					:id="column.key"
					v-model="filterModel.val"
					v-model:type="filterModel.type"
					:name="column.label"
				/>
			</template>
			<template #filterclear="{ filterModel, filterCallback }">
				<Button
					label="Clear"
					outlined
					size="small"
					@click="
						() => {
							filterModel.val =
								JOBS_FILTERS[column.key as keyof typeof JOBS_FILTERS]?.val;
							filterModel.type =
								JOBS_FILTERS[column.key as keyof typeof JOBS_FILTERS]?.type;
							filterCallback();
						}
					"
				/>
			</template>
			<template #body="{ data }">
				<GbDefaultCell :loading="!data">
					<a
						v-if="column.key === 'job_id'"
						class="tw3-font-bold tw3-ml-auto"
						:href="'/jobs/' + data[column.key]"
						target="_blank"
					>
						{{ data[column.key] }}
					</a>
					<StatusBadge
						v-else-if="
							column.key === 'status' && statusObject(data[column.key])
						"
						:style="{
							margin: 'auto',
							background: statusObject(data[column.key]).color
						}"
						:text="statusObject(data[column.key]).label"
					/>
					<div
						v-else-if="column.key === 'tags'"
						class="tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
					>
						<div class="tw3-w-full tw3-flex tw3-gap-2">
							<JobTagsCell
								:data="data[column.key] ? data[column.key] : []"
								:name="column.key"
							/>
						</div>
						<JobEditCell
							v-if="editStore.editPermission"
							class="tw3-flex-shrink-0 tw3-mr-[0.5rem]"
							:loading="
								editStore.jobTags.isLoading &&
								data.job_id === editStore.currentJobId
							"
							@click="editStore.toggleJobTagsDialog(data)"
						/>
					</div>
					<JobTagsCell
						v-else-if="column.key === 'parts'"
						class="tw3-mr-auto"
						:data="data[column.key] ? getPartsWithColors(data[column.key]) : []"
						:name="column.key"
					/>
					<JobTagsCell
						v-else-if="column.key === 'taxes'"
						class="tw3-mr-auto"
						:data="
							data[column.key]
								? data[column.key].map((v: any) => ({ text: v.name }))
								: []
						"
						:name="column.key"
					/>
					<div
						v-else-if="column.key === 'documents'"
						class="tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
					>
						<div class="tw3-w-full tw3-flex tw3-items-center tw3-gap-2">
							<JobDocumentCell
								v-if="data[column.key] && data[column.key][0]"
								:data="data[column.key][0]"
							/>
							<JobPopoverCell
								v-if="data[column.key]?.length && data[column.key].length > 1"
								:label="`+${data[column.key].length - 1}`"
								:name="column.key"
							>
								<div class="tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2">
									<template
										v-for="(document, documentIndex) in data[column.key]"
										:key="document?.id || documentIndex"
									>
										<JobDocumentCell
											v-if="documentIndex !== 0"
											:data="document"
										/>
									</template>
								</div>
							</JobPopoverCell>
						</div>
						<JobEditCell
							v-if="editStore.editPermission"
							class="tw3-flex-shrink-0 tw3-mr-[0.5rem]"
							:loading="
								editStore.jobDocuments.isLoading &&
								data.job_id === editStore.currentJobId
							"
							@click="editStore.toggleJobDocumentsDialog(data)"
						/>
					</div>
					<div
						v-else-if="column.key === 'notes'"
						class="tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
					>
						<div
							class="tw3-w-full tw3-flex tw3-items-center tw3-justify-center tw3-gap-2"
						>
							<JobPopoverCell
								v-if="data[column.key]?.length"
								label="See All"
								:name="column.key"
							>
								<div class="tw3-flex tw3-flex-wrap tw3-items-center tw3-gap-2">
									<JobNoteCell
										v-for="(note, noteIndex) in data[column.key]"
										:key="note?.id || noteIndex"
										:note="note"
										:showDelete="false"
										:user="note.user"
									/>
								</div>
							</JobPopoverCell>
						</div>
						<JobEditCell
							v-if="editStore.editPermission"
							class="tw3-flex-shrink-0 tw3-mr-[0.5rem]"
							:loading="
								editStore.jobNotes.isLoading &&
								data.job_id === editStore.currentJobId
							"
							@click="editStore.toggleJobNotesDialog(data)"
						/>
					</div>
					<div
						v-else-if="JOB_VENDOR_COLUMNS.includes(column.key)"
						class="tw3-flex tw3-items-center tw3-gap-2"
					>
						<StatusBadge
							v-for="(vendor, vendorIndex) in data[column.key]?.split(', ') ||
							[]"
							:key="vendorIndex"
							style="
								font-size: 1rem;
								line-height: 1rem;
								background-color: var(--p-tag-secondary-background);
								color: rgb(71, 85, 105);
							"
							:text="vendor"
						/>
					</div>
					<div
						v-else-if="JOB_PRICE_COLUMNS.includes(column.key)"
						class="tw3-whitespace-nowrap tw3-ml-auto"
					>
						{{ jobDollarFormatter(data[column.key]) }}
					</div>
					<StatusBadge
						v-else-if="column.key === 'install_context'"
						severity="secondary"
						:style="{
							margin: 'auto',
							backgroundColor: jobInstallContextColor(data[column.key])
						}"
						:text="data[column.key]"
					/>
					<StatusBadge
						v-else-if="column.key === 'consumer.is_commercial'"
						class="tw3-m-auto"
						style="
							background-color: var(--p-tag-secondary-background);
							color: rgb(71, 85, 105);
						"
						:text="data[column.key] === 1 ? $t('commercial') : $t('individual')"
					/>
					<div
						v-else-if="column.key === 'ediqueue.status'"
						class="tw3-w-full tw3-flex tw3-items-center tw3-gap-4"
					>
						<div
							class="tw3-w-full tw3-flex tw3-items-center tw3-justify-center tw3-gap-2"
						>
							<StatusBadge
								v-if="
									data[column.key] && data[column.key] !== EdiStatus.Unsubmitted
								"
								class="tw3-m-auto"
								style="
									background-color: var(--p-tag-secondary-background);
									color: rgb(71, 85, 105);
								"
								:text="data[column.key]"
							/>
						</div>
						<JobEditCell
							v-if="editStore.editPermission"
							class="tw3-flex-shrink-0 tw3-mr-[0.5rem]"
							:loading="
								editStore.jobEdi.isLoading &&
								data.job_id === editStore.currentJobId
							"
							@click="editStore.toggleJobEdiDialog(data)"
						/>
					</div>
					<div
						v-else-if="
							column.key === 'install_notes' ||
							column.key === 'technician_notes'
						"
						v-tooltip.bottom="data[column.key]"
						class="tw3-whitespace-nowrap tw3-max-w-64 tw3-truncate"
					>
						{{ data[column.key] }}
					</div>
					<div
						v-else
						class="tw3-whitespace-nowrap"
						:class="{
							'tw3-m-auto':
								JOB_DATE_COLUMNS.includes(column.key) ||
								column.key === 'consumer.address.state' ||
								column.key === 'install_g_address.state' ||
								column.key === 'scheduled_time_start' ||
								column.key === 'scheduled_time_end',
							'tw3-ml-auto':
								column.key === 'internal_number' ||
								column.key === 'consumer.address.unit' ||
								column.key === 'consumer.address.zip' ||
								column.key === 'vehicle.year' ||
								column.key === 'vehicle.vin' ||
								column.key === 'install_g_address.zip' ||
								column.key === 'install_g_address.unit' ||
								column.key === 'vehicle.unit' ||
								column.key === 'dot_number' ||
								column.key === 'lot_number' ||
								column.key === 'purchase_order_number' ||
								column.key === 'requisition_order_number' ||
								column.key === 'vehicle.plate_number' ||
								column.key === 'referral_number' ||
								column.key === 'shop_id' ||
								column.key === 'policy_number' ||
								column.key === 'vehicle.unit_number' ||
								column.key === 'consumer_id' ||
								column.key === 'full_job_number' ||
								column.key === 'salesidejob_id'
						}"
					>
						{{ data[column.key] }}
					</div>
				</GbDefaultCell>
			</template>
		</Column>

		<template #empty>
			<div class="tw3-flex tw3-w-full">
				<p
					v-if="!(loading || loadingFiltersData || updateViewLoading)"
					class="tw3-sticky tw3-top-1/2 tw3-left-1/2 tw3-text-3xl tw3-translate-x-[-50%] tw3-translate-y-[-50%]"
				>
					{{ t('noData') }}
				</p>
			</div>
		</template>
	</DataTable>
</template>
