<template>
	<svg
		id="Capa_1"
		fill="none"
		style="enable-background: new 0 0 612.099 612.099"
		version="1.1"
		viewBox="0 0 612.099 612.099"
		width="612.099px"
		xml:space="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g>
			<g>
				<g>
					<path
						d="M573.794,564.26c0,5.28-4.284,9.563-9.564,9.563c-5.279,0-9.563-4.283-9.563-9.563V248.666H353.88
				c-2.889,0-5.479-1.295-7.232-3.308l-34.969-34.949H19.178v160.118v222.425H306.06h286.862V277.358
				c0-5.28,4.284-9.564,9.562-9.564c5.28,0,9.564,4.304,9.564,9.564v324.918v0.26c0,5.26-4.284,9.562-9.564,9.562H306.06H9.873
				H9.614c-5.28,0-9.564-4.283-9.564-9.562V370.527V201.084v-0.239c0-5.28,4.284-9.564,9.564-9.564h305.97v0.04
				c2.431,0,4.86,0.937,6.715,2.77l35.486,35.447h206.186h0.239c5.279,0,9.562,4.304,9.562,9.564V564.26H573.794z"
						fill="currentColor"
					/>
					<path
						d="M38.306,162.569c0,5.28-4.284,9.564-9.564,9.564c-5.28,0-9.564-4.284-9.564-9.564V9.564c0-5.28,4.284-9.564,9.564-9.564
				h0.239h363.116c5.28,0,9.563,4.284,9.563,9.564v0.239v191.022c0,5.28-4.283,9.564-9.563,9.564s-9.563-4.284-9.563-9.564V19.128
				H38.306V162.569z"
						fill="currentColor"
					/>
					<path
						d="M105.255,76.533c-5.28,0-9.564-4.284-9.564-9.564c0-5.28,4.284-9.563,9.564-9.563h210.349
				c5.28,0,9.564,4.304,9.564,9.563c0,5.28-4.284,9.564-9.564,9.564H105.255z"
						fill="currentColor"
					/>
					<path
						d="M506.865,76.533c-5.28,0-9.564-4.284-9.564-9.564c0-5.28,4.284-9.563,9.564-9.563h38.256
				c5.279,0,9.563,4.304,9.563,9.563v0.259v133.598c0,5.28-4.284,9.564-9.563,9.564c-5.28,0-9.563-4.284-9.563-9.564V76.533H506.865
				z"
						fill="currentColor"
					/>
					<path
						d="M105.255,153.065c-5.28,0-9.564-4.284-9.564-9.564c0-5.28,4.284-9.564,9.564-9.564h210.349
				c5.28,0,9.564,4.284,9.564,9.564c0,5.28-4.284,9.564-9.564,9.564H105.255z"
						fill="currentColor"
					/>
					<path
						d="M430.312,57.424c-5.279,0-9.562-4.284-9.562-9.564c0-5.28,4.283-9.564,9.562-9.564h38.296
				c5.262,0,9.564,4.284,9.564,9.564v0.239v152.706c0,5.28-4.284,9.564-9.564,9.564c-5.278,0-9.562-4.284-9.562-9.564V57.424
				H430.312z"
						fill="currentColor"
					/>
				</g>
			</g>
		</g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
</template>
