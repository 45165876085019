import { capitalize } from 'lodash-es';

const EN = {
	active: 'Active',
	agencyName: 'Agency Name',
	agentFirstName: 'Agent First Name',
	agentLastName: 'Agent Last Name',
	all: 'All',
	allShops: 'All Shops',
	amount: 'Amount',
	appointmentDate: 'Appointment Date',
	archive: 'Archive',
	archiveDialog: {
		archiveTitle: 'Are you sure you want to unarchive this Claim?',
		unarchiveTitle: 'Are you sure you want to archive this Claim?'
	},
	autoAccept: 'Auto Accept',
	avatar: 'Avatar',
	averageInvoice: 'Average invoice',
	averageInvoiceAmount: 'Average invoice amount',
	averageRating: 'Average rating',
	averageStars: 'Average Stars',
	averageWarrantyRate: 'Average warranty rate',
	averageCostRatio: 'Average cost ratio',
	ascending: 'Ascending',
	billTo: 'bill to',
	bodyInjury: 'Bodily Injury',
	bodyInjuryTooltip: 'Was there an injury as it relates to this claim',
	//@ts-ignore
	capitalize: function (ctx) {
		//@ts-ignore
		return capitalize(ctx.named('key'));
	},
	cancel: 'Cancel',
	callBack: 'Call back',
	causeOfLoss: 'Cause of Loss',
	causeOfLossTooltip: 'Determine how loss occurred',
	changeFollowupDate: {
		message: 'Are you sure you want to update this information?',
		title: 'Change Follow Up date'
	},
	column: 'Column',
	columnRequiredMsg: 'At least two column must be selected.',
	columnSettings: 'Column Settings',
	costRatio: 'Cost Ratio',
	costRatioExplaining:
		'Technician {name} is a measure indicating how much of the operating revenue goes to technicians.  Jobs with zero revenue or zero commission are omitted from the calculation.',
	comm: 'Comm',
	commission: 'Commission',
	commissionAmount: 'Commission Amount',
	completedJobs: 'Completed Jobs',
	completedJobsCount: 'Completed Jobs Count',
	completionPercentage: 'Completion (%)',
	chipCommission: 'Chip Commission',
	city: 'City',
	clear: 'Clear',
	claimForJob: 'Claim for Job',
	claims: 'Claims',
	claimsDialog: {
		claimsSended: 'Claim successfully sended'
	},
	count: 'Count',
	contactPhone: 'Contact Phone',
	coverage: 'coverage',
	csr: 'CSR',
	commercial: 'Commercial',
	commercialAccount: 'Commercial Account',
	customer: 'Customer',
	customerCity: 'Customer City',
	customerFirstName: 'Customer First Name',
	customerLastName: 'Customer Last Name',
	customerPostalCode: 'Customer Postal Code',
	customerState: 'Customer State',
	customerStreetAddress: 'Customer Street Address',
	customerPhone: 'Customer Phone',
	customers: 'Customers',
	customerEmail: 'Customer Email',
	customerCount: 'Customer count',
	currentView: 'Current View',
	currentViewCreated: 'Current View was created successfully!',
	currentViewUpdated: 'Current View was updated successfully!',
	currentViewDeleted: 'Current View was deleted successfully!',
	currentFilters: 'Current Filters',
	create: 'Create',
	dataUpdatedOn: 'Data updated as of {date}',
	default: 'Default',
	revenueData: 'Revenue data',
	dashboardTooltipTitle:
		'{name} is tabulated from invoice totals within your GlassBiller account, it is exclusive of sales tax.',
	dashboardTooltipDescription:
		'Data displayed should not be used for accounting or tax reporting purposes. \nData shown is meant to give users a graphically zoomed out overview of the entire business as well as allow for comparisons to previous time periods. \nThese totals may, and in many cases will, deviate from professionally prepared accounting reports.',
	dashboard: {
		index: 'Dashboard',
		defaultChanged: 'Default dashboard changed successfully',
		permissionMsg:
			'Sorry, you do not have the necessary permissions to access this page. If you think you should have access, please reach out to your manager.',
		titles: {
			numberOfCustomers: 'Number of customers',
			totalNumberOfCustomers: 'Total number of customers',
			numberOfInvoices: 'Number of invoices',
			totalAmountOfInvoices: 'Total amount of invoices',
			totalNumberOfInvoices: 'Total number of invoices',
			singleInvoiceAmount: 'Single invoice amount',
			revenueAndNumberOfInvoices: 'Revenue and Number of Invoices',
			compareRevenue: 'Compare revenue trends over time',
			customerGrow: 'See your customer count grow over time',
			invoiceGrow: 'See your invoice count grow over time',
			averageInvoice: 'Monitor average invoice size over time'
		}
	},
	damage: 'Damage',
	damageTitles: {
		location: 'Capture what glass will be repaired or replaced',
		position: 'Nags Position Code',
		qty: 'Capture the pieces of glass\nthat will be used to service the vehicle',
		relLocation: 'Nags Relative Location Code',
		repairable:
			'If Glass Damage Opening is Windshield, \nthe Retailer must certify that the windshield\nwas not repairable',
		side: 'Nags Side Code'
	},
	data: 'Data',
	date: 'Date',
	delete: 'Delete',
	deductable: 'Deductable',
	descending: 'Descending',
	details: 'Details',
	delivery: 'Delivery',
	driverName: `Driver's Name (if available)`,
	dispatchDate: `Dispatch Date`,
	dropOff: `Drop off`,
	document: {
		index: 'Document',
		delete: 'Document Delete',
		createdMsg: 'Document was created successfully!',
		deletedMsg: 'Document was deleted successfully!',
		deleteConfirm: 'Are you sure you want permanently delete this document?'
	},
	email: 'Email',
	export: 'Export',
	exportMode: 'Export Mode',
	exportConfirm: '{n} rows will be exported. Do you want to continue?',
	error: 'error',
	ediRemitDetail: 'EDI Remit Detail',
	edi: {
		updatedMsg: 'Edi status was updated successfully!',
		status: 'Edi Status',
		Unsubmitted: 'Unsubmitted',
		Sent: 'Sent',
		Paid: 'Paid',
		Accepted: 'Accepted',
		Rejected: 'Rejected',
		Unknown: 'Unknown'
	},
	edit: 'Edit',
	errorNumber: 'Please enter a valid 10 digit number.',
	firstName: 'First Name',
	faxDialog: {
		fax: 'Fax',
		actions: {
			actions: 'Actions',
			attachFax: {
				attach: 'Attach to job',
				error: 'This fax could not be attached.',
				success: 'The document was successfully attached.'
			},
			burst: {
				burst: 'Burst',
				plural: 'Do you want to burst these faxes?',
				pluralTooltip: 'Burst selected faxes',
				single: 'Do you want to burst this fax?',
				error: 'Failed to burst fax. Please try again.',
				success: 'Fax burst complete'
			},
			cancel: 'Cancel',
			changeReferral: {
				error: 'An error occurred during the update Refferal/Notes',
				placeholder: 'Enter referral/Notes',
				success: 'Refferal/Notes successffully updated!'
			},
			changeStatus: {
				error: 'An error occurred while changing the status',
				success: 'Status successfully changed!'
			},
			delete: {
				delete: 'Delete',
				error: 'There was an error deleting a fax.',
				plural: 'Do you want to delete these faxes?',
				pluralTooltip: 'Delete selected faxes',
				single: 'Do you want to delete this fax?',
				success: 'Faxes succesfully deleted!'
			},
			download: 'Download fax',
			downloadConfirmation: 'Download Transmittal Page',
			noConfirmationAvailable: 'No Transmittal Page Available',
			confirmationError:
				'Something went wrong retreiving the copy of your previous fax. Please try again.',
			confirmationSuccess: 'Transmittal page successfully downloaded',
			pluralActions: 'Plural actions:',
			pluralActionsTooltip: 'Actions with selected faxes',
			upload: {
				upload: 'Upload fax'
			}
		},
		inbox: {
			dateTime: 'Date',
			pages: 'Pages',
			referral: 'Referral/Notes',
			sentFrom: 'Sent From',
			sentTo: 'Sent To',
			status: 'Status',
			title: 'Fax Inbox'
		},
		outbox: {
			dateTime: 'Date',
			file: 'File Name',
			pages: 'Pages',
			sentFrom: 'Sent From',
			sentTo: 'Sent To',
			status: 'Status',
			title: 'Fax Outbox'
		},
		status: {
			burst: 'Burst',
			error: 'Error',
			queued: 'Queued',
			read: 'Read',
			sending: 'Sending',
			sent: 'Sent',
			unread: 'Unread'
		},
		statusResponses: {
			error: 'Error when sending a fax',
			queued: 'The fax is in the queue',
			read: 'Fax is readed',
			sending: 'Fax is sending',
			sent: 'Fax in sended',
			unread: 'Fax is unread'
		},
		searchForJob: 'Search For Job...',
		type: 'Type',
		attachFaxJob: 'Attach Fax to Job',
		selectType: 'Select a type',
		selectStatus: 'Select status',
		attachJob: 'Attach job',
		uploadFax: {
			fileName: 'File Name:',
			dateTime: 'Date/Time:',
			enterFaxNumber: 'Enter fax number',
			yourFile: 'Your file:',
			uploadFaxHeader: 'Send/Burst new fax',
			burstNewFax: {
				burstFax: 'Burst Fax',
				error: 'This fax could not be burst.',
				success: 'The fax was successfully burst.'
			},
			sendFax: {
				sendFax: 'Send Fax',
				error: 'An error occurred while sending a fax',
				success: 'Fax is successfully sended'
			},
			chooseFile: 'Choose file'
		}
	},
	fullAddress: 'Full Address',
	job: 'Job',
	jobs: 'Jobs',
	jobRequiredFieldsForClaim:
		'The following fields are required to start a Claim',
	jobType: 'Job Type',
	jobTags: 'Job Tags',
	confirmation: 'Confirmation',
	defaultToast: {
		successSummary: 'Success Message',
		errorSummary: 'Error Message',
		errorDetails: 'Something went wrong'
	},
	searchBy: 'Search By ',
	followUp: 'Follow up',
	followUpJobs: 'Follow-up jobs',
	glassOnlyLoss: 'Glass Only Loss',
	glassOnlyLossTooltip: 'Determine if this is a Glass Only claim',
	id: 'Id',
	inactive: 'Inactive',
	invitationStatus: 'Invitation Status',
	inbox: 'Inbox',
	individual: 'Individual',
	incident: 'Incident on',
	insurance: 'Insurance',
	invoices: 'Invoices',
	invoiceDate: 'Invoice Date',
	invoiceCount: 'Invoice Count',
	inShop: 'In Shop',
	jobId: 'Job ID',
	jobNotes: 'Job Notes',
	jobDocuments: 'Job Documents',
	lastName: 'Last Name',
	location: 'Location',
	lossDate: 'Loss Date',
	ltlRevenue: 'LTL Rev',
	ltlJobs: 'LTL Jobs',
	ltlCommission: 'LTL Comm',
	lifetimeRevenue: 'Lifetime Revenue',
	lifetimeInvoices: 'Lifetime invoices',
	lifetimeCustomers: 'Lifetime customers',
	lifetimeCommission: 'Lifetime Commission',
	lifetimeCostRatio: 'Lifetime cost ratio',
	lifetimeRatings: 'Lifetime ratings',
	lifetimeWarrantyRate: 'Lifetime warranty rate',
	lifetimeCompletedJobs: 'Lifetime completed jobs',
	lifetimeJobs: 'Lifetime Jobs',
	lifetimeJobsCompleted: `{n}% of jobs are complete`,
	message: 'Message',
	myViews: 'My Views',
	mobileIndicator: 'Mobile',
	name: 'Name',
	new: 'new',
	no: 'No',
	note: {
		index: 'Note',
		delete: 'Note Delete',
		placeholder: 'Type a note',
		empty: 'No Notes yet',
		emptyError: 'Note cannot be empty',
		createdMsg: 'Note was created successfully!',
		deletedMsg: 'Note was deleted successfully!',
		deleteConfirm: 'Are you sure you want permanently delete this note?'
	},
	noData: 'No Data Found',
	newestInvoices: 'Newest Invoices',
	noInvoices: 'No invoices yet',
	home: 'Home',
	history: 'History',
	openClaim: 'Open Claim',
	optional: 'Optional',
	parts: 'Parts',
	pending: 'pending',
	phone: 'Phone',
	plate: 'Plate',
	policy: 'Policy',
	policyNumber: 'Policy Number',
	position: 'Position',
	permission: 'Permission Required',
	pickUp: 'Pick Up',
	primaryPhone: 'Primary Phone',
	mobile: 'Mobile',
	mobileAbout: 'Mobile jobs account for about {n}% of your installs',
	mobileVsInShop: 'Mobile vs In Shop',
	qty: 'Qty',
	quote: 'Quote#',
	quoteDate: 'Quote Date',
	row: 'Row',
	rating: 'Rating',
	revenue: 'Revenue',
	removeView: 'Remove View',
	removeViewConfirm: 'Current view will be deleted. Do you want to continue?',
	referral: 'Referral',
	refNumber: 'Reference Number',
	reference: 'Reference',
	rejectedInvoices: {
		tabs: {
			rejected: 'Rejected',
			queued: 'Queued',
			preparing: 'Preparing',
			pending: 'Pending',
			failed: 'Failed',
			unsubmitted: 'Unsubmitted'
		},
		columnNames: {
			amount: 'Amount',
			customer: 'Customer',
			invoice: 'Invoice',
			referral: 'Refferal',
			tradingPartner: 'Trading partner'
		},
		addTagsTitle: 'Add tags',
		title: 'Rejected invoices',
		applyTags: 'Apply tags',
		tagDeletedSuccess: 'Tag successfully deleted!',
		tagDeletedFailed: 'Failed to delete tag.',
		tagAddedSuccess: 'New tags added',
		tags: 'Tags',
		showDetails: 'Show Details',
		voidedJobs: 'Include voided jobs'
	},
	addNewTag: 'Add new tag',
	relLocation: 'Rel Location',
	removeDamageInfoHeader: 'Remove Damage Information',
	removeDamageInfoMsg:
		'Are you sure you want to delete this damage information?',
	repairable: 'Repairable',
	required: 'Required',
	result: 'Result',
	retail: 'Retail',
	recal: 'Recal',
	rAndI: 'R & I',
	save: 'Save',
	selectShop: 'Select Shop',
	sortColumn: 'Sort Column',
	sortDirection: 'Sort Direction',
	shop: 'Shop',
	shopName: 'Shop Name',
	selectDefault: 'Select Default',
	selectedFilters: 'Selected Filters',
	shopContactPerson: 'Shop Contact Person',
	shopContactPersonTooltip:
		'Allows Insurance Company or TPA to contact the originator.',
	showActive: 'Show Active',
	showArchive: 'Show Archive',
	side: 'Side',
	since: 'Since',
	statePolicty: 'State Policy was Written',
	status: 'Status',
	statusResponses: {
		coverage: 'coverage response received',
		error: 'error occured',
		new: 'claim created',
		pending: 'loss notice sent',
		success: 'acknowledge sent'
	},
	street: 'Street',
	submitClaim: 'Submit Claim',
	submitClaimMsg: 'Are you sure you would like to submit this claim?',
	subrogationContactName: {
		CAUSE_OF_LOSS_CODE_105: "Driver's Name (if available)",
		CAUSE_OF_LOSS_CODE_111: 'Details',
		CAUSE_OF_LOSS_CODE_199: "Person's Name (if available/applicable)",
		CAUSE_OF_LOSS_CODE_201: "Driver's Name (if available)",
		CAUSE_OF_LOSS_CODE_301: "Person's Name (if available)",
		CAUSE_OF_LOSS_CODE_341: "Person's Name (if available)",
		CAUSE_OF_LOSS_CODE_499: 'Details'
	},
	subrogationData: 'Subrogation Data',
	subrogationDataKeys: {
		CAUSE_OF_LOSS_CODE_105: 'License Plate Number',
		CAUSE_OF_LOSS_CODE_111: 'Type of Animal',
		CAUSE_OF_LOSS_CODE_199: 'Description of Object',
		CAUSE_OF_LOSS_CODE_201: 'Claim Number (if available) and Contact Info',
		CAUSE_OF_LOSS_CODE_301: 'Contact Info',
		CAUSE_OF_LOSS_CODE_341: 'Contact Info (e.g. policy report)',
		CAUSE_OF_LOSS_CODE_499: 'Type of Storm (e.g. lightning, wind, sand, etc)'
	},
	success: 'success',
	type: 'type',
	tagUpdatedMsg: 'Tags was updated successfully!',
	tech: {
		costRatioLifetime: 'Lifetime (LTL) Cost ratio',
		commissionPerJob: 'Commission per job',
		totalCommissions: 'Total commissions',
		titles: {
			numberOfCJobs: 'Number of completed jobs',
			totalNumberOfCJobs: 'Total number of completed jobs',
			numberOfWJobs: 'Number of warranty jobs',
			amountOfCommission: 'Amount of commission',
			commissionGrow: 'See your commission amount grow over time',
			completedGrow: 'See your completed jobs count over time',
			warrantyGrow: 'See your warranty jobs count over time',
			averageCostRatio: 'Monitor average cost ratio over time'
		}
	},
	techside: 'Techside',
	techsideJobsCompleted: '{n}% of jobs completed on Techside',
	technicians: 'Technicians',
	time: {
		day: 'Day',
		week: 'Week',
		month: 'Month',
		year: 'Year',
		thisMonth: 'this month',
		last90days: 'last 90 days',
		dayShort: 'TTD',
		dayLong: 'for trailing thirty days (ttd)',
		weekShort: 'TTW',
		weekLong: 'for trailing twenty four weeks (ttw)',
		monthShort: 'TTM',
		monthLong: 'for trailing twelve months (ttm)',
		yearShort: 'TSY',
		yearLong: 'for trailing seven years (tsy)'
	},
	tint: 'Tint',
	unarchive: 'Unarchive',
	update: 'Update',
	unsavedChanges: 'Unsaved changes!',
	uniqueViewName: 'View name must be unique!',
	vehicle: 'Vehicle',
	vehicleMake: 'Vehicle Make',
	vehicleModel: 'Vehicle Model',
	vehicleVin: 'Vehicle Vin',
	vehicleYear: 'Vehicle Year',
	vehicleNagsId: 'Vehicle NAGS ID',
	vehicleStyle: 'Vehicle Style',
	vendors: 'Vendors',
	vehicleNumber: 'Vehicle Number',
	vehicleOwnership: 'Vehicle Ownership',
	vehicleType: 'Vehicle Type',
	vendorType: 'Vendor Type',
	viewUniqueMsg: 'This view name already exists. It must be unique.',
	viewNotEmptyMsg: 'View name is required.',
	vin: 'VIN',
	vinTooltip:
		'A policyholder often has more than one vehicle with the same make/model/yr on a policy, therefore, if available, the VIN may be sent to filter coverage confirmation information.',
	voidJobs: {
		message: 'Are you sure you want to void these jobs?',
		title: 'Void Jobs'
	},
	workLocation: 'Work Location Postal Code',
	acceptanceContact: 'Acceptance Contact',
	warrCount: 'Warr',
	warrRate: 'Warr (%)',
	warrantyJobs: 'Warranty Jobs',
	warrantyRate: 'Warranty Rate',
	willCall: 'Will call',
	yes: 'Yes',
	download: 'Download',
	shops: 'Shops',
	both: 'Both',
	labor: 'Labor',
	subtotal: 'Subtotal',
	materials: 'Materials',
	flat: 'Flat',
	aging: 'Aging',
	charge: 'Charge',
	credit: 'Credit',
	due: 'Due',
	description: 'Description',
	payments: 'Payments',
	payment: 'Payment',
	forward: 'Forward',
	balance: 'Balance',
	period: 'Period',
	summary: 'Summary',
	invalid: 'Invalid',
	style: 'Style',
	model: 'Model',
	make: 'Make',
	select: 'Select',
	filter: 'Filter',
	pickDate: 'Pick date',
	pickDates: 'Pick date range',
	subject: 'Subject',
	body: 'Body',
	noteText: 'Note',
	warning: 'Warning',
	to: 'To',
	replyTo: 'Reply To',
	attachments: 'Attachments',
	additionalAttachments: 'Additional attachments',
	collapse: 'Collapse',
	expand: 'Expand',
	user: 'User',
	invoice: 'Invoice',
	copyTextToast: {
		succesSummary: 'Copied!',
		succesDetails: 'Text was copied to clipboard',
		errorDetail: 'Failed to copy text to clipboard',
		errorSummary: 'Failed!'
	},
	allReports: 'All Reports',
	reportsPage: {
		repair: 'Repair',
		replace: 'Replace',
		colNames: {
			partCost: 'Part Cost',
			total_taxes: 'Sales Tax',
			total_after_taxes: 'Gross Sales',
			total_commission: 'Commissions',
			customer_rebate: 'Rebate',
			other_costs: 'Other',
			total_margin: 'Margin',
			total_cost: 'Parts Cost',
			customer_name: 'Customer Name',
			referral_number: 'Referral #',
			total_tech_commission: 'Commision',
			total_sales_rep_commission: 'Commision',
			total_sale_source_commission: 'Commision',
			total_location_commission: 'Commision',
			total_csr_commission: 'Commission',
			payer: 'Payer',
			quote_date: 'Quote Date',
			work_order_date: 'Work Order Date',
			invoice_date: 'Invoice Date',
			install_date: 'Install Date',
			warranty_date: 'Warranty Date',
			warranty_complete_date: 'Warranty Complete Date',
			recal_date: 'Recal Date',
			recal_complete_date: 'Recal Complete Date',
			call_back_date: 'Call Back Date',
			call_back_complete_date: 'Call Back Complete Date',
			r_and_i_date: 'R&I Date',
			r_and_i_complete_date: 'R&I Complete Date',
			tint_date: 'Tint Date',
			tint_complete_date: 'Tint Complete Date',
			pick_up_date: 'Pick Up Date',
			pick_up_complete_date: 'Pick Up Complete Date',
			drop_off_date: 'Drop Off Date',
			drop_off_complete_date: 'Drop Off Complete Date',
			void_date: 'Void Date',
			payment_list: 'Payments',
			time: 'Time',
			part_list: 'Parts',
			install_notes: 'Job Notes',
			vehicle: 'Year / Make / Model',
			total_after_taxes2: 'Invoice total',
			type: 'Payment type',
			tpa: 'TPA',
			amount: 'Payment amount',
			x_action_type: 'X Action Type',
			x_action_number: 'X Action Number',
			memo: 'Memo',
			total: 'Selected<br/>taxes total',
			total_taxes2: 'Total job<br/> taxes',
			total_after_taxes3: 'Gross cost'
		},
		colNamesSecondary: {
			total_taxes: 'Total Tax',
			total_taxable: 'Net taxable',
			material_exempt: 'Material Exempt',
			labor_exempt: 'Labor Exempt',
			total_exempt: 'Exempt Subtotal',
			total_subtotal: 'Subtotal',
			amount: 'Total collected',
			total_materials_taxes: 'Total material taxes',
			total_labor_taxes: 'Total labor taxes',
			total_tax_collected: 'Total tax collected',
			repairSales: 'Collected Repair Sales Volume',
			royaltyPercentage: 'Repair Percent',
			repairRoyalty: 'Repair Royalty',
			replaceSales: 'Collected Replacement Sales Volume',
			replaceRoyaltyPercentage: 'Replacement Percent',
			replaceRoyalty: 'Replacement Royalty',
			additionalSales: 'Collected Additional Products and Service Sales Volume',
			additionalRoyaltyPercentage: 'Additional Products and Service Percent',
			additionalRoyalty: 'Additional Royalty',
			repairVehicles: 'Total Number of Vehicles for Repair Service',
			replaceVehicles: 'Total Number of Vehicles for Replacement Service',
			additionalInvoices: 'Invoices for Additional Products and Services',
			royaltySubtotal: 'Subtotal of Royalties'
		},
		filters: {
			workType: 'Work Type',
			jobTypes: {
				jobType: 'Job type',
				jobTypes: 'Job types',
				quote: 'Quote',
				workOrder: 'Work order',
				invoice: 'Invoice',
				warranty: 'Warranty',
				warrantyComplete: 'Warranty complete',
				recal: 'Recal',
				recalComplete: 'Recal complete',
				callBack: 'Call back',
				callBackComplete: 'Call back complete',
				rAndI: 'R & I',
				rAndIComplete: 'R & I complete',
				tint: 'Tint',
				tintComplete: 'Tint complete',
				pickUp: 'Pick up',
				pickUpComplete: 'Pick up complete',
				dropOff: 'Drop off',
				dropOffComplete: 'Drop off complete',
				void: 'Void'
			},
			customerTypes: {
				cash: 'Cash',
				commercial: 'Commercial',
				insurance: 'Insurance'
			},
			groupBy: {
				age: 'By age',
				groupBy: 'Group by',
				none: 'None',
				date: 'By date',
				shop_name: 'By shop',
				customer_name: 'By customer',
				x_action_type: 'By x action type',
				insurancefleet_name: 'By insurance',
				vendor: 'By vendor'
			},
			customFilterLabels: {
				installer: 'Installer',
				techs: 'Technicians',
				salesreps: 'Sales reps',
				salesources: 'Sale sources',
				locations: 'Locations',
				taxes: 'Sales taxes',
				csrs: 'CSR`S'
			},
			accrualCashOptions: {
				accrual: 'Accrual',
				cashBasis: 'Cash Basis'
			},

			errorFetchingConsumer: 'Error fetching consumer communication options:',
			generateAReport: 'Please generate a report first.',
			portrait: 'Portrait',
			landscape: 'Landscape',
			orientationSelect: 'Select The Orientation',
			permissionDenied: 'You do not have permission to view this report.',

			accrual: 'Accrual/Cash Basis',
			paidStatus: 'Paid status',
			billToCustomer: 'Bill-to customer type',
			licenseTypes: 'License types',
			accountingPackage: 'Accounting Package',
			noResults: 'No results for selected filters',
			noGenerated: 'Press "Run Report" to get a report',
			csrs: 'CSR`S',
			salesources: 'Sale source',
			salesreps: 'Sales rep',
			techs: 'Tech',
			locations: 'Location',
			install_date: 'Install date',
			invoice_date: 'Invoice date',
			work_order_date: 'Work order date',
			quote_date: 'Quote date',
			warranty_date: 'Warranty date',
			warranty_complete_date: 'Warranty complete date',
			recal_date: 'Recal date',
			recal_complete_date: 'Recal complete date',
			call_back_date: 'Call back date',
			call_back_complete_date: 'Call back complete date',
			r_and_i_date: 'R & I date',
			r_and_i_complete_date: 'R & I complete date',
			tint_date: 'Tint date',
			tint_complete_date: 'Tint complete date',
			pick_up_date: 'Pick up date',
			pick_up_complete_date: 'Pick up complete date',
			drop_off_date: 'Drop off date',
			drop_off_complete_date: 'Drop off complete date',
			unscheduled: 'Unscheduled',
			scheduled_date: 'Schedule date',
			void_date: 'Void date',
			filterGroup: 'Filter group',
			filterBy: {
				csrs: 'CSR`S',
				salesources: 'Sales sources',
				salesreps: 'Sales reps',
				techs: 'Techs',
				locations: 'Locations'
			},
			tags: {
				exempt: 'Exempt',
				poReq: 'PO required',
				flagged: 'Flagged'
			},
			dateType: 'Date Type'
		},
		textMessagesTemplate: {
			templates: 'Templates',
			selectTemplate: 'Select Email Template (Optional)',
			manageTemplates: 'Manage email templates',
			emailTemplates: 'Email templates',
			updateTemplate: 'Update a template',
			createTemplate: 'Create a template',
			subject: 'Enter A Subject',
			enterAName: 'Enter A Name',
			templateBody: 'Enter A Template Body',
			default: 'Make default for the shop',
			body: 'Template body',
			note: 'text "Please reply YES to confirm or reply NO to change" will be	automatically added in the end of the message.',
			errors: {
				name: 'Name is too short',
				body: 'Body should have at least 10 characters',
				type_id: 'Please select a type',
				shop_id: 'Please select a shop',
				subject: 'Subject is too short',
				emailReq: 'Email is required',
				emailInvalid: 'Email is incorrect'
			},
			created: 'Template successfully created.',
			updated: 'Template successfully updated.',
			deleted: 'Template was succesfully deleted',
			fillRequired: 'Fill required fields',
			addTemplates: 'Add new template',
			shopName: 'Shop Name',
			typeName: 'Type name',
			deleteQuestion:
				'This will permanently delete the template.  Do you wish to continue?',
			sent: 'Sent',
			destination: 'To',
			sent_documents: "Add'l Docs",
			status: 'Status',
			quickAddresses: 'Quick Addresses',
			typeAndEnter: 'Type And Press Enter',
			emailSended: 'Email sended.'
		},
		table: {
			outstanding: 'Outstanding',
			statement: 'Statement',
			franchiseId: 'Franchise ID',
			account: 'Account',
			address: 'Address',
			license: 'License',
			anniversaryDate: 'Anniversary date',
			renewalDate: 'Renewal date',
			ofUsers: 'Of users',
			fee: 'Annual license fee',
			assets: 'Assets',
			liabilities: 'Liabilities',
			liabilitiesAndEquity: 'Liabilities and equity',
			equity: 'Equity',
			netIncome: 'Net income',
			income: 'Income',
			costOfGoodsSold: 'Cost of goods sold',
			profitMargin: 'profit margin',
			expenses: 'Expenses',
			netProfit: 'Net profit (loss)',
			pp: 'Partially paid',
			fp: 'Paid in full',
			unpaid: 'Unpaid',
			call_back_date: 'Call back date',
			call_back_complete_date: 'Call back complete date',
			draft_date: 'Draft date',
			drop_off_date: 'Drop off date',
			drop_off_complete_date: 'Drop off complete date',
			install_date: 'Install date',
			invoice_date: 'Invoice date',
			pick_up_date: 'Pick up date',
			pick_up_complete_date: 'Pick up complete date',
			quote_date: 'Quote date',
			r_and_i_date: 'R&I date',
			r_and_i_complete_date: 'R&I complete date',
			recal_date: 'Recal date',
			recal_complete_date: 'Recal complete date',
			tint_date: 'Tint date',
			tint_complete_date: 'Tint complete date',
			void_date: 'Void date',
			work_order_date: 'Work order date',
			warranty_date: 'Warranty date',
			warranty_complete_date: 'Warranty complete date',
			summary: 'Summary'
		},
		multipleShops: 'Multiple shops',
		customer: 'Customer',
		contractor: 'Contractor',
		xType: 'X action type',
		daily: 'Daily',
		total: 'Total',
		requests: 'Requests',
		totals: 'Totals',
		grand: 'Grand',
		requestDetails: {
			title: 'Request details',
			reqTime: 'Request time',
			vinInvalid: 'VIN number is invalid',
			helpReqSend:
				'Help request was sent. Please give us some time to process it and	we`ll get back to you right away.',
			glassType: 'Glass type',
			windshields: 'Windshields',
			calibrations: 'Calibrations',
			dealerNum: 'Dealer part numbers',
			noGlassFound: 'No glass results found'
		}
	},
	filePreview: {
		notAvailable: 'Preview not available',
		fileName: 'File Name',
		fileType: 'File Type',
		docType: 'Doc type',
		fileSize: 'File size',
		created: 'Created',
		openInNewTab: 'Open in new tab',
		preview: 'Preview'
	},
	workOrderDate: 'Work Order Date'
};

export default EN;
