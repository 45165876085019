import { Insurance } from '@/entities/insurance/lib/types';

/* claim */
export enum ClaimStatus {
	CLAIM_STATUS_NEW = 'CLAIM_STATUS_NEW',
	CLAIM_STATUS_PENDING = 'CLAIM_STATUS_PENDING',
	CLAIM_STATUS_COVERAGE = 'CLAIM_STATUS_COVERAGE',
	CLAIM_STATUS_SUCCESS = 'CLAIM_STATUS_SUCCESS',
	CLAIM_STATUS_ERROR = 'CLAIM_STATUS_ERROR'
}

export enum CauseOfLossCode {
	CAUSE_OF_LOSS_CODE_101 = 'CAUSE_OF_LOSS_CODE_101',
	CAUSE_OF_LOSS_CODE_105 = 'CAUSE_OF_LOSS_CODE_105',
	CAUSE_OF_LOSS_CODE_111 = 'CAUSE_OF_LOSS_CODE_111',
	CAUSE_OF_LOSS_CODE_121 = 'CAUSE_OF_LOSS_CODE_121',
	CAUSE_OF_LOSS_CODE_131 = 'CAUSE_OF_LOSS_CODE_131',
	CAUSE_OF_LOSS_CODE_199 = 'CAUSE_OF_LOSS_CODE_199',
	CAUSE_OF_LOSS_CODE_201 = 'CAUSE_OF_LOSS_CODE_201',
	CAUSE_OF_LOSS_CODE_301 = 'CAUSE_OF_LOSS_CODE_301',
	CAUSE_OF_LOSS_CODE_311 = 'CAUSE_OF_LOSS_CODE_311',
	CAUSE_OF_LOSS_CODE_321 = 'CAUSE_OF_LOSS_CODE_321',
	CAUSE_OF_LOSS_CODE_331 = 'CAUSE_OF_LOSS_CODE_331',
	CAUSE_OF_LOSS_CODE_341 = 'CAUSE_OF_LOSS_CODE_341',
	CAUSE_OF_LOSS_CODE_401 = 'CAUSE_OF_LOSS_CODE_401',
	CAUSE_OF_LOSS_CODE_411 = 'CAUSE_OF_LOSS_CODE_411',
	CAUSE_OF_LOSS_CODE_421 = 'CAUSE_OF_LOSS_CODE_421',
	CAUSE_OF_LOSS_CODE_431 = 'CAUSE_OF_LOSS_CODE_431',
	CAUSE_OF_LOSS_CODE_499 = 'CAUSE_OF_LOSS_CODE_499',
	CAUSE_OF_LOSS_CODE_901 = 'CAUSE_OF_LOSS_CODE_901'
}

type AckDetails = {
	mobileIndicator: boolean;
	acceptanceContact: string;
	workLocationPostalCode: string;
	requestedAppointmentDt: string;
};

export type AdditionalInfo = {
	glassOnlyDamage: boolean;
	bodilyInjury: boolean;
	originationContactName: string;
	causeOfLossCode: CauseOfLossCode;
	destinationPid: number;
	subrogationContactName: string;
	subrogationData: string;
};

export type Agent = {
	firstName: string;
	lastName: string;
	phone: string;
	agencyName: string;
};

type CoverageResponseVehicle = {
	errorCode: string;
	errorText: string;
	deductible: number;
	nagsId: string;
	number: number;
	alternativeNumber: string;
	description: string;
};

type ClaimResult = {
	referenceNumber: string;
	errorCode: string | null;
	status: ClaimStatus;
	errorMessage: string;
	insurerPhone: string;
	coverageResponseDt: string | null;
	claimRequest: string;
	claimResponse: string;
	coverageResponse: string;
	ackDt: string | null;
	ackRequest: string;
	ackResponse: string;
	coverageResponseVehicle: CoverageResponseVehicle;
};

type Customer = {
	id: number;
	firstName: string;
	lastName: string;
	phone1: string;
	phone2: string;
	state: string;
	city: string;
	streetAddress: string;
	postalCode: string;
};

export enum OpeningTypeCode {
	OPENING_TYPE_CODE_WR = 'OPENING_TYPE_CODE_WR',
	OPENING_TYPE_CODE_WS = 'OPENING_TYPE_CODE_WS',
	OPENING_TYPE_CODE_DR = 'OPENING_TYPE_CODE_DR',
	OPENING_TYPE_CODE_SI = 'OPENING_TYPE_CODE_SI',
	OPENING_TYPE_CODE_VN = 'OPENING_TYPE_CODE_VN',
	OPENING_TYPE_CODE_QT = 'OPENING_TYPE_CODE_QT',
	OPENING_TYPE_CODE_PT = 'OPENING_TYPE_CODE_PT',
	OPENING_TYPE_CODE_BK = 'OPENING_TYPE_CODE_BK',
	OPENING_TYPE_CODE_RF = 'OPENING_TYPE_CODE_RF'
}

export enum PositionCode {
	POSITION_CODE_B = 'POSITION_CODE_B',
	POSITION_CODE_F = 'POSITION_CODE_F',
	POSITION_CODE_M = 'POSITION_CODE_M',
	POSITION_CODE_R = 'POSITION_CODE_R'
}

export enum RelativeLocationCode {
	RELATIVE_LOCATION_CODE_IN = 'RELATIVE_LOCATION_CODE_IN',
	RELATIVE_LOCATION_CODE_LO = 'RELATIVE_LOCATION_CODE_LO',
	RELATIVE_LOCATION_CODE_OU = 'RELATIVE_LOCATION_CODE_OU',
	RELATIVE_LOCATION_CODE_UP = 'RELATIVE_LOCATION_CODE_UP'
}

export enum SideCode {
	SIDE_CODE_L = 'SIDE_CODE_L',
	SIDE_CODE_R = 'SIDE_CODE_R',
	SIDE_CODE_C = 'SIDE_CODE_C'
}

export type DamageInfo = {
	openingTypeCode: OpeningTypeCode | '';
	positionCode: PositionCode | '';
	relativeLocationCode: RelativeLocationCode | '';
	sideCode: SideCode | '';
	glassDamageQuantity: number;
};

export enum VehicleOwnership {
	VEHICLE_OWNERSHIP_OWNER = 'VEHICLE_OWNERSHIP_OWNER',
	VEHICLE_OWNERSHIP_COMMERCIAL = 'VEHICLE_OWNERSHIP_COMMERCIAL',
	VEHICLE_OWNERSHIP_RENTAL = 'VEHICLE_OWNERSHIP_RENTAL',
	VEHICLE_OWNERSHIP_BORROWED = 'VEHICLE_OWNERSHIP_BORROWED',
	VEHICLE_OWNERSHIP_THIRD_PARTY = 'VEHICLE_OWNERSHIP_THIRD_PARTY'
}

export enum VehicleType {
	VEHICLE_TYPE_STANDARD = 'VEHICLE_TYPE_STANDARD',
	VEHICLE_TYPE_RV = 'VEHICLE_TYPE_RV'
}

type Vehicle = {
	id: number;
	vin: string;
	year: number;
	make: string;
	makeId: number;
	model: string;
	plateNumber: string;
	imageUrl: string;
	thumbUrl: string;
	ownership: VehicleOwnership;
	nagsId: string;
	type: VehicleType;
	number: number;
	style: string;
};

export type ClaimDetails = {
	id: number;
	jobId: number;
	createdUserId: number;
	createdDt: string;
	uniqueId: string;
	shopId: number;
	providerName: string;
	ackDetails: AckDetails;
	additionalInfo: AdditionalInfo;
	agent: Agent;
	result: ClaimResult;
	customer: Customer;
	damageInfo: DamageInfo[];
	insurance: Insurance;
	vehicle: Vehicle;
	archived: boolean;
};

export type ClaimDetailsRequest = {
	id: number;
};

export type ClaimDetailsResponse = {
	claim: ClaimDetails;
};

/* Claims */

export type ClaimRaw = {
	jobId: number;
	id: number;
	submittedDt: string;
	status: keyof typeof ClaimStatus;
	errorMessage?: string;
	referenceNumber?: string;
	customerName?: string;
	vehicle?: string;
	insurerName?: string;
	insurerPhone?: string;
	archived: boolean;
};

export type ClaimRequestParams = {
	pageSize: number;
	page: number;
	'orderBy.field': string;
	'orderBy.desc': boolean;
	shopId?: number;
	status?: ClaimStatus;
	id?: number;
	jobId?: number;
	referenceNumber?: string;
	insurerName?: string;
	insurerPhone?: string;
	customerName?: string;
	vehicle?: string;
	submittedDt?: string;
	archived?: string;
};

export type ClaimResponse<T> = {
	claim: T[];
	totalCount: number;
};

export type ArchiveResponse = {
	result: 'success';
};

export type Claim = Pick<
	ClaimRaw,
	| 'archived'
	| 'id'
	| 'jobId'
	| 'submittedDt'
	| 'customerName'
	| 'referenceNumber'
	| 'vehicle'
> & { status: ClaimStatus };

export type CreateClaimRequest = Pick<
	ClaimDetails,
	| 'ackDetails'
	| 'additionalInfo'
	| 'agent'
	| 'customer'
	| 'damageInfo'
	| 'insurance'
	| 'jobId'
	| 'providerName'
	| 'vehicle'
>;

export type CreateClaimResponse = {
	id: number;
};
