const colors = {
	azureBlue: '#2898ff',
	skyBlue: '#299fd7',
	airForceBlue: '#337ab7',
	royalPurple: '#805ad5',
	crimsonRed: '#dc3545',
	goldOrange: '#f0ad4e',
	jadeGreen: '#54a955',
	teaGreen: '#cadfc9',
	supplementalInvoiceGreen: '#387039'
};

export const jobStatusConfig = {
	invoice: {
		label: 'Invoice',
		color: colors.jadeGreen,
		supplementalColor: colors.supplementalInvoiceGreen,
		dateKey: 'invoice_date',
		messageKey: 'customer_invoice_message'
	},
	workOrder: {
		label: 'Work Order',
		color: colors.airForceBlue,
		dateKey: 'work_order_date',
		messageKey: 'customer_workorder_message'
	},
	void: {
		label: 'Void',
		color: colors.crimsonRed,
		dateKey: 'void_date'
	},
	quote: {
		label: 'Quote',
		color: colors.royalPurple,
		dateKey: 'quote_date',
		messageKey: 'customer_quote_message'
	},
	warranty: {
		label: 'Warranty',
		color: colors.goldOrange,
		dateKey: 'warranty_date'
	},
	warrantyComplete: {
		label: 'W. Complete',
		color: colors.goldOrange,
		dateKey: 'warranty_complete_date'
	},
	creditMemo: {
		label: 'Credit Memo',
		color: colors.teaGreen,
		dateKey: 'credit_memo_date'
	},
	draft: {
		label: 'Draft',
		color: '#414141'
	},
	salesidejob: {
		label: 'Mobile Job',
		color: 'orange'
	},
	recal: {
		label: 'Recal',
		color: '#9834eb',
		dateKey: 'recal_date'
	},
	recalComplete: {
		label: 'Recal Complete',
		color: '#9834eb',
		dateKey: 'recal_complete_date'
	},
	callBack: {
		label: 'Call Back',
		color: '#34cdeb',
		dateKey: 'call_back_date'
	},
	callBackComplete: {
		label: 'Call Back Complete',
		color: '#34cdeb',
		dateKey: 'call_back_complete_date'
	},
	rAndI: {
		label: 'R & I',
		color: '#04cc94',
		dateKey: 'r_and_i_date'
	},
	rAndIComplete: {
		label: 'R & I Complete',
		color: '#04cc94',
		dateKey: 'r_and_i_complete_date'
	},
	tint: {
		label: 'Tint',
		color: '#735130',
		dateKey: 'tint_date'
	},
	tintComplete: {
		label: 'Tint Complete',
		color: '#735130',
		dateKey: 'tint_complete_date'
	},
	pickUp: {
		label: 'Pick Up',
		color: '#004646',
		dateKey: 'pick_up_date'
	},
	pickUpComplete: {
		label: 'Pick Up Complete',
		color: '#004646',
		dateKey: 'pick_up_complete_date'
	},
	dropOff: {
		label: 'Drop Off',
		color: '#3a0046',
		dateKey: 'drop_off_date'
	},
	dropOffComplete: {
		label: 'Drop Off Complete',
		color: '#3a0046',
		dateKey: 'drop_off_complete_date'
	}
};
