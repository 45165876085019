export enum DocumentType {
	swo = 'swo',
	dis = 'dis',
	pop = 'pop',
	fiv = 'fiv',
	quo = 'quo',
	dl = 'dl',
	ssmpic = 'ssmpic',
	tsmpic = 'tsmpic',
	ssign = 'ssign',
	tsign = 'tsign',
	pic = 'pic',
	app = 'app',
	auth = 'auth',
	recal = 'recal',
	esign = 'esign',
	smssign = 'smssign',
	payment = 'payment',
	oth = 'oth',
	tsmcnp = 'tsmcnp'
}
