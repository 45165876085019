<template>
	<AgingHeader :shopId="shopId" />
	<div style="padding-left: 3rem; padding-right: 3rem">
		<OutstandingBalances
			grandTotals
			:groupBy="groupBy"
			:ninetyPlusBalance="ninetyPlusBalance"
			:sixtyNinetyBalance="sixtyNinetyBalance"
			:thirtySixtyBalance="thirtySixtyBalance"
			:totalBalance="totalBalance"
			:zeroThirtyBalance="zeroThirtyBalance"
		/>
	</div>
	<template v-if="groupBy === 'age'">
		<InvoicesSection
			v-if="ninetyPlus && ninetyPlus.invoices.length"
			:data="ninetyPlus"
			:expandPayments="expandPayments"
			:groupBy="groupBy"
			name="91+ days"
			:shopId="shopId"
		/>
		<InvoicesSection
			v-if="sixtyNinety && sixtyNinety.invoices.length"
			:data="sixtyNinety"
			:expandPayments="expandPayments"
			:groupBy="groupBy"
			name="61 - 90 days"
			:shopId="shopId"
		/>
		<InvoicesSection
			v-if="thirtySixty && thirtySixty.invoices.length"
			:data="thirtySixty"
			:expandPayments="expandPayments"
			:groupBy="groupBy"
			name="31 - 60 days"
			:shopId="shopId"
		/>
		<InvoicesSection
			v-if="zeroThirty && zeroThirty.invoices.length"
			:data="zeroThirty"
			:expandPayments="expandPayments"
			:groupBy="groupBy"
			name="Under 31 days"
			:shopId="shopId"
		/>
	</template>
	<template v-if="groupBy === 'consumer'">
		<InvoicesSection
			v-for="(item, key) in data?.consumers"
			:key="key"
			:data="item"
			:expandPayments="expandPayments"
			:groupBy="groupBy"
			:name="item.name"
			:shopId="shopId"
		/>
	</template>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import AgingHeader from './AgingReport/AgingHeader.vue';
import InvoicesSection from './AgingReport/InvoicesSection.vue';
import OutstandingBalances from './AgingReport/OutstandingBalances.vue';

const props = defineProps<{
	data?: any;
	shopId: number;
	groupBy: string;
	expandPayments: boolean;
}>();

const ninetyPlus = computed(() => props.data?.categories.ninety_plus);
const sixtyNinety = computed(() => props.data?.categories.sixty_ninety);
const thirtySixty = computed(() => props.data?.categories.thirty_sixty);
const zeroThirty = computed(() => props.data?.categories.zero_thirty);
const totalBalance = computed(() => props.data?.balance);
const ninetyPlusBalance = computed(
	() => props.data?.categories.ninety_plus.balance
);
const sixtyNinetyBalance = computed(
	() => props.data?.categories.sixty_ninety.balance
);
const thirtySixtyBalance = computed(
	() => props.data?.categories.thirty_sixty.balance
);
const zeroThirtyBalance = computed(
	() => props.data?.categories.zero_thirty.balance
);
</script>
