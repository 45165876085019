<template>
	<div>
		<Button
			v-tooltip.top="openButtonLabel ? openButtonLabel : t('addNewTag')"
			class="w-full md:w-14rem"
			:icon="openButtonIcon ? openButtonIcon : 'pi pi-plus-circle'"
			:loading="isLoading"
			text
			@click="showModal = true"
		/>
		<TagsDialog
			v-model:visible="showModal"
			:currentTagsList="currentTagsList"
			:dialogTitle="dialogTitle"
			:isLoading="isLoading"
			:jobID="jobID"
			:tagsList="tagsList"
			:updateLabel="updateLabel"
			@update-tags="
				v => {
					emit('updateTags', v);
					showModal = false;
				}
			"
		/>
	</div>
</template>

<script lang="ts" setup>
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';

import { JobTag } from '@/entities/job/model/types';
import TagsDialog from '@/features/tagsDialog/TagsDialog.vue';

const showModal = defineModel<boolean>('visible');

const { t } = useI18n();

withDefaults(
	defineProps<{
		jobID: number;
		tagsList: JobTag[];
		currentTagsList: JobTag[];
		isLoading: boolean;
		openButtonLabel?: string;
		openButtonIcon?: string;
		dialogTitle?: string;
		updateLabel?: string;
	}>(),
	{
		jobID: 0,
		tagsList: () => [],
		currentTagsList: () => [],
		isLoading: false,
		dialogTitle: undefined,
		updateLabel: undefined,
		openButtonLabel: undefined,
		openButtonIcon: undefined
	}
);

const emit = defineEmits<{
	(e: 'updateTags', tags: JobTag[]): void;
}>();
</script>
