<script setup lang="ts">
import Button from 'primevue/button';
import Popover from 'primevue/popover';
import { ref } from 'vue';

defineProps<{ name: string; label: string }>();

const popup = ref<any>();

const toggle = (event: MouseEvent) => {
	popup.value.toggle(event);
};
</script>

<template>
	<div class="tw3-flex tw3-justify-center">
		<Button
			:aria-controls="`overlay_popover_${name}`"
			:label="label"
			rounded
			severity="secondary"
			small
			style="padding: 0.25rem 0.5rem; font-size: 0.8rem; white-space: nowrap"
			text
			@click="toggle"
		/>
		<Popover
			:id="`overlay_popover_${name}`"
			ref="popup"
			class="tw3-max-w-72 tw3-max-h-96 tw3-overflow-auto"
		>
			<slot />
		</Popover>
	</div>
</template>
