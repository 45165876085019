import { getFileByURL } from '../api/file';
import { documentsConfig } from '../constants/file';

export type FileInfoPreview = {
	fileKey: keyof typeof documentsConfig;
	type: string;
	size: number;
	name: string;
	date: string;
};

export const getFilePreviewInfo = async (
	fileUrl: string,
	fileSettingsKey: keyof typeof documentsConfig
): Promise<FileInfoPreview> => {
	const response = await getFileByURL(fileUrl || '');

	return {
		fileKey: fileSettingsKey,
		type: response['content-type'],
		size: response['content-length'],
		name: response['x-file-name'],
		date: response['date']
	};
};

export const openFilePreview = (
	fileUrl: string,
	fileSettingsKey: keyof typeof documentsConfig
) => {
	const newEvent = new CustomEvent('openFilePreview', {
		detail: {
			fileUrl: fileUrl,
			fileSettingsKey: fileSettingsKey
		}
	});
	window.dispatchEvent(newEvent);
};
