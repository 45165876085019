export const prepForExcelExport = (params: {
	type: string;
	data: any;
	mode: string;
}) => {
	let role = '';
	let dateType = 'Date';
	const excelData: any = [];
	let sortBy = 'Third Party';

	if (params.type === 'technicians') {
		role = 'Tech';
		params.data.forEach((item: any) => {
			const jobIdStatus = item.invoice_num.split(' ');
			excelData.push({
				Shop: item.shop_name,
				Tech: item?.job_techs?.map((t: any) => t.name)?.join(', ') || '',
				Date: item.date,
				Invoice: jobIdStatus[0],
				Status: jobIdStatus[1],
				Customer: item.customer_name,
				Referral: item.referral_number,
				Commission: item.total_tech_commission
			});
		});
	} else if (params.type === 'salesreps') {
		role = 'Sales Rep';
		params.data.forEach((item: any) => {
			const jobIdStatus = item.invoice_num.split(' ');
			excelData.push({
				Shop: item.shop_name,
				'Sales Rep':
					item?.job_salesreps?.map((s: any) => s.name)?.join(', ') || '',
				Date: item.date,
				Invoice: jobIdStatus[0],
				Status: jobIdStatus[1],
				Customer: item.customer_name,
				Referral: item.referral_number,
				Commission: item.total_sales_rep_commission
			});
		});
	} else if (params.type === 'salesources') {
		role = 'Sales Source';
		params.data.forEach((item: any) => {
			const jobIdStatus = item.invoice_num.split(' ');
			excelData.push({
				Shop: item.shop_name,
				'Sales Source':
					item?.job_salesources?.map((s: any) => s.name)?.join(', ') || '',
				Date: item.date,
				Invoice: jobIdStatus[0],
				Status: jobIdStatus[1],
				Customer: item.customer_name,
				Referral: item.referral_number,
				Commission: item.total_sale_source_commission
			});
		});
	} else if (params.type === 'locations') {
		role = 'Location';
		params.data.forEach((item: any) => {
			const jobIdStatus = item.invoice_num.split(' ');
			excelData.push({
				Shop: item.shop_name,
				Location:
					item?.job_locations?.map((l: any) => l.name)?.join(', ') || '',
				Date: item.date,
				Invoice: jobIdStatus[0],
				Status: jobIdStatus[1],
				Customer: item.customer_name,
				Referral: item.referral_number,
				Commission: item.total_location_commission
			});
		});
	} else if (params.type === 'tech-collections') {
		role = 'Tech';
		dateType = 'Inv. Date';
		params.data.forEach((item: any) => {
			const jobIdStatus = item.invoice_num.split(' ');
			excelData.push({
				Shop: item.shop_name,
				Tech: item?.job_techs?.map((t: any) => t.name)?.join(', ') || '',
				'Inv. Date': item.invoice_date,
				'Ins. Date': item.install_date,
				Invoice: jobIdStatus[0],
				Status: jobIdStatus[1],
				Customer: item.customer_name,
				Referral: item.referral_number,
				Commission: item.total_tech_commission
			});
		});
	} else if (params.type === 'csrs') {
		role = 'CSR';
		params.data.forEach((item: any) => {
			const jobIdStatus = item.invoice_num.split(' ');
			excelData.push({
				Shop: item.shop_name,
				CSR: item?.job_csrs?.map((c: any) => c.name)?.join(', ') || '',
				Date: item.date,
				Invoice: jobIdStatus[0],
				Status: jobIdStatus[1],
				Customer: item.customer_name,
				Referral: item.referral_number,
				Commission: item.total_csr_commission || 0
			});
		});
	} else if (params.type === 'aging') {
		role = 'Days';
		dateType = 'Invoice Date';
		if (params.mode === 'By customer') {
			sortBy = 'Customer';
		}
		Object.values(params.data.categories).forEach((category: any) => {
			category.forEach((item: any) => {
				if (item.invoice_number !== 'Total') {
					excelData.push({
						'Invoice Date': item.invoice_date,
						'Invoice #': item.invoice_number,
						'Referral #': item.referral_number,
						Customer: item.customerName,
						'Third Party': item.insurancefleet_name,
						Days: item.diffDays,
						'Total Invoice': item.total_after_taxes,
						'Balance Due': item.total_balance_after_payments
					});
				}
			});
		});
	}

	const sortData = (data: any[], role: string, dateType: string) => {
		return data.sort(
			(a, b) =>
				a.Shop.localeCompare(b.Shop) ||
				(a[role] ? a[role].localeCompare(b[role]) : 0) ||
				new Date(a[dateType]).getTime() - new Date(b[dateType]).getTime()
		);
	};

	const sortAgingData = (data: any[], sortBy: string) => {
		return data.sort((a, b) =>
			typeof a[sortBy] === 'string'
				? a[sortBy].localeCompare(b[sortBy])
				: a[sortBy] - b[sortBy]
		);
	};

	let sortedData = [];

	if (params.type !== 'aging') {
		sortedData = sortData(excelData, role, dateType);
	} else {
		sortedData = sortAgingData(excelData, sortBy);
	}

	return sortedData;
};
