<script setup lang="ts">
import { useRouter } from 'vue-router';

defineProps<{ tabs: { label: string }[] }>();

const router = useRouter();
</script>

<template>
	<nav class="layout-breadcrumb tw3-text-gray-500 tw3-overflow-auto">
		<ol>
			<i
				class="pi pi-home tw3-cursor-pointer hover:tw3-opacity-60 tw3-transition-all"
				@click="router.push('/')"
			/>
			<li class="layout-breadcrumb-chevron">/</li>
			<template v-for="(breadcrumbRoute, i) in tabs" :key="breadcrumbRoute">
				<li class="tw3-truncate">{{ breadcrumbRoute.label }}</li>
				<li v-if="i !== tabs.length - 1" class="layout-breadcrumb-chevron">
					/
				</li>
			</template>
		</ol>
	</nav>
</template>

<style lang="scss" scoped>
.layout-breadcrumb {
	flex-shrink: 0;

	ol {
		height: 18px;
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
		gap: 1rem;
		overflow: auto;

		li {
			font-weight: 600;
		}
	}
}
</style>
