<script setup lang="ts">
import { useStorage } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { permissionCheck, permissions } from '@/entities/user/api/util';
import { useUserStore } from '@/entities/user/store';
import { useMessages } from '@/shared/composables';
import GbDefaultBreadcrumb from '@/shared/ui/GbDefaultBreadcrumb.vue';

import { DEFAULT_DASHBOARD_KEY } from './config/constants';
import { DashboardType } from './model/enum';
import DefaultDashboardDialog from './ui/DefaultDashboardDialog.vue';
import RevenueDashboard from './ui/RevenueDashboard.vue';
import TechDashboard from './ui/TechDashboard.vue';

const { t } = useI18n();

const { showSuccess } = useMessages();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const graphPermission = computed(() =>
	permissionCheck(permissions.GRAPH, user.value)
);

const selectDialog = ref(false);
const defaultDashboard = useStorage<DashboardType>(
	DEFAULT_DASHBOARD_KEY + '_' + user.value.user.id,
	DashboardType.revenue
);
const selectedDashboard = ref(
	graphPermission.value ? defaultDashboard.value : DashboardType.tech
);
const updateDashboardByPermission = () => {
	if (
		!graphPermission.value &&
		defaultDashboard.value !== DashboardType.revenue
	) {
		defaultDashboard.value = DashboardType.revenue;
	}
};

const tabs = computed(() => [
	{ label: t('dashboard.index') },
	{
		label:
			selectedDashboard.value === DashboardType.revenue
				? t('revenue')
				: t('technicians')
	}
]);
const menu = ref();
const menuItems = computed(() => [
	{
		label: t('dashboard.index'),
		items: [
			{
				label: t('technicians'),
				icon: 'pi pi-user',
				active: defaultDashboard.value === DashboardType.tech,
				command: () => (selectedDashboard.value = DashboardType.tech)
			},
			...(graphPermission.value
				? [
						{
							label: t('revenue'),
							icon: 'pi pi-dollar',
							active: defaultDashboard.value === DashboardType.revenue,
							command: () => (selectedDashboard.value = DashboardType.revenue)
						},
						{
							label: t('selectDefault'),
							command: () => (selectDialog.value = true)
						}
					]
				: [])
		]
	}
]);

const toggle = (event: MouseEvent) => {
	menu.value.toggle(event);
};

const changeDefault = (v: DashboardType) => {
	defaultDashboard.value = v;
	showSuccess(t('dashboard.defaultChanged'));
};

onMounted(updateDashboardByPermission);
watch(() => graphPermission.value, updateDashboardByPermission);
</script>

<template>
	<div>
		<DefaultDashboardDialog
			v-model:show="selectDialog"
			:selected="defaultDashboard"
			@change-default="changeDefault"
		/>
		<div
			class="tw3-max-w-[1440px] tw3-mx-auto tw3-w-full tw3-flex tw3-justify-between tw3-mb-[1rem]"
		>
			<GbDefaultBreadcrumb :tabs="tabs" />
			<div class="tw3-flex tw3-justify-center tw3-mt-[-8px]">
				<Button
					v-if="graphPermission"
					aria-controls="dashboard_overlay_menu"
					aria-haspopup="true"
					icon="pi pi-cog"
					type="button"
					@click="toggle"
				/>
				<Menu
					v-if="graphPermission"
					id="dashboard_overlay_menu"
					ref="menu"
					:model="menuItems"
					popup
				>
					<template #item="{ item, props }">
						<div
							v-ripple
							class="tw3-flex tw3-items-center"
							v-bind="props.action"
						>
							<span v-if="item.icon" :class="item.icon" />
							<span>{{ item.label }}</span>
							<span
								v-if="item.active"
								class="tw3-ml-auto tw3-text-xs tw3-py-1 tw3-px-2 tw3-bg-gray-200 tw3-rounded-xl"
							>
								Default
							</span>
						</div>
					</template>
				</Menu>
			</div>
		</div>
		<RevenueDashboard v-if="selectedDashboard === DashboardType.revenue" />
		<TechDashboard v-if="selectedDashboard === DashboardType.tech" />
	</div>
</template>
