<script setup lang="ts">
import { FilterMatchMode } from '@primevue/core';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import Tag from 'primevue/tag';
import { computed, ref } from 'vue';

import {
	JobFilterTag,
	JobFilterTax
} from '../../model/types/job-filters.interface';

type DateFilter = {
	val?: { val_1?: string[] | null; val_2?: string[] | null } | null;
	type: string;
};

const props = defineProps<{
	selectedShopsById?: number[] | null;
	selectedShopsByName?: number[] | null;
	multiselectOptions: JobFilterTag[] | JobFilterTax[];
	modelValue: DateFilter;
	name: string;
}>();
const emit = defineEmits<{ (e: 'update:modelValue', v: DateFilter): void }>();

const options = ref([
	{ name: 'All', id: FilterMatchMode.EQUALS },
	{ name: 'All Tags', id: FilterMatchMode.BETWEEN },
	{ name: 'Selected Shops Tags', id: FilterMatchMode.IN }
]);

const multiselectOptions = computed(() => {
	if (props.modelValue.type !== FilterMatchMode.IN) {
		return props.multiselectOptions;
	}

	if (!props.selectedShopsById?.length && !props.selectedShopsByName?.length) {
		return [];
	}

	const shopIds = new Set([
		...(props.selectedShopsById ? props.selectedShopsById : []),
		...(props.selectedShopsByName ? props.selectedShopsByName : [])
	]);

	const filteredTags = [];
	for (const option of props.multiselectOptions) {
		const tagShopIds = option.shop_ids.split(',');
		for (const shopId of shopIds) {
			for (const tagShopId of tagShopIds) {
				if (tagShopId + '' === shopId + '') {
					filteredTags.push(option);
					continue;
				}
			}
		}
	}

	return filteredTags;
});

const updateMatchMode = (v: string) => {
	emit('update:modelValue', { type: v, val: null });
};

const updateHas = (v: string[]) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_2
				? {
						val_1: v ? v : null,
						val_2: props.modelValue.val?.val_2
					}
				: null
	});
};

const updateDoesNotHave = (v: string[]) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.val_1
				? {
						val_1: props.modelValue.val?.val_1,
						val_2: v ? v : null
					}
				: null
	});
};
</script>

<template>
	<Select
		appendTo="self"
		class="p-column-filter"
		:modelValue="modelValue.type"
		optionLabel="name"
		:options="options"
		optionValue="id"
		@update:model-value="updateMatchMode"
	/>
	<MultiSelect
		v-if="modelValue.type !== FilterMatchMode.EQUALS"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="1"
		:modelValue="props.modelValue.val?.val_1"
		optionLabel="name"
		:options="multiselectOptions"
		optionValue="id"
		:placeholder="`Has ${name}`"
		showClear
		@update:model-value="updateHas"
	>
		<template #option="{ option }">
			<Tag
				severity="secondary"
				:style="{
					'border-left': option.color ? '10px solid black' : '',
					'border-color': option.color ?? 'var(--p-tag-secondary-background)'
				}"
			>
				<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
					<span style="white-space: nowrap">{{ option.name }}</span>
				</div>
			</Tag>
		</template>
	</MultiSelect>
	<MultiSelect
		v-if="modelValue.type !== FilterMatchMode.EQUALS"
		appendTo="self"
		class="p-column-filter"
		filter
		:maxSelectedLabels="1"
		:modelValue="props.modelValue.val?.val_2"
		optionLabel="name"
		:options="multiselectOptions"
		optionValue="id"
		:placeholder="`Does Not Have ${name}`"
		showClear
		@update:model-value="updateDoesNotHave"
	>
		<template #option="{ option }">
			<Tag
				severity="secondary"
				:style="{
					'border-left': option.color ? '10px solid black' : '',
					'border-color': option.color ?? 'var(--p-tag-secondary-background)'
				}"
			>
				<div class="tw3-flex tw3-items-center tw3-gap-2 tw3-capitalize">
					<span style="white-space: nowrap">{{ option.name }}</span>
				</div>
			</Tag>
		</template>
	</MultiSelect>
</template>
