<template>
	<table class="scheduler-report-table">
		<tbody>
			<template v-for="(tableData, shop) in dataSplitedByShop" :key="shop">
				<tr class="table-shop-header">
					<td class="cell" :colspan="currentColumns.length + 3">{{ shop }}</td>
				</tr>
				<template
					v-for="(tech_data, tech) in splitByTech(tableData)"
					:key="shop + tech"
				>
					<tr class="table-tech-header">
						<td class="fake-cell" />
						<td class="cell" :colspan="currentColumns.length + 2">
							{{ tech && tech !== 'null' ? tech : 'UNASSIGNED' }}
						</td>
					</tr>
					<template
						v-for="(date_data, date) in splitReportDataByAttr('date')(
							tech_data
						)"
						:key="shop + tech + date"
					>
						<tr class="table-date-header">
							<td class="fake-cell" />
							<td class="fake-cell" />
							<td class="cell" :colspan="currentColumns.length + 2">
								{{ date }}
							</td>
						</tr>
						<tr class="table-head">
							<td class="fake-cell" />
							<td class="fake-cell" />
							<td class="fake-cell" />
							<td v-for="col in currentColumns" :key="col.prop" class="cell">
								{{ col.label }}
							</td>
						</tr>
						<tr
							v-for="row in date_data"
							:key="shop + tech + date + 1 + row.id"
							class="table-row"
						>
							<td class="fake-cell" />
							<td class="fake-cell" />
							<td class="fake-cell" />
							<td
								v-for="col in currentColumns"
								:key="col.prop"
								class="cell"
								:class="{ wrap: col.wrap }"
							>
								<span v-if="col.prop === 'vehicle'">
									{{ row[col.prop] }} {{ row.vin }}
								</span>
								<template v-else>
									<a
										v-if="col.link"
										:id="row.id"
										class="tw3-whitespace-nowrap"
										:href="`/jobs/${row.id}`"
									>
										{{ row[col.prop] }}
									</a>
									<div
										v-else
										:id="row.id"
										:class="{
											'tw3-whitespace-nowrap': col.prop === 'time',
											'tw3-text-right': col.dollar
										}"
										v-html="
											col.dollar
												? reportsGetters.formatNumber(row[col.prop], {
														style: 'dollar'
													})
												: row[col.prop]
										"
									></div>
								</template>
							</td>
						</tr>
						<tr class="row-spacer-date">
							<td class="fake-cell" />
							<td class="fake-cell" />
							<td class="fake-cell" />
							<td class="cell" :colspan="currentColumns.length" />
						</tr>
					</template>
					<tr class="row-spacer-tech">
						<td class="fake-cell" />
						<td class="fake-cell" />
						<td class="cell" :colspan="currentColumns.length + 1" />
					</tr>
				</template>
				<tr class="row-spacer-shop">
					<td class="fake-cell" />
					<td class="cell" :colspan="currentColumns.length + 2" />
				</tr>
			</template>
		</tbody>
	</table>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import useReportsStore from '@/widgets/reportsElements/model/store';
import {
	reportsGetters,
	splitReportDataByAttr
} from '@/widgets/reportsElements/model/utils/getters';

const props = defineProps<{
	data: any[];
}>();

const reportStore = useReportsStore();
const { currentColumns } = storeToRefs(reportStore);
const dataSplitedByShop = computed(() => {
	return splitReportDataByAttr('shop_name')(props.data);
});

const splitByTech = (data: any[]) => {
	const newData = [];
	for (let i = 0; i < data.length; i++) {
		if (data[i].job_techs?.length) {
			data[i].job_techs.map(item => {
				newData.push({ ...data[i], tech: item.name });
			});
		} else {
			newData.push({ ...data[i], tech: 'unassigned' });
		}
	}
	const val = splitReportDataByAttr('tech')(newData);
	return val;
};
</script>

<style scoped>
.scheduler-report-table {
	width: 100%;
}

.scheduler-report-table .cell.wrap {
	white-space: normal;
	max-width: 400px;
}

.scheduler-report-table .table-shop-header > .cell {
	background: #d1ffd0;
	color: #00a736;
	font-weight: 600;
}

.scheduler-report-table .table-tech-header > .cell {
	background: #d0e5ff;
	color: #3e94ff;
	font-weight: 600;
}

.scheduler-report-table .table-date-header > .cell {
	background: #d2d2d2;
	color: #5f5f5f;
	font-weight: 600;
}

.table-head {
	white-space: nowrap;
}

.scheduler-report-table .table-head > .cell {
	font-weight: 600;
	border-width: 0 1px 0 0;
}

.scheduler-report-table .table-row .cell {
	background: #fff;
	border-width: 1px 1px 0 0;
}

.scheduler-report-table .table-row .fake-cell:nth-child(3),
.scheduler-report-table .table-head .fake-cell:nth-child(3) {
	border-right: 2px solid #5f5f5f;
}

.scheduler-report-table .table-row .fake-cell:nth-child(2),
.scheduler-report-table .table-head .fake-cell:nth-child(2),
.scheduler-report-table .table-date-header .fake-cell:nth-child(2),
.scheduler-report-table .row-spacer-date .fake-cell:nth-child(2) {
	border-right: 2px solid #3e94ff;
}

.scheduler-report-table .table-row .fake-cell:nth-child(1),
.scheduler-report-table .table-head .fake-cell:nth-child(1),
.scheduler-report-table .table-date-header .fake-cell:nth-child(1),
.scheduler-report-table .table-tech-header .fake-cell:nth-child(1),
.scheduler-report-table .row-spacer-date .fake-cell:nth-child(1),
.scheduler-report-table .row-spacer-tech .fake-cell:nth-child(1) {
	border-right: 2px solid #00a736;
}

.scheduler-report-table .row-spacer-date .cell {
	border-top: 2px solid #5f5f5f;
}

.scheduler-report-table .row-spacer-tech .cell {
	border-top: 2px solid #3e94ff;
}

.scheduler-report-table .row-spacer-shop .cell {
	border-top: 2px solid #00a736;
}
</style>
