<template>
	<Suspense>
		<Dialog
			id="vue3FilePreview"
			v-model:visible="showModal"
			appendTo="#vue3app"
			class="tw3-max-w-[900px] tw3-w-full"
			:header="`${t('filePreview.preview')} - ${documentConfig.label}`"
			modal
			:pt:content="'tw3-h-full'"
		>
			<div v-if="fileInfo">
				<div v-if="isImage" class="image">
					<img :src="fileUrl" />
				</div>
				<div v-else class="content">
					<object
						class="tw3-w-full tw3-h-6/12 tw3-min-h-[450px]"
						:data="fileUrl"
						:type="fileInfo.type"
					/>
				</div>
			</div>
			<span v-else> {{ t('filePreview.notAvailable') }}</span>
			<div v-if="fileInfo" class="details tw3-mt-5">
				<div
					v-if="fileInfo.name"
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('filePreview.fileName') }}:
					</div>
					<div class="tw3-text-lg tw3-max-w-9/12 tw3-text-right">
						{{ fileInfo.name }}
					</div>
				</div>
				<div
					v-if="fileInfo.type"
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('filePreview.fileType') }}:
					</div>
					<div class="tw3-text-lg tw3-max-w-9/12 tw3-text-right">
						{{ fileInfo.type }}
					</div>
				</div>
				<div
					v-if="documentConfig.label"
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('filePreview.docType') }}:
					</div>
					<div class="tw3-text-lg tw3-max-w-9/12 tw3-text-right">
						{{ documentConfig.label }}
					</div>
				</div>
				<div
					v-if="fileInfo.size"
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('filePreview.fileSize') }}:
					</div>
					<div class="tw3-text-lg tw3-max-w-9/12 tw3-text-right">
						{{ formatFileSize(fileInfo.size) }}
					</div>
				</div>
				<div
					v-if="fileInfo.date"
					class="tw3-flex tw3-gap-3 tw3-justify-between tw3-py-2 tw3-border-b tw3-border-gray-300 tw3-w-full"
				>
					<div
						class="tw3-text-lg tw3-font-bold tw3-text-gray-500 tw3-whitespace-nowrap"
					>
						{{ t('filePreview.created') }}:
					</div>
					<div class="tw3-text-lg tw3-max-w-9/12 tw3-text-right">
						{{ fileInfo.date }}
					</div>
				</div>
				<div
					class="tw3-flex tw3-flex-wrap tw3-mt-5 tw3-gap-3 tw3-justify-end tw3-w-full"
				>
					<Button
						as="a"
						class="!tw3-no-underline"
						:href="fileUrl"
						rel="noopener"
						severity="secondary"
						target="_blank"
					>
						{{ t('filePreview.openInNewTab') }}
					</Button>
				</div>
			</div>
		</Dialog>
	</Suspense>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { formatFileSize } from '@/shared/helpers';
import { FileInfoPreview, getFilePreviewInfo } from '@/shared/helpers/file';

import { documentsConfig } from '../constants/file';

const { t } = useI18n();
const showModal = defineModel<boolean>('show');
const props = defineProps<{
	fileUrl: string;
	fileSettingsKey: keyof typeof documentsConfig;
}>();
const fileInfo = ref<FileInfoPreview>();

onMounted(async () => {
	fileInfo.value = await getFilePreviewInfo(
		props.fileUrl,
		props.fileSettingsKey
	);
});
const isImage = computed(() => {
	if (fileInfo.value) {
		return /image/.test(fileInfo.value.type);
	} else {
		return false;
	}
});
const documentConfig = computed(() =>
	fileInfo.value
		? documentsConfig[fileInfo.value.fileKey as keyof typeof documentsConfig]
		: {
				color: 'grey',
				tag: 'UNDEFINED',
				id: 'undefined',
				label: 'Document was not defined'
			}
);
watch(props, async () => {
	fileInfo.value = await getFilePreviewInfo(
		props.fileUrl,
		props.fileSettingsKey
	);
});
</script>

<style scoped>
img {
	width: 100%;
	display: block;
	max-height: 450px;
	object-fit: contain;
}
</style>
