<script setup lang="ts">
import Card from 'primevue/card';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Address } from '@/entities/address/lib/types';
import GbDefaultBreadcrumb from '@/shared/ui/GbDefaultBreadcrumb.vue';
import AddressDialog from '@/widgets/addressDialog/AddressDialog.vue';

import { getAddress } from './api';
import VendorsList from './ui/VendorsList.vue';

const { t } = useI18n();

const tabs = [{ label: t('data') }, { label: t('vendors') }];

const address = ref<Address>();
const showAddress = ref(false);

const toggleAddress = async (id: number, addressId: number) => {
	if (address.value && address.value.id === addressId) return;
	address.value = await getAddress(id, addressId);
	showAddress.value = true;
};

const edit = async (id: string) => {
	const newEvent = new CustomEvent('openVendor', { detail: { id } });
	window.dispatchEvent(newEvent);
};
</script>

<template>
	<div
		class="tw3-w-full tw3-h-full tw3-max-w-[1440px] tw3-flex tw3-flex-col tw3-gap-[1rem] tw3-overflow-hidden tw3-mx-auto"
	>
		<GbDefaultBreadcrumb :tabs="tabs" />
		<Card
			pt:body:class="tw3-overflow-auto tw3-h-full"
			pt:content:class="tw3-overflow-auto tw3-h-full"
			pt:root:class="tw3-w-full tw3-h-full tw3-overflow-auto"
		>
			<template #content>
				<AddressDialog
					v-if="address"
					v-model:show="showAddress"
					:fullAddress="address.full_address"
					:lat="address.lat"
					:lng="address.lng"
				/>
				<VendorsList @on-edit="edit" @on-open-address="toggleAddress" />
			</template>
		</Card>
	</div>
</template>
