export const JOB_VENDOR_COLUMNS = [
	'job_csr',
	'job_salesource',
	'job_salesrep',
	'job_tech',
	'job_location'
];

export const JOB_DATE_COLUMNS = [
	'quote_date',
	'work_order_date',
	'dispatch_date',
	'invoice_date',
	'follow_up_date',
	'loss_date',
	'scheduled_date',
	'install_date',
	'last_updated',
	'warranty_date',
	'warranty_complete_date',
	'recal_date',
	'recal_complete_date',
	'call_back_date',
	'call_back_complete_date',
	'r_and_i_date',
	'r_and_i_complete_date',
	'tint_date',
	'tint_complete_date',
	'pick_up_date',
	'pick_up_complete_date',
	'drop_off_date',
	'drop_off_complete_date'
];

export const JOB_PRICE_COLUMNS = [
	'total_materials',
	'total_labor',
	'total_subtotal',
	'total_taxes',
	'total_after_taxes',
	'deductible',
	'total_balance_after_payments',
	'total_sale_source_commission',
	'total_sales_rep_commission',
	'total_tech_commission',
	'total_location_commission',
	'total_cost',
	'customer_rebate',
	'other_costs',
	'total_margin'
];

export const JOB_MULTISELECT_COLUMNS = [
	'shop.name',
	'status',
	'ediqueue.status',
	'consumer.is_commercial',
	'shop_id',
	'job_csr',
	'job_salesource',
	'job_salesrep',
	'job_tech',
	'job_location',
	'commercialaccount_name',
	'bill_to.consumer_edi.trading_partner',
	'install_context'
];
