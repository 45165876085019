import { camelCase } from 'lodash-es';

import { InstallContext } from '../model/enums';

export const jobInstallContextColor = (type: InstallContext | string) => {
	const formatted = camelCase(type) as InstallContext;

	if (!Object.values(InstallContext).includes(formatted)) {
		return '';
	}

	if (formatted === InstallContext.delivery) {
		return '#a52a2a';
	} else if (formatted === InstallContext.willCall) {
		return '#337ab7';
	} else if (formatted === InstallContext.inShop) {
		return '#805ad5';
	} else if (formatted === InstallContext.mobile) {
		return '#00897b';
	} else {
		return '';
	}
};
