<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

import { useJobEditCellStore } from '../../../model/store';

const JobEditEdi = defineAsyncComponent(() => import('./JobEditEdi.vue'));
const JobEditNotes = defineAsyncComponent(() => import('./JobEditNotes.vue'));
const TagsDialog = defineAsyncComponent(
	() => import('@/features/tagsDialog/TagsDialog.vue')
);
const JobEditDocuments = defineAsyncComponent(
	() => import('./JobEditDocuments.vue')
);

const dStore = useJobEditCellStore();
</script>

<template>
	<JobEditEdi
		:data="dStore.jobEdi.state"
		:loading="dStore.updateJobEdiLoading || dStore.jobEdi.isLoading"
		:show="dStore.editJobEdiDialog"
		@on-close="dStore.toggleJobEdiDialog()"
		@on-update="dStore.updateJobEdi"
	/>
	<JobEditNotes
		:data="dStore.jobNotes.state"
		:loading="dStore.updateJobNoteLoading || dStore.jobNotes.isLoading"
		:show="dStore.editJobNotesDialog"
		@on-close="dStore.toggleJobNotesDialog()"
		@on-create="dStore.createJobNote"
		@on-delete="dStore.deleteJobNote"
	/>
	<TagsDialog
		v-model:visible="dStore.editJobTagDialog"
		:currentTagsList="dStore.jobTags.state"
		:dialogTitle="$t('jobTags')"
		:isLoading="dStore.updateJobTagLoading || dStore.jobTags.isLoading"
		:jobID="dStore.currentJobId"
		:tagsList="dStore.shopTags"
		@update-tags="dStore.updateJobTag"
	/>
	<JobEditDocuments
		:data="dStore.jobDocuments.state"
		:loading="dStore.updateJobDocumentLoading || dStore.jobDocuments.isLoading"
		:show="dStore.editJobDocumentsDialog"
		@on-close="dStore.toggleJobDocumentsDialog()"
		@on-create="dStore.createJobDocument"
		@on-delete="dStore.deleteJobDocument"
	/>
</template>
