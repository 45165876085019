import { computed, ref, Ref } from 'vue';

export const useTableLoadingMore = (
	loading: Ref<boolean>,
	preventFetchMore: Ref<boolean>
) => {
	const items = ref<any[]>(Array.from({ length: 25 }));
	const shownItems = computed(() => {
		if (loading.value) {
			return Array.from({ length: 25 });
		} else {
			return items.value;
		}
	});

	const initCb = (v: any[]) => {
		items.value = v;

		if (!preventFetchMore.value) {
			items.value.push(...Array.from({ length: 3 }));
		}
	};

	const moreCb = (v: any[]) => {
		let newData = v;
		if (!preventFetchMore.value) {
			newData = newData.concat(Array.from({ length: 3 }));
		}
		items.value.splice(items.value.length - 3, 3, ...newData);
	};

	return { items, shownItems, initCb, moreCb };
};
