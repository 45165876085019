<template>
	<TableHead
		v-if="columns && data"
		:columns="columns"
		:displayFortyPercentHeader="displayFortyPercentHeader(data)"
		:fake="fake"
	/>
	<template v-if="columns && data">
		<TableRow
			v-for="row in data"
			:key="row.id"
			:columns="columns"
			:data="row"
			:fake="fake"
		/>
	</template>
	<TableTotals
		v-if="showTotal && columns && data"
		:columns="columns"
		:data="data"
		:fake="fake"
		:label="totalLabel"
	/>
</template>

<script setup lang="ts">
import TableHead from './TableHead.vue';
import TableRow from './TableRow.vue';
import TableTotals from './TableTotals.vue';

defineProps<{
	showTotal?: boolean;
	data: any;
	columns: any;
	fake?: number;
	totalLabel?: string;
}>();

const displayFortyPercentHeader = (
	data: {
		forty_percent?: boolean;
	}[]
) => {
	if (data && data.length) {
		if (data[0].forty_percent) {
			return true;
		}
	}
	return false;
};
</script>
