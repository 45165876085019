import { CORE_API } from '@/shared/api';

import {
	JobDocument,
	JobEdi,
	JobEdiUpdateRequest,
	JobEdiUpdateResponse
} from '../model/types';
import { JobNote, JobNoteResponse } from '../model/types/note';

const LEGACY_API = '/legacy/api/v1';
const JOB_UPDATE_CELL = (jobId: number, shopId: number) =>
	`/api/shop/${shopId}/job/${jobId}`;

export const getJobEdiqueue = async (
	jobId: number,
	shopId: number
): Promise<JobEdi> => {
	try {
		const response = await CORE_API.get<JobEdi>(
			JOB_UPDATE_CELL(jobId, shopId) + '/ediqueue'
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const updateJobEdiqueue = async (
	jobId: number,
	data: JobEdiUpdateRequest
): Promise<JobEdiUpdateResponse> => {
	try {
		const response = await CORE_API.post<JobEdiUpdateResponse>(
			LEGACY_API + '/edi/setstatus/' + jobId,
			JSON.stringify(data)
		);

		if (
			typeof response.data.invoiceStatus === 'undefined' ||
			typeof response.data.invoiceStatusDetails === 'undefined'
		) {
			throw Error('Updated status not received from server.');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getJobNote = async (
	jobId: number,
	shopId: number
): Promise<JobNoteResponse> => {
	try {
		const response = await CORE_API.get<JobNoteResponse>(
			JOB_UPDATE_CELL(jobId, shopId) + '/note'
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const createJobNoteApi = async (
	shopId: number,
	data: Partial<JobNote>
): Promise<JobNote> => {
	try {
		const response = await CORE_API.post<JobNote>(
			JOB_UPDATE_CELL(data.job_id as number, shopId) + '/note/' + data.id,
			data
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deleteJobNoteApi = async (shopId: number, data: JobNote) => {
	try {
		await CORE_API.delete(
			JOB_UPDATE_CELL(data.job_id, shopId) + '/note/' + data.id
		);
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getJobDocuments = async (
	jobId: number,
	shopId: number
): Promise<JobDocument[]> => {
	try {
		const response = await CORE_API.get<JobDocument[]>(
			JOB_UPDATE_CELL(jobId, shopId) + '/document'
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const createJobDocumentApi = async (
	shopId: number,
	data: Partial<JobDocument>
): Promise<JobNote> => {
	try {
		const response = await CORE_API.post<JobNote>(
			JOB_UPDATE_CELL(data.job_id as number, shopId) + '/document/' + data.id,
			data
		);

		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deleteJobDocumentApi = async (
	shopId: number,
	data: JobDocument
) => {
	try {
		await CORE_API.delete(
			JOB_UPDATE_CELL(data.job_id, shopId) + '/document/' + data.id
		);
	} catch (error) {
		console.error(error);
		throw error;
	}
};
