<script setup lang="ts">
import { FilterMatchMode } from '@primevue/core';
import dayjs from 'dayjs';
import DatePicker from 'primevue/datepicker';
import Select from 'primevue/select';
import { computed, ref } from 'vue';

type DateFilter = {
	val?: { date_1?: string | null; date_2?: string | null } | null;
	type: string;
};

const props = defineProps<{
	modelValue: DateFilter;
	name: string;
}>();
const emit = defineEmits<{ (e: 'update:modelValue', v: DateFilter): void }>();

const formattedDate1 = computed(() =>
	props.modelValue.val?.date_1 ? new Date(props.modelValue.val.date_1) : null
);
const formattedDate2 = computed(() =>
	props.modelValue.val?.date_2 ? new Date(props.modelValue.val.date_2) : null
);

const options = ref([
	{ name: 'Single Value', id: FilterMatchMode.EQUALS },
	{ name: 'Value Range', id: FilterMatchMode.BETWEEN }
]);

const updateMatchMode = (v: string) => {
	emit('update:modelValue', { type: v, val: null });
};

const updateDate1 = (v: Date) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.date_2
				? {
						date_1: v ? dayjs(v).format('YYYY-MM-DD') : null,
						date_2: props.modelValue.val?.date_2
					}
				: null
	});
};

const updateDate2 = (v: Date) => {
	emit('update:modelValue', {
		type: props.modelValue.type,
		val:
			v || props.modelValue.val?.date_1
				? {
						date_1: props.modelValue.val?.date_1,
						date_2: v ? dayjs(v).format('YYYY-MM-DD') : null
					}
				: null
	});
};
</script>

<template>
	<Select
		appendTo="self"
		class="p-column-filter"
		:modelValue="modelValue.type"
		optionLabel="name"
		:options="options"
		optionValue="id"
		@update:model-value="updateMatchMode"
	/>
	<DatePicker
		appendTo="self"
		class="p-column-filter"
		dateFormat="m/d/yy"
		mask="9/9/9999"
		:modelValue="formattedDate1"
		:placeholder="
			modelValue.type === FilterMatchMode.BETWEEN
				? `${name} from`
				: `Search by ${name}`
		"
		@update:model-value="updateDate1"
	/>
	<DatePicker
		v-if="modelValue.type === FilterMatchMode.BETWEEN"
		appendTo="self"
		class="p-column-filter"
		dateFormat="m/d/yy"
		mask="9/9/9999"
		:modelValue="formattedDate2"
		:placeholder="`${name} to`"
		@update:model-value="updateDate2"
	/>
</template>
